import React from 'react'
import DOMEvents from './DOMEvents'
import DOMInput from './DOMInput'
import DOMNode from './DOMNode'
import NodeTracker from './NodeTracker'
import { ToolContext } from './ToolContext'

export default class DOMDocument extends DOMNode {
  static contextType = ToolContext

  _serializeSeletionRetries = 0
  _serializeSeletionTimeoutId = null

  static nodeFilter (node) {
    return node
  }

  componentDidMount () {
    this.el = this.props.window.document

    super.componentDidMount()
    this.renderTextSelection(this.props.node.selection)
  }

  shouldComponentUpdate (nextProps) {
    // DOMNode only re-renders when the element references changes so we set the tool here
    // to ensure we pick up any updates
    if (this.props.tool !== nextProps.tool) {
      this.context.setTool(nextProps.tool)
    }

    return super.shouldComponentUpdate(nextProps)
  }

  componentDidUpdate () {
    super.componentDidUpdate()
    this.renderTextSelection(this.props.node.selection)
  }

  renderTextSelection = (selection) => {
    // Whenever the this component renders it will try to render the text selection even if the value didn't change
    // to solve this we could only run this function when the value of the selection actually changes but this breaks
    // when the user had a selection of null, the agent selects some text and then the user unselects it
    // in this scenario the user selection would not be applied as the value of the selection prop didn't change
    // As such, we save a timestamp when an agent selection has been made to prevent clearing the ranges when
    // an agent is selecting text
    if (this.el.__last_trusted_selection_ts > Date.now() - 100) {
      return
    }

    // Firefox can return null while calling getSelection https://bugzilla.mozilla.org/show_bug.cgi?id=827585
    if (!this.el.getSelection()) {
      return
    }

    this.el.__last_user_selection_ts = Date.now()

    try {
      if (selection) {
        // clear any existing selection
        this.el.getSelection().removeAllRanges()

        // firefox supports multiple selections
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Selection/rangeCount
        selection.forEach(serializedRange => {
          const { start, startOffset, end, endOffset } = serializedRange
          const range = document.createRange()

          const startNode = NodeTracker.get(start)
          const endNode = NodeTracker.get(end)

          range.setStart(startNode, startOffset)
          range.setEnd(endNode, endOffset)

          this.el.getSelection().addRange(range)
        })
      } else if (
        // skip if an input is selected on the user side
        selection !== false &&
        // or if the agent is typing on an input
        !DOMInput.isInputDirty(DOMInput.getDocumentActiveElement(this.el))
      ) {
        this.el.getSelection().removeAllRanges()
      }

      this._serializeSeletionRetries = 0
      // reset timeout if some other selection was applied in between
      clearTimeout(this._serializeSeletionTimeoutId)
      this._serializeSeletionTimeoutId = null
    } catch (err) {
      console.debug('Failed to apply selection', selection, err)
      // when the agent frame loads there's a chance that we try to serialise the selection before
      // the elements are created and the NodeTracker cache is populated. As such we retry a few times
      if (this._serializeSeletionRetries <= 3) {
        this._serializeSeletionTimeoutId = setTimeout(() => {
          this._serializeSeletionRetries += 1

          this.renderTextSelection(this.props.node.selection)
        }, 100)
      } else {
        console.warn('Exausted max retries and failed to apply selection', selection, err)
      }
    }
  }

  htmlNode = () => {
    return (this.props.node && this.props.node.childNodes &&
      this.props.node.childNodes.find(n => n.tagName === 'HTML'))
  }

  render () {
    const htmlNode = this.htmlNode()
    if (!htmlNode) return null
    return (
      <DOMEvents el={this.props.window.document}>
        <DOMNode key={this.props.node.id} node={htmlNode} base={this.props.node.url} proxy={this.props.proxy} window={this.props.window} />
      </DOMEvents>
    )
  }
}
