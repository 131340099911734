import React, { Component } from 'react'
import DOMNode from './DOMNode'

export default class DOMLink extends Component {
  static nodeFilter (node) {
    const attributes = { ...node.attributes }

    // integrity hashes should be removed as the contents
    // of the CSS is most likely modified by the proxy
    delete attributes.integrity

    return { ...node, attributes }
  }

  render () {
    if (this.props.node.attributes?.rel === 'preload') {
      if (this.props.node.attributes?.as == null || !['document', 'font', 'image', 'style'].includes(this.props.node.attributes?.as)) {
        return null
      }
    }

    // link tags that pull in localhost / file url css files get rendered as inline style tags
    // with the content sent directly from the client rather than via the proxy
    if (this.props.node.css) return <style key={this.props.node.id}>{this.props.node.css}</style>
    else return <DOMNode {...this.props} key={this.props.node.id} node={this.props.node} />
  }
}
