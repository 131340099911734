import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import ButtonComponent from './Button'
import Icon from './Icon'

const commonClasses = 'p-4'

const SettingsGroupRoot = ({ children, className, ...props }) => (
  <div className={mergeClassNames('bg-white rounded-lg shadow', className)} {...props}>
    {children}
  </div>
)

const Header = ({ children, className, ...props }) => (
  <div className={mergeClassNames('border-b-2 retina:border-b-1.5 border-b-black/15 text-md text-black/75 flex justify-between gap-x-6', commonClasses, className)} {...props}>
    {children}
  </div>
)

const HeaderTitle = ({ children, className, ...props }) => (
  <div className={mergeClassNames('relative flex flex-col', className)} {...props}>
    {children}
  </div>
)

const HelpText = ({ children, className, ...props }) => (
  <p className={mergeClassNames('text-md text-black/75', className)} {...props}>
    {children}
  </p>
)

const Body = ({ children, className, ...props }) => (
  <div className={mergeClassNames(commonClasses, className)} {...props}>
    {children}
  </div>
)

const Footer = ({ children, className, ...props }) => (
  <div className={mergeClassNames('bg-black-100', commonClasses, className)} {...props}>
    {children}
  </div>
)

const Title = ({ children, className, icon, ...props }) => (
  <div className={mergeClassNames('flex gap-x-2 items-center')}>
    {icon && <Icon type={icon} className='text-black/60 h-4' />}
    <h2 className={mergeClassNames('text-base text-black', className)} {...props}>{children}</h2>
  </div>
)

const Button = ({ children, className, ...props }) => (
  <ButtonComponent className={mergeClassNames('self-center', className)} {...props}>{children}</ButtonComponent>
)

export const SettingsGroup = Object.assign(SettingsGroupRoot, { Header, HeaderTitle, HelpText, Body, Footer, Title, Button })
