/* eslint-disable react/jsx-handler-names */
import React, { useContext } from 'react'
import DOMNode from './DOMNode'
import { ToolContext } from './ToolContext'
import { CapabilitiesContext } from './CapabilitiesContext'
import { SESSION_CAPABILITY_TYPE_KEYPRESS, SESSION_CAPABILITY_TYPE_POINTER } from '../sessions/SessionCapabilityTypes'

class DOMInput extends DOMNode {
  componentDidMount () {
    super.componentDidMount()
    this.setInputValues()
    this.updateTextSelection()

    // This will prevent the agent from being able to delete text from inputs
    // when using the "Delete" item from the context menu.
    this.el.addEventListener('beforeinput', (e) => {
      if (!this.props.capabilities[SESSION_CAPABILITY_TYPE_KEYPRESS]) {
        e.preventDefault()
      }
    })
  }

  componentDidUpdate () {
    super.componentDidUpdate()
    this.setInputValues()
    this.updateTextSelection()
  }

  updateTextSelection = () => {
    if (this.el.__last_trusted_selection_ts > Date.now() - 100) return

    // text selection is only available on inputs of type text
    // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange
    const hasTextSelectionSupport = ['text', 'textarea', 'search', 'url'].includes(this.el?.type)

    if (!hasTextSelectionSupport || (this.props.tool === 'control' && DomInputWithContext.isInputDirty(this.el))) {
      return
    }

    const { selection } = this.props.node

    // flag the current input and document that the user selection has been made
    this.el.__last_user_selection_ts = Date.now()
    this.el.ownerDocument.__last_user_selection_ts = Date.now()

    try {
      if (selection) {
        const { start, end, direction } = selection
        this.el.focus()
        this.el.setSelectionRange(start, end, direction)

        // when text is selected within an input the document element is also rendering as it
        // gets a new node object reference even though no properties seem to have changed
        // this means that if the previous document selection is null it will override whatever
        // input selection should be applied
        this.el.ownerDocument.__last_trusted_selection_ts = Date.now()
      } else if (
        // if the input is selected and not in control by the agent then reset the selection
        DomInputWithContext.getDocumentActiveElement(this.el) === this.el && !this.inputIsBlocked(true)
      ) {
        this.el.setSelectionRange(null, null)
      }
    } catch (err) {
      console.warn('Failed to select text', err)
    }
  }

  onDOMInputRef = (el) => {
    this.el = el
  }

  inputIsBlocked = () => {
    // if the active element is not the target element, then remote updates
    // should always be applied
    if (this.el !== DomInputWithContext.getDocumentActiveElement(this.el)) return false

    return DomInputWithContext.isInputDirty(this.el)
  }

  setInputValues = () => {
    try {
      if (typeof this.el.checked !== 'undefined') {
        if (typeof this.props.node.checked !== 'undefined') {
          this.el.checked = this.props.node.checked
        }
      }
      if (typeof this.props.node.value !== 'undefined' &&
        !this.inputIsBlocked()) {
        this.el.value = this.props.node.value
      }
      if (this.props.node.attributes?.type === 'file') {
        this.el.disabled = true
      }
    } catch (e) {
      console.warn(e)
    }
  }

  onInput = (e) => {
    e.target.__last_trusted_input_ts = Date.now()
  }

  onKeyUp = (e) => {
    // special case for pressing enter. Often this will clear an input
    // so we don't want to supress that change coming back from the SDK
    // exception is textareas where `enter` is part of regular text
    if (e.code === 'Enter' && e.target.tagName !== 'TEXTAREA') e.target.__last_trusted_input_ts = 0
  }

  onKeyDown = (e) => {
    // Prevent typing into the input while allowing to tab out of it
    if (!this.props.capabilities[SESSION_CAPABILITY_TYPE_KEYPRESS] && e.code !== 'Tab') {
      e.preventDefault()
    }
  }

  onBlur = () => {
    // when in control mode, we need to make sure the focus is not trapped and the agent
    // is able to control the page by changing focus and typing on other inputs
    // we set the last timestamp on the input to ensure we don't actually remove the
    // focus from the input while its being interacted
    this.el.__last_trusted_input_ts = Date.now()
    this.updateTextSelection()
  }

  onClick = (e) => {
    if (!this.props.capabilities[SESSION_CAPABILITY_TYPE_POINTER]) {
      e.preventDefault()
    }
  }

  onPaste = (e) => {
    if (!this.props.capabilities[SESSION_CAPABILITY_TYPE_KEYPRESS]) {
      e.preventDefault()
    }
  }

  onCut = (e) => {
    if (!this.props.capabilities[SESSION_CAPABILITY_TYPE_KEYPRESS]) {
      e.preventDefault()
    }
  }

  render () {
    const { node } = this.props
    const Tag = node.tagName.toLowerCase()

    return (
      <Tag
        onInput={this.onInput}
        onClick={this.onClick}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        onPaste={this.onPaste}
        onCut={this.onCut}
        onBeforeInput={this.onBeforeInput}
        ref={this.onDOMInputRef}
      />
    )
  }
}

function DomInputWithContext (props) {
  const capabilities = useContext(CapabilitiesContext)
  const tool = useContext(ToolContext)

  return <DOMInput {...props} capabilities={capabilities} tool={tool} />
}

DomInputWithContext.isInputDirty = function isInputDirty (element) {
  if (element == null) return false

  // if the agent was recently typing in this input, then drop the remote
  // updates as they would overwrite what the agent is typing
  const lastInput = element.__last_trusted_input_ts
  const delta = Date.now() - lastInput
  // allow 2.5 seconds after the agent finished typing where
  // inputs are blocked (agent typing changes come back via
  // the socket)
  return delta >= 0 && delta < 2.5 * 1000
}

DomInputWithContext.getActiveElement = function getActiveElement (element) {
  return element.activeElement?.shadowRoot ? DomInputWithContext.getActiveElement(element.activeElement.shadowRoot) : element.activeElement
}

DomInputWithContext.getDocumentActiveElement = function getDocumentActiveElement (element) {
  return DomInputWithContext.getActiveElement(element.getRootNode({ composed: true }))
}

DomInputWithContext.nodeFilter = function nodeFilter (node) {
  return node
}

export default DomInputWithContext
