import RESTObject from '../redux-rest'

export default class RedactionConfig extends RESTObject {
  static namespace () {
    return 'RedactionConfig'
  }

  static url () {
    return '/api/1/redaction/configuration'
  }
}
