import qs from 'qs'
import RESTObject from '../redux-rest'

export default class EnterpriseLicense extends RESTObject {
  static namespace () {
    return 'EnterpriseLicense'
  }

  static url (params = {}, context = {}) {
    return `/api/1/licenses/${params.id || ''}?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }
}
