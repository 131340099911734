import React, { Fragment } from 'react'
import { Listbox as HeadlessListBox, Transition } from '@headlessui/react'
import Icon from './Icon'
import mergeClassNames from '../utils/mergeClassNames'

const ListBoxRoot = ({ selected, onChange, className, buttonClassName, children }) => {
  return (
    <div className={mergeClassNames('relative w-full z-10 leading-6', className)}>
      <HeadlessListBox value={selected} onChange={onChange}>
        <HeadlessListBox.Button className={mergeClassNames('relative flex gap-x-1 items-center w-full cursor-default rounded-lg bg-white py-2 px-3 text-left focus:outline-none focus-visible:border-purple-500 focus-visible:ring-2 focus-visible:ring-black', buttonClassName)}>
          {({ open }) => (
            <>
              <Icon type={selected.icon} className='h-4 text-black/60' />
              <span className='block truncate'>{selected.title}</span>
              <span className='pointer-events-none absolute inset-y-0 end-0 flex items-center pe-2'>
                <Icon type='down-open' className={mergeClassNames('h-4', open && 'rotate-180')} />
              </span>
            </>
          )}
        </HeadlessListBox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <HeadlessListBox.Options className='absolute flex flex-col gap-y-1 mt-2 w-full rounded-lg bg-white p-2 shadow-risen ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {children}
          </HeadlessListBox.Options>
        </Transition>
      </HeadlessListBox>
    </div>
  )
}
const Option = ({ className, children, ...props }) => (
  <HeadlessListBox.Option
    className={() => mergeClassNames('cursor-default select-none py-2 px-3', className)}
    {...props}
  >
    {({ active }) => typeof children === 'function' ? children({ active }) : children}
  </HeadlessListBox.Option>
)

export const ListBox = Object.assign(ListBoxRoot, { Option })
