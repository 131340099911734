import qs from 'qs'
import URL from 'url-parse'

export default class EmbedParams {
  static parse () {
    return qs.parse(window.location.search.substr(1))
  }

  static stringify (params = this.parse()) {
    return qs.stringify(params)
  }

  // DEPRECATED DO NOT USE
  static nochrome () {
    const params = this.parse()
    return params.nochrome === 'true'
  }

  static proxy () {
    const params = this.parse()
    return params.proxy !== 'none'
  }

  static forceStart () {
    const params = this.parse()
    return params.force_start === 'true'
  }

  // DEPRECATED DO NOT USE
  static allowPopout () {
    const params = this.parse()
    return params.allow_popout !== 'false'
  }

  static popout () {
    // legacy parameter support
    if (this.allowPopout() === false) return false
    const popout = this.parse().popout
    if (popout === 'none') return false
    return true
  }

  static endAction () {
    const params = this.parse()
    return params.end_action || 'dashboard'
  }

  static seenAfter () {
    return this.parse().seen_after || new Date(Date.now() - (2 * 24 * 60 * 60 * 1000)).toISOString()
  }

  static endRedirect () {
    if (this.endAction() === 'code') return `/code?${this.stringify()}`
    return `/dashboard?${this.stringify()}`
  }

  static agentTools () {
    const tools = this.parse().agent_tools
    if (tools === 'none') return false
    else return true
  }

  static deviceControls () {
    const controls = this.parse().device_controls
    if (controls === 'none') return false
    else return true
  }

  static messages () {
    const messages = this.parse().messages
    if (messages === 'none') return false
    else return true
  }

  static navigation () {
    // legacy parameter support
    if (this.nochrome()) return false
    const navigation = this.parse().navigation
    if (navigation === 'none') return false
    else return true
  }

  static sessionDetails () {
    // legacy parameter support
    if (this.nochrome()) return false
    const details = this.parse().session_details
    if (details === 'none') return false
    else return true
  }

  static set (params) {
    const query = qs.parse(window.location.search.substr(1))
    const toSet = { ...query, ...params }
    const url = new URL(window.location)
    url.set('query', qs.stringify(toSet, { arrayFormat: 'brackets' }))
    window.history.replaceState({}, '', url.toString())
  }
}
