import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Device from './Device.state'
import useThrottle from '../../hooks/useThrottle'

const NewDeviceNotification = (props) => {
  const [devices, setDevices] = useState([])
  const [mountedAt, setMountedAt] = useState()
  const { t } = useTranslation()

  const refresh = useThrottle(async () => {
    const mostRecentInDeviceList = mostRecent(props.devices)

    if (!props.devices) return
    // no point trying if we're offline
    if (navigator.onLine === false) return
    // ignore requested right after load
    if ((Date.now() - mountedAt) < 10 * 1000) return
    if (!document.hasFocus()) return
    // no point polling for more if we are already displaying the notification
    if (newDevicesFound()) return

    const res = await Device.fetch(Device.types.GET, {}, { seen_after: new Date(mostRecentInDeviceList), ...props.filters })
    const resource = await res.json()

    if (res.status === 200) {
      setDevices(resource)
    }
  }, 10 * 1000)

  useEffect(() => {
    window.addEventListener('mousemove', refresh)

    const refreshInterval = setInterval(refresh, 30 * 1000)

    setMountedAt(Date.now())

    return () => {
      window.removeEventListener('mousemove', refresh)
      clearInterval(refreshInterval)
      refresh.cancel()
    }
  }, [refresh])

  const mostRecent = (devices) => {
    if (!devices || devices.length === 0) {
      return 0
    }

    const actives = devices.map(device => device.last_active && new Date(device.last_active).getTime())

    return Math.max(...actives)
  }

  const newDevicesFound = () => {
    if (!props.devices || !devices) {
      return false
    }

    const mostRecentInDeviceList = mostRecent(props.devices)

    return devices.filter(device => {
      return new Date(device.last_active).getTime() > mostRecentInDeviceList
    }).length > 0
  }

  if (!newDevicesFound()) return null

  return (
    <div
      className='absolute block top-0.5 end-0.5 bg-black border-2 border-white rounded-full h-3.5 w-3.5'
      title={t('New devices found.')}
    />
  )
}

export default NewDeviceNotification
