import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { getTestId } from '../utils/getTestId'
import Icon from './Icon'

export const inputClassName = 'bg-white px-3 py-2 w-full border-2 retina:border-1.5 border-black/15 rounded text-base placeholder:text-black/25 focus:outline-none'

const Input = forwardRef(({ variant = 'primary', type, icon, iconPosition = 'end', iconClassName, disabled, className, testId, ...props }, ref) => {
  const variants = {
    primary: 'hover:border-black/50 focus:border-black active:border-black'
  }
  const unifiedClassNames = mergeClassNames(variants[variant], variant !== 'plain' && inputClassName, disabled && 'opacity-50', icon && 'flex gap-x-2 items-center justify-between focus-within:!border-black', className)

  const inputTag = (
    <input
      ref={ref}
      type={type ?? 'text'}
      className={!icon ? unifiedClassNames : 'w-full focus:outline-none'}
      disabled={disabled}
      {...getTestId(testId)}
      {...props}
    />
  )

  if (!icon) {
    return inputTag
  }

  return (
    <div className={unifiedClassNames}>
      {inputTag}
      <Icon type={icon} className={mergeClassNames({ 'order-first': iconPosition === 'start', 'order-last': iconPosition === 'end' }, iconClassName)} />
    </div>
  )
})

export default Input
