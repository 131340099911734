import RESTObject from '../redux-rest'

export default class SAMLProviderConfig extends RESTObject {
  static namespace () {
    return 'SAMLProviderConfig'
  }

  static url () {
    return '/api/1/saml/configuration'
  }
}
