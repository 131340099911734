import qs from 'qs'
import RESTObject from '../redux-rest'

export default class AllowedRegions extends RESTObject {
  static namespace () {
    return 'AllowedRegions'
  }

  static url (params, context = {}) {
    return `/api/1/regions/allowed?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }
}
