import React from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'
import mergeClassNames from '../utils/mergeClassNames'

const ExpandDetailsButton = ({ expanded, setExpanded, layoutUpdating, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant='tertiary'
      className={mergeClassNames('px-2 py-3 min-w-fit leading-6 h-11 sm:h-9 sm:flex sm:ms-auto sm:max-lg:mt-auto lg:bg-transparent lg:hover:bg-grey lg:w-full lg:justify-end', expanded && 'md:bg-transparent', className)}
      onClick={() => setExpanded(!expanded)}
      {...props}
      aria-expanded={expanded}
    >
      {expanded || layoutUpdating ? t('Show less') : t('Show more')}
      <Icon type='down-open' className={mergeClassNames(expanded && 'rotate-180')} />
    </Button>
  )
}

export default ExpandDetailsButton
