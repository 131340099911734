import React from 'react'
import { SESSION_CAPABILITY_TYPE_KEYPRESS, SESSION_CAPABILITY_TYPE_POINTER } from '../sessions/SessionCapabilityTypes'
import { CapabilitiesContext } from './CapabilitiesContext'
import DOMNode from './DOMNode'

export default class DOMSelect extends DOMNode {
  static contextType = CapabilitiesContext

  static nodeFilter (node) {
    return node
  }

  handleAction = (e) => {
    if (
      e.code !== 'Tab' &&
      (
        (e.type === 'mousedown' && !this.context[SESSION_CAPABILITY_TYPE_POINTER]) ||
        (e.type === 'keydown' && !this.context[SESSION_CAPABILITY_TYPE_KEYPRESS])
      )
    ) {
      e.preventDefault()
    }
  }

  render () {
    const { node } = this.props
    const Tag = node.tagName.toLowerCase()

    return (
      <Tag
        key={node.id}
        ref={this.onDOMNodeElRef}
        onKeyDown={this.handleAction}
        onMouseDown={this.handleAction}
      >
        {node.childNodes.length && this.renderChildren(node.childNodes)}
      </Tag>
    )
  }
}
