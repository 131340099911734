import qs from 'qs'
import RESTObject from '../redux-rest'

export default class TrustedOrigins extends RESTObject {
  static namespace () {
    return 'TrustedOrigins'
  }

  static idFor (resource) {
    return resource
  }

  static url (params, context = {}) {
    return `/api/1/origins/trusted?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }
}
