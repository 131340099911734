import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from './Loading'
import { getTestId } from '../utils/getTestId'
import mergeClassNames from '../utils/mergeClassNames'
import Icon from './Icon'
import EmbedParams from '../utils/EmbedParams'

const StandardList = ({
  className,
  scrollableContentClassName,
  listContainerClassName,
  style,
  testId,
  children,
  items,
  loading,
  renderItem,
  renderEmptyList,
  onBottomReached,
  backToTop = true
}) => {
  const topRef = useRef()
  const bottomRef = useRef()
  const [showBackToTop, setShowBackToTop] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!onBottomReached || items.length === 0) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries

      if (entry.isIntersecting) {
        onBottomReached()
      }
    })

    observer.observe(bottomRef.current)

    return () => observer.disconnect()
  }, [onBottomReached, items.length])

  useEffect(() => {
    if (!backToTop) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries

      setShowBackToTop(!entry.isIntersecting)
    })

    observer.observe(topRef.current)

    return () => observer.disconnect()
  }, [backToTop])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const renderRow = (item, { index, style }) => {
    return (
      <div key={index} style={style} className='group/list-item'>
        {renderItem(item)}
      </div>
    )
  }

  const renderList = (items) => {
    return items.map((item, index) => renderRow(item, { index }))
  }

  const renderContents = (items) => {
    if (items.length === 0 && loading) {
      return <Loading className='h-full' />
    }

    if (items.length === 0) {
      return renderEmptyList()
    }

    return renderList(items)
  }

  return (
    <div
      className={mergeClassNames('relative flex flex-col h-full w-full min-h-0 min-w-0', className)}
      style={style}
      {...getTestId(testId)}
    >
      <div className={mergeClassNames('flex flex-col h-full gap-y-4 sm:gap-y-0', scrollableContentClassName)}>
        <div className='sm:mb-4 empty:mb-0' ref={topRef}>
          {children}
        </div>
        <div className={mergeClassNames('flex flex-col gap-y-4 sm:gap-y-0 group/list h-full', listContainerClassName)}>
          {renderContents(items)}
        </div>
        <div className={mergeClassNames('flex justify-center fixed start-0 top-20 z-30 w-full transition-opacity opacity-0 duration-150 ease-in-out pointer-events-none', showBackToTop && 'opacity-100 pointer-events-auto', !EmbedParams.navigation() && 'top-6')}>
          <button
            className='flex items-center gap-x-1 px-4 py-2 text-sm font-semibold text-black rounded bg-white border border-black/15 shadow-risen hover:bg-grey-dark sm:h-6 sm:px-2'
            onClick={scrollToTop}
            {...getTestId('back-to-top-button')}
          >
            {t('Back to top')}
            <Icon type='arrow-narrow-up' className='h-3' />
          </button>
        </div>
        <div ref={bottomRef} />
      </div>
    </div>
  )
}

export default StandardList
