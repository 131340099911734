import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../errors/ErrorMessage'
import LoadingScreen from '../../components//LoadingScreen'
import Login from '../auth/Login'
import SettingsMenu from './SettingsMenu'
import UserNavigation from '../navigation/UserNavigation'
import Account from './Account.state'
import User from '../users/User.state'
import EmbedParams from '../../utils/EmbedParams'
import mergeClassNames from '../../utils/mergeClassNames'
import usePageTitle from '../../hooks/usePageTitle'

const SettingsPageRoot = ({ feature, className, children }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => User.fromState(state))
  const account = useSelector(state => Account.activeAccount(state))
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(Account.actionCreators().getAccounts())
  }, [dispatch])

  if ((user.working && (!user.resource)) ||
        (account.working && (!account.resource))) {
    return <LoadingScreen />
  }

  if (!user.resource) {
    return <Login />
  }

  if (feature && !Account.hasFeature(account.resource, feature, false)) {
    return <ErrorMessage>{t('Sorry, you do not have permission to view this page.')}</ErrorMessage>
  }

  if (!account.resource) {
    return <ErrorMessage error={account.error}>{t('Sorry, something went wrong loading your account.')}</ErrorMessage>
  }

  return (
    <UserNavigation subMenu={<SettingsMenu />} hide={!EmbedParams.navigation()}>
      <div className={mergeClassNames('w-full flex flex-col gap-y-4', className)}>
        {children}
        {process.env.REACT_APP_VERSION && (
          <div className='text-right text-sm text-black/60'>
            v{process.env.REACT_APP_VERSION}
          </div>
        )}
      </div>
    </UserNavigation>
  )
}

const Title = ({ className, children, ...props }) => {
  usePageTitle(children)

  return (
    <h1 className={mergeClassNames('hidden sm:block sm:text-h-sm md:text-h-md', className)} {...props}>{children}</h1>
  )
}

const HelpText = ({ className, children, ...props }) => (
  <p className={mergeClassNames('text-md text-black/75 sm:mt-4', className)} {...props}>{children}</p>
)

const Body = ({ className, children, ...props }) => (
  <div className={mergeClassNames('flex flex-col gap-y-4', className)} {...props}>{children}</div>
)

export default Object.assign(SettingsPageRoot, { Title, HelpText, Body })
