import React from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import mergeClassNames from '../utils/mergeClassNames'

const Switch = ({ name, label, stacked, checked, onChange, disabled, readOnly, labelPosition = 'after', inline = false, className, labelClassName, children, ...props }) => {
  return (
    <HeadlessSwitch.Group>
      <div className={mergeClassNames('flex items-center gap-x-3', inline && 'inline-flex', stacked && 'flex-col gap-y-1 -mt-1 md:gap-y-2')}>
        <HeadlessSwitch
          checked={checked}
          onChange={(checked) => onChange(name, checked)}
          disabled={disabled}
          readOnly={readOnly}
          className={mergeClassNames(
            'bg-white border-2 retina:border-1.5 border-black/15 p-0 rounded-2xl inline-block relative w-12 h-6 align-middle cursor-pointer after:absolute after:w-4 after:h-4 after:rounded-full after:bg-black after:top-0.5 after:start-0.5 after:retina:top-[2.5px] after:retina:start-[3px] after:transition-[left] after:delay-100 after:ease-linear after:motion-reduce:transition-none focus-visible:ring focus-visible:ring-black/75',
            checked && 'bg-black border-black after:bg-white after:start-[calc(100%_-_18px)] after:retina:start-[calc(100%_-_18px)]',
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            className
          )}
          {...props}
        >
          <span className='sr-only'>{label}</span>
        </HeadlessSwitch>
        <div className={mergeClassNames(children && 'flex flex-col flex-1', labelPosition === 'before' ? 'order-first' : 'order-last')}>
          <HeadlessSwitch.Label
            as='span'
            className={mergeClassNames('uppercase text-label-sm text-black/75', stacked && '-mt-1', labelClassName)}
          >
            {label || name}
          </HeadlessSwitch.Label>
          {children}
        </div>
      </div>
    </HeadlessSwitch.Group>
  )
}

export default Switch
