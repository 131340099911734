import React, { Component } from 'react'
import { camelCase, mapKeys, pickBy } from 'lodash'
import NodeTracker from './NodeTracker'
import rewriteSrcSet from '../../utils/rewriteSrcSet'
import { rewriteURLs } from '../css'

export default class DOMNode extends Component {
  static registerTag (tag, component) {
    if (!this._tags) this._tags = new Map()
    this._tags.set(tag, component)
  }

  static component (tag) {
    if (!tag) return false
    const normalizedTag = tag.toUpperCase().trim()
    if (this._tags && this._tags.has(normalizedTag) && this._tags.get(normalizedTag)) {
      return this._tags.get(normalizedTag)
    }
    return false
  }

  componentDidMount () {
    this.applyRawParams()
  }

  componentWillUnmount () {
    this.deleteNodeCache()
  }

  shouldComponentUpdate (nextProps) {
    // ignore updates to contenteditables while focused and there have been recent changes
    // inputted at the agent side (we're probably still waiting to get these back from the SDK)
    // Also see the note below in componentDidUpdate()
    if (this.el?.isContentEditable && this.el.getRootNode().activeElement === this.el) {
      const interval = Date.now() - this.el.last_local_input
      return interval > 2000
    }

    const shouldUpdate = this.props.node !== nextProps.node

    if (shouldUpdate && this.props.node?.id !== nextProps.node?.id) {
      // when the node id changes we need remove the elements from the NodeTracker to prevent
      // memory leaks
      this.deleteNodeCache(nextProps.node?.childNodes)
    }

    return shouldUpdate
  }

  deleteNodeCache (newChildNodes = []) {
    try {
      const newChildNodeIds = newChildNodes.map(({ id }) => id)

      NodeTracker.delete(this.props.node.id)

      this.props.node?.childNodes.forEach(({ nodeType, id }) => {
        if (nodeType === 3 && !newChildNodeIds.includes(id)) {
          NodeTracker.delete(id)
        }
      })
    } catch (err) {
      console.warn('Failed to delete node from cache', this.props.node, err)
    }
  }

  updateTextChildNodesTracking () {
    if (this.el) {
      // Selection happens on text nodes and these get actually created
      // by react-dom and not our component tree (see the method renderChildren)
      // as such we iterate over the childnodes and store the correct id
      // for those as well
      this.props.node?.childNodes.forEach(({ nodeType, id }, idx) => {
        if (nodeType === 3) {
          if (!this.el?.childNodes?.[idx] || this.el?.childNodes[idx].nodeType !== 3) {
            return
          }
          this.setId(this.el.childNodes[idx], id)
          NodeTracker.set(id, this.el.childNodes[idx])
        }
      })
    }
  }

  componentDidUpdate () {
    this.applyRawParams()
    // clean up contenteditables
    // this is a bit funky - the nodes added by editing the agent side
    // of a contentesditable are not tracked by react. This means that
    // when the changes entered by the agent come back from the SDK they
    // are treated as new nodes, therefore we end up with duplciate content.
    // To get around around this we hacketyhacketyhackety the chidlren
    // of the contenteditable and remove any nodes that aren't being managed
    // by cobrowse directly.
    if (this.el && this.el.isContentEditable) {
      this.el.childNodes.forEach(e => {
        if (e.nodeType === 1 && !e.__cobrowse_id) {
          this.el.removeChild(e)
        }
      })
    }

    this.updateTextChildNodesTracking()
  }

  componentDidCatch (error, info) {
    console.warn('DOMNode error', error, info, this.props.node)
  }

  onDOMNodeElRef = (el) => {
    this.el = el

    if (!this.el) {
      // when an element is being removed this will become null so we skip it
      return
    }

    try {
      // if an element is redacted we want to map it to the first
      // text child instead. This is done by creating an empty text
      // node on the render function when the element is not void.
      // Void elements (which do not have children) will be selected
      // correctly even if redacted.
      if (this.props.node.redaction && this.el.childNodes?.length) {
        NodeTracker.set(this.props.node.id, this.el.childNodes[0])
        return
      }

      NodeTracker.set(this.props.node.id, this.el)

      this.updateTextChildNodesTracking()
    } catch (err) {
      console.warn('Failed to store ref for element or child node', err)
    }

    this.props.onDOMNodeElRef?.(el)
  }

  setScroll = (params) => {
    if (this.el) {
      if ((Date.now() - this.el.last_local_scroll) < 1000) return
      this.el.last_remote_scroll = Date.now()
      setTimeout(() => {
        if (this.el) {
          const scrollable = this.el.scrollingElement || this.el
          if (scrollable.scrollTo) scrollable.scrollTo(params)
          else console.warn('expected node to be scrollable', this.el)
        }
      }, 0)
    } else console.warn('tried to scroll without element', this.props.node, this)
  }

  setId = (element, id) => {
    if (element) {
      element.__cobrowse_id = id
      element.__cobrowse_node = this.props.node
    } else {
      console.warn('tried to apply id without element', this.props.node, this)
    }
  }

  proxyUrl = (url) => {
    // use elements shouldn't use the regionalised proxy as we need them
    // to be rendered from the same origin as the frontend due to CORS
    const useGlobalProxy = this.el?.tagName?.toLowerCase() === 'use'

    return this.props.proxy(this.props.base, url, useGlobalProxy)
  }

  setAttributes = (attributes) => {
    if (!attributes) return

    // backwards compatibility support for the change of prefix which
    // should be removed once we no longer support older JS SDK versions
    if (this.el && this.el.attributes.getNamedItem('__cbio_pseudo_hover')) this.el.attributes.removeNamedItem('__cbio_pseudo_hover')
    if (typeof attributes.__cbio_hover !== 'undefined') {
      attributes.__cbio_pseudo_hover = ''
    }

    if (this.el) {
      Object.keys(attributes).forEach(key => {
        let value = attributes[key]
        if (this.el.tagName === 'A' && key === 'href') value = `${'javascript'}:${'void(0)'}`
        if (key === 'srcdoc') return
        if (key.startsWith('on')) return

        if (key === 'srcset') value = rewriteSrcSet(value, this.proxyUrl)
        if (key === 'href' || key === 'src' || key === 'xlink:href') value = this.proxyUrl(value)
        if (key === 'style') value = rewriteURLs(value, this.proxyUrl)

        if (typeof value === 'undefined') return
        if (this.el.getAttribute(key) === value) return
        try {
          // SVG is very picky about case of attributes, so we force all props to camel case
          // as well as setting them as the version that comes accross the wire
          if (this.el.namespaceURI?.includes('svg')) this.el.setAttribute(camelCase(key), value)
          this.el.setAttribute(key, value)
        } catch (e) {
          console.warn(key, value, 'error', e, this.el)
        }
      })

      for (let i = 0; i < this.el.attributes.length; i += 1) {
        const attr = this.el.attributes.item(i)
        if (typeof attributes[attr.nodeName] === 'undefined') { this.el.attributes.removeNamedItem(attr.nodeName) }
      }
    } else {
      console.warn('tried to set attributes without element', this.props.node)
    }
  }

  applyRawParams = () => {
    this.setId(this.el, this.props.node.id)
    this.setAttributes(this.props.node.attributes)
    this.applyScrollPositions()
    this.applyRedaction()
  }

  applyScrollPositions = () => {
    if (this.props.node && this.props.node.scroll) {
      const scrollChangeX = this._last_scroll_x !== this.props.node.scroll.x
      const scrollChangeY = this._last_scroll_y !== this.props.node.scroll.y
      // const docChanged = this._last_document !== this.props.node.id;
      if (scrollChangeX || scrollChangeY) {
        this._last_scroll_x = this.props.node.scroll.x
        this._last_scroll_y = this.props.node.scroll.y
        this._last_document = this.props.node.id
        this.setScroll({
          left: this.props.node.scroll.x,
          top: this.props.node.scroll.y
          // behavior: docChanged ? 'auto': 'smooth'
        })
      }
    }
  }

  applyRedaction = () => {
    if (this.props.node && this.props.node.redaction) {
      const { height, width } = this.props.node.redaction
      this.el.style.width = width
      this.el.style.height = height
      this.el.style.setProperty('background-color', 'black', 'important')
      this.el.style.setProperty('border-color', 'black', 'important')
    }
  }

  renderChildren = (children) => {
    return children.map(n => {
      const SpecialTag = DOMNode.component(n.tagName)
      if (SpecialTag) return <SpecialTag {...this.props} key={n.id} node={SpecialTag.nodeFilter(n)} />

      // TODO: provide override system for nodeType based differences?
      const DOMShadowRoot = require('./DOMShadowRoot').default
      if (n.nodeType === 11) return <DOMShadowRoot {...this.props} key={n.id} node={n} />
      if (n.nodeType === 3) return n.content
      return <DOMNode {...this.props} key={n.id} node={n} onDOMNodeElRef={null} />
    })
  }

  isVoidElement = () => {
    // If an element is void then it's forbidden by the spec to have children
    // and setting an empty string as children will throw
    // See full list: https://html.spec.whatwg.org/multipage/syntax.html#void-elements
    return [
      'AREA',
      'BASE',
      'BR',
      'COL',
      'EMBED',
      'HR',
      'IMG',
      'INPUT',
      'LINK',
      'META',
      'SOURCE',
      'TRACK',
      'WBR'
    ].includes(this.props.node.tagName)
  }

  specialAttributes = (attrs) => {
    const picked = pickBy(attrs, (v, k) => {
      // some attributes don't apply properly unless specified when the node is created
      // so we have some special cases for these
      return k.startsWith('xmlns:') || k.startsWith('xlink:') || k.startsWith('xml:')
    })
    return mapKeys(picked, (v, k) => camelCase(k))
  }

  registerCustomElement = (tagName) => {
    const { window: remoteWindow } = this.props
    if (!remoteWindow) return
    try {
      if (!remoteWindow.customElements.get(tagName)) {
        remoteWindow.customElements.define(tagName, class extends remoteWindow.HTMLElement { })
      }
    } catch (err) {
      console.warn('Failed to register custom element', err)
    }
  }

  render () {
    const { node, onError, onLoad, onClick } = this.props
    if (!node.tagName) {
      console.error('node without tag name', node)
      return null
    }

    // Strip invalid characters from tag names
    let Tag = node.tagName.replace(/[^a-z0-9-]+/ig, '') || 'invalid-element-name'

    // React doesn't like tags that start with capital letters unless they're components
    if (/^[A-Z]/.test(Tag)) Tag = Tag.toLowerCase()

    // Register tags which look like custom elements to satisfy CSS pseudoselectors like :defined
    if (node.tagName.indexOf('-') > -1) this.registerCustomElement(node.tagName.toLowerCase())

    return (
      <Tag ref={this.onDOMNodeElRef} key={node.id} onError={onError} onLoad={onLoad} onClick={onClick} {...this.specialAttributes(node.attributes)}>
        {node.childNodes.length
          ? this.renderChildren(node.childNodes)
          // When an element is redacted we include a space to ensure we react creates a TEXT node (nodeType === 3)
          // This is needed to handle text selection that starts or end in a redacted element
          // If an element is void though we much return null because those do not take child elements
          : node.redaction && !this.isVoidElement() ? ' ' : null}
      </Tag>
    )
  }
}
