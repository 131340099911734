import RESTObject from '../redux-rest'

export default class SalesforceConfig extends RESTObject {
  static namespace () {
    return 'SalesforceConfig'
  }

  static url () {
    return '/api/1/salesforce/configuration'
  }
}
