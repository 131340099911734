import RESTObject from '../redux-rest'

export default class IntercomConfig extends RESTObject {
  static namespace () {
    return 'IntercomConfig'
  }

  static url () {
    return '/api/1/intercom/configuration'
  }
}
