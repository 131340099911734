import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../errors/ErrorMessage'
import LoadingScreen from '../../components/LoadingScreen'
import Session from '../sessions/Session.state'
import Device from '../devices/Device.state'
import PushNotification from '../push-notifications/PushNotification.state'
import EmbedParams from '../../utils/EmbedParams'
import { useNavigate, useParams } from 'react-router-dom'
import mergeClassNames from '../../utils/mergeClassNames'
import usePageTitle from '../../hooks/usePageTitle'
import isInIframe from '../../utils/isInIframe'
import { sendPushNotifications, cancelPushNotifications } from '../push-notifications/PushNotificationSender'

const MaxAttempts = 5

const ConnectDevice = () => {
  const [error, setError] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const push = useSelector(state => PushNotification.fromState(state))
  const session = useSelector(state => Session.fromState(state))
  const device = useSelector(state => Device.fromState(state))
  const [pushAttempts, setPushAttempts] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { device: deviceId } = useParams()
  const { t } = useTranslation()

  usePageTitle(t('Connecting to device'))

  const resetSession = useCallback(() => dispatch(Session.actionCreators().resetSession()), [dispatch])
  const resetDevice = useCallback(() => dispatch(Device.actionCreators().resetDevice()), [dispatch])
  const getDevice = useCallback((id) => dispatch(Device.actionCreators().getDevice(id)), [dispatch])
  const createSession = useCallback((data, options = {}) => dispatch(Session.actionCreators().createSession(data, {}, options)), [dispatch])
  const subscribeSession = useCallback(() => dispatch(Session.actionCreators().subscribeSession()), [dispatch])
  const endSession = useCallback((data) => dispatch(Session.actionCreators().endSession(data)), [dispatch])

  const handleCreateSession = useCallback(async (abortController) => {
    try {
      await resetSession()
      await resetDevice()

      const [region, device] = await Promise.all([
        window.fetch('/api/1/regions/closest').then(res => res.json()),
        getDevice({ id: deviceId })
      ])

      const session = await createSession({
        custom_data: device.custom_data,
        region: region.id,
        agent: 'me'
      }, { signal: abortController.signal })

      if (!session) {
        return
      }

      if (session.type !== 'Session') {
        throw new Error()
      }

      subscribeSession()

      return session
    } catch (error) {
      setError(error)
    }
  }, [deviceId, createSession, getDevice, resetDevice, resetSession, subscribeSession])

  const isPending = useCallback(() => {
    return session.resource?.state === 'pending'
  }, [session.resource?.state])

  const cancel = () => {
    if (EmbedParams.endAction() === 'none') {
      setCancelled(true)
    } else {
      navigate(EmbedParams.endRedirect())
    }

    // destroy the session if we created it already
    if (session.resource) {
      endSession(session.resource)
    }
  }

  useEffect(() => {
    const abortController = new AbortController()

    const runEffect = async () => {
      const session = await handleCreateSession(abortController)

      // This will only happen during local dev due to strict mode. The first unmount will trigger
      // the abort signal, so we won't have a session available to send push notifications to.
      if (abortController.signal.aborted) {
        return
      }

      sendPushNotifications(deviceId, session.id, MaxAttempts, (error, attempts) => {
        if (error) {
          setError(error)

          return
        }

        setPushAttempts(attempts)
      })
    }

    runEffect()

    return () => {
      abortController.abort()
      cancelPushNotifications()
    }
  }, [handleCreateSession, deviceId])

  useEffect(() => {
    if (session.resource && session.resource.state !== 'ended' && !isPending() && !cancelled) {
      navigate(`/session/${session.resource.id}${window.location.search}`, { replace: true })
    }
  }, [session.resource, isPending, cancelled, navigate])

  const renderError = (error) => {
    return (
      <ErrorMessage error={error}>
        {t('Sorry, something went wrong connecting to the device.')}
      </ErrorMessage>
    )
  }

  const renderMessage = () => {
    if (pushAttempts >= MaxAttempts) {
      return t('Device not responding')
    }

    if (pushAttempts >= MaxAttempts - 1) {
      return t('Trying once more')
    }

    if (pushAttempts > 2) {
      return t('Retrying connection')
    }

    return t('Contacting device')
  }

  const errorMessage = device.error || push.error || session.error || error

  return (
    <div className={mergeClassNames('flex items-center justify-center h-full bg-slate-800 text-slate-50', isInIframe() && 'bg-transparent')}>
      <h1 className='sr-only'>{t('Connecting to device')}</h1>
      {errorMessage && renderError(errorMessage)}
      {cancelled && <div className='container text-center'><p>{t('Connection stopped')}</p></div>}
      {!errorMessage && !cancelled && !session.resource && <LoadingScreen loading message={t('Creating session')} />}
      {!errorMessage && !cancelled && session.resource && (
        <LoadingScreen
          loading={pushAttempts < MaxAttempts}
          cancelText={t('Cancel')}
          message={renderMessage()}
          onCancel={cancel}
        />
      )}
      {/* <LoadingScreen loading message={t('Creating session')} /> */}
    </div>
  )
}

export default ConnectDevice
