import RESTObject from '../redux-rest'

export default class FirebaseConfig extends RESTObject {
  static namespace () {
    return 'FirebaseConfig'
  }

  static url () {
    return '/api/1/firebase/configuration'
  }
}
