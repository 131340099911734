import RESTObject from '../redux-rest'

export default class JWTSSOConfig extends RESTObject {
  static namespace () {
    return 'JWTSSOConfig'
  }

  static url () {
    return '/api/1/jwtsso/configuration'
  }
}
