import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const PopoverContainer = forwardRef(({ children, className, arrowClassName, show, arrowPosition = 'right', hideArrow = false, disableTransition = false, ...props }, ref) => {
  return (
    <div className={mergeClassNames('absolute drop-shadow-risen z-10', !disableTransition && 'opacity-100 transition-all motion-reduce:transition-none duration-150 ease-in-out', !show && 'opacity-0 invisible', className)} {...props} ref={ref}>
      {!hideArrow && (
        <div className={mergeClassNames('absolute -top-1 end-3.5 rotate-45 w-3 h-3 rounded-sm bg-white sm:max-md:end-2.5', arrowPosition === 'left' && 'start-3.5', arrowClassName)} />
      )}
      {children}
    </div>
  )
})

export default PopoverContainer
