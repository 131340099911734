import Debug from 'debug'

// If the document is destroyed and recreated, we'll get a new queue as a result
// and it's hard to tell what happens on one queue v.s. another instance. So
// we keep tabs on a unique queue identifier for debugging
let queueCount = 0

/**
 * A class that accepts prioritized async function instances, and ensures
 * that they are invoked in parallel and in priority order.
 */
export class PriorityScheduler {
  #ref
  #debugFn

  /**
   * The queue of items to invoke. Order of the queue is which was added
   * sooner. Ordering does not reflect priority.
   */
  #queue = []

  #willDigest = false

  constructor ({ ref = queueCount++ } = {}) {
    this.#ref = ref
    this.#debugFn = Debug(`cbio.priority.PriorityScheduler.queue.${this.#ref}`)
    this.#debug('constructor()')
  }

  /** Get the metadata associated to the specified function instance */
  get (id) {
    this.#debug('get()/start', id)
    const item = this.#queue.find((item) => item.id === id)
    const result = item && !item.isComplete ? item.ctx() : null
    this.#debug('get()/finish', id, result)
    return result
  }

  /**
   * Schedule an item to run after all higher-priority items have completed.
   *
   * If the scheduling queue is empty, this function will be invoked
   * immediately.
   *
   * If the priority of this function is equal to or higher than the currently
   * executing function with the highest priority, it will begin running
   * immediately. Lower-priority items that are already running will be allowed
   * to continue running in parallel with this item.
   *
   * If there are higher-priority items already running, this function will be
   * deferred and run automatically after all higher-priority items have
   * completed.
   *
   * If there is already a function in the queue with this id, it will be
   * cancelled, even if it is already running. Note that this does not mean the
   * actual underlying function operation will be cancelled, it will continue
   * regardless.
   *
   * @param {string} id an identifier for the item to reschedule/cancel etc...
   * @param {number} rank the numeric rank to run the item. Lower rank means higher priority (i.e., run sooner)
   * @param {function} fn the async function to "wait" for before executing lower-priority items
   */
  async schedule (id, rank, fn) {
    this.#debug('schedule()/start', id, rank)
    this.#queue.unshift(new PrioritySchedulerItem(this.#ref, id, rank, fn))
    await this.#digest()
    this.#debug('schedule()/finish', id, rank)
  }

  /**
   * Cancel any existing function instance in the queue. If the function is
   * already in running state, it will still be cancelled and lower-priority
   * items will be allowed to continue, however the actual behaviour of the
   * function won't be cancelled and will continue running outside the scope
   * of the scheduling queue.
   *
   * @param {string} id the id of the function instance to cancel
   */
  async cancel (id) {
    this.#debug('cancel()/start', id)
    const item = this.#queue.find((item) => item.id === id)
    item?.cancel()
    await this.#digest()
    this.#debug('cancel()/finish', id, item)
  }

  /** Destroy the queue, cancelling all items in the queue. */
  destroy () {
    this.#debug('destroy()/start')

    for (const item of this.#queue) {
      item.cancel()
    }

    this.#queue = []
    this.#debug('destroy()/finish')
  }

  /**
   * Run a digest cycle on the queue that:
   *
   *  * Cancels items with duplicate ids (ids that were scheduled earlier)
   *  * Removes cancelled and completed items
   *  * Invokes all functions of the highest priority that are not running yet
   */
  async #digest () {
    this.#debug('digest()/init')

    // Defer digest cycles to ensure we collect all synchronous DOM updates
    // before potentially immediately executing a bunch of lower-priority tasks
    // if they come through first
    if (this.#willDigest) {
      this.#debug('digest()/yield')
      return
    } else {
      this.#willDigest = true
      this.#debug('digest()/scheduled')
    }

    await this.#defer()
    this.#debug('digest()/start')
    this.#willDigest = false

    // First clean up duplicate and cancelled/completed items
    this.#clean()

    // Ensure all the highest priority items are in running state
    this.#queue
      .reduce((acc, item) => {
        const currRank = acc[0]?.rank ?? Number.MAX_SAFE_INTEGER
        if (item.rank < currRank) return [item]
        else if (item.rank === currRank) return [...acc, item]
        else return acc
      }, [])
      .filter((item) => !item.isRunning)
      .forEach(async (item) => {
        // If the workload completes without being cancelled externally,
        // invoke another queue cycle
        if (await item.run()) this.#digest()
      })
    this.#debug('digest()/finish')
  }

  /** Cancel duplicates and remove cancelled/completed items from the queue. */
  #clean () {
    this.#debug('clean()/start')
    this.#queue = this.#queue.reduce((acc, item) => {
      if (item.isComplete) {
        // Filter any completed items out of the array
        return acc
      }

      // If there is a more recent version of this item in the queue, cancel
      // it and filter it out. The newer one will execute eventually
      const existing = acc.find((x) => x.id === item.id)
      if (existing) {
        this.#debug('cancelling duplicate item', item.id, {
          existing: existing.ctx(),
          cancel: item.ctx()
        })

        // If this queue item has a newer one in the queue, cancel and
        // forget about it
        item.cancel()
        return acc
      }

      // Not a duplicate, leave it be, but prepend because we're working from
      // back to front
      return [item, ...acc]
    }, [])
    this.#debug('clean()/finish')
  }

  #debug (...args) {
    if (this.#debugFn.enabled) {
      this.#debugFn(
        ...args,
        this.#queue
          .map((item) => item.ctx())
          .sort((a, b) => a.rank - b.rank)
      )
    }
  }

  #defer () {
    return new Promise((resolve) => setTimeout(resolve))
  }
}

/** Helper class that holds execution status of a priority function item. */
class PrioritySchedulerItem {
  id
  rank

  #debugFn
  #fn
  #resolve

  get isRunning () {
    return !!this.#resolve
  }

  get isComplete () {
    return !this.#fn
  }

  constructor (queueRef, id, rank, fn) {
    this.id = id
    this.rank = rank
    this.#fn = fn

    this.#debugFn = Debug(`cbio.priority.PrioritySchedulerItem.queue.${queueRef}`)
    this.#debug('constructor()')
  }

  ctx () {
    return {
      id: this.id,
      isComplete: this.isComplete,
      isRunning: this.isRunning,
      rank: this.rank
    }
  }

  run () {
    this.#debug('run()')
    return new Promise((resolve) => {
      this.#resolve = resolve
      setTimeout(async () => {
        if (this.#fn) {
          try {
            const result = await this.#fn(this.rank)
            this.#debug('run()/success', result)
          } catch (err) {
            this.#debug('run()/error', err)
          }
          this.#complete(false)
        } else {
          this.#debug('run()/cancelled')
          this.#complete(true)
        }
      }, 0)
    })
  }

  cancel () {
    this.#debug('cancel()')
    this.#complete(true)
  }

  #complete (isCancelled) {
    this.#debug('complete()', { isCancelled })
    const resolve = this.#resolve
    this.#fn = null
    this.#resolve = null
    resolve?.(!isCancelled)
  }

  #debug (...args) {
    if (this.#debugFn.enabled) {
      this.#debugFn(this.id, ...args, this.ctx())
    }
  }
}
