import React, { useState } from 'react'
import DeviceInfo from '../modules/devices/DeviceInfo'
import ExpandDetailsButton from './ExpandDetailsButton'
import mergeClassNames from '../utils/mergeClassNames'
import { getTestId } from '../utils/getTestId'

const ResourceInfo = ({ resource, addFilter, actionButton, showActionButton, afterDeviceInfoContent, className, children }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const [layoutUpdating, setLayoutUpdating] = useState(false)

  return (
    <div className={mergeClassNames('relative flex justify-between gap-4 flex-wrap p-4 min-w-0 bg-white rounded-lg shadow sm:flex-nowrap sm:gap-2 lg:p-6', className)} {...getTestId('device-item')}>
      <div className='min-w-0 sm:w-full lg:flex lg:gap-5 lg:flex-grow'>
        <div className={mergeClassNames('flex flex-row items-center gap-4 flex-wrap sm:max-lg:grid sm:max-lg:grid-flow-col sm:max-lg:auto-cols-fr sm:max-lg:grid-rows-[auto_1fr] sm:max-lg:items-start sm:w-full lg:flex-nowrap', (detailsExpanded || layoutUpdating) && 'lg:flex-wrap')}>
          {/* min-w-0 is needed here for text-ellipsis to work below */}
          <div className='col-span-2 place-items-start flex min-w-0 basis-full sm:basis-auto'>
            {children}
          </div>
          <div className={mergeClassNames('col-span-2 row-span-2 flex flex-col gap-y-4 items-center justify-center w-full overflow-hidden lg:w-auto lg:justify-end lg:ms-auto', (detailsExpanded || layoutUpdating) && 'lg:order-last lg:basis-full lg:ms-0')}>
            <DeviceInfo
              device={resource.device}
              customData={resource.custom_data}
              addFilter={addFilter}
              showMore={detailsExpanded}
              layoutUpdating={layoutUpdating}
              onBeforeTransitionLeave={() => setLayoutUpdating(true)}
              onAfterTransitionLeave={() => setLayoutUpdating(false)}
            />
            {typeof afterDeviceInfoContent === 'function' ? afterDeviceInfoContent({ detailsExpanded, layoutUpdating }) : afterDeviceInfoContent}
          </div>
          <div className={mergeClassNames('row-span-3 flex w-full sm:ms-auto sm:w-auto sm:max-lg:h-full lg:order-2 lg:ms-0', (detailsExpanded || layoutUpdating) && 'lg:ms-auto')}>
            <div className={mergeClassNames('flex flex-row gap-6 sm:gap-4 w-full sm:flex-col sm:items-center sm:w-auto sm:min-w-[140px] lg:flex-row', showActionButton && 'lg:min-w-[300px]')}>
              <ExpandDetailsButton expanded={detailsExpanded} setExpanded={setDetailsExpanded} layoutUpdating={layoutUpdating} />
              <div className={mergeClassNames('h-9 border border-grey rounded-full hidden lg:block', !showActionButton && 'lg:hidden')} />
              {actionButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceInfo
