import React, { useEffect, useState } from 'react'
import moment from 'moment'

const Stopwatch = ({ start, end, live = true, className }) => {
  const [time, setTime] = useState(0)

  useEffect(() => {
    if (!live) {
      return
    }

    const interval = setInterval(() => {
      setTime((time) => time + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [live])

  const startTime = moment(start || time)
  const endTime = moment(end || Date.now())
  const ms = moment(endTime).diff(startTime)
  const delta = moment.duration(ms)

  return (
    <div className={className} role='timer'>
      {delta.hours() > 0 ? <span>{delta.hours()}<span>:</span></span> : null}
      <span>{moment.utc(ms).format('mm:ss')}</span>
    </div>
  )
}

export default Stopwatch
