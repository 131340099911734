import React from 'react'
import { useTranslation } from 'react-i18next'
import AuditEvent from './AuditEvent'
import StandardList from '../../components/StandardList'
import mergeClassNames from '../../utils/mergeClassNames'
import useThrottle from '../../hooks/useThrottle'

const AuditEventList = ({ events, refresh, className }) => {
  const { t } = useTranslation()

  const loadMore = useThrottle(() => {
    if (events.working) {
      return
    }

    refresh({ skip: events.collection.length }, { merge: true })
  }, 300)

  const renderEvent = (event) => {
    return <AuditEvent event={event} />
  }

  const renderEmptyList = () => {
    if (events.working) {
      return null
    }

    return (
      <div className='p-4'>
        <span>{t('No audit events found for your account yet.')}</span>
      </div>
    )
  }

  return (
    <StandardList
      className={mergeClassNames('', className)}
      items={events.collection}
      renderItem={renderEvent}
      renderEmptyList={renderEmptyList}
      onBottomReached={loadMore}
    />
  )
}

export default AuditEventList
