import React, { Component } from 'react'

export default class ImageScreen extends Component {
  constructor () {
    super()
    this.state = { data: null }
  }

  componentDidMount () {
    this.onFrames(this.props.display.frames)
    this.props.display.frames.on('frame', this.onFrames)
  }

  componentDidUpdate () {
    this.notifySize()
  }

  componentWillUnmount () {
    this.props.display.frames.off('frame', this.onFrames)
  }

  onFrames = (frames) => {
    frames.read('image/').forEach(frame => {
      const data = `data:${frame.mime_type};base64,${this.arrayBufferToBase64(frame.data || frame.image)}`
      this.setState({ data })
    })
  }

  onImageRef = (img) => {
    if (!img) return
    this.image = img
    if (img.complete) {
      this.notifySize()
    } else {
      img.addEventListener('load', () => {
        this.notifySize()
      }, { once: true })
    }
  }

  arrayBufferToBase64 = (buffer) => {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i += 1) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  notifySize = () => {
    if ((!this.image) || (!this.image.complete)) return
    if ((!this.image.naturalHeight) || (!this.image.naturalWidth)) return
    if (this.props.onSize) {
      this.props.onSize({
        height: this.image.naturalHeight,
        width: this.image.naturalWidth
      })
    }
  }

  render () {
    return (
      <img className='ImageScreen' style={{ height: '100%' }} ref={this.onImageRef} alt='' src={this.state.data} />
    )
  }
}
