import React from 'react'
import { useTranslation } from 'react-i18next'
import SDKButtons from '../../components/SDKButtons'
import Icon from '../../components/Icon'
import CopyText from '../../components/CopyText'

const EmptyDeviceList = ({ account }) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col gap-y-4 h-full'>
      <div className='flex flex-col items-center justify-center gap-y-4 h-full'>
        <div className='flex flex-col items-center gap-y-2 text-center'>
          <div className='p-3 rounded-full bg-black/25'>
            <Icon type='device-mobile-search' className='h-6 w-6' />
          </div>
          <h2 className='text-h-md'>{t("We haven't seen any of your devices yet")}</h2>
          <p className='text-base'>{t('Have you added our SDKs to your app or website?')}</p>
        </div>
        <div className='px-3 py-2 bg-white text-black/60 text-base rounded flex flex-wrap justify-center items-center gap-x-2'>
          <CopyText
            label={t("You'll need your license key:")}
            labelStyle='inner'
            className='text-black'
          >
            {account.resource.license_key}
          </CopyText>
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <p className='text-black/75 text-base mb-3'>{t('Need help setting up? Read docs for:')}</p>
        <SDKButtons />
      </div>
    </div>
  )
}

export default EmptyDeviceList
