import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const IconContainer = ({ className, children, ...props }) => {
  return (
    <div
      className={mergeClassNames('relative text-white inline-flex items-center justify-center outline outline-1.5 outline-black/15 -outline-offset-2 text-center bg-cover rounded-full text-md w-10 h-10 sm:w-8 sm:h-8 whitespace-nowrap lg:w-10 lg:h-10', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default IconContainer
