import RESTObject from '../redux-rest'

export default class AccountMember extends RESTObject {
  static namespace () {
    return 'AccountMember'
  }

  static url (params = {}) {
    return `/api/1/members/${params.id || ''}`
  }
}
