import { useCallback, useState } from 'react'

export function useToggle (initialValue = false) {
  const [isExpanded, setIsExpanded] = useState(initialValue)

  const handleToggle = useCallback((value) => {
    if (typeof value === 'boolean') {
      return setIsExpanded(value)
    }

    return setIsExpanded((v) => !v)
  }, [])

  return [isExpanded, handleToggle]
}
