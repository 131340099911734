import React, { memo } from 'react'
import parser from 'ua-parser-js'
import { pick, omit, isEmpty } from 'lodash'
import StandardFields from './StandardFields'
import deviceNameFromModel from './DeviceNames'
import mergeClassNames from '../../utils/mergeClassNames'
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion'
import { useTranslation } from 'react-i18next'
import { Transition } from '@headlessui/react'

const NUMBER_OF_FIELDS_TO_SHOW = 2

const selectPrioritizedItems = (items, priorityArray) => {
  const filteredItems = Object.fromEntries(Object.entries(items).filter(([, value]) => !!value))
  const selectedItems = pick(filteredItems, priorityArray)

  return Object.fromEntries(
    Object.entries(selectedItems).slice(0, NUMBER_OF_FIELDS_TO_SHOW)
  )
}

const transformLocaleValue = (value, currentLocale = 'en') => {
  try {
    const languageNames = new Intl.DisplayNames([currentLocale], { type: 'language' })

    return languageNames.of(value)
  } catch (e) {
    console.warn(`Failed to transform locale value: ${value}`, e)

    return value
  }
}

const KeyValue = ({ code, value, type, fieldInfo, addFilter, limitWidth, expanded, layoutUpdating, onClick, showIcons }) => {
  const { i18n } = useTranslation()

  if (value === null || value === '') return null
  if (value === true) value = 'YES'
  if (value === false) value = 'NO'

  const info = fieldInfo[code] || {}

  let transformedValue = value

  if (code === 'device_locale') {
    transformedValue = transformLocaleValue(value, i18n.language)
  }

  if (typeof transformedValue === 'string' && (info.capitalize == null || info.capitalize)) {
    transformedValue = transformedValue.charAt(0).toUpperCase() + transformedValue.slice(1)
  }

  return (
    <div
      className={mergeClassNames(
        'py-1 flex items-center w-full border-b-[1px] border-gray-200 text-xs',
        type === 'custom_data' && addFilter && 'cursor-pointer'
      )}
      title={info.label}
      onClick={() => onClick(code, value, type)}
    >
      <span className='text-gray-700 w-[50%] break-all flex item-center gap-x-1'>
        {showIcons && info.icon}
        {info.label || code}
      </span>
      <div className='w-[50%] flex gap-1 flex-wrap'>

        {Array.isArray(value)
          ? value.map(item => (
            <span key={item} className='text-slate-900 bg-gray-100 py-[2px] px-2 rounded-[10px] capitalize'>{item.replace(/_/g, ' ')}</span>
          ))
          : <span className={mergeClassNames('text-slate-900 break-all min-w-0', (!(expanded || layoutUpdating) && limitWidth) && 'lg:max-w-lg')} title={value}>{transformedValue}</span>}
      </div>

    </div>
  )
}

const CategorizedInfo = ({ items, category, type, fieldInfo, addFilter, onClick, showIcons = false }) => {
  console.log('🚀 ~ CategorizedInfo ~ items:', items)

  return (
    <div className='flex flex-col gap-2'>
      <div className='text-sm font-medium text-slate-900 tracking-[1px] capitalize'>{category}</div>
      <div className='flex flex-wrap gap-2'>
        {Object.keys(items).map((key) => (
          <KeyValue
            key={key}
            code={key}
            value={items[key]}
            type={type}
            fieldInfo={fieldInfo}
            addFilter={addFilter}
            onClick={onClick}
            showIcons={showIcons}
          />
        ))}
      </div>
    </div>
  )
}

const CategorizedInfoWrapper = ({ expanded = false, className, onBeforeTransitionLeave, onAfterTransitionLeave, children }) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  if (prefersReducedMotion) {
    return expanded && <div className={className}>{children}</div>
  }

  return (
    <Transition
      className={className}
      show={!!expanded}
      enter='transition-all motion-reduce:transition-none duration-300'
      enterFrom='max-h-0 opacity-0'
      enterTo='max-h-[1000px] opacity-100'
      leave='transition-all motion-reduce:transition-none duration-300'
      leaveFrom='max-h-[1000px] opacity-100'
      leaveTo='max-h-0 opacity-0'
      beforeLeave={onBeforeTransitionLeave}
      afterLeave={onAfterTransitionLeave}
    >
      {children}
    </Transition>
  )
}

const DeviceInfo = ({ device, customData, addFilter, showAll, showMore, layoutUpdating, onBeforeTransitionLeave, onAfterTransitionLeave, className }) => {
  console.log('🚀 ~ DeviceInfo ~ device:', device)
  const { t } = useTranslation()
  const expanded = showAll || showMore

  const onClick = (key, value, type) => {
    if (addFilter && type === 'custom_data') {
      addFilter(key, value)
    }
  }

  const deviceInfo = () => {
    const info = { ...device }

    delete info.app_installation_id

    if (info.platform === 'web') {
      const ua = parser(info.device)

      info.device = `${ua.os.name} / ${ua.browser.name}`
    } else if (info.device) {
      info.device = deviceNameFromModel(info.device)
    }

    console.log('🚀 ~ deviceInfo ~ omit(info):', omit(info, [
      'push_enabled',
      'horizontal_accuracy',
      'vertical_accuracy',
      'latitude',
      'longitude',
      'altitude',
      'video_codecs'
    ]))

    return omit(info, [
      'push_enabled',
      'horizontal_accuracy',
      'vertical_accuracy',
      'latitude',
      'longitude',
      'altitude',
      'video_codecs'
    ])
  }

  const renderKeyValue = (key, value, type, fieldInfo, limitWidth = false, expanded = false, layoutUpdating = false) => (
    <KeyValue
      key={key}
      code={key}
      value={value}
      type={type}
      fieldInfo={fieldInfo}
      addFilter={addFilter}
      limitWidth={limitWidth}
      expanded={expanded}
      layoutUpdating={layoutUpdating}
      onClick={onClick}
    />
  )

  const fieldPriority = []

  // Show the device locale as an extra prioritized item only if it's set
  const prioritized = selectPrioritizedItems({ ...customData, ...deviceInfo() }, fieldPriority)
  const deviceInfoItems = omit(deviceInfo(), Object.keys(prioritized))
  console.log('🚀 ~ DeviceInfo ~ customData:', customData)
  console.log('🚀 ~ DeviceInfo ~ deviceInfo():', deviceInfo())
  const customDataItems = omit(customData, Object.keys(prioritized))

  return (
    <div className={mergeClassNames('text-md flex flex-wrap min-w-0 w-full text-black rounded-lg', className)}>
      <CategorizedInfoWrapper
        expanded={expanded}
        className='flex flex-col w-full gap-y-4 md:gap-y-6'
        onBeforeTransitionLeave={onBeforeTransitionLeave}
        onAfterTransitionLeave={onAfterTransitionLeave}
      >
        {!isEmpty(customDataItems) && (
          <CategorizedInfo
            items={customDataItems}
            category={t('Custom data')}
            type='custom_data'
            fieldInfo={StandardFields.customData()}
            addFilter={addFilter}
            onClick={onClick}
          />
        )}
        {!isEmpty(deviceInfoItems) && (
          <CategorizedInfo
            items={deviceInfoItems}
            category={t('Device info')}
            type='device'
            fieldInfo={StandardFields.deviceData()}
            addFilter={addFilter}
            onClick={onClick}
            showIcons
          />
        )}

      </CategorizedInfoWrapper>
    </div>
  )
}

export default memo(DeviceInfo)
