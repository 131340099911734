import { useToggle } from '../hooks/useToggle'
import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

export function ExpandableVerticleMenu ({ Button, children, className, menuClassname, isHorizontal = false }) {
  const [isExpanded, toggle] = useToggle()

  // @todo use click outside hook to close it when the user clicks outside the menu container

  return (
    <div
      className={mergeClassNames('relative flex items-center justify-center w-10 h-10 md:rounded', className)}
      onClick={toggle}
    >
      <Button isExpanded={isExpanded} />
      <div
        className={mergeClassNames(
          'absolute z-50 flex items-center justify-center h-fit p-4 gap-4 md:gap-2 transition-opacity duration-300 ease-in-out transform bg-slate-800 rounded-full shadow translate-x-0 bottom-12',
          !isHorizontal && 'flex-col',
          isExpanded
            ? 'opacity-100 pointer-events-auto'
            : 'opacity-0 pointer-events-none',
          menuClassname
        )}
      >
        {children}
      </div>
    </div>
  )
}
