import React from 'react'
import UserIcon from '../users/UserIcon'
import mergeClassNames from '../../utils/mergeClassNames'
import ViewRecordingButton from './ViewRecordingButton'
import ResourceInfo from '../../components/ResourceInfo'
import OpenSessionButton from './OpenSessionButton'
import SessionOverview from './SessionOverview'
import Alert from '../../components/Alert'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'

const Session = ({ session, addFilter }) => {
  const hasRecording = session.state === 'ended' && session.recorded
  const recordingExpired = !hasRecording && session.recording?.deleted
  const { t } = useTranslation()

  const actionButton = () => {
    if (session.state === 'active') {
      return (
        <OpenSessionButton session={session} />
      )
    }

    return (
      <ViewRecordingButton
        session={session}
        disabled={recordingExpired}
        className={mergeClassNames(!hasRecording && !recordingExpired && 'invisible lg:hidden')}
      />
    )
  }

  return (
    <ResourceInfo
      resource={session}
      addFilter={addFilter}
      actionButton={actionButton()}
      showActionButton={session.state === 'active' || hasRecording || recordingExpired}
      afterDeviceInfoContent={
        ({ detailsExpanded, layoutUpdating }) => (
          session.recording?.expires && !session.recording?.deleted && (detailsExpanded || layoutUpdating) && (
            <Alert
              type='warning'
              variant='inline'
              className={mergeClassNames('self-start opacity-0 transition-opacity duration-300 ease-in-out motion-safe:transition-none', detailsExpanded && 'opacity-100')}
            >
              {t('The recording of this session expires {{time}}', { time: moment(session.recording.expires).fromNow() })}
            </Alert>
          ))
    }
    >
      <div className='flex items-center'>
        <div className='me-2 lg:me-4'>
          {/* We need to override each viewport width from UserIcon */}
          <UserIcon className='h-9 w-9 sm:h-9 sm:w-9 lg:h-9 lg:w-9' user={session.agent} />
        </div>
      </div>
      <div className='overflow-hidden md:h-9'>
        <SessionOverview session={session} />
      </div>
    </ResourceInfo>
  )
}

export default Session
