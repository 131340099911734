import qs from 'qs'
import _ from 'lodash'
import URL from 'url-parse'
import EmbedParams from '../../utils/EmbedParams'

export default class FilterParams {
  static parse () {
    const query = qs.parse(window.location.search.substr(1))
    return _.omitBy(query, (value, key) => {
      return !key.startsWith('filter_')
    })
  }

  static empty () {
    return Object.keys(this.parse()).length === 0
  }

  static get params () {
    return _.mapKeys(this.parse(), (value, key) => key.substr('filter_'.length))
  }

  static set params (params) {
    const query = qs.parse(window.location.search.substr(1))
    const withoutFilters = _.omitBy(query, (value, key) => {
      return key.startsWith('filter_')
    })
    const toSet = { ...withoutFilters, ..._.mapKeys(params, (value, key) => `filter_${key}`) }
    const url = new URL(window.location)
    url.set('query', qs.stringify(toSet, { arrayFormat: 'brackets' }))
    window.history.replaceState({}, '', url.toString())
  }

  static set search (query) {
    EmbedParams.set({ filter: query })
  }

  static get search () {
    return EmbedParams.parse().filter
  }
}
