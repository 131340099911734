import React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingScreen from '../../components/LoadingScreen'
import mergeClassNames from '../../utils/mergeClassNames'
import isInIframe from '../../utils/isInIframe'

const PendingSession = ({ session, endSession }) => {
  const { t } = useTranslation()

  const handleEndSession = () => {
    endSession && endSession(session)
  }

  return (
    <div className={mergeClassNames('flex items-center justify-center h-full w-full bg-slate-900 text-slate-50', isInIframe() && 'bg-transparent')}>
      <LoadingScreen
        message={session.state === 'authorizing' ? t('Waiting for authorization') : t('Waiting for device')}
        cancelText={t('Cancel')}
        onCancel={handleEndSession}
      />
    </div>
  )
}

export default PendingSession
