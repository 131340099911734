import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import Button from '../../components/Button'
import Input from '../../components/Input'

const ZendeskLoginScreen = () => {
  const [subdomain, setSubdomain] = useState(window.localStorage?.zendeskSubdomain)
  const { t } = useTranslation()

  const updateSubdomain = (e) => {
    const newSubdomain = e.target.value

    setSubdomain(newSubdomain)

    try {
      window.localStorage.setItem('zendeskSubdomain', newSubdomain)
    } catch (e) {
      console.warn('Failed to set zendeskSubdomain in local storage', e)
    }
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      doLogin()
    }
  }

  const doLogin = () => {
    if (!subdomain) {
      return
    }

    const params = qs.parse(window.location.search.substring(1))
    const link = `/api/1/zendesk/auth?${qs.stringify({ ...params, subdomain })}`

    window.location.href = link
  }

  return (
    <div className='h-full w-full bg-white'>
      <div className='max-w-xl flex flex-col mt-13 mx-auto'>
        <img alt='Zendesk' className='h-5 ms-8 self-start' src={require('./zendesk-logo.svg').default} />
        <div className='border border-[#dddddd] rounded-md mt-8 overflow-hidden'>
          <div className='p-8'>
            <h1 className='text-h-md mb-4'>{t('Sign in with {{provider}}', { provider: 'Zendesk' })}</h1>
            <p>{t('Enter your Zendesk subdomain to access your Zendesk-linked Cobrowse account.')}</p>
          </div>
          <div className='border-t border-t-[#dddddd] bg-[#f3f3f3] p-6'>
            <div className='flex items-center text-[#555555] mb-4'>
              <Input className='text-right text-[#555555] flex-1' onKeyDown={onEnter} onChange={updateSubdomain} value={subdomain} type='text' />.zendesk.com
            </div>
            <Button className='w-full bg-[#06615a] enabled:hover:bg-[#079080]' onClick={doLogin} disabled={!subdomain}>{t('Sign in with {{provider}}', { provider: 'Zendesk' })}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ZendeskLoginScreen
