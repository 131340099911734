import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'
import TextArea from '../../components/TextArea'
import Icon from '../../components/Icon'

function FeedbackInput ({ label, onReasonSelect }) {
  return (
    <label className='flex items-center text-sm transition-colors rounded-lg cursor-pointer group gap-x-2'>
      <input value='hard_to_start_session' type='checkbox' className='flex-shrink-0 text-white transition-colors bg-center bg-no-repeat bg-cover border border-purple-400 rounded-md appearance-none cursor-pointer size-5 hover:border-slate-50 checked:text-purple-400 checked:border-none checked:bg-cover checked:bg-no-repeat checked:bg-checked' onChange={onReasonSelect} />
      {label}
    </label>
  )
}

const SessionRating = ({ ratingComplete }) => {
  const [rating, setRating] = useState(null)
  const [feedback, setFeedback] = useState([])
  const [issues, setIssues] = useState('')
  const [complete, setComplete] = useState(false)
  const { t } = useTranslation()

  const onRatingComplete = useCallback(() => {
    setComplete(true)
    ratingComplete({ rating, feedback: [...feedback, issues].filter(v => v) })
  }, [rating, feedback, issues, ratingComplete])

  useEffect(() => {
    if (rating > 3) {
      onRatingComplete()
    }
  }, [rating, onRatingComplete])

  function renderRating () {
    return (
      <div className='flex flex-row-reverse justify-center'>
        {[5, 4, 3, 2, 1].map((score) => (
          <button
            key={score}
            className={mergeClassNames('px-1.5 text-[40px] cursor-pointer duration-300 transition-opacity peer text-white [&_path]:fill-none peer-hover:text-violet [&_path]:peer-hover:fill-violet hover:text-violet [&_path]:hover:fill-violet', rating >= score && 'text-violet [&_path]:fill-violet')}
            onClick={() => setRating(score)}
          >
            <Icon type='star' className={mergeClassNames()} />
            <span className='sr-only'>{t('Rate with a score of {{score}}', { score })}</span>
          </button>
        ))}
      </div>
    )
  }

  function onReasonSelect (e) {
    const value = e.target.value
    if (e.target.checked) setFeedback([...feedback, value])
    else setFeedback(feedback.filter(v => v !== value))
  }

  const hasFeedbackVisible = rating != null && rating <= 3
  console.log('🚀 ~ SessionRating ~ hasFeedbackVisible:', rating, hasFeedbackVisible)

  function renderFeedback () {
    if (!hasFeedbackVisible) return null

    return (
      <div className='grid gap-y-6 w-[400px]'>
        <div className='flex flex-col gap-y-3'>
          <FeedbackInput
            label={t('It was too hard to start the session with the user')}
            onReasonSelect={onReasonSelect}
          />
          <FeedbackInput
            label={t('The screen was too slow to load or update')}
            onReasonSelect={onReasonSelect}
          />
          <FeedbackInput
            label={t('Parts of the screen did not load correctly')}
            onReasonSelect={onReasonSelect}
          />
          <FeedbackInput
            label={t('The tools were not helpful or too hard to use')}
            onReasonSelect={onReasonSelect}
          />
        </div>
        <TextArea placeholder={t('Please describe any issues you experienced')} onChange={(e) => setIssues(e.target.value)} className='bg-white/15 text-sm text-slate-50/75 [&::placeholder]:text-slate-50/75 [&::placeholder]:text-sm w-full h-[72px]' />
      </div>
    )
  }

  function renderComplete () {
    if (!complete) return null
    else return <div>{t('Thank you for your feedback.')}</div>
  }

  if (complete) return renderComplete()

  return (
    <>
      <div className={mergeClassNames('flex flex-col px-4 gap-y-4', !rating && 'absolute bottom-6')}>
        <p className='text-xs text-center text-gray-500 uppercase'>{t('Rate your experience')}</p>
        {renderRating()}
      </div>
      <div className={mergeClassNames('flex flex-col px-4 gap-y-4', hasFeedbackVisible && 'gap-y-6')}>
        {renderFeedback()}
        <Button variant='primary-v2' className='self-center' onClick={onRatingComplete}>{t('Continue')}</Button>
      </div>
    </>
  )
}

export default SessionRating
