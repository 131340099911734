/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import Icon from './Icon'

const buttons = [
  {
    id: 'web',
    text: 'Web',
    icon: 'globe',
    href: 'https://docs.cobrowse.io/sdk-installation/web'
  },
  {
    id: 'ios-native',
    text: 'iOS',
    icon: 'apple',
    href: 'https://docs.cobrowse.io/sdk-installation/ios'
  },
  {
    id: 'android-native',
    text: 'Android',
    icon: 'android',
    href: 'https://docs.cobrowse.io/sdk-installation/android'
  },
  {
    id: 'react-native',
    text: 'React Native',
    icon: 'react',
    href: 'https://docs.cobrowse.io/sdk-installation/react-native'
  },
  {
    id: 'dotnet',
    text: '.NET',
    icon: 'microsoft-dotnet',
    href: 'https://docs.cobrowse.io/sdk-installation/dotnet'
  },
  {
    id: 'macos',
    text: 'macOS',
    icon: 'macos',
    href: 'https://docs.cobrowse.io/sdk-installation/macos'
  },
  {
    id: 'windows',
    text: 'Windows',
    icon: 'windows',
    href: 'https://docs.cobrowse.io/sdk-installation/windows'
  }
]

const SDKButton = ({ id, className, children, ...props }) => (
  <a
    id={`${id}-sdk`}
    target='_blank'
    className={mergeClassNames('bg-white w-[100px] px-1 pt-1 pb-2 rounded-lg flex flex-col gap-y-1 shadow transition-shadow duration-150 ease-in hover:shadow-risen', className)}
    {...props}
  >
    {children}
  </a>
)

const SDKButtons = () => {
  return (
    <div className='flex flex-wrap justify-center gap-2'>
      {buttons.map((button) => (
        <SDKButton id={button.id} href={button.href} key={button.id}>
          <div className='bg-grey-dark flex justify-center p-3'>
            <Icon type={button.icon} className='h-9' />
          </div>
          <div className='text-center text-md'>{button.text}</div>
        </SDKButton>
      ))}
    </div>
  )
}

export default SDKButtons
