import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { inputClassName } from './Input'

const TextArea = forwardRef(({ variant = 'primary', className, children, ...props }, ref) => {
  const variants = {
    primary: 'hover:border-black/50 focus:border-black active:border-black'
  }

  return (
    <textarea
      ref={ref}
      className={mergeClassNames(variants[variant], variant !== 'plain' && inputClassName, className)}
      {...props}
    >{children}
    </textarea>
  )
})

export default TextArea
