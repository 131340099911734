import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Icon from '../../components/Icon'
import Stopwatch from '../../components/Stopwatch'
import Timestamp from '../../components/Timestamp'
import mergeClassNames from '../../utils/mergeClassNames'

const userIdentifier = (data = {}) => {
  return data.user_email || data.user_id
}

const sessionDate = (session) => {
  return <Timestamp timestamp={session.created} />
}

const SessionOverview = ({ session, ip, variant = 'listing' }) => {
  const { t } = useTranslation()
  const endUser = userIdentifier(session.custom_data)
  const listingVariant = variant === 'listing'
  const recordingVariant = variant === 'recording'

  return (
    <div className={mergeClassNames(recordingVariant && 'flex flex-wrap gap-1 items-center')}>
      <div className={mergeClassNames('text-base truncate leading-5', listingVariant && '-mt-0.5', recordingVariant && 'flex flex-wrap gap-1')}>
        {session.agent ? session.agent.name : t('No agent')}
        {endUser && <span className='text-black/60 ps-1'> <Trans>with <span className='text-black inline-block ps-1'>{endUser}</span></Trans></span>}
      </div>
      <div className={mergeClassNames('flex items-center gap-x-2 text-md h-4 mt-[3px]', recordingVariant && 'contents text-base leading-5')}>
        <Icon type='clock-hour-3' className='h-4 text-black/60' />
        <Stopwatch className='inline' start={session.activated} end={session.ended} live={!session.ended} />
        {listingVariant && <div className='border-l-2 retina:border-l-1.5 border-black/15 w-px h-4' />}
        {session.state === 'active' && (
          <div className='flex gap-x-1 items-center'>
            <div className='bg-green-500 rounded-full h-2 w-2' />
            <span className='text-black/60 tracking-wider'>{t('Active')}</span>
          </div>
        )}
        {session.state === 'ended' && (
          <span className='text-black/60 tracking-wider truncate'>
            {sessionDate(session)}
            {ip && (
              <span> {t('with IP {{ip}}', { ip })}</span>
            )}
          </span>
        )}
      </div>
    </div>
  )
}

export default SessionOverview
