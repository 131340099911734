export const reverseHost = (host) => host.split('.').reverse().map(encodeURIComponent).join('/')

export const proxifyUrl = (url, queryParams) => {
  const { hostname, protocol, pathname, search, port, username, password, hash } = new URL(url)

  const targetHost = reverseHost(hostname)
  const targetPath = `${targetHost}/${protocol}${pathname}${hash}`
  const targetUrl = new URL(targetPath, 'http://placeholder.base')

  if (search) targetUrl.searchParams.set('search', search.replace('?', ''))
  if (port) targetUrl.searchParams.set('port', port)
  if (username) targetUrl.searchParams.set('username', username)
  if (password) targetUrl.searchParams.set('password', password)

  for (const [key, value] of Object.entries(queryParams || {})) {
    targetUrl.searchParams.set(key, value)
  }

  return `${targetUrl.pathname}${targetUrl.search}${targetUrl.hash}`
}
