import rsaKeyToPEM from './rsaKeyToPEM'

/**
 * An object representing a generated private/public RSA key pair.
 * @typedef {object} RSAKeyPair
 * @property {string} publicKey - The complete public key object in PRM string format.
 * @property {string} privateKeyUrl - The blob URL of the private key.
 */

/** Method for creating a public/private RSA key pair.
 * @returns {Promise<RSAKeyPair>}
 */
export default async function createRSAKeyPair () {
  const options = {
    name: 'RSA-PSS',
    modulusLength: 4096,
    publicExponent: new Uint8Array([1, 0, 1]),
    hash: 'SHA-256'
  }
  const { publicKey, privateKey } = await crypto.subtle.generateKey(options, true, ['sign', 'verify'])
  const privateKeyASN1 = await crypto.subtle.exportKey('pkcs8', privateKey)
  const publicKeyASN1 = await crypto.subtle.exportKey('spki', publicKey)
  const privateKeyPEM = rsaKeyToPEM(privateKeyASN1, 'PRIVATE')
  const publicKeyPEM = rsaKeyToPEM(publicKeyASN1, 'PUBLIC')
  const privateKeyBuffer = new TextEncoder().encode(privateKeyPEM)
  const privateKeyBlob = new Blob([privateKeyBuffer], { type: 'application/x-pem-file' })
  const privateKeyUrl = URL.createObjectURL(privateKeyBlob)

  return {
    publicKey: publicKeyPEM,
    privateKeyUrl
  }
}
