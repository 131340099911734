/** Function to convert an ArrayBuffer of RSA private or publc key to PEM.
 * @param {ArrayBuffer} buffer - The RSA key array buffer to convert
 * @param {'PUBLIC' | 'PRIVATE'} keyType - Either PUBLIC or PRIVATE.
 * @returns {string} The RSA key file with headers and trailers.
 */
export default function rsaKeyToPEM (buffer, keyType) {
  const keyText = `${keyType} KEY`
  const header = `-----BEGIN ${keyText}-----`
  const trailer = `-----END ${keyText}-----`
  const bytes = new Uint8Array(buffer)
  const chars = Array.from(bytes, byte => String.fromCharCode(byte))
  const base64 = window.btoa(chars.join(''))
  const lineLength = 64
  const pemArray = [header]

  for (let start = 0, end = lineLength; start < base64.length; start += lineLength, end += lineLength) {
    pemArray.push(base64.substring(start, end))
  }

  pemArray.push(trailer)

  return `${pemArray.join('\r\n')}\r\n`
}
