import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EmbedParams from '../../utils/EmbedParams'
import SessionRating from '../ratings/SessionRating'
import Rating from '../ratings/SessionRating.state'
import mergeClassNames from '../../utils/mergeClassNames'
import isInIframe from '../../utils/isInIframe'

const EndedSession = ({ session, allowRating }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const finished = useCallback(() => {
    if (EmbedParams.endAction() !== 'none') {
      navigate(EmbedParams.endRedirect(), { replace: true })
    }
  }, [navigate])

  function onRatingComplete (values) {
    if (values.rating) {
      // Note: we're not awaiting this. If it fails we'll just ignore that error...
      Rating.fetch(Rating.types.UPDATE, { id: session.id, ...values })
    }
    finished()
  }

  useEffect(() => {
    if (!allowRating) return finished()
    if (!session.activated) return finished()
  }, [navigate, session.activated, finished, allowRating])

  return (
    <div className={mergeClassNames('flex flex-col gap-y-6 justify-center items-center h-full w-full bg-slate-900 text-slate-50', isInIframe() && 'bg-transparent')}>
      <h2 className='text-lg font-medium'>{t('This session has ended.')}</h2>
      {(allowRating && session.activated) ? <SessionRating ratingComplete={onRatingComplete} /> : null}
    </div>
  )
}

export default EndedSession
