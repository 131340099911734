import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import Button from './Button'
import Icon from './Icon'
import mergeClassNames from '../utils/mergeClassNames'

const Dialog = ({
  isOpen = false,
  onAccept,
  onCancel,
  onClose,
  acceptText,
  cancelText,
  acceptProps = {},
  cancelProps = {},
  titleText,
  id = 'modal',
  className,
  panelClassName,
  bodyClassName,
  children
}) => {
  const { t } = useTranslation()

  const handleOnAccept = (e) => {
    onAccept?.(e)
  }

  const handleOnCancel = (e) => {
    onCancel?.(e)
  }

  const handleOnClose = (e) => {
    onClose?.(e)
  }

  const accept = typeof onAccept === 'function'
    ? (<Button className='w-full' onClick={handleOnAccept} {...acceptProps}>{acceptText || t('Accept')}</Button>)
    : null
  const cancel = typeof onCancel === 'function'
    ? (<Button className='w-full' variant='error' onClick={handleOnCancel} {...cancelProps}>{cancelText || t('Cancel')}</Button>)
    : null
  const close = typeof onClose === 'function'
    ? (
      <Button variant='plain' onClick={handleOnClose}>
        <Icon type='close' className='h-6' />
      </Button>
      )
    : null

  const showHeader = !!titleText
  const showFooter = accept || cancel

  return (
    <HeadlessDialog
      open={isOpen}
      onClose={handleOnClose}
      id={id}
      className='relative z-50'
    >
      <div className='fixed inset-0 bg-black/50 md:hidden md:pointer-events-none' aria-hidden='true' />
      <div className={mergeClassNames('fixed inset-0 flex items-center justify-center p-4 md:hidden md:pointer-events-none', className)}>
        <HeadlessDialog.Panel
          className={mergeClassNames('mx-auto max-w-lg rounded-lg bg-grey p-4', panelClassName)}
        >
          {showHeader && (
            <div className='flex justify-between mb-4'>
              <HeadlessDialog.Title className='text-title-md'>
                {titleText}
              </HeadlessDialog.Title>
              {close}
            </div>
          )}
          <div className={mergeClassNames('flex flex-col gap-y-4', bodyClassName)}>
            {children}
          </div>
          {showFooter && (
            <div className='flex flex-row justify-between gap-4 mt-4'>
              {accept}
              {cancel}
            </div>
          )}
        </HeadlessDialog.Panel>
      </div>
    </HeadlessDialog>
  )
}

export default Dialog
