import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AdminAccountList from './AdminAccountList'
import Login from '../auth/Login'
import AccountSearch from './AccountSearch.state'
import User from '../users/User.state'
import FeatureSwitches from '../accounts/FeatureSwitches.state'

const AdminDashboard = () => {
  const accounts = useSelector(state => AccountSearch.fromState(state))
  const user = useSelector(state => User.fromState(state))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(AccountSearch.actionCreators().getAccountSearch())
  }, [dispatch])

  useEffect(() => {
    if (!user.resource.superuser) {
      navigate('/dashboard', { replace: true })
    }
  }, [user.resource.superuser, navigate])

  const refresh = (params = {}, options = {}) => {
    getAccountSearch({}, params, options)
  }

  const getAccountSearch = (resource = {}, params = {}, options = {}) => {
    dispatch(AccountSearch.actionCreators().getAccountSearch(resource, params, options))
  }

  const updateAccountSearch = (resource = {}, params = {}, options = {}) => {
    dispatch(AccountSearch.actionCreators().updateAccountSearch(resource, params, options))
  }

  const updateFeatureSwitches = (resource = {}, params = {}, options = {}) => {
    dispatch(FeatureSwitches.actionCreators().updateFeatureSwitches(resource, params, options))
  }

  const renderAccounts = () => {
    return (
      <AdminAccountList
        accounts={accounts}
        user={user}
        getAccount={getAccountSearch}
        updateAccount={updateAccountSearch}
        updateFeatureSwitches={updateFeatureSwitches}
        refresh={refresh}
      />
    )
  }

  if (!user.resource) {
    return <Login />
  }

  return renderAccounts()
}

export default AdminDashboard
