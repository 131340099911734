import _ from 'lodash'
import qs from 'qs'
import { CBORSocket } from 'cocom'
import RESTObject from '../redux-rest'

export const SOCKET_STATE = 'socket/SOCKET_STATE'
export const SOCKET_MESSAGE = 'socket/SOCKET_MESSAGE'
export const SOCKET_EMIT = 'socket/SOCKET_EMIT'

const initialState = {}

export default function websockets (state = initialState, action) {
  switch (action.type) {
    case SOCKET_STATE: {
      const socketState = state[action.id] || {}
      if (action.state) socketState.state = action.state
      return { ...state, [action.id]: { ...socketState, error: action.error } }
    }
    default:
      return state
  }
}

const sockets = {}

export const disconnect = (id) => {
  return dispatch => {
    const socket = sockets[id]
    if (socket) {
      dispatch({ type: SOCKET_STATE, id, state: 'disconnecting' })
      socket.close()
      delete sockets[id]
    }
  }
}

export function makeUrl (url, token) {
  const headers = RESTObject.headers()
  const xHeaders = _.omitBy(headers, (value, key) => {
    if (key.toLowerCase().startsWith('x-csrf')) return true
    return !key.toLowerCase().startsWith('x-')
  })
  const resolved = new URL(url, window.location.href).toString()
  const wsUrl = resolved.replace('https://', 'wss://').replace('http://', 'ws://')
  return new URL(`sockets/1/ws?access_token=${token}&${qs.stringify(xHeaders)}`, wsUrl)
}

export const connect = (id, { getUrl, configureSocket }) => {
  return dispatch => {
    if (sockets[id]) return sockets[id]

    const socket = new CBORSocket({ getUrl })
    sockets[id] = socket

    dispatch({ type: SOCKET_STATE, id, state: 'connecting' })

    socket.on('open', () => {
      dispatch({ type: SOCKET_STATE, id, error: false, state: 'connected' })
    })

    socket.on('close', () => {
      dispatch({ type: SOCKET_STATE, id, state: 'disconnected' })
    })

    socket.on('error', err => {
      dispatch({ type: SOCKET_STATE, id, error: err })
    })

    // forward on socket events to the event emitter
    socket.on('event', (event, data) => socket.emit(event, data))

    if (configureSocket) configureSocket(socket, dispatch)

    return socket
  }
}

export const emit = (id, event, data) => {
  return dispatch => {
    const socket = sockets[id]
    if (socket) {
      socket.send(event, data)
      dispatch({ type: SOCKET_EMIT, id, event, data })
    } else {
      console.warn('tried to send to a non-existant socket')
    }
  }
}
