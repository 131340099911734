import React from 'react'
import Button from './Button'
import Loading from './Loading'
import Icon from './Icon'

const LoadingScreen = ({ loading, message, cancelText, onCancel }) => {
  return (
    <div className='flex flex-col m-auto'>
      {loading === false ? message : <Loading>{message}</Loading>}
      {onCancel && (
        <Button variant='error' className='self-center w-auto px-5 py-1 mt-4 rounded-[62px] h-10 bg-red-v2-500 hover:bg-red-v2-600 disabled:hover:bg-red-v2-600' onClick={onCancel} aria-label={cancelText}>
          <Icon type='phone' className='w-6 h-6' />
        </Button>
      )}
    </div>
  )
}

export default LoadingScreen
