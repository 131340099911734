import React, { Component } from 'react'
import DOMNode from './DOMNode'

export default class DOMLabel extends Component {
  static nodeFilter (node) {
    return node
  }

  handleClick = (e) => {
    // clicking on a label which is explicitly or implicitly associated with another element
    // will cause a double, "fake" click on the associated element. We don't want to send
    // the second click event over the network so we preventDefault() but try to maintain the
    // default browser behaviour of focussing the associated element programatically
    const target = e.target
    const associatedEl = target.htmlFor ? target.ownerDocument.getElementById(target.htmlFor) : target.querySelector('input, select, textarea')
    if (associatedEl) associatedEl.focus()
    e.preventDefault()
  }

  render () {
    return <DOMNode {...this.props} key={this.props.node.id} node={this.props.node} onClick={this.handleClick} />
  }
}
