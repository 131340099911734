import qs from 'qs'
import RESTObject from '../redux-rest'

export default class AuditEvent extends RESTObject {
  static namespace () {
    return 'AuditEvent'
  }

  static url (params, context = {}) {
    return `/api/1/audit/events?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }
}
