import { Component } from 'react'
import { EventEmitter } from 'events'
import { throttle } from 'lodash'

export default class DOMEvents extends Component {
  static get events () {
    if (!DOMEvents._event_emitter) {
      DOMEvents._event_emitter = new EventEmitter()
    }
    return DOMEvents._event_emitter
  }

  componentDidMount () {
    this.props.el.addEventListener('scroll', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('keydown', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('keypress', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('keyup', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('mousedown', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('mousemove', this.onMouseMove, { capture: true, passive: true })
    this.props.el.addEventListener('mouseup', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('mouseover', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('mouseout', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('pointerdown', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('pointermove', this.onPointerMove, { capture: true, passive: true })
    this.props.el.addEventListener('pointerup', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('click', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('dblclick', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('focus', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('blur', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('focusin', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('focusout', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('change', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('input', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('selectionchange', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('select', this.onEvent, { capture: true, passive: true })
    this.props.el.addEventListener('contextmenu', this.supressEvent, { capture: true })
  }

  componentWillUnmount () {
    this.props.el.removeEventListener('scroll', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('keydown', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('keypress', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('keyup', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('mousedown', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('mousemove', this.onMouseMove, { capture: true, passive: true })
    this.props.el.removeEventListener('mouseup', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('mouseover', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('mouseout', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('pointerdown', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('pointermove', this.onPointerMove, { capture: true, passive: true })
    this.props.el.removeEventListener('pointerup', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('click', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('dblclick', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('focus', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('blur', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('focusin', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('focusout', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('change', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('input', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('selectionchange', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('select', this.onEvent, { capture: true, passive: true })
    this.props.el.removeEventListener('contextmenu', this.supressEvent, { capture: true })
  }

  onEvent = (e) => {
    // we lose the path when sending the event through a setTimeout
    e.originalPath = e.path || e.composedPath?.()

    // the setTimeout here is to allow some events effect on the DOM to be applied
    // e.g. keydown events
    setTimeout(() => { DOMEvents.events.emit('event', e) }, 0)
  }

  onMouseMove = throttle((e) => {
    this.onEvent(e)
  }, 80, { trailing: false })

  onPointerMove = throttle((e) => {
    this.onEvent(e)
  }, 80, { trailing: false })

  supressEvent = (e) => {
    e.preventDefault()
  }

  render () {
    return this.props.children
  }
}
