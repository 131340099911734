import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'
import LoginButtons from '../auth/LoginButtons'
import JoinToken from './JoinToken.state'
import Icon from '../../components/Icon'
import usePageTitle from '../../hooks/usePageTitle'

const JoinScreen = () => {
  const params = useParams()
  const joinToken = useSelector(state => JoinToken.fromState(state))
  const dispatch = useDispatch()
  const { t } = useTranslation()

  usePageTitle(t('Join team'))

  useEffect(() => {
    dispatch(JoinToken.actionCreators().getJoinToken({ token: params.token }))
  }, [params.token, dispatch])

  const token = () => {
    return params.token
  }

  const renderContents = () => {
    if (!joinToken.resource && joinToken.working) {
      return <div><Icon type='spin2' spin /> {t('Loading invitation')}</div>
    }

    if (!joinToken.resource) {
      return <p>{t('Sorry, this link is invalid or has expired.')}</p>
    }

    return (
      <div>
        <h3 className='my-4'>
          <Trans>
            You have been invited to join {{ organisation_name: joinToken.resource.organisation_name || t('an account') }}.
          </Trans>
        </h3>
        <p className='mb-4'>{t('You will need to log in before you can join this team. Choose a login method to continue:')}</p>
        <LoginButtons providers={['email', 'google', 'github']} query={{ join_token: token(), redirectTo: '/dashboard' }} />
      </div>
    )
  }

  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-center mt-4'>
        <div className='text-center pb-12'>
          <h1 className='sr-only'>{t('Join team')}</h1>
          {renderContents()}
        </div>
      </div>
    </div>
  )
}

export default JoinScreen
