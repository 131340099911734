import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import TimelineEvent from '../../components/TimelineEvent'
import Timestamp from '../../components//Timestamp'
import UserIcon from '../users/UserIcon'
import Icon from '../../components/Icon'
import mergeClassNames from '../../utils/mergeClassNames'
import IconContainer from '../../components/IconContainer'

const SessionAction = ({ agent, event, variant = 'audit', className }) => {
  const { t } = useTranslation()
  const iconClassName = 'inline-flex'

  const agentInfo = () => {
    // we only have the agent record for the agent that started the session
    // but it's possible other agents also joined the session, so we should
    // check whether the event was actually performed by a different agent
    if (agent.id !== event.agent) {
      return {
        name: t('Unknown agent'),
        colour: 'black'
      }
    }

    return agent
  }

  const renderAttribute = (key, value) => {
    if (value === '') {
      return <span key={key} className='ms-1.5 text-red-900/80'>{key}</span>
    }

    return (
      <span key={key}>
        <span className='ms-1.5'>{key}</span>
        =
        <span className='text-black'>{`"${value}"`}</span>
      </span>
    )
  }

  const renderTarget = (target) => {
    if (!target || !target.tagName) {
      return null
    }

    const attrs = Object.keys(target.attributes).map(key => {
      return renderAttribute(key, target.attributes[key])
    })

    return (
      <div key='target' className='font-mono block bg-white p-1.5 rounded-lg text-purple-500 max-h-52 overflow-auto'>
        {'<'}
        <span className='text-red-900'>{target.tagName.toLowerCase()}</span>
        {attrs}
        {'/>'}
      </div>
    )
  }

  const renderTargetValue = (target) => {
    if (!target) {
      return null
    }

    if (typeof target.checked !== 'undefined') {
      return (
        <div key='target-value' className='flex flex-wrap items-center p-2 text-base rounded-lg bg-grey text-black/60 gap-x-1 md:flex-nowrap'>
          <span className='text-black break-all sm:break-normal'>{t('checked')}</span>
          <span> → </span>
          <span className='break-all'>{target.checked ? 'true' : 'false'}</span>
        </div>
      )
    }

    if (typeof target.value !== 'undefined') {
      return (
        <div key='target-value' className='flex flex-wrap items-center p-2 text-base rounded-lg bg-grey text-black/60 gap-x-1 md:flex-nowrap'>
          <span className='text-black break-all sm:break-normal'>{t('value')}</span>
          <span> → </span>
          <span className='break-all'>{`"${target.value}"`}</span>
        </div>
      )
    }

    return null
  }

  const renderInputEvent = (event) => {
    return {
      title: <span key='desc'>{t('{{agent_name}} changed an input', { agent_name: agentInfo().name })}</span>,
      content: [
        renderTarget(event.target),
        renderTargetValue(event.target)
      ]
    }
  }

  const renderKeypressEvent = (event) => {
    if (event.target) {
      return null
    }

    return {
      title: <span><Trans>{{ agent_name: agentInfo().name }} {{ event_state: event.state === 'keydown' ? 'pressed' : 'released' }} key <b>{{ event_key: event.key }}</b></Trans></span>,
      content: null
    }
  }

  const renderPointerEvent = (event) => {
    return {
      title: <span key='desc'><Trans>{{ agent_name: agentInfo().name }} performed a <b>{{ event_state: event.state }}</b> <Icon type='hand-pointer' className={iconClassName} /></Trans></span>,
      content: renderTarget(event.target)
    }
  }

  const renderLaserEvent = (event) => {
    return {
      title: <span><Trans>{{ agent_name: agentInfo().name }} {{ event_state: event.state === 'touchstart' ? t('started') : t('finished') }} using <b>laser pointer</b></Trans> <Icon type='laserpointer' className={iconClassName} /></span>,
      content: null
    }
  }

  const renderDrawingEvent = (event) => {
    const disappearingInkText = event.disappears_in != null ? t(' that will disappear in {{ duration }} seconds', { duration: (event.disappears_in + (event.fade_duration ?? 1000)) / 1000 }) : null

    return {
      title: <span key='desc'><Trans>{{ agent_name: agentInfo().name }} drew an annotation{{ disappearing_ink_text: disappearingInkText }}<Icon type='ink-pen' className={iconClassName} /></Trans>{t('')}</span>,
      content: <div key='annotation' className='mt-2.5 w-auto text-center bg-white p-3.5 rounded-lg self-stretch'><img className='max-h-52 max-w-[80%]' src={event.image} alt='' /></div>
    }
  }

  const renderNavigationEvent = (event) => {
    return {
      title: t('Device navigated to page'),
      content: <code>{event.url}</code>
    }
  }

  const renderEvent = (event) => {
    switch (event.action) {
      case 'laser':
        return renderLaserEvent(event)
      case 'drawing':
        return renderDrawingEvent(event)
      case 'keypress':
        return renderKeypressEvent(event)
      case 'input':
        return renderInputEvent(event)
      case 'touch':
      case 'mouse':
        return renderPointerEvent(event)
      case 'navigate':
        return renderNavigationEvent(event)
    }

    return null
  }

  const renderSubtitle = (event) => {
    return <Timestamp timestamp={event.timestamp} />
  }

  const content = renderEvent(event)

  if (!content) {
    return false
  }

  return (
    <TimelineEvent
      className={mergeClassNames('break-words', className)}
      icon={
        event.agent
          ? <UserIcon user={agentInfo()} />
          : <IconContainer className='text-black bg-grey'><Icon type='mobile' className='h-6' /></IconContainer>
      }
      title={content.title}
      subtitle={renderSubtitle(event)}
      variant={variant}
    >
      {content.content}
    </TimelineEvent>
  )
}

export default SessionAction
