import qs from 'qs'
import RESTObject from '../redux-rest'

export default class MagicLink extends RESTObject {
  static namespace () {
    return 'MagicLink'
  }

  static url (params, context = {}) {
    return `/api/1/magiclink/auth?${qs.stringify(context)}`
  }
}
