import React, { useEffect, useState } from 'react'
import Checkbox from '../../components/Checkbox'
import Input from '../../components/Input'
import AllowedRegions from '../accounts/AllowedRegions.state'
import ArrayMapEditor from '../../components/ArrayMapEditor'
import SessionConfig from '../sessions/SessionConfig.state'

const AdvancedConfiguration = ({ account }) => {
  const [visible, setVisible] = useState(false)
  const [regions, setRegions] = useState([])
  const [sessionConfig, setSessionConfig] = useState(null)

  useEffect(() => {
    if (!visible) return

    const fetchData = async () => {
      const res = await AllowedRegions.fetch(AllowedRegions.types.GET, {}, { forceAccount: account.license_key })
      const resource = await res.json()
      if (res.status === 200) setRegions(resource.allowed_regions)
    }

    fetchData()
  }, [visible, account.license_key])

  useEffect(() => {
    if (!visible) return

    const fetchData = async () => {
      const res = await SessionConfig.fetch(SessionConfig.types.GET, {}, { forceAccount: account.license_key })
      const resource = await res.json()
      if (res.status === 200) setSessionConfig(resource)
    }

    fetchData()
  }, [visible, account.license_key])

  const updateAllowedRegions = async (value) => {
    const regions = value.split(',').map(region => region.trim()).filter(region => !!region)
    const res = await AllowedRegions.fetch(AllowedRegions.types.UPDATE, { allowed_regions: regions }, { forceAccount: account.license_key })
    const resource = await res.json()
    if (res.status === 200) setRegions(resource.allowed_regions)
  }

  const updateSessionConfig = async (contentTypes) => {
    const res = await SessionConfig.fetch(SessionConfig.types.UPDATE, { content_types: contentTypes }, { forceAccount: account.license_key })
    const resource = await res.json()
    if (res.status === 200) setSessionConfig(resource)
  }

  const renderAllowedRegions = () => {
    return (
      <Input
        onBlur={(e) => updateAllowedRegions(e.target.value)}
        style={{ width: 400, padding: 5 }}
        type='text'
        defaultValue={regions.join(',')}
      />
    )
  }

  const renderContentTypes = () => {
    if (!sessionConfig) return null
    return (
      <ArrayMapEditor arrayMap={sessionConfig.content_types} onArrayMapChange={updateSessionConfig} keyPlaceHolder='text/html' valuePlaceHolder='*.mysite.com' editOnlyKeys={['text/html']} />
    )
  }

  return (
    <div style={{ border: '1px solid lightgrey', backgroundColor: visible ? '#f3f3f3' : '' }}>
      <div className='p-1.5' style={{ borderBottom: visible ? '1px solid lightgrey' : '' }}>
        <b>Advanced Configuration</b> <Checkbox style={{ float: 'right' }} type='checkbox' onChange={(e) => setVisible(e.target.checked)} />
      </div>
      {visible &&
        <>
          <div className='p-1.5 m-1.5'>
            <div className='mb-1.5'>Allowed Regions</div>
            <div>{renderAllowedRegions()}</div>
          </div>

          <div className='p-1.5 m-1.5'>
            <div className='mb-1.5'>Proxy Content Types</div>
            <div>{renderContentTypes()}</div>
          </div>
        </>}
    </div>
  )
}

export default AdvancedConfiguration
