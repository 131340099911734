import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccountMenu from '../../components/AccountMenu'
import Toolbar, { ToolbarButton } from '../../components/Toolbar'
import UpgradeBanner, { showUpgradeBanner } from '../billing/UpgradeBanner'
import UserIcon from '../users/UserIcon'
import User from '../users/User.state'
import Account from '../accounts/Account.state'
import mergeClassNames from '../../utils/mergeClassNames'
import Icon from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'
import CodeEntry from '../sessions/CodeEntry'
import { useDispatch, useSelector } from 'react-redux'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import SkipToMainContent from '../../components/SkipToMainContent'
import useScrollPosition from '../../hooks/useScrollPosition'
import GlobalError from '../../components/GlobalError'
import usePageTitle from '../../hooks/usePageTitle'

const NavBar = ({ hide = false, hideCodeEntry = false, subMenu }) => {
  const [floatingMenuStartPosition, setFloatingMenuStartPosition] = useState(0)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ui = useSelector(state => state.ui)
  const account = useActiveAccountSelector()
  const accounts = useSelector(state => Account.fromState(state))
  const user = useSelector(state => User.fromState(state))
  const scrollPosition = useScrollPosition()

  // Set the floating menu start position when the upgrade banner is shown. We need to calculate the height of the
  // banner as it can vary depending on the content and viewport.
  useEffect(() => {
    if (showUpgradeBanner(account)) {
      setFloatingMenuStartPosition(document.querySelector('#banners')?.offsetHeight)
    }
  }, [account])

  const logout = () => {
    dispatch(User.logout())
      .then(() => {
        window.location = '/'
      })
  }

  const setActiveAccount = (account) => {
    dispatch(Account.setActiveAccount(account))
  }

  return (
    <Toolbar className={mergeClassNames('flex gap-x-4 gap-y-7 p-4 sticky top-0 inset-x-0 z-40 backdrop-blur-[6px] bg-grey-dark/50', hide && 'hidden')}>
      <div className={mergeClassNames('absolute top-0 start-0 w-full h-full shadow-nav-scrolled opacity-0 transition-opacity duration-300 ease-in-out pointer-events-none', scrollPosition > floatingMenuStartPosition && 'opacity-100')} />
      {hide === false && (
        <div className='flex gap-x-4 md:max-lg:gap-x-3'>
          <NavBarButton
            id='navigation-devices'
            iconType='mobile'
            text={t('Connect')}
            route='/dashboard'
            end
          />
          <NavBarButton
            id='navigation-sessions'
            iconType='history'
            text={t('Sessions')}
            route='/dashboard/history'
          />
          {Account.hasFeature(account.resource, 'present_mode', true) && (
            <NavBarButton
              id='navigation-present'
              iconType='slideshare'
              text={t('Present')}
              route='/dashboard/present'
            />
          )}
          <NavBarButton
            id='navigation-settings'
            iconType='cog'
            text={t('Settings')}
            route='/dashboard/settings'
          />
        </div>
      )}
      <div className={mergeClassNames('hidden sm:ms-auto sm:flex md:items-center', ui.user_info === false && 'md:pe-3.5', hide && 'sm:hidden', hideCodeEntry && 'hidden sm:hidden md:hidden', subMenu && 'hidden md:flex')}>
        <CodeEntry
          inputClassName='sm:h-8 sm:w-6 sm:p-0 sm:max-md:text-base sm:max-md:placeholder:text-base'
          showHint
        />
      </div>
      {ui.user_info && hide === false && (
        <div className={mergeClassNames('relative ms-auto sm:max-md:ms-0 md:mx-0.5', hideCodeEntry && 'sm:max-md:ms-auto md:ms-auto')}>
          <AccountMenu
            className='top-13 end-0 sm:top-11 md:max-lg:-end-1 lg:top-14'
            user={user.resource}
            organisationName={account.resource.organisation_name}
            currentAccount={account.resource}
            accounts={accounts.collection}
            setActiveAccount={setActiveAccount}
            showContact={ui.show_contact}
            logout={logout}
          >
            <UserIcon user={user.resource} expandable />
          </AccountMenu>
        </div>
      )}
    </Toolbar>
  )
}

const NavBarButton = ({ id, iconType, text, route, ...props }) => (
  <ToolbarButton
    aria-labelledby={id}
    title={text}
    className='justify-center gap-x-1'
    to={`${route}${window.location.search}`}
    {...getTestId(id)}
    {...props}
  >
    {({ isActive }) => (
      <>
        <Icon type={iconType} className={mergeClassNames('h-6 relative after:hidden after:absolute after:border-b after:w-[20px] after:h-px after:bottom-[-5px] after:left-[2px] md:after:hidden', isActive && 'after:block')} />
        <span id={id} className={mergeClassNames('hidden text-md md:inline lg:text-base', !isActive && '[@media(min-width:740px)_and_(max-width:850px)]:hidden')}>{text}</span>
      </>
    )}
  </ToolbarButton>
)

const UserNavigation = ({ subMenu, hide, hideCodeEntry, title, children }) => {
  const user = useSelector(state => User.fromState(state))

  usePageTitle(title)

  return (
    <div className={mergeClassNames('flex flex-col basis-full relative bg-grey-dark')}>
      <SkipToMainContent />
      <UpgradeBanner />
      {user.resource && <NavBar hide={hide} hideCodeEntry={hideCodeEntry} subMenu={subMenu} />}
      <main className={mergeClassNames('h-full p-4 flex flex-col overflow-hidden sm:flex-row sm:pt-0 sm:gap-x-4 md:gap-x-6', hide && 'pt-4 sm:pt-4', subMenu && 'gap-y-4 lg:container lg:mx-auto')} id='main-content'>
        {title && (
          <h1 className='sr-only'>{title}</h1>
        )}
        {subMenu && (
          <div className='sm:min-w-max'>
            {subMenu}
          </div>
        )}
        <GlobalError fixed={false} hideChildrenIfBlocking>
          {children}
        </GlobalError>
      </main>
    </div>
  )
}

export default memo(UserNavigation)
