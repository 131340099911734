import _ from 'lodash'

export default function textSearch (searchable, filter) {
  if (!filter) return true
  if (_.isObject(searchable)) searchable = [..._.values(searchable)].filter(v => v)
  const searchText = searchable.join(' ').toLowerCase()
  const filterTerms = _.uniq(_.compact(filter.toLowerCase().split(' ').map(s => s.trim())))
  const matches = filterTerms.map(term => searchText.includes(term))
  return _.every(matches)
}
