import { Component } from 'react'

export default class DOMEmpty extends Component {
  static nodeFilter () {
    return null
  }

  render () {
    return null
  }
}
