import React, { useState } from 'react'
import StandardList from '../../components/StandardList'
import Timestamp from '../../components/Timestamp'
import Input from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import Select from '../../components/Select'
import SelectOption from '../../components/SelectOption'
import Account from '../accounts/Account.state'
import Icon from '../../components/Icon'
import useDebounce from '../../hooks/useDebounce'
import useThrottle from '../../hooks/useThrottle'
import AdvancedConfiguration from './AdvancedConfiguration'

const AdminAccountList = ({
  accounts,
  user,
  getAccount,
  updateAccount,
  updateFeatureSwitches,
  refresh
}) => {
  const [filter, setFilter] = useState('')
  const [sort, setSort] = useState('created')

  const onFilterUpdated = (filter) => {
    setFilter(filter)
    refreshItems()
  }

  const onSortUpdated = (sort) => {
    setSort(sort)
    refreshItems()
  }

  const refreshItems = useDebounce(() => {
    refresh(filters())
  }, 500)

  const loadMore = useThrottle(() => {
    if (accounts.working) {
      return
    }

    refresh({
      ...filters(),
      skip: accounts.collection.length
    }, { merge: true })
  }, 200)

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  const filters = () => {
    return { filter, sort }
  }

  const updateLimit = (account, field, value) => {
    const previousValue = account.limits[field]
    const newValueIsNotNumber = value === undefined || value === null || Number.isNaN(value)
    const previousValueIsNotNumber = previousValue === undefined || previousValue === null || Number.isNaN(previousValue)

    if (value === previousValue || (newValueIsNotNumber && previousValueIsNotNumber)) {
      return
    }

    updateAccount({
      id: account.id,
      limits: {
        [field]: value
      }
    }, {
      forceAccount: account.license_key
    })
  }

  const updateFeatureSwitch = async (account, feature, state) => {
    await updateFeatureSwitches({
      [feature]: { enabled: state }
    }, {
      forceAccount: account.license_key
    })

    return getAccount({
      id: account.id
    }, {
      forceAccount: account.license_key
    })
  }

  const updateStripeSubscription = (account, subscription) => {
    const previousValue = account.stripe.subscription
    const previousValueIsEmpty = !previousValue
    const newValueIsEmpty = !subscription

    if (previousValue === subscription || (previousValueIsEmpty && newValueIsEmpty)) {
      return
    }

    updateAccount({
      id: account.id,
      stripe: { subscription }
    }, {
      forceAccount: account.license_key
    })
  }

  const renderStripeSubscription = (account) => {
    if (!user.resource.superuser) {
      return null
    }

    return (
      <Input
        onBlur={(e) => updateStripeSubscription(account, e.target.value)}
        onKeyDown={handleKeyDown}
        style={{ width: 200, padding: 5 }}
        type='text'
        defaultValue={account.stripe.subscription || ''}
      />
    )
  }

  const renderLimitEditor = (account, field, options = {}) => {
    if (!account.limits) {
      return <span>Unlimited</span>
    }

    if (!user.resource.superuser) {
      return <span>{account.limits[field] || 'Unlimited'}</span>
    }

    return (
      <Input
        onBlur={(e) => updateLimit(account, field, options.float ? parseFloat(e.target.value) : parseInt(e.target.value, 10))}
        onKeyDown={handleKeyDown}
        style={{ width: 70, padding: 5 }}
        type='number'
        defaultValue={account.limits[field]}
      />
    )
  }

  const renderAccount = (account) => {
    const hasSubscription = account.stripe && account.stripe.subscription

    return (
      <div key={account.id} className='flex flex-col p-4'>
        <div className='p-1.5'>
          <a target='_blank' href={`/dashboard?forceAccount=${account.license_key}`} rel='noopener noreferrer'>
            <b style={{ fontSize: 18 }}>{account.organisation_name || 'Unnamed account'}</b>
          </a>
          <span> – </span>
          <code style={{ fontSize: 15 }}>{account.license_key}</code>
          <span> – </span>
          <span style={{ fontSize: 12 }}> {account.id}</span>
        </div>
        <div className='md:flex p-1.5'>
          <div className='flex gap-x-2 basis-full md:basis-1/2 lg:basis-1/4'>
            <Icon type='mobile' />
            <span><b>{account.devices_active}</b> / <b>{account.devices_total}</b> <small>(active / total)</small></span>
          </div>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'><b title={account.created}>Created <Timestamp timestamp={account.created} /></b></div>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>
            Banners: <Checkbox type='checkbox' disabled={hasSubscription} defaultChecked={Account.hasFeature(account, 'upgrade_banners')} onChange={(e) => updateFeatureSwitch(account, 'upgrade_banners', e.target.checked)} />
          </div>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>Timeout: <b>{renderLimitEditor(account, 'session_duration')}</b></div>
        </div>
        <div className='md:flex p-1.5'>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>Period Sessions <b>{account.sessions_period}</b> of {renderLimitEditor(account, 'sessions')}</div>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>Active Sessions <b>{account.sessions_active}</b> of {renderLimitEditor(account, 'session_concurrency')}</div>
          {
            account.stripe
              ? <div className='basis-full md:basis-1/2'>  Subscription: <b>{renderStripeSubscription(account)}</b> <b>{account.stripe.customer || ''}</b></div>
              : null
          }
        </div>
        <div className='md:flex p-1.5'>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>Period Members: <b>{account.members_period}</b> of {renderLimitEditor(account, 'members')}</div>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>Daily Members: <b>{account.members_daily}</b> of {renderLimitEditor(account, 'members_daily')}</div>
          <div className='basis-full md:basis-1/2 lg:basis-1/4'>Members Concurrency: <b>{renderLimitEditor(account, 'members_concurrency')}</b></div>
        </div>
        <AdvancedConfiguration account={account} />
      </div>
    )
  }

  const renderEmptyList = () => {
    if (accounts.working) {
      return null
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <p>No accounts found for {filter}.</p>
      </div>
    )
  }

  const renderControls = () => {
    return (
      <div className='list-controls flex gap-x-3 mx-2'>
        <Input
          value={filter || ''}
          type='text'
          placeholder='Search Accounts...'
          onChange={e => onFilterUpdated(e.target.value)}
        />
        <Select value='' onChange={e => onSortUpdated(e.target.value)}>
          <SelectOption disabled value=''>Sort By</SelectOption>
          <SelectOption value='members'>Members</SelectOption>
          <SelectOption value='devices_active'>Active Devices</SelectOption>
          <SelectOption value='devices_total'>Total Devices</SelectOption>
          <SelectOption value='created'>Created</SelectOption>
        </Select>
      </div>
    )
  }

  return (
    <StandardList
      items={accounts.collection}
      renderItem={renderAccount}
      renderEmptyList={renderEmptyList}
      onBottomReached={loadMore}
      backToTop={false}
    >{renderControls()}
    </StandardList>
  )
}

export default AdminAccountList
