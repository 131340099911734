import React from 'react'
import { useTranslation } from 'react-i18next'
import LoginButtons from './LoginButtons'
import AuthContainer from './AuthContainer'
import Icon from '../../components/Icon'
import Panel from '../../components/Panel'
import usePageTitle from '../../hooks/usePageTitle'

const RegisterScreen = () => {
  const { t } = useTranslation()

  usePageTitle(t('Create a new account'))

  return (
    <>
      <AuthContainer>
        <a href='/'>
          <Panel className='absolute end-8 md:end-12 top-4 text-black p-1 rounded-lg'>
            <Icon type='close' />
          </Panel>
        </a>
        <div className='container mx-auto px-8 md:px-4 mt-6'>
          <div className='flex flex-col max-w-2xl mx-auto'>
            <h1 className='text-h-md md:text-h-lg mb-6'>{t('Create a new account')}</h1>
            <LoginButtons register />
          </div>
        </div>
      </AuthContainer>
    </>
  )
}

export default RegisterScreen
