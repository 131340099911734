import RESTObject from '../redux-rest'

export default class StripeCheckout extends RESTObject {
  static namespace () {
    return 'StripeCheckout'
  }

  static url () {
    return '/api/1/stripe/checkout'
  }
}
