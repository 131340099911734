import AppleDevices from 'ios-device-list/devices.json'

function deviceNameFromModel (model) {
  if (model === 'x86_64') return 'Simulator'
  if (model === 'generic_x86') return 'Simulator'
  if (model === 'generic_x86_64') return 'Simulator'
  const device = AppleDevices.find(d => d.model === model)
  if (device) return device.name
  return model
}

export default deviceNameFromModel
