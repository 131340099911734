import React, { useEffect, useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import CodeEntryInput from './CodeEntryInput'
import Session from './Session.state'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CodeEntryHint from './CodeEntryHint'

const CODE_LENGTH = 6

const CodeEntry = ({ className, inputClassName, showHint = false, focusOnRender = false }) => {
  const [validating, setValidating] = useState(false)
  const [code, setCode] = useState(['', '', '', '', '', ''])
  const firstDigitRef = useRef(null)
  const lastDigitRef = useRef(null)
  const codeEntryLabelId = useId()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Run the tryComplete function when the code state changes
  useEffect(() => {
    tryComplete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  const validateCode = async (code) => {
    const session = await dispatch(Session.actionCreators().sessionForCode(code))

    if (session) {
      navigate(`/session/${session.id}${window.location.search}`)
    }
  }

  const getCode = () => {
    return code.join('').substring(0, CODE_LENGTH)
  }

  const setDigit = (digit, value) => {
    if (digit > (CODE_LENGTH - 1)) {
      return
    }

    setCode((code) => {
      const updatedCode = [...code]

      updatedCode[digit] = value

      return updatedCode
    })
  }

  const isEditable = () => {
    return !validating
  }

  const tryComplete = () => {
    if (!isEditable()) {
      return
    }

    const codeValue = getCode()

    if (codeValue.length !== CODE_LENGTH) {
      return
    }

    setValidating(true)
    validateCode(codeValue)
      .then((isValid) => {
        if (!isValid) {
          setValidating(false)
        }

        if (lastDigitRef.current && !isValid) {
          setTimeout(() => {
            lastDigitRef.current?.focus()
          }, 0)
        }
      }).catch(() => {
        setValidating(false)
      })
  }

  const handlePaste = (event) => {
    if (!isEditable()) {
      return false
    }

    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text').toString()

    // Exit early if the pasted data contains anything other than just digits
    if (!/^\d+$/.test(pastedData)) {
      return event.preventDefault()
    }

    const digits = pastedData.split('')
    // Start pasting from the first digit if the clipboard holds a full code,
    // otherwise just paste from the currently selected input onwards
    let digit = pastedData.length === CODE_LENGTH
      ? 0
      : parseInt(event.target.getAttribute('data-digit'), 10)
    let target = event.target

    digits.forEach((value) => {
      setDigit(digit, value)

      digit += 1

      if (target) {
        target = target.nextSibling
      }
    })

    if (target) {
      target.focus()
    }

    return event.preventDefault()
  }

  const handleOnChange = (event) => {
    const value = event.target.value.slice(-1)

    // validate text on any change
    const digit = parseInt(event.target.getAttribute('data-digit'), 10)

    // Only allow digits and deleting the current value
    if (/\d/.test(value) || value === '') {
      setDigit(digit, value)
    }

    if (!event.repeat && /\d/.test(value)) {
      event.target.value = ''

      const nextSibling = event.target.nextSibling

      if (nextSibling) {
        nextSibling.focus()
      }
    }
  }

  const handleKeyDown = (event) => {
    // on backspace on empty node go back to previous
    if (event.target.value.length === 0 && event.keyCode === 8) {
      if (event.target.previousSibling) {
        event.target.previousSibling.focus()
      }
    } else if (event.keyCode === 37) { // left arrow moves to previous
      if (event.target.previousSibling) {
        event.target.previousSibling.focus()
      }
      return event.preventDefault()
    } else if (event.keyCode === 39) { // right arrow moves to next
      if (event.target.nextSibling) {
        event.target.nextSibling.focus()
      }
      return event.preventDefault()
    }
  }

  const refs = {
    0: firstDigitRef,
    [CODE_LENGTH - 1]: lastDigitRef
  }
  const invalid = (!validating) && (getCode().length === CODE_LENGTH)

  return (
    <div className={className} {...getTestId('code-entry')}>
      <div className={mergeClassNames('flex gap-x-1 items-center', invalid && 'animate-shake')} role='group' aria-labelledby={`code-entry-label-${codeEntryLabelId}`}>
        {showHint && (
          <CodeEntryHint />
        )}
        <span className='sr-only' id={`code-entry-label-${codeEntryLabelId}`}>{t('Code entry')}</span>
        {code.map((value, index) => (
          <CodeEntryInput
            key={index}
            className={inputClassName}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            onPaste={handlePaste}
            disabled={!isEditable()}
            digit={index}
            value={value}
            focusOnRender={focusOnRender && index === 0}
            ref={refs[index]}
          />
        ))}
      </div>
    </div>
  )
}

export default CodeEntry
