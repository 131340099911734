import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Login from '../auth/Login'
import SessionList from './SessionList'
import UserNavigation from '../navigation/UserNavigation'
import Session from './Session.state'
import User from '../users/User.state'
import EmbedParams from '../../utils/EmbedParams'
import { useTranslation } from 'react-i18next'

const SessionHistoryDashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(state => User.fromState(state))
  const sessions = useSelector(state => Session.fromState(state))

  const getSessions = useCallback((params, options) => dispatch(Session.actionCreators().getSessions({}, params, options)), [dispatch])
  const resetSessions = useCallback(() => dispatch(Session.actionCreators().resetSession()), [dispatch])

  const refresh = useCallback((params = {}, options = {}) => {
    const filter = { agent: 'all', state: ['active', 'ended'] }
    return getSessions({
      ...params,
      ...filter
    }, options)
  }, [getSessions])

  if (!user.resource) {
    return <Login />
  }

  return (
    <UserNavigation hide={!EmbedParams.navigation()} title={t('Session history')}>
      <SessionList
        sessions={sessions}
        refresh={refresh}
        reset={resetSessions}
      />
    </UserNavigation>
  )
}

export default SessionHistoryDashboard
