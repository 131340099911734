import React from 'react'
import DOMDocument from './DOMDocument'
import DOMNode from './DOMNode'
import Frame from './Frame'
import { ToolContext } from './ToolContext'
import { CapabilitiesContext } from './CapabilitiesContext'
import { SelectedColorContext } from './SelectedColorContext'

const constructDoctypeTag = ({ name, publicId, systemId }) => {
  if (publicId && systemId) {
    return `<!DOCTYPE ${name} PUBLIC "${publicId}" "${systemId}">`
  }

  return `<!DOCTYPE ${name}>`
}

const getDoctypeTag = (documentNode) => {
  if (!documentNode) return '<!DOCTYPE html>'

  const { doctype } = documentNode

  // for backwards compatibility an undefined doctype over
  // the wire should result in the default doctype being set
  if (doctype === undefined) return '<!DOCTYPE html>'
  if (doctype) return constructDoctypeTag(doctype)

  // an explicit doctype of null means we should
  // render in quirks mode with no doctype being set
  return ''
}

export default class DOMIFrame extends DOMNode {
  static nodeFilter (node) {
    const attributes = { ...node.attributes }
    delete attributes.src
    delete attributes.srcdoc
    return { ...node, attributes }
  }

  onDOMIFrameRef = (el) => {
    this.el = el
  }

  getDocumentNode = () => this.props.node?.childNodes?.find(n => n.nodeType === 9)

  getHTMLNode = () => this.getDocumentNode()?.childNodes?.find(n => n.tagName === 'HTML')

  renderDocument = (iframe) => {
    const documentNode = this.getDocumentNode()
    const htmlNode = this.getHTMLNode()
    if (!documentNode || !htmlNode) {
      return (
        <html lang='en-GB'>
          <head>
            <style type='text/css'>{'html{background:black;}'}</style>
          </head>
          <body />
        </html>
      )
    }
    return (
      <DOMDocument
        {...this.props}
        key={documentNode.id}
        node={documentNode}
        window={iframe.contentWindow}
      />
    )
  }

  render () {
    const documentNode = this.getDocumentNode()
    const doctype = getDoctypeTag(documentNode)

    return (
      <CapabilitiesContext.Consumer>
        {(capabilities) => (
          <ToolContext.Consumer>
            {/* the key of doctype ensures the Frame component is remounted properly when there is a change in doctype */}
            {(tool) => (
              <SelectedColorContext.Consumer>
                {(selectedColor) => (
                  <Frame elRef={this.onDOMIFrameRef} capabilities={capabilities} tool={tool} doctype={doctype} key={`${doctype}-${this.getDocumentNode()?.id}`} selectedColor={selectedColor}>
                    {this.renderDocument}
                  </Frame>
                )}
              </SelectedColorContext.Consumer>
            )}
          </ToolContext.Consumer>
        )}
      </CapabilitiesContext.Consumer>
    )
  }
}
