import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Member from './Member'
import StandardList from '../../components/StandardList'
import search from '../../utils/textSearch'
import Dialog from '../../components/Dialog'

const MemberList = ({ user, filter, members, updateAccountMember, removeAccountMember, children }) => {
  const [showRemoveAccountMemberDialog, setShowRemoveAccountMemberDialog] = useState(false)
  const [accountMemberToRemove, setAccountMemberToRemove] = useState()
  const { t } = useTranslation()

  const filterMember = (member) => {
    if (!filter) {
      return true
    }

    const searchable = `${member.name} ${member.username} ${member.email} ${member.role}`.toLowerCase().split(' ')

    return search(searchable, filter)
  }

  const handleRemoveAccountMember = () => {
    setShowRemoveAccountMemberDialog(false)
    setAccountMemberToRemove(null)

    removeAccountMember(accountMemberToRemove)
  }

  const showAccountMemberRemovalDialog = (member) => {
    setAccountMemberToRemove(member)
    setShowRemoveAccountMemberDialog(true)
  }

  const renderMember = (member) => {
    const isSelf = member.id === user.resource.id

    return (
      <Member
        member={member}
        self={isSelf}
        updateAccountMember={isSelf ? null : updateAccountMember}
        removeAccountMember={showAccountMemberRemovalDialog}
      />
    )
  }

  const renderEmptyList = () => {
    if (members.working) {
      return null
    }

    return (
      <div className='text-center'>
        <p>{t('No members found')}</p>
      </div>
    )
  }

  return (
    <>
      <StandardList
        items={members.collection.filter(filterMember)}
        working={members.working && (members.collection.length === 0)}
        renderItem={renderMember}
        renderEmptyList={renderEmptyList}
        scrollableContentClassName='p-0 md:p-0'
        backToTop={false}
      >
        {children}
      </StandardList>
      <Dialog
        panelClassName='sm:min-w-[400px]'
        isOpen={showRemoveAccountMemberDialog}
        titleText={t('Remove member')}
        acceptText={t('Remove member')}
        acceptProps={{ variant: 'error' }}
        cancelProps={{ variant: 'secondary' }}
        onAccept={handleRemoveAccountMember}
        onCancel={() => setShowRemoveAccountMemberDialog(false)}
        onClose={() => setShowRemoveAccountMemberDialog(false)}
      >
        <p>{t('Really remove member?')}</p>
      </Dialog>
    </>
  )
}

export default MemberList
