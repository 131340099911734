import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const MagicLinkRedirect = () => {
  const params = useParams()

  useEffect(() => {
    window.location = `/api/1/magiclink/auth/callback${window.location.search}&magic=${params.token}`
  }, [params.token])
}

export default MagicLinkRedirect
