import React from 'react'
import { colors } from '../modules/sessions/colorPicker'
import { ExpandableVerticleMenu } from './ExpandableVerticleMenu'
import mergeClassNames from '../utils/mergeClassNames'

export function ColorPicker ({
  selectedColor,
  onColorPicked
}) {
  return (
    <ExpandableVerticleMenu
      Button={() => <div
        className='w-5 h-5 rounded-full shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_#F5F6FB] cursor-pointer'
        style={{ background: selectedColor.color }}
                    />}
    >
      {colors.map((colorObj) => {
        const { color, title } = colorObj

        return (
          <div
            className='flex items-center justify-center md:rounded'
            key={`color-picker-${title}`}
          >
            <div
              className={mergeClassNames('w-5 h-5 rounded-full cursor-pointer hover:shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_#F5F6FB]', color === selectedColor.color && 'shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_#F5F6FB]')}
              style={{ background: color }}
              onClick={() => {
                onColorPicked(colorObj)
              }}
            />
          </div>
        )
      })}
    </ExpandableVerticleMenu>

  )
}
