import RESTObject from '../redux-rest'

export default class Subscription extends RESTObject {
  static namespace () {
    return 'Invoices'
  }

  static url () {
    return '/api/1/stripe/invoices'
  }
}
