import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useCombobox } from 'downshift'
import { useTranslation } from 'react-i18next'
import useElementPosition from '../hooks/useElementPosition'
import Input from './Input'
import { getTestId } from '../utils/getTestId'
import PopoverContainer from './PopoverContainer'
import mergeClassNames from '../utils/mergeClassNames'
import Icon from './Icon'

const baseTestId = 'tagging-text-entry'

function getFilteredItems (inputValue = '') {
  const wordsArr = inputValue.split(' ')
  const wordBeforeCursor = wordsArr[wordsArr.length - 1].toLowerCase()
  return function itemsFilter (item) {
    return (
      !inputValue ||
      item.field?.toLowerCase().includes(wordBeforeCursor) ||
      item.label?.toLowerCase().includes(wordBeforeCursor)
    )
  }
}

function isVisible (isOpen, items) {
  return isOpen && items.length > 0
}

export default function TaggingTextEntryInput ({
  items,
  onSelect,
  inputRef,
  inputOnKeyDown,
  handleOnChange,
  value,
  ...props
}) {
  const [inputItems, setInputItems] = useState(items)
  const { top, left, updatePosition } = useElementPosition()
  const hasSelectedItem = useRef(false)
  const { t } = useTranslation()

  const stateReducer = useCallback((state, actionAndChanges) => {
    const { type, changes } = actionAndChanges

    switch (type) {
      case useCombobox.stateChangeTypes.InputChange: {
        const { inputValue } = changes
        const filteredItems = items.filter(getFilteredItems(inputValue))
        setInputItems(filteredItems)

        // when an item is selected, the selected action (InputKeyDownEnter or ItemClick is emitted
        // first and then this. The code bellow prevents from handling the selection twice
        if (!hasSelectedItem.current) {
          setTimeout(() => handleOnChange(inputValue), 0)
        }
        hasSelectedItem.current = false

        // if there're results and no highlighted item, set the first one as highlighted
        // otherwise, we reset the highlighted item when the input is cleared or no item matches
        if (filteredItems.length > 0 && state.highlightedIndex === -1) {
          return {
            ...changes,
            highlightedIndex: 0
          }
        } else if (filteredItems.length === 0 || inputValue === '') {
          return {
            ...changes,
            highlightedIndex: -1
          }
        }

        return {
          ...changes,
          highlightedIndex: state.highlightedIndex
        }
      }

      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...changes,
          // we only select items explicity
          selectedItem: null,
          inputValue: state.inputValue
        }

      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick: {
        hasSelectedItem.current = true
        setTimeout(() => onSelect(changes.selectedItem), 0)

        // reset to all items
        setInputItems(items)

        return {
          ...changes,
          inputValue: value,
          selectedItem: null,
          highlightedIndex: -1
        }
      }
      case useCombobox.stateChangeTypes.MenuMouseLeave:
        if (changes.isOpen) {
          return state
        }

        return changes

      default:
        return changes
    }
  }, [items, hasSelectedItem, handleOnChange, onSelect, setInputItems, value])

  const {
    isOpen,
    openMenu,
    closeMenu,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    setInputValue
  } = useCombobox({
    items: inputItems,
    stateReducer,
    itemToString (item) {
      return item ? item.label : ''
    },
    initialInputValue: value
  })

  const inputOptions = useMemo(() => {
    return ({
      ref: (node) => {
        if (node && node !== inputRef.current) {
          inputRef.current = node

          // Expose the setInputValue on the ref so that the input can be reset from the parent
          inputRef.current.setInputValue = setInputValue
          updatePosition(inputRef.current)
        }
      }
    })
  }, [inputRef, setInputValue, updatePosition])

  const { onKeyDown: baseOnKeyDown, ...inputProps } = getInputProps(inputOptions)

  const onKeyDown = useCallback((ev) => {
    inputOnKeyDown(ev)
    baseOnKeyDown(ev)
  }, [inputOnKeyDown, baseOnKeyDown])

  useEffect(() => {
    setInputValue(value)
  }, [value, setInputValue])

  return (
    <div className='w-full'>
      <div {...getComboboxProps()}>
        <label {...getLabelProps()} className='sr-only'>{t('Filter devices...')}</label>
        <Input
          type='text'
          className='border-none min-w-max w-full'
          onFocus={openMenu}
          onBlur={closeMenu}
          testId={`${baseTestId}-input`}
          {...{ onKeyDown }}
          {...inputProps}
          {...props}
        />
      </div>

      <PopoverContainer
        className={mergeClassNames('bg-white text-left rounded-[10px] min-w-[235px] p-2 start-0 top-14')}
        arrowPosition='left'
        show={isVisible(isOpen, inputItems)}
      >
        <div
          {...getMenuProps()}
          className='flex flex-col gap-y-2'
          style={{
            left,
            top
          }}
          {...getTestId(`${baseTestId}-menu`)}
        >
          {inputItems.map((item, index) => (
            <div
              className={mergeClassNames('hover:bg-grey rounded-lg px-4 py-3 cursor-pointer flex items-center gap-x-4', {
                'bg-grey': highlightedIndex === index
              })}
              key={`${item.field}-${index}`}
              {...getTestId(`${baseTestId}-menu-item`)}
              {...getItemProps({ item, index })}
            >
              <div className='w-6 flex items-center justify-center'><Icon className='h-6' type={item.iconType} /></div>
              <div className='label flex flex-col gap-y-2'>
                {t('by {{label}}', { label: item.label })}
              </div>
            </div>
          ))}
        </div>
      </PopoverContainer>
    </div>
  )
}
