import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/Button'
import Login from '../auth/Login'
import StandardList from '../../components/StandardList'
import Input from '../../components/Input'
import User from '../users/User.state'
import Config from './Config.state'
import Icon from '../../components/Icon'
import Textarea from '../../components/TextArea'
import Alert from '../../components/Alert'

const AdminConfiguration = () => {
  const user = useSelector(state => User.fromState(state))
  const config = useSelector(state => Config.fromState(state))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const keyInput = useRef(null)
  const valueInput = useRef(null)

  useEffect(() => {
    dispatch(Config.actionCreators().getConfig())
  }, [dispatch])

  useEffect(() => {
    if (!user.resource.superuser) {
      return navigate('/dashboard', { replace: true })
    }
  }, [user.resource.superuser, navigate])

  const updateConfig = (resource = {}, params = {}, options = {}) => {
    dispatch(Config.actionCreators().updateConfig(resource, params, options))
  }

  const removeConfig = (resource = {}, params = {}, options = {}) => {
    dispatch(Config.actionCreators().removeConfig(resource, params, options))
  }

  const updateSetting = () => {
    const key = keyInput.current.value.trim()
    const value = valueInput.current.value.trim()

    if (!key) {
      return
    }

    updateConfig({ id: key, value })
  }

  const deleteConfig = (id) => {
    removeConfig({ id })
  }

  const renderSetting = ({ id, value }) => {
    return (
      <div key={id} className='p-2.5 flex justify-between'>
        <div>
          <b>{id}</b>
          <span> ⟶ </span>
          <code>{value}</code>
        </div>
        <Icon type='trash-empty' className='cursor-pointer hover:text-red-900' onClick={() => deleteConfig(id)} />
      </div>
    )
  }

  const renderEmptyList = () => {
    return <div>No settings defined for instance</div>
  }

  const renderEditor = () => {
    return (
      <div className='flex flex-col items-baseline gap-2 md:flex-row'>
        <Input className='w-auto' ref={keyInput} type='text' placeholder='Key' />
        <span> ⟶ </span>
        <Textarea className='w-auto resize' ref={valueInput} type='text' placeholder='Value' />
        <Button onClick={updateSetting} thinking={config.working}>Save</Button>
      </div>
    )
  }

  if (!user.resource) {
    return <Login />
  }

  return (
    <div className='flex flex-col gap-y-4 p-4'>
      <h2>Instance Configuration</h2>
      <p>Here you can configure global setting for your Cobrowse instance. <i>Be very careful changing these settings.</i></p>
      <div>
        <StandardList
          items={config.collection}
          working={config.working && (config.collection.length === 0)}
          renderItem={renderSetting}
          renderEmptyList={renderEmptyList}
          scrollableContentClassName='pb-0'
        />
      </div>
      <Alert type='warning'>You must <b>restart the API containers</b> after changing any of these settings before they take effect!</Alert>
      <div>
        {renderEditor()}
      </div>
    </div>
  )
}

export default AdminConfiguration
