import React, { useId } from 'react'
import Icon from './Icon'
import mergeClassNames from '../utils/mergeClassNames'
import Button from './Button'
import { useTranslation } from 'react-i18next'

const StepButton = ({ onClick, iconType, label }) => {
  return (
    <Button
      variant='secondary'
      className='h-7 w-7 p-3 rounded-sm'
      onClick={onClick}
      tabIndex={-1}
    >
      <Icon type={iconType} className='h-4' />
      <span className='sr-only'>{label}</span>
    </Button>
  )
}

const NumberInput = ({ value, onChange, min, max, step = 1, className, label, ...props }) => {
  const inputId = useId()
  const { t } = useTranslation()

  const onBlur = () => {
    setValue(value || 1)
  }

  const onTextChanged = (e) => {
    setValue(e.target.value)
  }

  const isFloat = (number) => {
    return Number(number) === number && number % 1 !== 0
  }

  const setValue = (value) => {
    const clean = isFloat(step)
      ? Math.max(min, parseFloat(value)).toFixed(2)
      : Math.max(1, parseInt(value, 10))

    onChange && onChange((value === '') ? '' : clean)
  }

  const stepDown = () => {
    let newValue = value - step

    if (isFloat(newValue)) {
      newValue = newValue.toFixed(2)
    }

    if (min && newValue < min) {
      return
    }

    setValue(newValue)
  }

  const stepUp = () => {
    let newValue = value + step

    if (isFloat(newValue)) {
      newValue = newValue.toFixed(2)
    }

    if (max && newValue > max) {
      return
    }

    setValue(newValue)
  }

  return (
    <div className={mergeClassNames('flex gap-x-1 items-center justify-center', className)}>
      <StepButton iconType='minus' onClick={stepDown} label={t('Decrease')} />
      {label && <label htmlFor={inputId} className='sr-only'>{label}</label>}
      <input
        id={inputId}
        type='number'
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onTextChanged}
        onBlur={onBlur}
        className='text-center min-w-[3ch] m-0 p-0 border-0 bg-transparent appearance-none'
        style={{ width: `${value.toString().length + 1}ch` }}
        {...props}
      />
      <StepButton iconType='plus' onClick={stepUp} label={t('Increase')} />
    </div>
  )
}

export default NumberInput
