import React, { useState, useEffect, useRef, useCallback } from 'react'
import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ButtonLink from '../../components/ButtonLink'
import CodeEntry from '../sessions/CodeEntry'
import Device from './Device'
import FilterParamsEditor from '../filters/FilterParamsEditor'
import NewDeviceNotification from './NewDeviceNotification'
import StandardFields from './StandardFields'
import StandardList from '../../components/StandardList'
import FilterParams from '../filters/FilterParams'
import Icon from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import EmbedParams from '../../utils/EmbedParams'
import useThrottle from '../../hooks/useThrottle'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import Dialog from '../../components/Dialog'
import CodeEntryHint from '../sessions/CodeEntryHint'
import EmptyDeviceList from './EmptyDeviceList'

const DeviceList = ({ refresh, devices, getPresence }) => {
  const [filter, setFilter] = useState(FilterParams.search)
  const [codeEntryOpen, setCodeEntryOpen] = useState(false)
  const account = useActiveAccountSelector()
  const filterEditor = useRef()
  const { t } = useTranslation()

  const filters = useCallback(() => {
    return {
      filter: FilterParams.search,
      ...FilterParams.parse()
    }
  }, [])

  const refreshDevices = useCallback(() => {
    refresh(filters())
  }, [refresh, filters])

  useEffect(() => {
    refreshDevices()
  }, [refreshDevices])

  const loadMore = useThrottle(() => {
    if (devices.working) {
      return
    }

    const devicesCollection = devices.collection
    const last = devicesCollection[devicesCollection.length - 1]

    refresh({
      ...filters(),
      seen_before: (last && last.last_active) || 0
    }, { merge: true })
  }, 600)

  const commitFilter = (text) => {
    refreshDevices()
    setFilter(text)
  }

  const clearFilters = () => {
    filterEditor.current.clearFilters()
  }

  const addFilter = useCallback((key, value) => {
    FilterParams.params = { ...FilterParams.params, [key]: value }
    filterEditor.current.setParams(FilterParams.search, FilterParams.params)
    refreshDevices()
  }, [refreshDevices])

  const suggestionsFor = (field) => {
    if (!devices) return []

    const suggestions = devices.collection.map(d => d.custom_data && d.custom_data[field]).filter(v => v)

    return uniq(suggestions.map(v => `${v}`))
  }

  const renderDevice = (device) => {
    return (
      <Device
        key={device.id}
        device={device}
        getPresence={getPresence}
        addFilter={addFilter}
      />
    )
  }

  const renderEmptyList = () => {
    if (devices.working) {
      return null
    }

    if (filter || (!FilterParams.empty())) {
      return renderFilterInfo()
    }

    return (
      <EmptyDeviceList account={account} />
    )
  }

  const renderFilterInfo = () => {
    if (devices.working) {
      return null
    }

    if (!filter && FilterParams.empty()) {
      return null
    }

    return (
      <div className='text-center mt-5'>
        <span>{t('No devices found that match your filters.')}</span>{' '}
        <ButtonLink className='underline' onClick={clearFilters}>{t('Clear filters')}</ButtonLink>
      </div>
    )
  }

  const renderControls = () => {
    return (
      <div className={mergeClassNames('flex gap-x-3 flex-wrap')}>
        <div className='flex-1 min-w-0'>
          <FilterParamsEditor
            ref={filterEditor}
            loading={devices.working}
            placeholder={t('Filter devices...')}
            autocompleteFields={StandardFields.customData()}
            getSuggestions={suggestionsFor}
            onUpdated={commitFilter}
          />
        </div>
        <div className={mergeClassNames('sm:hidden', !EmbedParams.navigation() && 'sm:block md:hidden')}>
          <Button
            variant='tertiary'
            onClick={() => setCodeEntryOpen(true)}
            className='p-2 w-12 h-12 flex items-center justify-center rounded-lg'
            {...getTestId('show-code-entry-button')}
            aria-label={t('Show code entry')}
          >
            <Icon type='numbers' className='h-6' />
          </Button>
          <Dialog
            isOpen={codeEntryOpen}
            onClose={() => setCodeEntryOpen(false)}
            className='inset-x-0 inset-y-auto top-16'
            panelClassName='bg-grey'
          >
            <div className='flex justify-between'>
              <div className='flex flex-grow gap-x-2'>
                <span>{t('Enter a code')}</span>
                <CodeEntryHint className='w-auto' contentClassName='end-auto start-6 top-auto bottom-8' />
              </div>
              <Button
                variant='plain'
                onClick={() => setCodeEntryOpen(false)}
              >
                <Icon type='close' className='h-6' />
              </Button>
            </div>
            <CodeEntry focusOnRender />
          </Dialog>
        </div>
        <div>
          <Button
            variant='tertiary'
            onClick={refreshDevices}
            className='p-2 w-12 h-12 flex items-center justify-center rounded-lg'
            {...getTestId('refresh-devices-button')}
            aria-label={t('Refresh')}
          >
            <Icon type='refresh' className='h-6' spin={devices.working} reverse />
            <NewDeviceNotification filters={filters()} devices={devices.collection} />
          </Button>
        </div>
        <div className={mergeClassNames('hidden', !EmbedParams.navigation() && 'w-full order-first md:flex md:items-center md:w-auto md:order-none md:mb-0')}>
          <CodeEntry
            inputClassName='md:h-12 md:w-12 lg:h-12 lg:w-12'
            showHint
          />
        </div>
      </div>
    )
  }

  return (
    <StandardList
      testId='device-list'
      scrollableContentClassName='h-full'
      listContainerClassName='sm:gap-y-2'
      items={devices.collection}
      renderItem={renderDevice}
      renderEmptyList={renderEmptyList}
      onBottomReached={loadMore}
    >{renderControls()}
    </StandardList>
  )
}

export default DeviceList
