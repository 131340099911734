export const UPDATE_SCREEN = 'screens/UPDATE'

const initialState = {
  resource: false
}

export default function screens (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SCREEN:
      return { ...state, resource: action.resource }

    default:
      return state
  }
}

export const updateScreen = (resource) => {
  return {
    type: UPDATE_SCREEN,
    resource
  }
}
