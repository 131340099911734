import React, { useState, useCallback } from 'react'
import { ToolbarButton } from '../../components/Toolbar'
import Icon from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'
import Callout from '../../components/Callout'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import mergeClassNames from '../../utils/mergeClassNames'
import { ExpandableVerticleMenu } from '../../components/ExpandableVerticleMenu'

export function DisconnectButton({ account, session, user, performAction, className }) {
  const { t } = useTranslation()
  const isAgentOfRecord = session.agent.id === user.resource.id
  const isAdmin = account.resource.features.manage_account === true

  const Btn = useCallback(({ isExpanded }) => {
    return (
      <ToolbarButton
        className={mergeClassNames('bg-red-v2-500 text-white flex-col justify-center min-w-[56px] hover:bg-red-v2-600 hover:text-white h-10 px-5 py-1 rounded-full md:rounded-full', isExpanded && 'bg-slate-800 hover:bg-slate-800', className)}
        onClick={() => isAgentOfRecord ? performAction('end') : null}
        {...getTestId('toolbar-button-end-session')}
      >
        <span className='sr-only'>{t('End session')}</span>
        <Icon type='phone' className='w-6 h-6' />
      </ToolbarButton>
    )
  }, [isAgentOfRecord, performAction, className])

  console.log('🚀 ~ DisconnectButton ~ isAgentOfRecord:', isAgentOfRecord)
  if (isAgentOfRecord) {
    return <Btn />
  }

  return (
    <ExpandableVerticleMenu
      Button={Btn}
      className='w-auto'
      menuClassname='p-1 gap-1 w-[352px] right-[-60px] md:w-auto md:right-auto'
      isHorizontal
    >
      <Button
        Component={Link} to={`/dashboard${window.location.search}`} variant='secondary' className='flex flex-1 px-4 py-3 text-sm font-medium text-white h-9 rounded-3xl whitespace-nowrap bg-slate-900 hover:bg-slate-950 disabled:hover:bg-grey'
      >
        {t('Leave session')}
      </Button>
      <Button onClick={() => performAction('end')} variant='error' className='flex flex-1 px-4 py-3 text-sm font-medium text-white bg-red-v2-500 whitespace-nowrap h-9 rounded-3xl hover:bg-red-v2-600 disabled:hover:bg-grey'>{t('End session')}</Button>
    </ExpandableVerticleMenu>
  )
}
