import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { EventEmitter } from 'events'
import RemoteScreen from '../screens/RemoteScreen'
import { updateFrame } from '../sessions/Displays.state'
import { proxyUrl } from '../proxy'
import { updateMouse } from '../sessions/Mouse.state'
import { getAnnotationColor } from '../sessions/colorPicker'

class RenderScreen extends Component {
  #socket = new EventEmitter()

  constructor (props) {
    super(props)
    this.state = {
      displayId: null,
      height: 600,
      width: 800
    }
  }

  componentDidMount () {
    window.socket = this.#socket
    this.#socket.on('frame', (resource) => this.props.updateFrame(resource, this.#socket))
    this.#socket.on('mouse', (resource) => this.props.updateMouse(resource))
  }

  componentDidUpdate () {
    if (!this.state.displayId && Object.keys(this.props.displays).length > 0) {
      this.setState({ displayId: Object.keys(this.props.displays)[0] })
    }
  }

  render () {
    return this.state.displayId
      ? <RemoteScreen
          display={this.props.displays[this.state.displayId]}
          width={this.state.width}
          height={this.state.height}
          mouse={this.props.mouse}
          scale={1}
          toolHandler={() => undefined}
          onSize={(size) => {
            if (size.width !== this.state.width || size.height !== this.state.height) {
              this.setState(size)
              this.#socket.emit('_resize', { size })
            }
          }}
          onDOMChange={() => undefined}
          proxy={proxyUrl}
          selectedColor={getAnnotationColor()}
        />
      : null
  }
}

const mapStateToProps = state => ({
  displays: state.displays,
  mouse: state.mouse.resource
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFrame,
  updateMouse
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderScreen)
