import { compile, middleware, serialize, stringify, RULESET } from 'stylis'

const SUPPORTED_PSEUDO_CLASSES = /:hover|:active|:focus(?!-)/

const rewriteSelector = (selector) => `[__cbio_pseudo_${selector.slice(1)}]`

const rewritePseudoClassMiddleware = (element) => {
  if (element.type !== RULESET) return
  if (!SUPPORTED_PSEUDO_CLASSES.test(element.value)) return
  const pseudoSelectors = element.props.filter(selector => SUPPORTED_PSEUDO_CLASSES.test(selector))
  const attrSelectors = pseudoSelectors.map(selector => selector.replace(SUPPORTED_PSEUDO_CLASSES, rewriteSelector))

  element.props = [...element.props, ...attrSelectors]
}

export const rewriteSelectors = (css) => serialize(compile(css), middleware([rewritePseudoClassMiddleware, stringify]))
