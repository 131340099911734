import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/light.css'
import './DateRangePicker.css'

const DateRangePicker = ({ selectedDates, inline = false, onChange, ...props }) => {
  const datePickerRef = useRef()

  useEffect(() => {
    const fp = flatpickr(datePickerRef.current, {
      mode: 'range',
      inline,
      showMonths: 1,
      defaultDate: selectedDates,
      maxDate: moment().toDate(),
      onChange: (dates) => dates.length === 2 && onChange([
        moment(dates[0]).startOf('day').toDate(),
        moment(dates[1]).endOf('day').toDate()
      ])
    })

    fp.setDate(selectedDates)
    // Move to the beginning of the date range so that it's noticeable that the date preset buttons actually change the selection
    fp.jumpToDate(selectedDates[0])
  }, [selectedDates, inline, onChange])

  return (
    <div ref={datePickerRef} {...props} />
  )
}

export default DateRangePicker
