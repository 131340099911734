import React from 'react'
import ReactDOM from 'react-dom'

export default class ShadowRoot extends React.PureComponent {
  constructor () {
    super()
    this.state = { initialized: false }
  }

  componentDidMount () {
    const parent = this.placeholder.parentNode
    if (!parent.attachShadow) return
    this.shadowRoot = parent.attachShadow({ mode: 'open' })

    if (this.props.stylesheets) { this.shadowRoot.adoptedStyleSheets = this.props.stylesheets }

    this.props.elRef(this.shadowRoot)

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ initialized: true })
  }

  onPlaceholderRef = (el) => {
    this.placeholder = el
    this.props.elRef(el)
  }

  render () {
    if (!this.state.initialized) return <span ref={this.onPlaceholderRef} />
    else return ReactDOM.createPortal(this.props.children, this.shadowRoot)
  }
}
