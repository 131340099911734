import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import Icon from './Icon'
import Dialog from './Dialog'
import mergeClassNames from '../utils/mergeClassNames'
import isInIframe from '../utils/isInIframe'

/*
 * The sidebar component is a bit hacky. For the mobile view, the state is
 * managed by the component itself. For the desktop view, the state is managed
 * by the parent component. This is because the desktop view is a bit more
 * complex and requires extra interaction between SessionToolbar and this
 * component. Additionally, the mobile view makes use of our accessible Dialog
 * component, while the desktop view uses a different approach.
 */

export const ToggleButton = ({ isOpen, hideText, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant='plain'
      className={mergeClassNames('flex flex-shrink-0 gap-x-3 justify-between px-4 h-14 w-full rounded-t-lg rounded-b-none text-base bg-transparent hover:bg-transparent disabled:hover:bg-transparent', isInIframe() && 'shadow-risen', isOpen && 'border-b-2 retina:border-b-1.5 border-b-black/15 !shadow-none md:border-none', className)}
      {...props}
    >
      {/* {!hideText && <span>{t('Device info')}</span>} */}
      {hideText && <span className='sr-only'>{t('Device info')}</span>}
      <Icon type='info-circle' className={mergeClassNames('h-6 text-white')} reverse />
    </Button>
  )
}

const MobileSidebar = ({ children, visible }) => {
  const [open, setOpen] = useState(false)
  console.log('🚀 ~ MobileSidebar ~ open:', open)

  useEffect(() => {
    setOpen(visible)
  }, [visible])

  return (
    // @todo this really can only be open on mobile????
    <Dialog
      isOpen={open}
      onClose={() => setOpen(false)}
      panelClassName='absolute inset-4 bg-white rounded-lg p-0 md:hidden'
      bodyClassName='p-0 gap-y-0 max-h-full'
    >
      {/* <ToggleButton isOpen={open} onClick={() => setOpen(false)} /> */}
      <div className='flex flex-col overflow-y-auto gap-y-4'>
        {children}
      </div>
    </Dialog>
  )
}

const DesktopSidebar = ({ visible, onVisibilityChanged, children }) => {
  return (
    <div className={mergeClassNames('h-full w-full overflow-y-auto bg-white hidden rounded-lg max-h-[calc(100vh-88px)]', visible && 'block h-full')}>
      <div className='flex flex-col gap-y-6'>
        {children}
      </div>
    </div>
  )
}

const Sidebar = ({ visible, onVisibilityChanged, className, children }) => {
  console.log('🚀 ~ Sidebar ~ visible:', visible)
  return (
    <div className={mergeClassNames('hidden', visible && 'block', className)}>
      <div className='block md:hidden'>
        <MobileSidebar visible={visible}>
          {children}
        </MobileSidebar>
      </div>
      <div className={mergeClassNames('hidden h-full w-[335px] md:block drop-shadow-risen')}>
        <DesktopSidebar visible={visible} onVisibilityChanged={onVisibilityChanged}>
          {children}
        </DesktopSidebar>
      </div>
    </div>
  )
}

export default Sidebar
