import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { inputClassName } from './Input'

const Select = forwardRef(({ variant = 'primary', disabled, readOnly = false, className, children, ...props }, ref) => {
  const variants = {
    primary: 'hover:border-black/50 focus:border-black active:border-black',
    simple: 'border-white w-44 focus-visible:border-black'
  }

  return (
    <select
      ref={ref}
      className={mergeClassNames(variant !== 'plain' && inputClassName, variants[variant], 'cursor-pointer appearance-none bg-[url(./icons/down-open.svg)] bg-no-repeat bg-[position:calc(100%-8px)_50%] rtl:bg-[position:8px] bg-[length:auto_15px]', disabled && 'opacity-50 cursor-default', readOnly && 'bg-none', className)}
      disabled={disabled}
      {...props}
    >{children}
    </select>
  )
})

export default Select
