import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'

const LoginButton = ({ text, icon, color, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      {...props}
      className='p-0 px-2 w-full flex gap-x-2 border border-solid border-transparent cursor-pointer items-center overflow-hidden text-left'
      style={{ background: color }}
    >
      <img className='w-10 p-2 min-h-[40px]' alt={text ?? t('Sign in')} src={icon} />
      <div>{text ?? t('Sign in')}</div>
    </Button>
  )
}

export default LoginButton
