import qs from 'qs'
import RESTObject from '../redux-rest'

export default class SessionRating extends RESTObject {
  static namespace () {
    return 'SessionRating'
  }

  static url (params, context = {}) {
    return `/api/1/sessions/${params.id}/rating?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }
}
