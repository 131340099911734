import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import Login from '../auth/Login'
import SDKButtons from '../../components/SDKButtons'
import Account from '../accounts/Account.state'
import User from '../users/User.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import { GenerateJoinLink } from '../accounts/GenerateJoinLink'
import JoinToken from '../accounts/JoinToken.state'
import Alert from '../../components/Alert'
import usePageTitle from '../../hooks/usePageTitle'

const ORDER = ['team', 'develop', 'done']

const WelcomeTutorial = ({ stage }) => {
  const { t } = useTranslation()
  const account = useSelector(state => Account.activeAccount(state))
  const user = useSelector(state => User.fromState(state))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  usePageTitle(t('Welcome tutorial'))

  const createJoinToken = (role) => dispatch(JoinToken.actionCreators().createJoinToken(role))

  useEffect(() => {
    if (!Account.hasFeature(account.resource, 'manage_members', false)) {
      return navigate('/dashboard', { replace: true })
    }
  }, [account.resource, navigate])

  const navigation = () => {
    const currentStage = stage || ORDER[0]
    const next = ORDER[ORDER.indexOf(currentStage) + 1]
    const prev = ORDER[ORDER.indexOf(currentStage) - 1]

    return { next, current: currentStage, prev }
  }

  const renderTeam = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Invite your team')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t('Add members to your Cobrowse team by sharing the link below. You can generate a new link at any time from your account settings.')}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <GenerateJoinLink createJoinToken={createJoinToken} defaultRole='administrator' />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderDevelop = () => {
    return (
      <div>
        <SettingsGroup>
          <SettingsGroup.Header>
            <SettingsGroup.HeaderTitle>
              <SettingsGroup.Title>{t('Add the Cobrowse SDKs')}</SettingsGroup.Title>
              <SettingsGroup.HelpText>
                {t("We've provided a set of easy to use SDKs to integrate into all of your apps or websites. They take only a few minutes of developer time to integrate.")}
              </SettingsGroup.HelpText>
              <Alert className='mt-2'><Trans>Contact us any time at <a className='underline' href='mailto:hello@cobrowse.io'>hello@cobrowse.io</a> and we{'\''}ll be happy to help!</Trans></Alert>
            </SettingsGroup.HeaderTitle>
          </SettingsGroup.Header>
          <SettingsGroup.Body>
            <div className='text-center'><SDKButtons /></div>
          </SettingsGroup.Body>
          <SettingsGroup.Footer className='border-t border-t-grey bg-grey text-black/60 text-sm block'>
            {t("You'll need your license key:")} <code className='font-bold'>{account.resource.license_key}</code>
          </SettingsGroup.Footer>
        </SettingsGroup>
      </div>
    )
  }

  const renderDone = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Body className='flex flex-col gap-y-2'>
          <SettingsGroup.Title>{t("You're all set")}</SettingsGroup.Title>
          <p>
            <Trans>Once you have added the Cobrowse SDKs to your code, your devices will show on your <Link target='_blank' rel='noopener noreferrer' to='/dashboard'>dashboard</Link>.</Trans>
          </p>
          <p>
            <Trans>Looking for more documentation? See our <a className='underline' target='_blank' rel='noreferrer' href='https://docs.cobrowse.io'>full docs</a>.</Trans>
          </p>
          <Alert><Trans>If you need help at any point, email our team at <a href='mailto:hello@cobrowse.io'>hello@cobrowse.io</a>.</Trans></Alert>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderStageContent = () => {
    const stageToRender = stage || ORDER[0]

    switch (stageToRender) {
      case 'team':
        return renderTeam()
      case 'develop':
        return renderDevelop()
      case 'done':
        return renderDone()
      default:
        return null
    }
  }

  const renderControls = () => {
    const { next, prev } = navigation()

    return (
      <div className='flex items-center mt-7'>
        {(next && prev) ? <Link className='m-4 text-black' to='/dashboard'>{t('Skip tutorial')}</Link> : null}
        <div className='flex-1' />
        {prev ? <Link className='m-4' to={`/welcome/${prev}`}>{t('Back')}</Link> : null}
        {next
          ? <Link to={`/welcome/${next}`}><Button>{t('Next')}</Button></Link>
          : <Link to='/dashboard'><Button>{t('Finish')}</Button></Link>}
      </div>
    )
  }

  if (!user.resource) {
    return <Login />
  }

  // currently there's no onboarding flow for support agents at all
  if (!Account.hasFeature(account.resource, 'manage_members', false)) {
    return navigate('/dashboard')
  }

  return (
    <div className='flex items-center justify-center h-full w-full bg-grey-dark'>
      <h1 className='sr-only'>{t('Welcome tutorial')}</h1>
      <div className='p-4 max-w-2xl w-full'>
        {renderStageContent()}
        {renderControls()}
      </div>
    </div>
  )
}

export default WelcomeTutorial

export function WelcomeTutorialStage () {
  const { stage } = useParams()

  return <WelcomeTutorial {... { stage }} />
}
