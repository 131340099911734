import React from 'react'
import UserNavigation from '../navigation/UserNavigation'
import { useTranslation } from 'react-i18next'
import LinkComponent from '../../components/Link'
import Account from '../accounts/Account.state'

const Link = ({ to, children }) => {
  return (
    <LinkComponent to={`${to}${window.location.search}`}>
      {children}
    </LinkComponent>
  )
}

const SiteMap = ({ account }) => {
  const { t } = useTranslation()
  const settingsEntries = [
    {
      title: t('General'),
      path: '/dashboard/settings'
    },
    {
      title: t('Team membership'),
      feature: 'manage_members',
      path: '/dashboard/settings/team'
    },
    {
      title: t('Redaction'),
      feature: 'manage_redaction',
      path: '/dashboard/settings/redaction'
    },
    {
      title: t('Push notifications'),
      feature: 'manage_push_settings',
      path: '/dashboard/settings/push'
    },
    {
      title: t('Integrations'),
      feature: 'manage_integrations',
      path: '/dashboard/settings/integrations'
    },
    {
      title: t('Billing'),
      feature: 'manage_billing',
      path: '/dashboard/settings/billing',
      targetIfIframe: '_blank'
    },
    {
      title: t('Audit trail'),
      feature: 'view_audit_trail',
      path: '/dashboard/settings/audit'
    }
  ]
  const enabledSettingsEntries = settingsEntries.filter(entry => !entry.feature || Account.hasFeature(account.resource, entry.feature, false))

  return (
    <UserNavigation title={t('Site map')}>
      <div className='container mx-auto'>
        <h1 className='text-h-lg'>{t('Site map')}</h1>
        <div className='mt-8'>
          <ul className='list-disc list-inside flex flex-col gap-y-2'>
            <li><Link to='/dashboard'>{t('Devices')}</Link></li>
            <li><Link to='/dashboard/history'>{t('Sessions')}</Link></li>
            {Account.hasFeature(account.resource, 'present_mode', true) && (
              <li><Link to='/dashboard/present'>{t('Present')}</Link></li>
            )}
            {enabledSettingsEntries.length > 0 && (
              <li>
                {t('Settings')}
                <ul className='list-disc list-inside ps-6 mt-2 flex flex-col gap-y-2'>
                  {enabledSettingsEntries.map((entry, index) => (
                    <li key={index}>
                      <Link to={entry.path}>{entry.title}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            )}
            <li><Link to='/code'>{t('Code entry')}</Link></li>
          </ul>
        </div>
      </div>
    </UserNavigation>
  )
}

export default SiteMap
