import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Session from '../sessions/Session.state'
import EventSerialization from '../sessions/EventSerialization'
import { updateUiState } from '../ui-state/UI.state'
import { SESSION_TOOL_TYPE_CONTROL, SESSION_TOOL_TYPE_DRAWING, SESSION_TOOL_TYPE_DISAPPEARING_INK, SESSION_TOOL_TYPE_LASER } from '../sessions/SessionToolTypes'

class PostMessageAPI extends PureComponent {
  componentDidMount () {
    window.addEventListener('message', this.onPostMessage)
  }

  componentDidUpdate (prevProps) {
    if (this.props.session?.resource) this.emitSessionState(this.props.session.resource)

    if (this.props.session.error && !prevProps.session.error) this.emitError(this.props.session.error)

    if (prevProps.ui.screenSize?.height !== this.props.ui.screenSize?.height ||
      prevProps.ui.screenSize?.width !== this.props.ui.screenSize?.width ||
      prevProps.connectivity.metrics.last_alive !== this.props.connectivity.metrics.last_alive) {
      this.emitScreenState(this.props.ui.screenSize)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.onPostMessage)
  }

  changeTool = async (tool) => {
    this.props.updateUiState({ tool })
  }

  onSessionEvent = async (data) => {
    if (!this.props.updateSession) return
    if (!this.props.session?.resource) return
    const session = this.props.session.resource

    const update = {}
    if (data.state && data.state === 'ended') {
      update.state = 'ended'
    }
    if (typeof data.full_device !== 'undefined' && data.full_device !== session.full_device) {
      update.full_device = data.full_device
    }
    if (data.remote_control && data.remote_control !== session.remote_control) {
      update.remote_control = data.remote_control
    }
    await this.props.updateSession({ id: session.id, ...update })
  }

  onControlEvent = (message) => {
    if (message.state === 'keydown' && ['GoBack', 'GoHome'].includes(message.key)) {
      this.props.sendControlEvent(EventSerialization.serialize({
        type: message.state,
        key: message.key,
        code: message.key
      }), '0')
    }
  }

  onToolEvent = (data) => {
    if ([
      SESSION_TOOL_TYPE_LASER,
      SESSION_TOOL_TYPE_DRAWING,
      SESSION_TOOL_TYPE_CONTROL,
      SESSION_TOOL_TYPE_DISAPPEARING_INK
    ].includes(data)) this.changeTool(data)
  }

  onAnnotationEvent = (drawing) => {
    if (drawing === null) this.props.updateUiState({ drawing: null })
  }

  onPostMessage = (e) => {
    const message = e.data
    if (typeof message.session !== 'undefined') this.onSessionEvent(message.session)
    else if (typeof message.tool !== 'undefined') this.onToolEvent(message.tool)
    else if (typeof message.drawing !== 'undefined') this.onAnnotationEvent(message.drawing)
    else if (typeof message.control !== 'undefined') this.onControlEvent(message.control)
  }

  emitError = (err) => {
    (window.opener || window.parent).postMessage({
      event: 'error',
      data: {
        id: err.id,
        message: err.message
      }
    }, '*')
  }

  emitSessionState = (session) => {
    (window.opener || window.parent).postMessage({
      event: 'session',
      data: {
        id: session.id,
        type: session.type,
        state: session.state,
        created: session.created,
        activated: session.activated,
        updated: session.updated,
        ended: session.ended,
        full_device: session.full_device,
        remote_control: session.remote_control
      }
    }, '*')
  }

  emitScreenState = (size) => {
    (window.opener || window.parent).postMessage({
      event: 'screen',
      data: {
        ...size,
        updated: new Date(this.props.connectivity.metrics.last_alive)
      }
    }, '*')
  }

  render () {
    return this.props.children || null
  }
}

const mapStateToProps = state => ({
  session: Session.fromState(state),
  ui: state.ui,
  connectivity: state.connectivity
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...Session.actionCreators(),
  updateUiState
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostMessageAPI)
