import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const Panel = ({ className, disabled, children }) => (
  <div className={mergeClassNames('bg-white bg-opacity-70 px-4 py-3 shadow-cards rounded-2xl', className, 'transition-opacity', disabled && 'opacity-30 pointer-events-none')}>
    {children}
  </div>
)

export default Panel
