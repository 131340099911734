import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import NumberInput from '../../components/NumberInput'
import { SegmentedInput } from '../../components/SegmentedInput'
import mergeClassNames from '../../utils/mergeClassNames'
import {
  PLAN_NAMED_AGENT,
  PLAN_ACTIVE_SESSIONS,
  PLAN_ALL_NAMED_AGENT,
  PLAN_ALL_ACTIVE_SESSIONS
} from './PlanTypes'

// Force these translation keys to be added/updated automatically
// t('month')
// t('year')

const SubscriptionPicker = ({ checkout, plans, planType, onPlanSelected }) => {
  const [paymentInterval, setPaymentInterval] = useState('year')
  const [quantity, setQuantity] = useState(1)
  const { t } = useTranslation()

  const doCheckout = (plan) => {
    onPlanSelected({
      id: plan.id,
      quantity,
      interval: paymentInterval
    })
  }

  const planTypeLabel = () => {
    const labels = {
      [PLAN_NAMED_AGENT]: t('named agents'),
      [PLAN_ACTIVE_SESSIONS]: t('active sessions')
    }

    return labels[planType]
  }

  const planPricePerMonth = (plan) => {
    return plan.pricing[paymentInterval] * Math.max(1, quantity) / (paymentInterval === 'month' ? 1 : 12)
  }

  const renderPlan = (plan) => {
    const isAllPlan = [PLAN_ALL_NAMED_AGENT, PLAN_ALL_ACTIVE_SESSIONS].includes(plan.id)

    return (
      <div
        key={plan.id}
        className={mergeClassNames('flex flex-col gap-4 bg-white rounded-xl p-6 w-full', isAllPlan && 'bg-black text-white')}
      >
        <div className='flex flex-col gap-y-2'>
          <h3 className={mergeClassNames('uppercase text-purple text-h-md', isAllPlan && 'text-lime')}>{plan.title}</h3>
          <ul className='flex flex-col gap-y-2'>
            {plan.features.map((feature, index) => (
              <li key={index} className='flex gap-x-3 text-md w-full items-start'>
                <div className='mt-1'>
                  <Icon type='wand' className='w-5 h-5' />
                </div>
                <p className={mergeClassNames('text-black', isAllPlan && 'text-grey-dark')}>{feature}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className='flex flex-col gap-y-4'>
          <div className={mergeClassNames('flex gap-3 items-center text-black/75', isAllPlan && 'text-white/75')}>
            <div>
              <span className={mergeClassNames('text-black text-display-sm', isAllPlan && 'text-white')}>
                ${planPricePerMonth(plan)}
              </span>
              <span className='text-sm'>/{t('mo')}</span>
            </div>
            <div>
              <p className='text-[.625rem] leading-[.6875rem]'>{t('Each licensed named agent has access during the period. Licenses may not be shared.')}</p>
            </div>
          </div>
          <div>
            <Button
              thinking={checkout.working}
              disabled={checkout.working}
              onClick={() => doCheckout(plan)}
              className={mergeClassNames('w-full bg-purple px-4 py-3 enabled:hover:bg-purple-500 disabled:hover:bg-purple-400', isAllPlan && 'bg-lime text-black enabled:hover:bg-lime-dark disabled:hover:bg-lime-dark')}
            >
              {t('Checkout')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-y-6'>
      <div className='flex flex-col gap-6 flex-wrap self-center md:flex-row md:justify-between md:self-auto'>
        <div className='flex gap-2 items-center justify-center text-left md:justify-normal'>
          <div className='bg-white p-1 rounded'>
            <NumberInput value={quantity} onChange={setQuantity} label={t('Quantity')} />
          </div>
          <p className='text-black/60 uppercase text-label-sm font-semibold w-28'>{t('Number of {{type}}', { type: planTypeLabel() })}</p>
        </div>
        <div className='self-center lg:self-auto'>
          <SegmentedInput value={paymentInterval} onChange={setPaymentInterval}>
            <SegmentedInput.Option
              defaultChecked
              value='year'
            >
              {t('Annually')} <span className='opacity-50'>{t('(save 10%)')}</span>
            </SegmentedInput.Option>
            <SegmentedInput.Option value='month'>{t('Monthly')}</SegmentedInput.Option>
          </SegmentedInput>
        </div>
      </div>
      <div className='flex flex-col gap-6 w-full max-w-xs self-center md:flex-row md:max-w-2xl md:self-center'>
        {plans.map(renderPlan)}
      </div>
    </div>
  )
}

export default SubscriptionPicker
