import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AuditEventList from './AuditEventList'
import SettingsPage from '../accounts/SettingsPage'
import AuditEvent from './AuditEvent.state'
import { SettingsGroup } from '../../components/SettingsGroup'

const AuditSettings = () => {
  const dispatch = useDispatch()
  const audit = useSelector(state => AuditEvent.fromState(state))
  const { t } = useTranslation()

  const refresh = useCallback((params = {}, options = {}) => {
    return dispatch(AuditEvent.actionCreators().getAuditEvents(undefined, {
      resource_type: ['AccountMember', 'Account', 'SAMLProvider', 'JoinToken'],
      ...params
    }, options))
  }, [dispatch])

  useEffect(() => {
    refresh()
  }, [refresh])

  const renderAuditTrail = () => {
    if (!audit.collection) {
      return null
    }

    return (
      <SettingsGroup className='h-full'>
        <SettingsGroup.Body>
          <AuditEventList events={audit} refresh={refresh} className='h-full' />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  return (
    <SettingsPage feature='view_audit_trail'>
      <div>
        <SettingsPage.Title>
          {t('Audit trail')}
        </SettingsPage.Title>
        <SettingsPage.HelpText>
          {t('These are all the events that have occurred in your account.')}
        </SettingsPage.HelpText>
      </div>
      <SettingsPage.Body>
        {renderAuditTrail()}
      </SettingsPage.Body>
    </SettingsPage>
  )
}

export default AuditSettings
