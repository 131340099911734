/* eslint-disable react/jsx-handler-names */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import Login from '../auth/Login'
import Button from '../../components/Button'
import Input, { inputClassName } from '../../components/Input'
import DatePicker from '../../components/DatePicker'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import EnterpriseLicense from './EnterpriseLicense.state'
import CopyText from '../../components/CopyText'
import Label from '../../components/Label'
import { SettingsGroup } from '../../components/SettingsGroup'
import mergeClassNames from '../../utils/mergeClassNames'
import useDebounce from '../../hooks/useDebounce'
import Select from '../../components/Select'
import SelectOption from '../../components/SelectOption'
import Icon from '../../components/Icon'
import useThrottle from '../../hooks/useThrottle'
import formatPlatformName from '../../utils/formatPlatformName'
import Checkbox from '../../components/Checkbox'

const PLATFORM_TYPES = ['ios', 'android', 'web', 'windows', 'macos']

const EnterpriseLicenseDashboard = (props) => {
  const [searchParams] = useSearchParams()
  const [domain, setDomain] = useState('')
  const [reportingUrl, setReportingUrl] = useState('')
  const [expiry, setExpiry] = useState()
  const [members, setMembers] = useState('')
  const [membersDaily, setMembersDaily] = useState('')
  const [sessions, setSessions] = useState('')
  const [sessionConcurrency, setSessionConcurrency] = useState('')
  const [membersConcurrency, setMembersConcurrency] = useState('')
  const [platforms, setPlatforms] = useState([])
  const [generatedLicense, setGeneratedLicense] = useState({})
  const [filterDomain, setFilterDomain] = useState('')
  const [sortBy, setSortBy] = useState('created')
  const dispatch = useDispatch()
  const bottomRef = useRef()

  const enterpriseLicenses = useSelector(state => EnterpriseLicense.fromState(state))

  const getEnterpriseLicenses = useCallback((params = {}, options = {}) => dispatch(EnterpriseLicense.actionCreators().getEnterpriseLicenses({}, params, options)), [dispatch])

  useEffect(() => {
    getEnterpriseLicenses()
  }, [getEnterpriseLicenses])

  useEffect(() => {
    if (searchParams.get('domain')) {
      setDomain(searchParams.get('domain'))
    }

    if (searchParams.get('expiry')) {
      setExpiry(moment(searchParams.get('expiry')).toDate())
    }

    if (searchParams.get('members')) {
      setMembers(searchParams.get('members'))
    }

    if (searchParams.get('members_daily')) {
      setMembersDaily(searchParams.get('members_daily'))
    }

    if (searchParams.get('sessions')) {
      setSessions(searchParams.get('sessions'))
    }

    if (searchParams.get('session_concurrency')) {
      setSessionConcurrency(searchParams.get('session_concurrency'))
    }

    if (searchParams.get('members_concurrency')) {
      setMembersConcurrency(searchParams.get('members_concurrency'))
    }

    // Accept platforms as a JSON array (e.g. `platforms=["ios","android"]`)
    if (searchParams.get('platforms')) {
      try {
        const value = JSON.parse(searchParams.get('platforms')).map((platform) => platform.toLowerCase())

        if (Array.isArray(value) && value.every((platform) => PLATFORM_TYPES.includes(platform))) {
          setPlatforms(value)
        }
      } catch (e) {
        console.warn('Failed to parse platforms from search params:', e.message)
      }
    }

    if (searchParams.get('reporting_url')) {
      setReportingUrl(searchParams.get('reporting_url'))
    }
  }, [searchParams])

  const filters = () => {
    return { domain: filterDomain, sort_by: sortBy }
  }

  const loadMore = useThrottle(() => {
    if (enterpriseLicenses.working) {
      return
    }

    getEnterpriseLicenses({
      ...filters(),
      skip: enterpriseLicenses.collection.length
    }, { merge: true })
  })

  useEffect(() => {
    if (enterpriseLicenses.collection.length === 0) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries

      if (entry.isIntersecting) {
        loadMore()
      }
    })

    observer.observe(bottomRef.current)

    return () => observer.disconnect()
  }, [loadMore, enterpriseLicenses.collection.length])

  const generateLicense = async () => {
    /* eslint-disable camelcase */
    const requestBody = {
      domain,
      expires: expiry,
      limits: {
        members,
        members_daily: membersDaily,
        sessions,
        session_concurrency: sessionConcurrency,
        members_concurrency: membersConcurrency
      }
    }
    if (platforms.length) requestBody.limits.platforms = platforms
    if (reportingUrl) requestBody.reporting = { url: reportingUrl }
    /* eslint-enable camelcase */

    const data = await dispatch(EnterpriseLicense.actionCreators().createEnterpriseLicense(requestBody))

    if (data) {
      setGeneratedLicense(data)
      getEnterpriseLicenses(filters())
    }
  }

  const debouncedGetLicenses = useDebounce(() => {
    getEnterpriseLicenses(filters())
  })

  const onPlatformsChanged = (event) => {
    const { value, checked } = event.target

    if (checked) {
      setPlatforms(platforms.concat(value))
    } else {
      setPlatforms(platforms.filter((platform) => platform !== value))
    }
  }

  const onFilterDomainChanged = (value) => {
    setFilterDomain(value)

    debouncedGetLicenses()
  }

  const onSortByChanged = (value) => {
    setSortBy(value)

    debouncedGetLicenses()
  }

  const onRenewLicenseClicked = (license) => {
    setDomain(license.domain)
    setReportingUrl(license.reporting?.url || '')
    setMembers(license.limits?.members || '')
    setMembersDaily(license.limits?.members_daily || '')
    setSessions(license.limits?.sessions || '')
    setSessionConcurrency(license.limits?.session_concurrency || '')
    setMembersConcurrency(license.limits?.members_concurrency || '')
    setExpiry(moment(license.expires).add(1, 'year').toDate())
    setPlatforms(license.limits?.platforms || [])
  }

  const onDeleteLicenseClicked = async (license) => {
    if (window.confirm(`Are you sure you want to delete the license for ${license.domain}?`)) {
      await dispatch(EnterpriseLicense.actionCreators().removeEnterpriseLicense({ id: license.id }))
    }
  }

  const formatLimitValueForTable = (limit) => {
    if (Array.isArray(limit)) {
      return limit.join(', ')
    }

    return limit
  }

  const renderEditor = () => {
    return (
      <div className={mergeClassNames('flex flex-col gap-y-6 w-full md:w-[calc(50%-10px)]', generatedLicense?.license && 'md:w-full')}>
        <div className='flex flex-col gap-2 lg:flex-row lg:flex-wrap lg:justify-center'>
          <div className='flex flex-col gap-6 grow'>
            <div className='flex gap-2 w-full'>
              <div className='flex flex-col gap-1 w-full'>
                <Label htmlFor='domain'>Domain</Label>
                <Input id='domain' type='text' placeholder='Domain' value={domain} onChange={(e => { setDomain(e.target.value) })} />
              </div>
              <div className='flex flex-col gap-1 w-full'>
                <Label htmlFor='expiry'>Expiry</Label>
                <DatePicker id='expiry' placeholder='Expiry date' onChange={setExpiry} selectedDate={expiry} minDate={moment().add(1, 'day').startOf('day').toDate()} />
              </div>
            </div>
            <div className='flex gap-2 w-full'>
              <div className='flex flex-col gap-1 w-full'>
                <Label htmlFor='members'>Members</Label>
                <Input id='members' type='number' placeholder='Members' value={members} onChange={(e => { setMembers(e.target.value) })} />
                <Label htmlFor='daily_members'>Daily Members</Label>
                <Input id='daily_members' type='number' placeholder='Daily Members' value={membersDaily} onChange={(e => { setMembersDaily(e.target.value) })} />
                <Label htmlFor='sessions'>Sessions</Label>
                <Input id='sessions' type='number' placeholder='Sessions' value={sessions} onChange={(e => { setSessions(e.target.value) })} />
              </div>
              <div className='flex flex-col gap-1 w-full'>
                <Label htmlFor='session_concurrency'>Concurrent Sessions</Label>
                <Input id='session_concurrency' type='number' placeholder='Concurrent Sessions' value={sessionConcurrency} onChange={(e => { setSessionConcurrency(e.target.value) })} />
                <Label htmlFor='members_concurrency'>Concurrent Members</Label>
                <Input id='members_concurrency' type='number' placeholder='Concurrent Members' value={membersConcurrency} onChange={(e => { setMembersConcurrency(e.target.value) })} />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-y-1'>
          <Label htmlFor='reporting-url'>Reporting URL</Label>
          <Input id='reporting-url' type='text' placeholder='Reporting URL' value={reportingUrl} onChange={(e => { setReportingUrl(e.target.value) })} />
        </div>
        <div className='flex flex-col'>
          <Label>Platforms</Label>
          <div className='flex flex-row flex-wrap gap-4'>
            {PLATFORM_TYPES.map((type) => (
              <div key={type} className='flex gap-x-2 items-center'>
                <Checkbox value={type} id={`platform-${type}`} checked={platforms.includes(type)} onChange={onPlatformsChanged} />
                <Label htmlFor={`platform-${type}`}>{formatPlatformName(type)}</Label>
              </div>
            ))}
          </div>
        </div>
        <Button onClick={generateLicense}>Generate</Button>
      </div>
    )
  }

  const renderGeneratedLicensePanel = () => {
    if (generatedLicense?.license) {
      return (
        <SettingsGroup className='w-full mb-auto'>
          <SettingsGroup.Header>
            <SettingsGroup.HeaderTitle>
              <SettingsGroup.Title>Generated license</SettingsGroup.Title>
            </SettingsGroup.HeaderTitle>
          </SettingsGroup.Header>
          <SettingsGroup.Body className='flex flex-col gap-y-4'>
            <div>
              <Label htmlFor='license'>License</Label>
              <CopyText id='license' variant='input'>
                {generatedLicense.license}
              </CopyText>
            </div>
            <div>
              <Label htmlFor='expiry'>Expiry</Label>
              <CopyText id='expiry' variant='input'>
                {moment(generatedLicense.expires).format('YYYY-MM-DD')}
              </CopyText>
            </div>
            <div>
              <Label htmlFor='limits'>Limits</Label>
              <CopyText id='limits' variant='text' className={mergeClassNames(inputClassName, 'bg-grey border-grey w-full justify-between font-mono whitespace-pre')}>
                {JSON.stringify(generatedLicense.limits, null, '\t')}
              </CopyText>
            </div>
            <div>
              <Label htmlFor='reporting'>Reporting</Label>
              <CopyText id='reporting' variant='text' className={mergeClassNames(inputClassName, 'bg-grey border-grey w-full justify-between font-mono whitespace-pre')}>
                {generatedLicense.reporting?.url || ''}
              </CopyText>
            </div>
          </SettingsGroup.Body>
        </SettingsGroup>
      )
    }
  }

  const renderLicensesList = () => {
    return (
      <>
        <div className='flex gap-4'>
          <div className='w-full'>
            <Label htmlFor='filter-domain'>Domain</Label>
            <Input id='filter-domain' type='text' placeholder='Filter by domain' onChange={(e) => onFilterDomainChanged(e.target.value)} />
          </div>
          <div className='w-full'>
            <Label htmlFor='sort-by'>Sort by</Label>
            <Select id='sort-by' onChange={(e) => onSortByChanged(e.target.value)}>
              <SelectOption value='created'>Created at</SelectOption>
              <SelectOption value='expires'>Expires at</SelectOption>
            </Select>
          </div>
        </div>
        {enterpriseLicenses?.collection?.length === 0
          ? (!enterpriseLicenses.working && (<div className='p-4 text-center'>No licenses found.</div>))
          : (
            <div className='bg-white rounded-lg shadow overflow-x-auto'>
              <table className='w-full text-left'>
                <thead className='uppercase text-title-sm border-b border-b-black/25'>
                  <tr>
                    <th className='px-4 py-2'>Domain</th>
                    <th className='px-4 py-2 whitespace-nowrap'>Created at</th>
                    <th className='px-4 py-2 whitespace-nowrap'>Expires at</th>
                    <th className='px-4 py-2 whitespace-nowrap'>Limits</th>
                    <th className='px-4 py-2 text-center'>Expired</th>
                    <th className='px-4 py-2 text-center'>Reporting</th>
                    <th className='px-4 py-2 text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {enterpriseLicenses?.collection?.map((license, index) => {
                    const expiresSoon = moment(license.expires).diff(moment(), 'days') < 7

                    return (
                      <tr key={license.id || index} className={mergeClassNames('border-b border-b-black/10 last:border-b-0', expiresSoon && 'bg-yellow/25', license.expired && 'bg-red/25')}>
                        <td className='px-4 py-2'>{license.domain}</td>
                        <td className='px-4 py-2 whitespace-nowrap'>{moment(license.created).format('YYYY-MM-DD')}</td>
                        <td className='px-4 py-2 whitespace-nowrap'>{moment(license.expires).format('YYYY-MM-DD')}</td>
                        <td className='px-4 py-2'>
                          {license.limits && (
                            <table className='w-full text-sm'>
                              <tbody>
                                {Object.keys(license.limits).map((limit) => {
                                  return (
                                    <tr key={limit}>
                                      <td className='align-baseline'>{limit}</td>
                                      <td className='ps-2 text-right'>{formatLimitValueForTable(license.limits[limit])}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </td>
                        <td className='px-4 py-2 text-center'>{license.expired ? 'Yes' : 'No'}</td>
                        <td className='px-4 py-2 text-center' title={license.reporting?.url}>{license.reporting ? 'Yes' : 'No'}</td>
                        <td className='px-4 py-2'>
                          <div className='flex gap-x-2 h-full items-center justify-around'>
                            <Button
                              variant='plain'
                              title='Renew'
                              onClick={() => onRenewLicenseClicked(license)}
                            >
                              <Icon type='refresh' />
                            </Button>
                            <Button
                              variant='plain'
                              className='text-red-500'
                              title='Delete'
                              onClick={() => onDeleteLicenseClicked(license)}
                            >
                              <Icon type='trash-empty' />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div ref={bottomRef} />
            </div>
            )}
      </>
    )
  }

  if (!props.user?.resource) {
    return <Login />
  }

  if (!props.user?.resource?.superuser) {
    return <Navigate to='/dashboard' replace />
  }

  return (
    <div className='p-4 lg:mx-auto lg:container'>
      <h1 className='text-h-sm'>License Dashboard</h1>
      <p className='my-4'>Here you can generate new self-hosted licenses. <i>These licenses are irrevocable, be very careful about sharing them.</i></p>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-4 md:flex-row'>
          {renderEditor()}
          {renderGeneratedLicensePanel()}
        </div>
        <div className='flex flex-col gap-y-2'>
          {renderLicensesList()}
        </div>
      </div>
    </div>
  )
}

export default EnterpriseLicenseDashboard
