import React, { forwardRef } from 'react'
import Icon from './Icon'
import mergeClassNames from '../utils/mergeClassNames'

export const buttonClassName = (variant = 'primary', className = '', ...other) => {
  const variants = {
    primary: 'bg-black text-white hover:bg-black/85 disabled:hover:bg-black',
    'primary-v2': 'bg-purple hover:bg-pink disabled:hover:bg-purple rounded-lg text-sm font-medium',
    secondary: 'bg-grey text-black hover:bg-grey-dark disabled:hover:bg-grey',
    tertiary: 'bg-white text-black hover:bg-grey disabled:hover:bg-grey',
    error: 'bg-red-300 text-white hover:bg-red-900 disabled:hover:bg-red-300'
  }
  const defaultClassName = 'relative h-11 flex justify-center items-center gap-x-2 py-3 px-4 text-button font-normal cursor-pointer rounded disabled:opacity-60 disabled:cursor-not-allowed'

  return mergeClassNames(variant !== 'plain' && defaultClassName, variants[variant], className, ...other)
}

const Button = forwardRef(({ variant = 'primary', disabled, style, onClick, className, thinking, children, Component = 'button', ...props }, ref) => {
  return (
    <Component
      ref={ref}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={buttonClassName(variant, className, thinking && 'overflow-hidden')}
      {...props}
    >
      {children}
      {!!thinking && <span className='absolute inset-0 flex items-center justify-center bg-inherit'><Icon type='spin2' spin /></span>}
    </Component>
  )
})

export default Button
