import React from 'react'
import { useTranslation } from 'react-i18next'
import mergeClassNames from '../utils/mergeClassNames'
import Icon from './Icon'

const iconColors = {
  success: 'text-green-700',
  warning: 'text-yellow',
  alert: 'text-red-500',
  info: 'text-black'
}
const types = {
  success: 'bg-green-500 border-green-500',
  warning: 'bg-yellow-300 border-yellow',
  alert: 'bg-red-100 border-red-300',
  info: 'bg-grey border-black'
}
const icons = {
  success: 'circle-check',
  warning: 'alert-triangle',
  alert: 'alert-triangle',
  info: 'info-circle'
}
const variants = {
  inline: 'bg-transparent',
  'inline-bg': '',
  floating: 'p-3 shadow-risen border-2 retina:border-1.5 border-opacity-50'
}

const AlertIcon = ({ type }) => (
  <Icon type={icons[type]} className={mergeClassNames('h-4', iconColors[type])} />
)

const Alert = ({ type = 'info', variant = 'inline-bg', fixed = false, className, onDismiss, children, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={mergeClassNames('p-2 flex items-center gap-x-2 rounded-lg text-black/75 text-md leading-5', types[type], variants[variant], fixed && 'fixed z-50 top-2.5 start-1/2 -translate-x-1/2', className)} {...props}>
      <AlertIcon type={type} className={mergeClassNames(iconColors[type])} />
      <div>
        {children}
      </div>
      {onDismiss && (
        <button onClick={onDismiss} className='ms-auto'>
          <Icon type='close' className='h-4 text-black' />
          <span className='sr-only'>{t('Dismiss')}</span>
        </button>
      )}
    </div>
  )
}

export default Alert
