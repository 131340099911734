import RESTObject from '../redux-rest'

export default class Config extends RESTObject {
  static namespace () {
    return 'Config'
  }

  static url (params = {}) {
    return `/api/1/service/configuration/${params.id || ''}`
  }
}
