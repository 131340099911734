import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import qs from 'qs'
import LoginButton from './LoginButton'
import MagicLinkLogin from './MagicLinkLogin'
import Panel from './../../components/Panel'
import Input from './../../components/Input'
import Link from '../../components/Link'
import mergeClassNames from '../../utils/mergeClassNames'
import Alert from '../../components/Alert'

const LoginButtons = ({ providers, register, query }) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()

  const text = (provider) => {
    return register
      ? t('Sign up with {{provider}}', { provider })
      : t('Sign in with {{provider}}', { provider })
  }

  const enabled = () => {
    if (!register) {
      return true
    } else {
      return !!name
    }
  }

  const params = () => {
    return {
      ...query,
      organisation_name: name || undefined
    }
  }

  const allow = (provider) => {
    if (providers && !providers.includes(provider)) {
      return false
    }

    return true
  }

  const renderLoginError = () => {
    const { error } = qs.parse(window.location.search.substring(1))

    if (!error) {
      return null
    }

    let message = t('Sorry, something went wrong logging into your account. Try again, or contact hello@cobrowse.io for assistance.')
    if (error === 'google_unverified_email') message = t('Your email address must be verified with {{provider}} before signing up.', { provider: 'Google' })
    if (error === 'intercom_unverified_email') message = t('Your email address must be verified with {{provider}} before signing up.', { provider: 'Intercom' })
    if (error === 'account_create_denied') message = t('You do not have permission to create new accounts.')
    if (error === 'login_no_accounts') message = t('You are not a member of any Cobrowse accounts yet. Ask your administrator for access to Cobrowse or register a new account.')
    if (error === 'jwt expired') message = t('Your login link has expired. Please request a new link and try again.')
    if (error === 'magic_link_used') message = t('Your login link has already been used. Please request a new link and try again.')

    return (
      <Alert type='alert'>{message}</Alert>
    )
  }

  const renderRegistration = () => {
    if (!register) {
      return null
    }

    return (
      <div className='mb-4'>
        <Panel className='flex-auto md:flex-1 w-full'>
          <div className='px-3 py-4'>
            <p className='mb-4 uppercase text-sm font-semibold'>{t('Step 1 - Enter your company name')}</p>
            <Input
              required
              name='organisation_name'
              className='placeholder:opacity-50'
              placeholder={t('Company name')}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </Panel>
      </div>
    )
  }

  const renderLoginMethods = () => {
    return (
      <>
        <Panel disabled={!enabled()}>
          <div className='px-3 py-4 flex flex-col flex-auto md:flex-1 w-full gap-y-4'>
            {register ? <p className='uppercase text-sm font-semibold'>{t('Step 2 - Choose your login method')}</p> : null}
            {allow('email')
              ? (
                <div className='mb-4'>
                  <MagicLinkLogin disabled={!enabled()} text={register ? t('Sign up') : t('Sign in')} query={params()} />
                </div>
                )
              : null}
            <div className='flex flex-col gap-y-2'>
              {allow('google')
                ? <LoginButton
                    variant='secondary'
                    disabled={!enabled()}
                    icon={require('./providers/google.svg').default}
                    text={text('Google')}
                    onClick={() => { window.location = `/api/1/google/auth?${qs.stringify(params())}` }}
                  />
                : null}
              {allow('github')
                ? <LoginButton
                    variant='secondary'
                    disabled={!enabled()}
                    icon={require('./providers/github.svg').default}
                    text={text('GitHub')}
                    onClick={() => { window.location = `/api/1/github/auth?${qs.stringify(params())}` }}
                  />
                : null}
              {allow('intercom')
                ? <LoginButton
                    variant='secondary'
                    disabled={!enabled()}
                    icon={require('./providers/intercom.svg').default}
                    text={text('Intercom')}
                    onClick={() => { window.location = `/api/1/intercom/auth?${qs.stringify(params())}` }}
                  />
                : null}
              {allow('zendesk')
                ? <LoginButton
                    variant='secondary'
                    disabled={!enabled()}
                    icon={require('./providers/zendesk.svg').default}
                    text={text('Zendesk')}
                    onClick={() => { window.location = `/login/zendesk?${qs.stringify(params())}` }}
                  />
                : null}
            </div>
          </div>
        </Panel>
        {register && (
          <p className={mergeClassNames('text-base text-black/75 mt-8 text-center transition-opacity', !enabled() && 'opacity-30 pointer-events-none')}>
            <Trans>By continuing you agree to our <Link target='_blank' to='/legal'>terms and policies</Link>.</Trans>
          </p>
        )}
      </>
    )
  }

  return (
    <div className='w-full mx-auto flex flex-col gap-y-3 text-left'>
      {renderLoginError()}
      {renderRegistration()}
      {renderLoginMethods()}
    </div>
  )
}

export default LoginButtons
