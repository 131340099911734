import store from '../../utils/store'
import PushNotification from './PushNotification.state'

let pushTimeout = null

export const sendPushNotifications = (deviceId, sessionId, maxAttempts, callback) => {
  const send = (attempt = 1) => {
    if (attempt > maxAttempts) {
      callback(null, attempt)

      return
    }

    const push = PushNotification.fromState(store.getState())

    if (push.error) {
      callback(new Error(`PushNotification error: ${push.error}`), attempt)

      return
    }

    store.dispatch(PushNotification.actionCreators().createPushNotification({
      device: deviceId,
      session: sessionId,
      attempt
    }))

    pushTimeout = setTimeout(() => send(attempt + 1), 4000)

    callback(null, attempt)
  }

  send()
}

export const cancelPushNotifications = () => {
  clearTimeout(pushTimeout)
}
