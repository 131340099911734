import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import MagicLink from './MagicLink.state'
import Input from '../../components/Input'
import Icon from '../../components/Icon'

const MagicLinkLogin = ({ disabled, text, query }) => {
  const [email, setEmail] = useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const magic = useSelector((state) => MagicLink.fromState(state))

  // Clear the magic link state when the component is unmounted to avoid showing the "success" message when the user
  // navigates to the create account page
  useEffect(() => {
    return () => {
      dispatch(MagicLink.actionCreators().resetMagicLink())
    }
  }, [dispatch])

  const createMagicLink = (email, query) => (
    dispatch(MagicLink.actionCreators().createMagicLink({ email }, query))
  )

  const alreadySent = () => {
    return magic.resource && magic.resource.success
  }

  const sendLink = (e) => {
    if (disabled) {
      return false
    }

    e.preventDefault()
    e.stopPropagation()

    if (!magic.working) {
      createMagicLink(email, query)
    }

    return false
  }

  const renderError = () => {
    const { error } = magic

    // When the createMagicLink action is rejected due to a network error, the error property will be null because
    // there won't be any response from the API. We want to show a generic error message in this case.
    if (error || error === null) {
      return <div className='text-red font-bold py-3'>{error?.message || error?.id || t('An error has occurred')}</div>
    }

    return null
  }

  const renderCheckEmail = () => {
    return (
      <div className='flex flex-col items-center'>
        <Icon type='mail' className='h-10 mb-4 block' />
        <p>{t('We have sent you a login link. Please check your email.')}</p>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <form onSubmit={sendLink}>
        <Input className='mb-4 placeholder:opacity-50' required type='email' name='email' placeholder={t('Enter your email')} autoComplete='email' onChange={e => setEmail(e.target.value)} />
        <Button variant='primary' className='w-full' disabled={disabled || !email} thinking={magic.working}>{text}</Button>
      </form>
    )
  }

  return (
    <div>
      {renderError()}
      {alreadySent() ? renderCheckEmail() : renderForm()}
    </div>
  )
}

export default MagicLinkLogin
