import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { RadioGroup } from '@headlessui/react'

const SegmentedInputRoot = ({ className, value, onChange, children }) => (
  <RadioGroup
    className={mergeClassNames('flex items-center max-w-fit whitespace-nowrap rounded-full bg-white shadow', className)}
    value={value}
    onChange={onChange}
  >
    {children}
  </RadioGroup>
)

const Option = ({ value, children, ...props }) => {
  return (
    <RadioGroup.Option value={value} {...props} className='group'>
      {({ checked }) => (
        <div className={mergeClassNames('relative flex h-full font-thin group-first:m-0 group-last:-ms-2', checked && 'z-[1]')}>
          <RadioGroup.Label
            className={mergeClassNames('flex gap-x-2.5 w-full items-center px-4 py-3 rounded-full cursor-pointer bg-white text-button leading-3', checked && 'bg-black text-white')}
          >
            {children}
          </RadioGroup.Label>
        </div>
      )}
    </RadioGroup.Option>
  )
}

export const SegmentedInput = Object.assign(SegmentedInputRoot, { Option })
