import { throttle } from 'lodash'
import { useEffect, useRef, useMemo } from 'react'

const useThrottle = (fn, delay = 500) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = fn
  }, [fn])

  const throttledFn = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return throttle(func, delay, { trailing: false })
  }, [delay])

  return throttledFn
}

export default useThrottle
