import React from 'react'
import Callout from '../../components/Callout'
import Icon from '../../components/Icon'
import mergeClassNames from '../../utils/mergeClassNames'
import { useTranslation } from 'react-i18next'

const CodeEntryHint = ({ className, iconClassName, contentClassName, ...props }) => {
  const { t } = useTranslation()

  return (
    <Callout
      content={
        <div className='bg-white p-2'>
          <p className='text-sm'>{t('Enter a session code from the customer')}</p>
        </div>
      }
      className={mergeClassNames('w-full', className)}
      contentClassName={mergeClassNames('end-6 -top-4.5 w-44', contentClassName)}
      {...props}
    >
      <Icon type='info-circle' className={mergeClassNames('text-black/60', iconClassName)} />
    </Callout>
  )
}

export default CodeEntryHint
