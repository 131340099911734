import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Toolbar, { ToolbarButton } from '../../components/Toolbar'
import Account from './Account.state'
import { ListBox } from '../../components/Listbox'
import { useLocation } from 'react-router-dom'
import mergeClassNames from '../../utils/mergeClassNames'
import isInIframe from '../../utils/isInIframe'

const SettingsMenu = () => {
  const account = useSelector(state => Account.activeAccount(state).resource)
  const ui = useSelector(state => state.ui)
  const { t } = useTranslation()
  const menuEntries = [
    {
      title: t('General'),
      icon: 'general-settings',
      path: '/dashboard/settings'
    },
    {
      title: t('Team membership'),
      icon: 'team',
      feature: 'manage_members',
      path: '/dashboard/settings/team'
    },
    {
      title: t('Redaction'),
      icon: 'redaction',
      feature: 'manage_redaction',
      path: '/dashboard/settings/redaction'
    },
    {
      title: t('Push notifications'),
      icon: 'push-notifications',
      feature: 'manage_push_settings',
      path: '/dashboard/settings/push'
    },
    {
      title: t('Integrations'),
      icon: 'integrations',
      feature: 'manage_integrations',
      path: '/dashboard/settings/integrations'
    },
    {
      title: t('Billing'),
      icon: 'billing',
      feature: 'manage_billing',
      path: '/dashboard/settings/billing',
      targetIfIframe: '_blank'
    },
    {
      title: t('Audit trail'),
      icon: 'audit-trail',
      feature: 'view_audit_trail',
      path: '/dashboard/settings/audit'
    }
  ]
  const location = useLocation()
  const currentMenuEntry = menuEntries.find(entry => location.pathname === entry.path)

  if (!currentMenuEntry) {
    return false
  }

  return (
    <Toolbar className='flex justify-center w-full'>
      <div className='flex w-full shadow sm:hidden'>
        <ListBox selected={currentMenuEntry} buttonClassName='h-11'>
          {menuEntries.map((entry, index) => {
            if (entry.feature && !Account.hasFeature(account, entry.feature, false)) {
              return null
            }

            return (
              <ListBox.Option key={index} value={entry} className='flex items-center p-0 px-1'>
                {({ active }) => (
                  <ToolbarButton
                    to={`${entry.path}${window.location.search}`}
                    end
                    target={entry.targetIfIframe && isInIframe() ? entry.targetIfIframe : undefined}
                    title={entry.title}
                    className={mergeClassNames('gap-x-1 px-0 py-3 w-full rounded-md', active && 'bg-grey')}
                  >
                    <ToolbarButton.Icon type={entry.icon} className='h-4 text-black/60' />
                    <ToolbarButton.Label className='block'>{t(entry.title)}</ToolbarButton.Label>
                  </ToolbarButton>
                )}
              </ListBox.Option>
            )
          })}
        </ListBox>
      </div>
      <div className='hidden relative flex-grow justify-between max-w-md bg-white p-2 rounded-lg shadow sm:flex sm:flex-col sm:justify-start sm:gap-y-1 md:gap-y-2 lg:min-w-[235px]'>
        {menuEntries.map((entry, index) => {
          if (entry.feature && !Account.hasFeature(account, entry.feature, false)) {
            return null
          }

          return (
            <ToolbarButton
              key={index}
              end
              type='square'
              title={entry.title}
              to={`${entry.path}${window.location.search}`}
              target={entry.targetIfIframe && isInIframe() ? entry.targetIfIframe : undefined}
              className='relative'
            >
              {({ isActive }) => (
                <>
                  <div className={mergeClassNames('absolute -start-2 rounded-e w-0.5 h-6 bg-black', !isActive && 'hidden')} />
                  <ToolbarButton.Icon type={entry.icon} className='h-6' />
                  <ToolbarButton.Label>{t(entry.title)}</ToolbarButton.Label>
                </>
              )}
            </ToolbarButton>
          )
        })}
        {ui.show_contact
          ? (
            <div className='hidden text-center py-4 max-w-[180px] text-sm self-center lg:block'>
              <div className='mb-2 font-bold uppercase'>{t('Need some help?')}</div>
              <div className='text-black/75'>
                <Trans>
                  Contact us any time at <a className='underline' href='mailto:hello@cobrowse.io'>hello@cobrowse.io</a> and we{'\''}ll be happy to help!
                </Trans>
              </div>
            </div>
            )
          : null}
      </div>
    </Toolbar>
  )
}

export default SettingsMenu
