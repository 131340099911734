import { unproxifyPath } from './unproxifyPath.js'
import { proxifyUrl } from './proxifyUrl.js'

export class URLRewriter {
  constructor (proxyApiUrl, proxyPrefix, proxyToken, baseUrl, queryParams) {
    this.proxyApiUrl = new URL(proxyPrefix, proxyApiUrl)

    this.queryParams = queryParams ? { ...queryParams } : {}
    if (proxyToken) this.queryParams.access_token = proxyToken

    this.baseUrl = baseUrl
  }

  #shouldIgnore = (urlString) => {
    if (urlString === 'about:blank') return true
    if (urlString.startsWith('data:')) return true
    if (urlString.startsWith('javascript:')) return true
    if (urlString.startsWith('#')) return true
    return false
  }

  #isProxyUrl = (url) => {
    return url.hostname === this.proxyApiUrl.hostname
  }

  #getPathWithoutPrefix = (url) => {
    const path = `${url.pathname}${url.search}${url.hash}`
    return path.replace(/^.*?\/proxy\/1\/[^/]+/i, '')
  }

  proxify = (rawUrl) => {
    if (!rawUrl) return rawUrl
    if (this.#shouldIgnore(rawUrl.toString())) return rawUrl

    try {
      const url = new URL(rawUrl, this.baseUrl)
      const urlString = this.#isProxyUrl(url) ? this.unproxify(url.toString()) : url.toString()
      const targetPath = proxifyUrl(urlString, this.queryParams)
      const targetUrl = new URL(`.${targetPath}`, this.proxyApiUrl)
      return targetUrl.toString()
    } catch (err) {
      return rawUrl
    }
  }

  unproxify = (rawUrl) => {
    if (!rawUrl) return rawUrl
    if (this.#shouldIgnore(rawUrl.toString())) return rawUrl

    try {
      const url = new URL(rawUrl, this.baseUrl)
      if (!this.#isProxyUrl(url)) return rawUrl
      const pathWithoutPrefix = this.#getPathWithoutPrefix(url)
      const targetUrl = unproxifyPath(pathWithoutPrefix)
      return targetUrl.toString()
    } catch (err) {
      return rawUrl
    }
  }
}
