export function generateUUID () {
  const array = new Uint8Array(16)
  crypto.getRandomValues(array) // Filling the array with random values

  // Set the version to 4 (UUIDv4)
  array[6] = (array[6] & 0x0f) | 0x40
  // Set the variant to RFC4122
  array[8] = (array[8] & 0x3f) | 0x80

  return [...array].map((b, i) => {
    const hex = b.toString(16).padStart(2, '0')
    // Insert dashes in the appropriate spots
    return [4, 6, 8, 10].includes(i) ? `-${hex}` : hex
  }).join('')
}
