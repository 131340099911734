import React from 'react'
import { useTranslation } from 'react-i18next'
import Toolbar, { ToolbarButton } from '../../components/Toolbar'
import SessionCapability from './SessionCapability'
import Icon from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import {
  SESSION_CAPABILITY_TYPE_DRAWING,
  SESSION_CAPABILITY_TYPE_LASER,
  SESSION_CAPABILITY_GROUP_REMOTE_CONTROL,
  SESSION_CAPABILITY_TYPE_DISAPPEARING_INK
} from './SessionCapabilityTypes'
import { DisconnectButton } from './DisconnectButton'
import { SESSION_TOOL_TYPE_CONTROL, SESSION_TOOL_TYPE_DRAWING, SESSION_TOOL_TYPE_DISAPPEARING_INK, SESSION_TOOL_TYPE_LASER } from './SessionToolTypes'
import { ColorPicker } from '../../components/ColorPicker'
import Session from './Session.state'
import { ExpandableVerticleMenu } from '../../components/ExpandableVerticleMenu'
import { ToggleButton } from '../../components/Sidebar'
import EmbedParams from '../../utils/EmbedParams'

const SessionToolbar = ({
  session,
  selected,
  control,
  allowFullDevice,
  onToolSelected,
  onFullDeviceChanged,
  onExpanded,
  performAction,
  user,
  account,
  className,
  selectedColor,
  colors,
  onColorPicked
}) => {
  const { t } = useTranslation()

  const disable = (capability) => {
    return capability && !SessionCapability.hasCapability(session, capability)
  }

  const toolbarButtonClassName = 'rounded-full bg-gray-800 text-slate-50 w-10 h-10 p-1 justify-center data-[selected=true]:bg-slate-100 hover:bg-slate-800 md:p-2 md:ps-2 md:pe-2 md:rounded-full hover:text-slate-50 data-[selected=true]:text-slate-900'
  const desktopOnlyToolbarButtonClassName = mergeClassNames(toolbarButtonClassName, 'hidden md:block')
  const iconClassName = 'w-6 h-6'

  return (
    <Toolbar className={mergeClassNames('flex w-auto', className)}>
      <div className='items-center w-full h-fit sm:w-fit' {...getTestId('session-toolbar')}>
        <div className='flex items-center justify-between w-full gap-4 md:gap-2'>
          <ColorPicker colors={colors} selectedColor={selectedColor} onColorPicked={onColorPicked} />
          <ToolbarButton
            className={toolbarButtonClassName}
            disabled={disable(SESSION_CAPABILITY_TYPE_LASER)}
            title={t('Laser pointer')}
            selected={selected === SESSION_TOOL_TYPE_LASER}
            onClick={() => onToolSelected(SESSION_TOOL_TYPE_LASER)}
            {...getTestId('toolbar-button-laser')}
          >
            <Icon type='laserpointer' className={iconClassName} />
          </ToolbarButton>

          <ToolbarButton
            className={toolbarButtonClassName}
            disabled={disable(SESSION_CAPABILITY_TYPE_DISAPPEARING_INK)}
            title={t('Disappearing ink tool')}
            selected={selected === SESSION_TOOL_TYPE_DISAPPEARING_INK}
            onClick={() => onToolSelected(SESSION_TOOL_TYPE_DISAPPEARING_INK)}
            {...getTestId('toolbar-button-fade')}
          >
            <Icon type='disappearing-ink' className={iconClassName} />
          </ToolbarButton>
          <ToolbarButton
            className={desktopOnlyToolbarButtonClassName}
            disabled={disable(SESSION_CAPABILITY_TYPE_DRAWING)}
            title={t('Drawing tool')}
            selected={selected === SESSION_TOOL_TYPE_DRAWING}
            onClick={() => onToolSelected(SESSION_TOOL_TYPE_DRAWING)}
            {...getTestId('toolbar-button-drawing')}
          >
            <Icon type='ink-pen' className={iconClassName} />
          </ToolbarButton>
          <ToolbarButton
            className={desktopOnlyToolbarButtonClassName}
            disabled={disable(SESSION_CAPABILITY_TYPE_DRAWING)}
            title={t('Clear annotations')}
            onClick={() => performAction('clear')}
            {...getTestId('toolbar-button-clear')}
          >
            <Icon type='trash-empty' className={iconClassName} />
          </ToolbarButton>

          {(!control)
            ? null
            : (
              <ToolbarButton
                className={desktopOnlyToolbarButtonClassName}
                disabled={disable(SESSION_CAPABILITY_GROUP_REMOTE_CONTROL)}
                title={t('Remote control')}
                selected={selected === SESSION_TOOL_TYPE_CONTROL}
                onClick={() => onToolSelected(SESSION_TOOL_TYPE_CONTROL)}
                {...getTestId('toolbar-button-remote-control')}
              >
                <Icon type='hand-pointer' className={iconClassName} />
              </ToolbarButton>
              )}

          {allowFullDevice && (
            <>
              <ToolbarButton
                className={mergeClassNames(desktopOnlyToolbarButtonClassName, 'data-[selected=true]:bg-violet-500 data-[selected=true]:text-slate-50')}
                disabled={false}
                title={t('Full device')}
                selected={Session.isFullDevice(session)}
                onClick={() => onFullDeviceChanged(!Session.isFullDevice(session))}
                {...getTestId('toolbar-button-full-device')}
              >
                <Icon type='full-device' className={iconClassName} />
              </ToolbarButton>
            </>
          )}

          <DisconnectButton
            session={session}
            user={user}
            performAction={performAction}
            account={account}
          />

          <ExpandableVerticleMenu
            className='md:hidden'
            Button={() => <ToolbarButton
              className={toolbarButtonClassName}
              title={t('Expand toolbar')}
              onClick={() => { }}
              {...getTestId('toolbar-button-expand')}
                          >
              <Icon type='ellipsis-h' className={iconClassName} />
            </ToolbarButton>}
          >
            <ToolbarButton
              className={toolbarButtonClassName}
              disabled={disable(SESSION_CAPABILITY_TYPE_DRAWING)}
              title={t('Drawing tool')}
              selected={selected === SESSION_TOOL_TYPE_DRAWING}
              onClick={() => onToolSelected(SESSION_TOOL_TYPE_DRAWING)}
              {...getTestId('toolbar-button-drawing')}
            >
              <Icon type='ink-pen' className={iconClassName} />
            </ToolbarButton>
            <ToolbarButton
              className={toolbarButtonClassName}
              disabled={disable(SESSION_CAPABILITY_TYPE_DRAWING)}
              title={t('Clear annotations')}
              onClick={() => performAction('clear')}
              {...getTestId('toolbar-button-clear')}
            >
              <Icon type='trash-empty' className={iconClassName} />
            </ToolbarButton>

            {(!control)
              ? null
              : (
                <ToolbarButton
                  className={toolbarButtonClassName}
                  disabled={disable(SESSION_CAPABILITY_GROUP_REMOTE_CONTROL)}
                  title={t('Remote control')}
                  selected={selected === SESSION_TOOL_TYPE_CONTROL}
                  onClick={() => onToolSelected(SESSION_TOOL_TYPE_CONTROL)}
                  {...getTestId('toolbar-button-remote-control')}
                >
                  <Icon type='hand-pointer' className={iconClassName} />
                </ToolbarButton>
                )}

            {allowFullDevice && (
              <>
                <ToolbarButton
                  className={mergeClassNames(toolbarButtonClassName, 'data-[selected=true]:bg-violet-500 data-[selected=true]:text-slate-50')}
                  disabled={false}
                  title={t('Full device')}
                  selected={Session.isFullDevice(session)}
                  onClick={() => onFullDeviceChanged(!Session.isFullDevice(session))}
                  {...getTestId('toolbar-button-full-device')}
                >
                  <Icon type='full-device' className={iconClassName} />
                </ToolbarButton>
              </>
            )}

            {EmbedParams.sessionDetails()
              ? <ToolbarButton
                  className={toolbarButtonClassName}
                  title={t('Device info')}
                  selected={false}
                  onClick={() => onExpanded(true)}
                  {...getTestId('toolbar-device-info')}
                >
                <Icon type='info-circle' className={mergeClassNames('h-6 text-white')} reverse />
              </ToolbarButton>
              : null}
          </ExpandableVerticleMenu>
        </div>
      </div>

    </Toolbar>
  )
}

export default SessionToolbar
