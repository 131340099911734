import React from 'react'
import { useTranslation } from 'react-i18next'

const SkipToMainContent = () => {
  const { t } = useTranslation()

  return (
    <a
      href='#main-content'
      className='sr-only focus:not-sr-only focus:absolute focus:top-0 focus:start-0 focus:p-4 focus:z-[9999] bg-purple-100 text-black outline-dashed'
    >
      {t('Skip to main content')}
    </a>
  )
}

export default SkipToMainContent
