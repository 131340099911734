import RESTObject from '../redux-rest'

export default class JoinToken extends RESTObject {
  static namespace () {
    return 'JoinToken'
  }

  static url (params = {}) {
    return `/api/1/accounts/join${params.token ? `/${params.token}` : ''}`
  }
}
