import parseSrcset from '@prettier/parse-srcset'

const getCandidateDescriptor = (candidate) => {
  if (candidate.width) return `${candidate.width.value}w`
  if (candidate.height) return `${candidate.height.value}h`
  if (candidate.density) return `${candidate.density.value}x`
  return null
}

export default function rewriteSrcSet (srcSet, rewrite) {
  if (!srcSet) return srcSet

  try {
    const parsedSrcSet = parseSrcset(srcSet)

    const rewrittenSrcSet = parsedSrcSet.map(candidate => {
      const src = rewrite(candidate.source.value)
      const descriptor = getCandidateDescriptor(candidate)

      return descriptor ? `${src} ${descriptor}` : src
    })

    return rewrittenSrcSet.join(', ')
  } catch (error) {
    return srcSet
  }
}
