import DOMNode from './DOMNode'

export default class DOMOption extends DOMNode {
  static nodeFilter (node) {
    return node
  }

  componentDidMount () {
    super.componentDidMount()
    this.setOptionValues()
  }

  componentDidUpdate () {
    super.componentDidUpdate()
    this.setOptionValues()
  }

  setOptionValues = () => {
    try {
      if (typeof this.props.node.selected !== 'undefined') {
        this.el.selected = this.props.node.selected
      }
    } catch (e) {
      console.warn(e)
    }
  }
}
