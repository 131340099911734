import React from 'react'
import DOMEvents from './DOMEvents'
import DOMNode from './DOMNode'
import ShadowRoot from '../../components/ShadowRoot'

export default class DOMShadowRoot extends DOMNode {
  static nodeFilter (node) {
    return node
  }

  onShadowDomRef = (el) => {
    if (el && this.el !== el) {
      // remove listener from any previous element
      if (this.el) this.el.removeEventListener('scroll', this.onScrollEvent, { capture: true, passive: true })

      // scroll events need to be passed on to root event handling as they don't bubble
      el.addEventListener('scroll', this.onScrollEvent, { capture: true, passive: true })

      this.el = el
    }
  }

  updateAdoptedStyleSheets = () => {
    try {
      const css = new this.el.ownerDocument.defaultView.CSSStyleSheet()
      css.replaceSync(this.props.node.css)
      this.el.adoptedStyleSheets = [css]
    } catch (e) {
      console.log('Failed to apply adoptedStyleSheet', e)
    }
  }

  componentDidMount () {
    super.componentDidMount()
    this.updateAdoptedStyleSheets()
  }

  componentDidUpdate () {
    super.componentDidUpdate()
    this.updateAdoptedStyleSheets()
  }

  componentWillUnmount () {
    super.componentWillUnmount()
    this.el.removeEventListener('scroll', this.onScrollEvent, { capture: true, passive: true })
  }

  onScrollEvent = (e) => {
    DOMEvents.events.emit('event', e)
  }

  render () {
    return (
      <ShadowRoot elRef={this.onShadowDomRef}>
        {this.renderChildren(this.props.node.childNodes)}
      </ShadowRoot>
    )
  }
}
