import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CodeEntry from '../sessions/CodeEntry'
import DeviceList from '../devices/DeviceList'
import Login from '../auth/Login'
import UserNavigation from '../navigation/UserNavigation'
import Device from './Device.state'
import Account from '../accounts/Account.state'
import User from '../users/User.state'
import EmbedParams from '../../utils/EmbedParams'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import { isEqual } from 'lodash'

const DevicesDashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const account = useActiveAccountSelector().resource
  const devices = useSelector(state => Device.fromState(state), isEqual)
  const user = useSelector(state => User.fromState(state))

  const getPresence = useCallback((device) => dispatch(Device.actionCreators().getPresence(device)), [dispatch])

  const refresh = useCallback((params = {}, options = {}) => {
    return dispatch(Device.actionCreators().getDevices(undefined, params, options))
  }, [dispatch])

  const renderCodeEntry = () => {
    return (
      <div className='p-1 text-center flex flex-1 flex-col items-center justify-center'>
        <div className='p-2.5'>{t('Enter a session code from the customer')}</div>
        <CodeEntry inputClassName='sm:h-10 sm:w-10 md:h-10 md:w-10' />
      </div>
    )
  }

  const renderContent = () => {
    if (!Account.hasFeature(account, 'device_listing', true)) {
      return renderCodeEntry()
    }

    return (
      <DeviceList
        account={account}
        devices={devices}
        getPresence={getPresence}
        refresh={refresh}
      />
    )
  }

  if (!user.resource) {
    return <Login />
  }

  return (
    <UserNavigation
      hide={!EmbedParams.navigation()}
      hideCodeEntry={!Account.hasFeature(account, 'device_listing', true)}
      title={t('Devices')}
    >
      {renderContent()}
    </UserNavigation>
  )
}

export default DevicesDashboard
