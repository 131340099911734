import React from 'react'
import { Trans } from 'react-i18next'
import Link from '../../components/Link'
import BannerMessage from '../../components/BannerMessage'
import Account from '../accounts/Account.state'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'

export const showUpgradeBanner = (account) => {
  const { created } = account.resource
  const accountAgeInMilliseconds = (Date.now() - new Date(created).getTime())
  const isNewAccount = accountAgeInMilliseconds < (3 * 24 * 60 * 60 * 1000) // 3 days

  if (isNewAccount) {
    return false
  }

  return Account.hasFeature(account.resource, 'upgrade_banners')
}

const UpgradeBanner = () => {
  const account = useActiveAccountSelector()

  if (showUpgradeBanner(account)) {
    return (
      <BannerMessage>
        <Trans>
          <b>Ready for production?</b> <Link className='text-white underline hover:text-grey' to='/dashboard/settings/billing'>Upgrade</Link> to a production license before launching.
        </Trans>
      </BannerMessage>
    )
  }
}

export default UpgradeBanner
