import { useState, useEffect } from 'react'

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY)

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY)

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scrollPosition
}

export default useScrollPosition
