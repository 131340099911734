import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import Callout from '../../components/Callout'
import Icon from '../../components/Icon'
import { SettingsGroup } from '../../components/SettingsGroup'
import mergeClassNames from '../../utils/mergeClassNames'
import ServerTime from '../../utils/ServerTime'

const SessionTimeoutCountdown = ({ expires }) => {
  const { t } = useTranslation()
  const [, setRenderCount] = useState(0)

  useEffect(() => {
    const renderInterval = setInterval(() => setRenderCount((renderCount) => ++renderCount), 1000)

    return () => clearInterval(renderInterval)
  }, [])

  const remainingTime = () => {
    if (!expires) {
      return null
    }

    return Math.max(0, moment(new Date(expires)).diff(ServerTime.now()))
  }

  const renderMessage = () => {
    const remaining = remainingTime()

    if (remaining === null) {
      return null
    }

    const nearTimeout = remaining < (30 * 1000)

    return (
      <div className={mergeClassNames('flex gap-x-2 items-center', nearTimeout && 'text-red')}>
        <Icon type='clock-circled' className='min-w-fit' />
        <Trans><b>{{ time: moment.utc(remaining).format('mm:ss') }}</b><span className='hidden md:inline'> remaining</span></Trans>
      </div>
    )
  }

  const renderCallout = () => {
    return (
      <SettingsGroup className='max-w-[calc(100vw-100px)] w-72 whitespace-normal text-black z-50'>
        <SettingsGroup.Body>
          <Trans><b>Development version only.</b> This time limit exists during development and testing only.</Trans>
          <p className='mt-2.5'><Trans>When you’re ready to go live please <Link to='/dashboard/settings/billing' className='underline'>upgrade</Link> to a production license.</Trans></p>
        </SettingsGroup.Body>
        <SettingsGroup.Footer className='text-center'>
          <LinkButton target='_blank' to='/pricing'>{t('See pricing')}</LinkButton>
        </SettingsGroup.Footer>
      </SettingsGroup>
    )
  }

  return (
    <div className='text-sm' title='Free session time remaining'>
      <Callout content={renderCallout} contentClassName='bottom-12 -end-28 md:-end-36'>
        {renderMessage()}
      </Callout>
    </div>
  )
}

export default SessionTimeoutCountdown
