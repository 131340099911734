/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ButtonLink from '../../components/ButtonLink'
import Login from '../auth/Login'
import UserNavigation from '../navigation/UserNavigation'
import CopyText from '../../components/CopyText'
import Account from '../accounts/Account.state'
import Session from '../sessions/Session.state'
import User from '../users/User.state'
import Presentation from './Presentation.state'
import EmbedParams from '../../utils/EmbedParams'
import Icon from '../../components/Icon'
import { SettingsGroup } from '../../components/SettingsGroup'
import mergeClassNames from '../../utils/mergeClassNames'
import PlatformIcon from '../../components/PlatformIcon'
import Loading from '../../components/Loading'
import formatPlatformName from '../../utils/formatPlatformName'
import Label from '../../components/Label'
import ErrorMessage from '../errors/ErrorMessage'

const PresentMode = () => {
  const [localSharing, setLocalSharing] = useState(false)
  const [media, setMedia] = useState(null)
  const [sessionCode, setSessionCode] = useState(null)
  const video = useRef(null)
  const CobrowseIO = useRef(null)
  const { t } = useTranslation()
  const session = useSelector(state => Session.fromState(state))
  const account = useSelector(state => Account.activeAccount(state).resource)
  const user = useSelector(state => User.fromState(state))
  const presentation = useSelector(state => Presentation.fromState(state))
  const dispatch = useDispatch()

  const resetSession = useCallback(() => dispatch(Session.actionCreators().resetSession()), [dispatch])
  const resetPresentation = useCallback(() => dispatch(Presentation.actionCreators().resetPresentation()), [dispatch])
  const createSession = useCallback((data, options = {}) => dispatch(Session.actionCreators().createSession(data, {}, options)), [dispatch])
  const subscribeSession = useCallback(() => dispatch(Session.actionCreators().subscribeSession()), [dispatch])
  const getPresentation = useCallback((params) => dispatch(Presentation.actionCreators().getPresentation(params)), [dispatch])
  const updateSession = (params) => dispatch(Session.actionCreators().updateSession(params))
  const endSession = useCallback((params) => dispatch(Session.actionCreators().endSession(params)), [dispatch])

  // create a session and then fetch the present mode tokens
  const reset = useCallback(async (abortController) => {
    resetSession()
    resetPresentation()

    // lookup our closest region
    const region = await Session.findClosestRegion()
    if (!region) return

    // create a session for that region
    const session = await createSession({ agent: 'me', region: region.id }, { signal: abortController?.signal })
    if (!session) return

    subscribeSession()
    await getPresentation({ id: session.id })
  }, [createSession, getPresentation, resetSession, resetPresentation, subscribeSession])

  // trigger a reset on load which will generate the session / presentation tokens
  useEffect(() => {
    const abortController = new AbortController()
    reset(abortController)
    return () => {
      abortController.abort()
    }
  }, [reset])

  // Lazy load and configure the Cobrowse JS SDK
  useEffect(() => {
    const handleSessionUpdated = (session) => {
      if (session.isActive() && !session.fullDevice()) {
        session.end()
      }
    }
    const runEffect = async () => {
      CobrowseIO.current = (await import('cobrowse-sdk-js')).default

      // Configure some SDK properties in case we start a session
      // using this built in SDK.
      // It's only useful in full device mode (otherwise they'd jsut be
      // sharing the agent dashboard)
      CobrowseIO.current.capabilities = ['full_device']
      // Session controls and consent will be provided by the agent frontend, the SDK
      // doesn't need to do anything here.
      CobrowseIO.current.showSessionControls = function () { }
      CobrowseIO.current.hideSessionControls = function () { }
      CobrowseIO.current.confirmSession = async () => true
      CobrowseIO.current.confirmRemoteControl = async () => false

      CobrowseIO.current.api = window.location.origin
      CobrowseIO.current.license = account.license_key
      CobrowseIO.current.on('session.updated', handleSessionUpdated)

      // try to clean up any bad state that is potentially hanging about
      try {
        await CobrowseIO.current.stop()
      } catch (e) {
        console.error(e)
      }
    }
    runEffect()

    return () => {
      CobrowseIO.current?.off('session.updated', handleSessionUpdated)
      try {
        CobrowseIO.current?.stop()
      } catch (e) {
        console.error(e)
      }
    }
  }, [account])

  // If an active present mode session ends, make sure the react / redux
  // state gets reset
  useEffect(() => {
    if (session.resource?.state === 'ended') {
      reset()
      setSessionCode(null)
      setLocalSharing(false)
    }
  }, [session.resource, reset])

  const endScreenShare = useCallback(async () => {
    await endSession(session.resource)
  }, [endSession, session.resource])

  const startWebBasedDesktopSharing = async () => {
    setLocalSharing(true)

    const media = navigator.mediaDevices.getDisplayMedia({
      video: {
        cursor: 'always',
        width: { ideal: 1400 },
        height: { ideal: 1000 },
        frameRate: { max: 10 }
      },
      audio: false
    })

    await updateSession({
      id: session.resource.id,
      full_device: 'requested'
    })

    CobrowseIO.current.confirmFullDevice = async () => await media
    CobrowseIO.current.start({ allowIFrameStart: true, register: false })

    const s = await CobrowseIO.current.getSession(session.resource.id)
    s.on('media', (media) => {
      if (video.current) {
        setMedia(media)
        video.current.srcObject = media
        video.current.play()
      }
    })
  }

  const createSessionCode = async () => {
    const code = session.resource.code

    setSessionCode(code)
  }

  const renderDeviceInfo = () => {
    const { device } = session.resource

    if (device && device.platform && !localSharing) {
      return (
        <div className='text-white'>
          {t('Sharing from {{device_platform}}', { device_platform: formatPlatformName(device.platform) })}
        </div>
      )
    }
  }

  const renderStartSharing = () => {
    if (sessionCode) {
      return
    }

    return (
      <div className='flex items-center justify-center'>
        <Button onClick={startWebBasedDesktopSharing} className='flex gap-x-2 items-center'>
          {t('Share my desktop')}
          <Icon type='slideshare' />
        </Button>
      </div>
    )
  }

  const renderShareCode = () => {
    return (
      <div className={mergeClassNames('flex items-center justify-center w-full', sessionCode && 'h-full')}>
        {!sessionCode && (
          <ButtonLink onClick={createSessionCode} className='px-4 py-3'>
            {t('Share a different device')}
          </ButtonLink>
        )}
        {sessionCode && (
          <div className='flex flex-col h-full w-full'>
            <div>
              <ButtonLink onClick={() => setSessionCode(null)} className='flex gap-x-2 items-center self-start'>
                <Icon type='arrow-left' className='h-4' />
                {t('Go back')}
              </ButtonLink>
            </div>
            <div className='flex flex-col items-center justify-center gap-y-4 h-full'>
              <p className='text-base'>
                {t('Enter this code on your device:')}
              </p>
              <p className='text-h-lg tracking-[3.2px]'>
                {sessionCode}
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderInstructions = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Share this link to let others see your screen')}</SettingsGroup.Title>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <Label htmlFor='session-url'>{t('Session URL')}</Label>
          <CopyText id='session-url' variant='input'>
            {window.location.origin}/session/{session.resource.id}?token={presentation.resource.token}&agent_tools=none&device_controls=none&end_action=none&popout=none&session_details=none
          </CopyText>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderMedia = () => {
    if (!localSharing) {
      return (
        <div className='bg-black w-full h-[500px] max-w-3xl flex items-center justify-center text-white' />
      )
    }

    return (
      <video className={mergeClassNames('hidden', media && 'block')} ref={video} />
    )
  }

  const renderScreenshare = () => {
    const { device } = session.resource

    return (
      <div
        className='relative w-full max-w-3xl mx-auto rounded-lg border-2 retina:border-1.5 border-black/15 shadow overflow-hidden'
      >
        {renderMedia()}
        <div className='absolute inset-0 flex flex-col gap-y-4 items-center justify-center bg-black/50 text-white'>
          {!localSharing && <PlatformIcon device={device} isOnline className='h-14 w-14 mb-8' />}
          <p>
            {localSharing ? t('Your screen is being shared') : t('You are sharing {{app_name}}', { app_name: device.app_name || t('an external app') })}
          </p>
          <Button variant='error' onClick={endScreenShare} className='flex gap-x-2.5'>
            <Icon type='stop' className='h-2.5' />
            {t('End session')}
          </Button>
          {renderDeviceInfo()}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (!Account.hasFeature(account, 'present_mode', true)) {
      return <div className='flex flex-col items-center justify-center h-full'>{t('Presentation mode has been disabled for your account')}</div>
    }

    if (session.error) {
      return (
        <ErrorMessage error={session.error} />
      )
    }

    if (!session.resource || !presentation.resource?.token) {
      return <Loading className='h-full' />
    }

    if (session.resource.state === 'pending' || session.resource.state === 'ended') {
      return (
        <div className='flex flex-col gap-y-4 h-full'>
          {renderInstructions()}
          <div className='flex flex-col gap-y-4 h-full items-center justify-center'>
            {renderStartSharing()}
            {renderShareCode()}
          </div>
        </div>
      )
    } else if (session.resource.state === 'authorizing' || session.resource.state === 'active') {
      return (
        <div className='flex flex-col gap-y-4'>
          {renderInstructions()}
          {renderScreenshare()}
        </div>
      )
    }
  }

  if (!user.resource) {
    return <Login />
  }

  return (
    <UserNavigation hide={!EmbedParams.navigation()} title={t('Present')}>
      <div className='w-full h-full lg:container lg:mx-auto'>
        {renderContent()}
      </div>
    </UserNavigation>
  )
}

export default PresentMode
