import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import * as icons from '../icons/icons'

const Icon = ({ type, spin = false, reverse = false, className, ...props }) => {
  const iconName = type
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
  const IconComponent = icons[iconName]

  return (
    <div aria-hidden='true' className={mergeClassNames('h-4.5', spin && 'animate-spin inline-block', reverse && ' [animation-direction:reverse]', className)} {...props}>
      <IconComponent width='0px' height='100%' />
    </div>
  )
}

export default Icon
