import RESTObject from '../redux-rest'

export default class SessionRecording extends RESTObject {
  static namespace () {
    return 'SessionRecording'
  }

  static url (params = {}) {
    return `/api/1/sessions/${params.id}/recording`
  }
}
