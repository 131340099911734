import React from 'react'
import { useSelector } from 'react-redux'
import CodeEntry from './CodeEntry'
import Login from '../auth/Login'
import UserNavigation from '../navigation/UserNavigation'
import User from '../users/User.state'
import EmbedParams from '../../utils/EmbedParams'
import { useTranslation } from 'react-i18next'

const CodeEntryScreen = () => {
  const user = useSelector(state => User.fromState(state))
  const { t } = useTranslation()

  const renderCodeEntry = () => {
    return (
      <div className='text-center flex flex-col flex-1 items-center justify-center'>
        <div className='p-2.5'>{t('Enter a session code from the customer')}</div>
        <CodeEntry />
      </div>
    )
  }

  if (!user.resource) {
    return <Login />
  }

  return (
    <UserNavigation hide={!EmbedParams.navigation()} title={t('Code entry')}>
      {renderCodeEntry()}
    </UserNavigation>
  )
}

export default CodeEntryScreen
