/* eslint-disable react/jsx-handler-names */
import { throttle } from 'lodash'
import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import AnnotationEditor from './AnnotationEditor'
import ButtonLink from '../../components/ButtonLink'
import DeviceInfo from '../devices/DeviceInfo'
import Loading from '../../components/Loading'
import PopoutButton from '../../components/PopoutButton'
import RemoteScreen from '../screens/RemoteScreen'
import SessionTimeoutCountdown from './SessionTimeoutCountdown'
import SessionToolbar from './SessionToolbar'
import Sidebar, { ToggleButton } from '../../components/Sidebar'
import Alert from '../../components/Alert'
import EmbedParams from '../../utils/EmbedParams'
import Session from './Session.state'
import mergeClassNames from '../../utils/mergeClassNames'
import Icon from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'
import SessionCapability from './SessionCapability'
import DeviceStatus from '../devices/DeviceStatus'
import URLView from '../../components/URLView'
import isInIframe from '../../utils/isInIframe'
import { SESSION_TOOL_TYPE_CONTROL } from './SessionToolTypes'
import { SESSION_CAPABILITY_TYPE_DISAPPEARING_INK } from './SessionCapabilityTypes'
import Stopwatch from '../../components/Stopwatch'
import ServerTime from '../../utils/ServerTime'
import Button from '../../components/Button'

function hasDisappearingInkToolEnabled (session) {
  return SessionCapability.hasDeviceCapability(session, SESSION_CAPABILITY_TYPE_DISAPPEARING_INK)
}

class ActiveSession extends Component {
  constructor () {
    super()
    this.state = {
      height: 0,
      width: 0,
      url: false,
      display: false,
      sidebarOpen: false
    }
  }

  componentDidMount () {
    // Keep sending deduplicated syncs until we get a sync response
    this.syncInterval = setInterval(() => this.props.sync({ id: `${Date.now() - (Date.now() % 5000)}` }), 100)

    // periodically force a screen render to display error messages
    // that are triggered after a time since the last alive message
    this.renderInterval = setInterval(() => this.forceUpdate(), 1000)

    document.addEventListener('keydown', this.onKeyPress)
    document.addEventListener('keyup', this.onKeyPress)
    window.addEventListener('resize', this.onResize)

    // see if we need to keep the session alive periodically
    this.activityInterval = setInterval(this.keepSessionAlive, 20 * 1000)

    this.setSupportedVideoCodecs()
  }

  componentDidUpdate (prevProps) {
    const prevTs = prevProps.screen && prevProps.screen.timestamp
    const newTs = this.props.screen && this.props.screen.timestamp
    if (this.props.drawing === null || prevTs !== newTs) this.clear()
  }

  componentWillUnmount () {
    clearInterval(this.syncInterval)
    clearInterval(this.renderInterval)
    clearInterval(this.activityInterval)

    document.removeEventListener('keydown', this.onKeyPress)
    document.removeEventListener('keyup', this.onKeyPress)
    window.removeEventListener('resize', this.onResize)
  }

  setSupportedVideoCodecs = () => {
    const supportedCodecs = []

    if (this.isH264Supported()) supportedCodecs.push('video/avc')

    supportedCodecs.push('image/jpeg')

    const currentCodecs = this.props.session.video_codecs || []
    const newCodecs = currentCodecs.length ? currentCodecs.filter(c => supportedCodecs.includes(c)) : supportedCodecs

    this.props.updateSession({ id: this.props.session.id, video_codecs: newCodecs })
  }

  isH264Supported = () => !!(window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.4d002a"'))

  keepSessionAlive = () => {
    // free sessions don't extend
    if (this.showUpgrade()) return
    // backgrounded pages don't get extended
    if (document.hidden) return
    // if the device is not responding, don't extend the session
    if (!this.deviceIsOnline()) return
    // when we're nearly at the end of the session, trigger an extension
    const remaining = Session.remainingTime(this.props.session)
    if (remaining && remaining < 60 * 1000) {
      console.log('Marking session as still active')
      this.props.updateSession({ id: this.props.session.id })
    }
  }

  onToolUsed = (tool, event, bounds) => {
    this.props.onToolUsed(tool, event, bounds, this.display())
  }

  onActiveSessionRef = (el) => {
    this.el = el
  }

  onEditorRef = (el) => {
    this.editor = el
  }

  onResize = throttle(() => {
    this.forceUpdate()
  }, 200)

  onKeyPress = (e) => {
    if (this.props.tool !== SESSION_TOOL_TYPE_CONTROL) return
    this.onToolUsed('control', e)
    e.preventDefault()
  }

  onRemoteScreenSize = (size) => {
    if (size && ((this.state.height !== size.height) ||
      (this.state.width !== size.width))) {
      this.setState(size)
      this.props.onScreenResize(size)
      // once a size has been recieved, streaming has started
      // so we can kill the initial sync requesting
      clearInterval(this.syncInterval)
    }
  }

  onDOMChange = (dom) => {
    this.setState({ url: dom.document.url })
  }

  handleFullDevice = (checked) => {
    this.props.updateSession({ id: this.props.session.id, full_device: checked ? 'requested' : 'off' })
  }

  streamingHasStarted = () => {
    return !!this.state.width
  }

  showUpgrade = () => {
    return this.props.session.expiry_behaviour === 'upgrade'
  }

  sessionExpired = () => {
    if (!this.props.session.expires) return false
    return Session.remainingTime(this.props.session) <= 0
  }

  display = () => {
    const displays = Object.keys(this.props.displays)
    return this.state.display || displays[0]
  }

  changeTool = (tool) => {
    this.props.onToolSelected(tool)
    if (tool !== SESSION_TOOL_TYPE_CONTROL) this.props.onToolUsed('control', { type: 'mousemove' }, { x: -1000, y: -1000 })
  }

  clear = () => {
    if (this.editor) {
      this.editor.clear()
      this.onToolUsed('drawing', { id: null })
    }
  }

  handleAndroidBack = () => {
    this.onToolUsed('control', {
      type: 'keydown',
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
      key: 'GoBack',
      code: 'GoBack'
    })
  }

  handleAndroidHome = () => {
    this.onToolUsed('control', {
      type: 'keydown',
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
      key: 'GoHome',
      code: 'GoHome'
    })
  }

  deviceIsOnline = () => {
    // if a device goes AWOL for more than X seconds, display a warning
    const { metrics } = this.props.connectivity
    return ((!metrics.last_alive) || ((Date.now() - metrics.last_alive) < 10 * 1000))
  }

  performAction = (action) => {
    if (action === 'clear') this.clear()
    if (action === 'end') this.props.endSession(this.props.session)
  }

  shouldApplyPadding = () => {
    return EmbedParams.agentTools() || EmbedParams.sessionDetails() || EmbedParams.deviceControls()
  }

  computeScreenPadding = () => {
    const padding = this.shouldApplyPadding() ? 60 : 0

    return {
      width: padding,
      height: padding
    }
  }

  computeScreenScale = () => {
    if (!this.el) return 1
    if (!(this.state.height && this.state.width)) return 1
    const padding = this.computeScreenPadding()
    const containerWidthRatio = (this.el.offsetWidth - padding.width) / this.state.width
    const containerHeightRatio = (this.el.offsetHeight - padding.height) / this.state.height
    const scale = Math.min(containerWidthRatio, containerHeightRatio)
    return scale
  }

  changeDisplay = (display) => {
    this.setState({ display })
    this.props.sync()
  }

  renderAndroidControl = (key, className, onClick, children) => {
    return <span key={key} className={mergeClassNames('w-8 cursor-pointer text-md hover:text-black-400', className)} onClick={onClick}>{children}</span>
  }

  renderAndroidControls = () => {
    const control = this.props.tool === SESSION_TOOL_TYPE_CONTROL

    return [
      control ? this.renderAndroidControl('back', 'ms-0 me-1 rotate-180', this.handleAndroidBack, <Icon type='play' />) : null,
      control ? this.renderAndroidControl('home', 'ms-1 me-4', this.handleAndroidHome, <Icon type='circle' />) : null
    ]
  }

  renderDisplayOption = (display) => {
    const selected = display === this.display()
    return (
      <div key={display} style={{ border: selected ? '3px solid red' : '3px solid black', margin: 10 }} onClick={() => this.changeDisplay(display)}>
        <RemoteScreen height={60} scale={1} display={this.props.displays[display]} selectedColor={this.props.selectedColor} />
      </div>
    )
  }

  renderDisplayPicker = () => {
    const displays = Object.keys(this.props.displays)
    if (displays.length <= 1) return null
    return (
      <div className='flex justify-center transition-all duration-150 ease-in-out delay-200'>
        {displays.map(this.renderDisplayOption)}
      </div>
    )
  }

  renderDeviceControls = () => {
    if (!this.streamingHasStarted()) return null

    return (
      <div className='m-2 flex flex-row flex-wrap gap-4 text-sm text-black-700 items-center justify-between min-w-0 whitespace-nowrap max-w-[80%] empty:hidden md:flex-nowrap' style={{ minWidth: this.state.width * this.computeScreenScale() }}>
        {this.props.session.device.platform === 'android' && this.renderAndroidControls()}
        {this.showUpgrade() && (
          <div className='flex justify-center w-full md:w-auto'>
            <SessionTimeoutCountdown expires={this.props.session.expires} />
          </div>
        )}
      </div>
    )
  }

  renderOverlayMessageComponent = (children) => {
    return <div className='absolute inset-0 flex flex-col items-center justify-center p-5 overflow-hidden text-center text-white bg-black/20' {...getTestId('session-overlay-message')}>{children}</div>
  }

  renderBannerComponent = (children, warning = false) => {
    return (
      <div
        className={mergeClassNames('flex absolute inset-0 justify-center items-center px-2 w-full h-full bg-black/50')}
        {...getTestId('session-banner')}
      >
        <Alert type={warning ? 'alert' : 'info'} variant='floating'>
          {children}
        </Alert>
      </div>
    )
  }

  renderOverlayMessage = () => {
    if (!EmbedParams.messages()) return null

    // development sessions are time-limited
    if (this.sessionExpired() && this.showUpgrade()) {
      return this.renderOverlayMessageComponent(<Trans>Development session expired<p className='text-base'>This limit only exists in development and testing.</p></Trans>)
    }

    if (!this.deviceIsOnline()) {
      return this.renderOverlayMessageComponent(this.props.t('Device is not responding'))
    }

    // show a message if the user has backgrounded the app
    if ((!Session.isFullDevice(this.props.session)) && this.props.screen?.state === 'background') {
      return this.renderOverlayMessageComponent(this.props.t('The app is running in the background on the users device'))
    }

    return null
  }

  renderRemoteControlAcceptance = () => {
    if (!EmbedParams.messages()) return null
    if ((this.props.tool === SESSION_TOOL_TYPE_CONTROL) && (this.props.session.remote_control === 'rejected')) {
      return this.renderOverlayMessageComponent(
        this.renderBannerComponent(<Trans>Remote control was not allowed by the user. <ButtonLink className='px-4 hover:text-black/85' onClick={this.props.requestRemoteControl}>Request again.</ButtonLink></Trans>, true)
      )
    }

    if ((this.props.tool === SESSION_TOOL_TYPE_CONTROL) && (this.props.session.remote_control !== 'on')) {
      return this.renderOverlayMessageComponent(
        this.renderBannerComponent(this.props.t('Waiting for the user to allow remote control'))
      )
    }

    return null
  }

  renderRemoteScreen = () => {
    const style = {}
    const scale = this.computeScreenScale()
    const width = Math.round(this.state.width * scale)
    const height = Math.round(this.state.height * scale)
    if (!this.streamingHasStarted()) style.display = 'none'

    return (
      <div style={style} className={mergeClassNames('bg-white inline-block relative border border-gray-700/25 transition-transform duration-300 select-none overflow-hidden rounded-lg', this.shouldApplyPadding() && 'rounded-lg shadow')} {...getTestId('screen-container')}>
        <div className={mergeClassNames(this.renderOverlayMessage() && 'blur-sm opacity-50')}>
          <RemoteScreen
            key={this.display()}
            onSize={this.onRemoteScreenSize}
            height={this.state.height}
            width={this.state.width}
            scale={scale}
            toolHandler={this.onToolUsed}
            display={this.props.displays[this.display()]}
            mouse={this.props.mouse}
            onDOMChange={this.onDOMChange}
            tool={this.props.tool}
            capabilities={SessionCapability.all(this.props.session)}
            proxy={this.props.proxy}
            selectedColor={this.props.selectedColor}
          />
          <AnnotationEditor
            ref={this.onEditorRef}
            disable={(Session.isFullDevice(this.props.session) || this.props.tool === SESSION_TOOL_TYPE_CONTROL) && this.props.session.device.platform === 'web'}
            touch={['android', 'ios'].includes(this.props.session.device.platform)}
            tool={this.props.tool}
            height={height}
            width={width}
            rotation={this.state.rotation}
            scale={scale}
            toolHandler={this.onToolUsed}
            selectedColor={this.props.selectedColor}
            allowMultipleDrawings={hasDisappearingInkToolEnabled(this.props.session)}
          />
        </div>
        {this.renderOverlayMessage() || this.renderRemoteControlAcceptance()}
      </div>
    )
  }

  handleSidebarVisibilityChanged = (state) => {
    this.setState({ sidebarOpen: state })
  }

  render () {
    const { metrics } = this.props.connectivity

    console.log('🚀 ~ ActiveSession ~ render ~ this.props.session.activated:', this.props.session.activated)
    console.log('🚀 ~ ActiveSession ~ render ~ ServerTime.now():', ServerTime.now())
    return (
      <div className={mergeClassNames('flex flex-col flex-1 items-center relative h-full max-h-full bg-slate-900 overflow-hidden md:max-h-screen p-6 md:p-4', isInIframe() && 'bg-transparent')}>
        <div className={mergeClassNames('flex flex-col flex-1 items-center relative w-full h-full max-h-screen transition-all duration-300 ease-in-out gap-4', this.state.sidebarOpen && 'md:overflow-hidden')}>
          <div className={mergeClassNames('flex flex-col flex-1 relative min-w-0 h-full w-full md:flex-auto')}>
            <div className='flex h-full'>
              <div className='relative flex flex-col items-center justify-center flex-1 flex-grow overflow-hidden md:flex-auto md:flex-shrink' ref={this.onActiveSessionRef}>
                {EmbedParams.popout() ? <PopoutButton /> : null}
                {this.renderRemoteScreen()}
                {EmbedParams.deviceControls() ? this.renderDeviceControls() : null}
                {this.streamingHasStarted() ? null : <Loading />}
              </div>
              <Sidebar
                className='relative'
                visible={this.state.sidebarOpen}
                onVisibilityChanged={this.handleSidebarVisibilityChanged}
              >
                <div className='sticky top-0 flex flex-col w-full px-4 pt-4 bg-white'>
                  <div className='flex items-center justify-between'>
                    <DeviceStatus device={{ device: this.props.session.device, custom_data: this.props.session.custom_data }} isOnline hideRealtimeInfo identifierClassName='text-lg text-slate-900 min-w-0' />
                    <Button
                      variant='tertiary'
                      className=''
                      onClick={() => this.handleSidebarVisibilityChanged(false)}
                    >
                      <span className='sr-only'>Close</span>
                      <Icon type='close' className='w-6 h-6 text-black' reverse />
                    </Button>
                  </div>
                  <div className='flex items-center' />
                </div>
                {this.state.url && <URLView url={this.state.url} />}
                <DeviceInfo
                  showAll
                  device={{ ...this.props.session.device, rtt: `${metrics.rtt || Infinity} ms` }}
                  customData={this.props.session.custom_data}
                  className='px-4 pb-4'
                />
              </Sidebar>
            </div>
            {this.renderDisplayPicker()}
          </div>
          <div className='flex items-center justify-between w-full'>
            <Stopwatch className='text-sm font-semibold text-gray-200 min-w-16' start={this.props.session.activated} end={ServerTime.now()} />
            {EmbedParams.agentTools()
              ? <SessionToolbar
                  selected={this.props.tool}
                  session={this.props.session}
                  control={this.props.allowControl}
                  allowFullDevice={this.props.allowFullDevice}
                  onToolSelected={this.changeTool}
                  onFullDeviceChanged={this.handleFullDevice}
                  onExpanded={this.handleSidebarVisibilityChanged}
                  performAction={this.performAction}
                  user={this.props.user}
                  account={this.props.account}
                  className=''
                  selectedColor={this.props.selectedColor}
                  onColorPicked={this.props.onColorPicked}
                />
              : null}
            {EmbedParams.sessionDetails()
              ? (
                <ToggleButton
                  isOpen
                  hideText
                  onClick={() => this.handleSidebarVisibilityChanged(!this.state.sidebarOpen)}
                  className='justify-center hidden w-10 h-10 p-1 md:block'
                />

                )
              : EmbedParams.agentTools() && <div className='mt-4 md:mt-0' />}
          </div>
        </div>

      </div>
    )
  }
}

export default withTranslation()(ActiveSession)
