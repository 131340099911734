import RESTObject from '../redux-rest'

export default class TalkdeskConfig extends RESTObject {
  static namespace () {
    return 'TalkdeskConfig'
  }

  static url () {
    return '/api/1/talkdesk/configuration'
  }
}
