import React, { forwardRef, useId } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import EmbedParams from '../../utils/EmbedParams'

const CodeEntryInput = forwardRef(({ className, onKeyDown, onChange, onPaste, disabled, value, digit, focusOnRender = false }, ref) => {
  const { t } = useTranslation()
  const id = useId()

  const handleFocus = (event) => {
    const input = event.target

    setTimeout(() => {
      input.selectionStart = input.selectionEnd = input.value.length
    }, 0)
  }
  return (
    <Input
      id={id}
      autoFocus={focusOnRender}
      className={mergeClassNames('w-11 h-11 text-center rounded border-none border-0 text-md hover:border-solid hover:border-2 hover:retina:border-1.5 hover:border-black/50 focus:border-solid focus:border-2 focus:retina:border-1.5 placeholder:focus:text-transparent md:w-8 md:h-8 md:max-lg:px-2 lg:h-10 lg:w-10 lg:text-base', !EmbedParams.navigation() && 'md:w-10 md:h-10', className)}
      type='text'
      onKeyDown={onKeyDown}
      onChange={onChange}
      onPaste={onPaste}
      onFocus={handleFocus}
      value={value}
      pattern='\d*'
      data-digit={digit}
      ref={ref}
      disabled={disabled}
      placeholder={digit + 1}
      aria-label={t('Code entry digit {{digit}}', { digit: digit + 1 })}
      autoComplete='off'
      {...getTestId(`code-entry-digit-${digit}`)}
    />
  )
})

export default CodeEntryInput
