const initialState = {
  resource: null,
  collection: [],
  working: false
}

function mergeInto (restObject, resource, collection, options = {}) {
  const id = restObject.idFor(resource)
  const index = collection.findIndex(el => restObject.idFor(el) === id)
  if ((index === -1) && (options.add === false)) return collection
  // if a version of the resource doesn't exist in the collection already
  // then we might need to add it
  if (index !== -1) return [...collection.slice(0, index), resource, ...collection.slice(index + 1)]
  else return [...collection, resource]
}

function mergeAllInto (restObject, resources, collection, options) {
  if (!options.merge) return resources
  let newCollection = collection
  resources.forEach(resource => {
    newCollection = mergeInto(restObject, resource, newCollection, options)
  })
  return newCollection
}

function applyActionToCaches (restObject, action, state) {
  // GET requests always blat the current state
  if (action.type === restObject.types.GET) {
    if (Array.isArray(action.resource)) {
      return { collection: mergeAllInto(restObject, action.resource, state.collection, action.options) }
    } else return { resource: action.resource }
  }

  // for POST, PUT, DELETE requests, we try to apply them intelligently to
  // whatever state we already have in the collection field.
  // i.e. updating or remove the any resources with matching ids in the collection
  switch (action.type) {
    // on resource creation just save the new resource into the collection.
    // if the collection has been filtered in some way this might not always be
    // appropriate, but will be most of the time.
    case restObject.types.CREATE: {
      return {
        resource: action.resource,
        collection: mergeInto(restObject, action.resource, state.collection)
      }
    }

    // cache actions update the collection, but only update the resource if it matches
    case restObject.types.CACHE: {
      return {
        resource: (!state.resource) || (restObject.idFor(action.resource) === restObject.idFor(state.resource)) ? action.resource : state.resource,
        collection: mergeInto(restObject, action.resource, state.collection, action.options)
      }
    }

    // if a resource is updated, then create an updated collection
    case restObject.types.UPDATE: {
      return {
        resource: action.resource,
        collection: mergeInto(restObject, action.resource, state.collection, action.options)
      }
    }
    // on resource deletion, then remove the resource from the collection
    // if it existed
    case restObject.types.DELETE: {
      const id = restObject.idFor(action.initial_resource)
      return {
        resource: action.resource,
        collection: state.collection.filter(item => restObject.idFor(item) !== id)
      }
    }

    default: {
      return { ...state }
    }
  }
}

export default function restReducer (restObject) {
  return (state = initialState, action) => {
    switch (action.type) {
      case restObject.types.RESET:
        return { ...initialState }

      case restObject.types.CACHE:
        return {
          ...state,
          ...applyActionToCaches(restObject, action, state)
        }

      case restObject.types.GET:
      case restObject.types.CREATE:
      case restObject.types.UPDATE:
      case restObject.types.DELETE:
        switch (action.state) {
          case 'pending':
            return {
              ...state,
              working: true,
              error: false
            }
          case 'resolved':
            return {
              ...state,
              ...applyActionToCaches(restObject, action, state),
              working: false,
              error: false
            }
          case 'rejected':
            return {
              ...state,
              working: false,
              error: action.resource
            }
          default:
            return state
        }

      default:
        return state
    }
  }
}
