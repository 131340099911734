import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { Menu as HeadlessMenu, Popover as HeadlessPopover } from '@headlessui/react'
import Button, { buttonClassName } from './Button'
import ButtonLink from './ButtonLink'
import Icon from './Icon'
import { useTranslation } from 'react-i18next'

const Menu = ({ menuItems = [], className }) => {
  return (
    <HeadlessMenu as='div' className='relative'>
      <HeadlessMenu.Button className={buttonClassName('tertiary', className, 'rounded-e-lg rounded-s-none')}>
        <Icon type='chevron-up' className='rotate-180' />
      </HeadlessMenu.Button>
      <HeadlessMenu.Items
        className='absolute end-0 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10'
      >
        {menuItems.map(({ text, ...props }, index) => (
          <div className='p-2' key={index}>
            <HeadlessMenu.Item>
              {({ active }) => (
                <ButtonLink
                  className={mergeClassNames(active && 'bg-grey', 'text-black text-left group w-full items-center rounded-md p-2 text-sm')}
                  {...props}
                >
                  {text}
                </ButtonLink>
              )}
            </HeadlessMenu.Item>
          </div>
        ))}
      </HeadlessMenu.Items>
    </HeadlessMenu>
  )
}

const Popover = ({ children, className }) => {
  const { t } = useTranslation()

  return (
    <HeadlessPopover className='relative'>
      <HeadlessPopover.Button className={buttonClassName('tertiary', className, 'ps-2 rounded-e-lg rounded-s-none')}>
        <Icon type='chevron-up' className='rotate-180' />
        <span className='sr-only'>{t('Open')}</span>
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        className='absolute end-0 mt-2 p-4 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10'
      >
        {children}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  )
}

const SplitButton = ({ menuItems = [], popoverContent, className, children, thinking, ...props }) => {
  if (menuItems.length && popoverContent) {
    console.error("menuItems and popoverContent props can't be used together, please use just one of them.")
    return
  }

  return (
    <div className='flex items-stretch h-full'>
      <Button
        className={mergeClassNames('rounded-s-lg rounded-e-none whitespace-nowrap', className, 'pe-2')}
        thinking={thinking}
        {...props}
      >
        {children}
      </Button>
      {menuItems.length > 0 && <Menu menuItems={menuItems} className={className} {...props} />}
      {!!popoverContent && <Popover className={className} {...props}>{popoverContent}</Popover>}
    </div>
  )
}

export default SplitButton
