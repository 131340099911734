import { memoize } from 'lodash'
import ALL_CAPABILITIES, {
  SESSION_CAPABILITY_TYPE_CURSOR,
  SESSION_CAPABILITY_TYPE_DRAWING,
  SESSION_CAPABILITY_TYPE_LASER,
  SESSION_CAPABILITY_TYPE_POINTER,
  SESSION_CAPABILITY_TYPE_SCROLL,
  SESSION_CAPABILITY_TYPE_KEYPRESS,
  SESSION_CAPABILITY_TYPE_SELECT,
  SESSION_CAPABILITY_TYPE_DISAPPEARING_INK
} from './SessionCapabilityTypes'
import {
  SESSION_EVENT_TYPE_CHANGE,
  SESSION_EVENT_TYPE_LASER,
  SESSION_EVENT_TYPE_DRAWING,
  SESSION_EVENT_TYPE_FOCUS,
  SESSION_EVENT_TYPE_INPUT,
  SESSION_EVENT_TYPE_KEYPRESS,
  SESSION_EVENT_TYPE_MOUSE,
  SESSION_EVENT_TYPE_POINTER,
  SESSION_EVENT_TYPE_SCROLL,
  SESSION_EVENT_TYPE_TOUCH,
  SESSION_EVENT_TYPE_SELECT
} from './SessionEventTypes'

export default class SessionCapability {
  static allowsEvent (session, event) {
    const capability = SessionCapability.capabilityForEvent(event)

    if (!capability) {
      return true
    }

    return SessionCapability.hasCapability(session, capability)
  }

  static hasCapability (session, capability) {
    // Allow any capability for older API versions
    if (typeof session.capabilities === 'undefined') {
      return true
    }

    return session.capabilities?.some(
      (item) => Array.isArray(capability)
        ? capability.includes(item)
        : capability === item
    )
  }

  static hasDeviceCapability (session, capability) {
    const capabilities = session.device?.capabilities
    // Allow any capability for older API versions
    if (typeof capabilities === 'undefined') {
      return true
    }

    return capabilities.some(
      (item) => Array.isArray(capability)
        ? capability.includes(item)
        : capability === item
    )
  }

  static all (session) {
    return ALL_CAPABILITIES.reduce((previous, capability) => ({
      ...previous,
      [capability]: SessionCapability.hasCapability(session, capability)
    }), {})
  }

  static capabilityForEvent (event) {
    const pointerMouseEventTypes = ['mousedown', 'mouseup', 'click', 'dblclick', 'pointerdown', 'pointerup']
    const events = {
      [SESSION_EVENT_TYPE_MOUSE]: pointerMouseEventTypes.includes(event.state)
        ? SESSION_CAPABILITY_TYPE_POINTER
        : [SESSION_CAPABILITY_TYPE_POINTER, SESSION_CAPABILITY_TYPE_CURSOR],
      [SESSION_EVENT_TYPE_TOUCH]: SESSION_CAPABILITY_TYPE_POINTER,
      [SESSION_EVENT_TYPE_POINTER]: SESSION_CAPABILITY_TYPE_POINTER,
      [SESSION_EVENT_TYPE_FOCUS]: [SESSION_CAPABILITY_TYPE_KEYPRESS, SESSION_CAPABILITY_TYPE_POINTER],
      [SESSION_EVENT_TYPE_KEYPRESS]: SESSION_CAPABILITY_TYPE_KEYPRESS,
      [SESSION_EVENT_TYPE_SCROLL]: SESSION_CAPABILITY_TYPE_SCROLL,
      [SESSION_EVENT_TYPE_CHANGE]: [SESSION_CAPABILITY_TYPE_KEYPRESS, SESSION_CAPABILITY_TYPE_POINTER],
      [SESSION_EVENT_TYPE_INPUT]: [SESSION_CAPABILITY_TYPE_KEYPRESS, SESSION_CAPABILITY_TYPE_POINTER],
      [SESSION_EVENT_TYPE_LASER]: [SESSION_CAPABILITY_TYPE_LASER],
      [SESSION_EVENT_TYPE_DRAWING]: event.disappears_in != null ? SESSION_CAPABILITY_TYPE_DISAPPEARING_INK : SESSION_CAPABILITY_TYPE_DRAWING,
      [SESSION_EVENT_TYPE_SELECT]: SESSION_CAPABILITY_TYPE_SELECT
    }
    const capability = events[event.type]

    return capability
  }
}

// Cache the result of SessionCapability.all
const originalAll = SessionCapability.all
SessionCapability.all = memoize(function all (session) {
  const capabilities = originalAll(session)

  // Ensure we only keep the cache for= the latest session
  SessionCapability.all.cache.clear()
  return capabilities
})
