import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import Icon from './Icon'
import LinkButton from './LinkButton'
import { useTranslation } from 'react-i18next'
import { Popover } from '@headlessui/react'
import PopoverContainer from './PopoverContainer'

const Divider = () => (
  <div className='border-t-2 retina:border-t-1.5 border-black/15' />
)

const AccountMenu = ({ user, organisationName, currentAccount, accounts, showContact, setActiveAccount, logout, className, children, ...props }) => {
  const { t } = useTranslation()

  const Account = ({ account }) => {
    return (
      <LinkButton
        to={window.location}
        key={account.id}
        variant={account.id === currentAccount.id ? 'primary' : 'tertiary'}
        className={mergeClassNames('text-md tracking-normal block px-4 py-1.5 h-auto', account.id === currentAccount.id && 'text-white bg-black hover:bg-black')}
        onClick={() => { setActiveAccount(account); return false }}
      >
        {account.organisation_name || t('Unnamed account')}
      </LinkButton>
    )
  }

  const AccountSwitcher = () => {
    const sortedAccounts = accounts.sort((a, b) => {
      if (!a.organisation_name) {
        return a.id.localeCompare(b.id) + 1
      }

      return a.organisation_name.localeCompare(b.organisation_name)
    })

    return (
      <>
        <Divider />
        <div className='p-2 max-h-52 overflow-y-auto flex flex-col gap-y-2'>
          {sortedAccounts.map((account, index) => <Account account={account} key={index} />)}
        </div>
        <Divider />
      </>
    )
  }

  const AccountInfo = () => {
    const { name, username } = user

    // If the user's name is their email or the local part of it, we will just
    // show the email address.
    const emailSegments = username.split('@')
    const customNameSet = name !== username && emailSegments[0] !== name

    return (
      <div className='flex flex-col gap-y-1 text-base pt-2'>
        <div className='uppercase text-label-sm text-black/60'>
          {organisationName || t('Unnamed account')}
        </div>
        {customNameSet && (
          <div>
            {name}
          </div>
        )}
        <div>
          {username}
        </div>
      </div>
    )
  }

  const hasMultipleAccounts = accounts.length > 1

  return (
    <Popover>
      <Popover.Button as='div' role='button' tabIndex={0} className='rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:rounded-full'>
        {children}
      </Popover.Button>
      <Popover.Panel>
        <PopoverContainer
          className={mergeClassNames('bg-white text-left rounded-lg', className)}
          show
          {...props}
        >
          <div className='min-w-[170px] flex flex-col'>
            <div className='px-6 py-2'>
              <AccountInfo />
            </div>
            <div className='empty:hidden'>
              {hasMultipleAccounts && <AccountSwitcher />}
            </div>
            <div className='p-2'>
              <LinkButton variant='secondary' to='/' onClick={logout} className='justify-between gap-x-2 px-4 py-1.5 text-black text-base h-11 sm:h-auto'>
                {t('Logout')}
                <Icon type='logout' />
              </LinkButton>
            </div>
            <div className='p-2 empty:hidden'>
              {showContact && (
                <div>
                  <a
                    href='mailto:hello@cobrowse.io'
                    className='flex gap-x-2 items-center text-sm'
                  >
                    <Icon type='comment-1' className='h-3' />
                    {t('Contact us')}
                  </a>
                </div>
              )}
            </div>
          </div>
        </PopoverContainer>
      </Popover.Panel>
    </Popover>
  )
}

export default AccountMenu
