import EmbedParams from '../../utils/EmbedParams'
import { URLRewriter } from './URLRewriter'
import { PROXY_PREFIX } from './proxyPrefix'

const proxyData = {
  proxyUrl: null,
  proxyToken: null
}

export function proxyUrl (base, url, useGlobalProxy) {
  if (!url) return null
  if (!EmbedParams.proxy()) return url

  // when url(...) is used within a a custom css property name
  // the some browsers (Chrome at least) sends a encoded version of
  // the string for data URLs, so we explcitly ignore these
  // TODO: work out a more reliable way to handle these, possibly
  //       will require some SDK side changes?
  // some urls are trusted and don't need to be proxied at all
  const trustedUrls = ['data\\:', 'https://fonts.googleapis.com']
  if (trustedUrls.find(u => url.startsWith(u))) return url

  const proxyUrl = new URL(useGlobalProxy ? '' : proxyData.proxyUrl, window.location.origin)
  const assetUrlRewriter = new URLRewriter(proxyUrl, PROXY_PREFIX.ASSETS, proxyData.proxyToken, base)

  try {
    return assetUrlRewriter.proxify(url)
  } catch (e) {
    console.warn(e)
    return url
  }
}

export function setProxyData (proxyUrl, proxyToken) {
  proxyData.proxyUrl = proxyUrl
  proxyData.proxyToken = proxyToken
}
