import RESTObject from '../redux-rest'

export default class SessionAction extends RESTObject {
  static namespace () {
    return 'SessionAction'
  }

  static url (params = {}) {
    return params.actions
  }
}
