import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import { isEqual } from 'lodash'
import TaggingTextEntry from '../../components/TaggingTextEntry'
import FilterParams from './FilterParams'
import { getTestId } from '../../utils/getTestId'
import Icon from '../../components/Icon'
import useDebounce from '../../hooks/useDebounce'

const FilterParamsEditor = forwardRef(({
  loading,
  autocompleteFields,
  getSuggestions,
  onUpdated,
  ...props
}, ref) => {
  const [text, setText] = useState('')
  const [tags, setTags] = useState([])
  const lastCommitedTags = useRef()
  const lastCommitedText = useRef()

  const setParams = useCallback((text, tags) => {
    lastCommitedTags.current = tags
    lastCommitedText.current = text

    const tagsAsArray = Object.keys(tags).map((key) => ({ field: key, value: tags[key] }))

    setText(text)
    setTags(tagsAsArray)
  }, [])

  useEffect(() => {
    setParams(FilterParams.search, FilterParams.params)
  }, [setParams])

  const throttledUpdate = useDebounce(() => {
    if (commitText(text)) {
      onUpdated(lastCommitedText.current, lastCommitedTags.current)
    }
  }, 300)

  const updateFilter = (text, tags) => {
    setText(text)
    setTags(tags)

    throttledUpdate()
  }

  const commitTags = (tags) => {
    const tagFilters = {}

    tags.forEach((tag) => { tagFilters[tag.field] = tag.value })

    const changed = (!isEqual(lastCommitedTags.current, tagFilters))

    if (changed) {
      FilterParams.params = tagFilters
      lastCommitedTags.current = tagFilters
    }

    return changed
  }

  const commitText = (text) => {
    text = text || undefined
    const changed = (text !== lastCommitedText.current)

    if (changed) {
      FilterParams.search = text
      lastCommitedText.current = text
    }

    return changed
  }

  const commitFilter = (text, tags) => {
    if (commitTags(tags) || commitText(text)) {
      onUpdated(text || undefined, tags)
    }
  }

  const clearFilters = useCallback(() => {
    setText(undefined)
    setTags([])

    FilterParams.params = {}
    FilterParams.search = undefined

    onUpdated(undefined, [])
  }, [onUpdated])

  const suggestionsFor = (field) => {
    if (getSuggestions) {
      return getSuggestions(field)
    }

    return []
  }

  const getAutocompleteFields = () => {
    return autocompleteFields || []
  }

  const getTags = () => {
    const _tags = tags || []

    // augment the tags with any metadata from the autocomplete fields
    return _tags.map((tag) => {
      return { ...getAutocompleteFields()[tag.field], ...tag }
    })
  }

  useImperativeHandle(ref, () => ({
    setParams,
    clearFilters
  }), [setParams, clearFilters])

  return (
    <TaggingTextEntry
      {...props}
      icon={
        <Icon
          type='search'
          className='h-6'
          {...getTestId(loading ? 'filter-icon-loading' : 'filter-icon-search')}
        />
      }
      fields={Object.values(getAutocompleteFields())}
      tags={getTags()}
      value={text || ''}
      onUpdated={updateFilter}
      onCommit={commitFilter}
      onClear={clearFilters}
      getSuggestions={suggestionsFor}
    />
  )
})

export default FilterParamsEditor
