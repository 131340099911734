import RESTObject from '../redux-rest'

export default class PaymentSource extends RESTObject {
  static namespace () {
    return 'PaymentSource'
  }

  static url () {
    return '/api/1/stripe/source'
  }
}
