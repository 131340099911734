import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Input from '../../components/Input'
import Member from './Member'
import MemberList from './MemberList'
import SettingsPage from './SettingsPage'
import Account from './Account.state'
import User from '../users/User.state'
import AccountMember from './AccountMember.state'
import MemberLicense from './MemberLicense.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import JoinToken from './JoinToken.state'
import { GenerateJoinLink } from './GenerateJoinLink'
import Alert from '../../components/Alert'
import mergeClassNames from '../../utils/mergeClassNames'

const TeamSettings = () => {
  const [filter, setFilter] = useState('')
  const dispatch = useDispatch()
  const account = useSelector(state => Account.activeAccount(state))
  const user = useSelector(state => User.fromState(state))
  const accountMembers = useSelector(state => AccountMember.fromState(state))
  const memberLicenses = useSelector(state => MemberLicense.fromState(state))
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(AccountMember.actionCreators().getAccountMembers())
    dispatch(MemberLicense.actionCreators().getMemberLicenses())
  }, [dispatch])

  const updateAccountMember = (member) => {
    return dispatch(AccountMember.actionCreators().updateAccountMember(member))
  }

  const removeAccountMember = (member) => {
    return dispatch(AccountMember.actionCreators().removeAccountMember(member))
  }

  const createJoinToken = ({ role }) => {
    return dispatch(JoinToken.actionCreators().createJoinToken({ role }))
  }

  const renderMembers = () => {
    if (!accountMembers.collection) return null
    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Members')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t('You can change permissions or remove members from your account here.')}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <div className='w-full mb-2'>
            <Input
              value={filter}
              type='text'
              placeholder={t('Filter members...')}
              onChange={e => setFilter(e.target.value)}
              icon='search'
              id='filter-members'
            />
          </div>
          <MemberList
            user={user}
            filter={filter}
            members={accountMembers}
            updateAccountMember={updateAccountMember}
            removeAccountMember={removeAccountMember}
          />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderJoinLink = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Invite team members')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              <Trans>
                You can <b>invite members</b> to your account by sharing a join link. Be careful who you share them with as anyone with the link can join your account.
              </Trans>
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <GenerateJoinLink createJoinToken={createJoinToken} />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderLicenseMember = (member) => {
    return (
      <Member member={member} key={member.email} />
    )
  }

  const renderLicenses = () => {
    if (!memberLicenses.collection) return null
    const { start, end } = account.resource.period
    const periodStart = moment(new Date(start)).format('MMMM Do')
    const periodEnd = moment(new Date(end)).format('MMMM Do')
    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Used licenses')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              <Trans>
                Here you can see how your licenses are allocated. Members listed here have used a license in the current period ({{ periodStart }} - {{ periodEnd }}).
              </Trans>
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <Alert className={mergeClassNames(memberLicenses.collection.length && 'mb-2')}>
            {t('Members that have been removed, or members granted access through an integration or JWT may appear here, but not in your account members list above!')}
          </Alert>
          {memberLicenses.collection.length
            ? memberLicenses.collection.map(renderLicenseMember)
            : <p className='p-2 text-center'>{t('No licenses have been used yet')}</p>}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  return (
    <SettingsPage feature='manage_members'>
      <SettingsPage.Title>{t('Team membership')}</SettingsPage.Title>
      <SettingsPage.Body>
        {renderJoinLink()}
        {renderMembers()}
        {renderLicenses()}
      </SettingsPage.Body>
    </SettingsPage>
  )
}

export default TeamSettings
