import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../components/Icon'
import mergeClassNames from '../utils/mergeClassNames'
import Button from './Button'

const ToolbarButtonRoot = ({ type = 'plain', to, title, selected, disabled, onClick, className, children, ...props }) => {
  const classNames = mergeClassNames(
    'rounded-lg cursor-pointer text-black/60 flex gap-x-3 p-2 sm:p-1 md:px-2 md:py-1 items-center hover:text-black md:w-auto md:rounded lg:py-1 lg:px-2',
    type === 'square' && 'text-black/60 bg-white rounded p-2 bg-transparent hover:text-black hover:bg-grey sm:p-2 md:p-3 lg:p-3',
    className,
    selected && !disabled && 'text-black',
    disabled && 'cursor-not-allowed text-black-300 hover:bg-transparent hover:text-black-300'
  )

  const activeStates = {
    'text-black underline underline-offset-[5px] relative': type === 'plain',
    'bg-grey text-black': type === 'square'
  }

  const mergedProps = {
    to,
    title,
    selected,
    disabled,
    onClick: !disabled ? onClick : () => false,
    'data-tooltip': title,
    'data-selected': selected,
    ...props
  }

  if (to) {
    return (
      <NavLink
        className={
          typeof className === 'function'
            ? ({ isActive, isPending }) => mergeClassNames(classNames, isActive && activeStates, className({ isActive, isPending }))
            : ({ isActive }) => mergeClassNames(classNames, isActive && activeStates)
        }
        {...mergedProps}
      >
        {
          typeof children === 'function'
            ? ({ isActive, isPending }) => children({ isActive, isPending })
            : children
        }
      </NavLink>
    )
  }

  return <Button variant='plain' className={classNames} {...mergedProps}>{children}</Button>
}

const Label = ({ className, children, ...props }) => <span className={mergeClassNames('text-base hidden lg:flex lg:gap-x-2 lg:items-center', className)} {...props}>{children}</span>

export const ToolbarButton = Object.assign(ToolbarButtonRoot, { Icon, Label })
