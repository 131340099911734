import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import Icon from './Icon'
import { useTranslation } from 'react-i18next'

const PopoutButton = ({ className, ...props }) => {
  const { t } = useTranslation()

  if (window === window.top) return null

  return (
    <a
      title='Popout'
      {...props}
      className={mergeClassNames('fixed top-2 end-2 opacity-50 transition-opacity duration-200 z-10 hover:opacity-100', className)}
      target='_blank'
      rel='noreferrer'
      href={window.location}
    >
      <span className='sr-only'>{t('Open in a new tab')}</span>
      <Icon type='resize-full' />
    </a>
  )
}

export default PopoutButton
