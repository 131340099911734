import React from 'react'
import Icon from './Icon'
import BrowserIcon from './BrowserIcon'
import mergeClassNames from '../utils/mergeClassNames'

const platformIconMapping = {
  ios: 'apple',
  macos: 'macos',
  windows: 'windows',
  android: 'android',
  web: 'globe'
}

const PlatformIcon = ({ device, isOnline, className, wrapperClassName, ...props }) => {
  const { platform } = device
  const isWeb = platform === 'web'

  return (
    <div className={mergeClassNames(wrapperClassName || 'contents', isWeb && 'p-1 h-9 w-9 flex items-center justify-center')}>
      {isWeb
        ? (
          <BrowserIcon
            {...props}
            userAgent={device.device}
            className={mergeClassNames(!isOnline && 'grayscale', className)}
          />
          )
        : (
          <Icon
            {...props}
            type={platformIconMapping[platform] ?? 'globe'}
            className={className}
          />
          )}
    </div>
  )
}

export default PlatformIcon
