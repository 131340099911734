import 'sanitize.css/sanitize.css'
import 'flexboxgrid/css/flexboxgrid.css'
import './index.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import qs from 'qs'
import env from './utils/env'
import User from './modules/users/User.state'
import Account from './modules/accounts/Account.state'
import App from './App'
import RESTObject from './modules/redux-rest'
import ServerTime from './utils/ServerTime'
import store from './utils/store'
import { updateUiState } from './modules/ui-state/UI.state'
import i18n from './i18n'
import './modules/sentry/init'

const query = qs.parse(window.location.search.substring(1))

if (query.token) RESTObject.setHeader('Authorization', `Bearer ${query.token}`)
RESTObject.setHeader('X-CobrowsePlatform', 'agent-web')
RESTObject.setHeader('X-CobrowseSDKVersion', env.REACT_APP_VERSION)

// Replace the fetch call that the REST classes use.
// We can hook into the Date header to detect
// inaccurate clocks in the client and adjust for them
RESTObject.fetcher = async (...args) => {
  const start = Date.now()
  const res = await window.fetch(...args)
  const rtt = (Date.now() - start) / 2
  ServerTime.update(res.headers.get('Date'), rtt)
  return res
}

// pull some config from ENV
store.dispatch(updateUiState({ user_info: !query.token }))

if (query.forceAccount) {
  store.dispatch(Account.setActiveAccount({ license_key: query.forceAccount }))
}

Promise.all([
  store.dispatch(User.get({ id: 'me' })),
  store.dispatch(Account.get())
]).then(() => {
  const account = Account.activeAccount(store.getState()).resource
  if (account) {
    store.dispatch(Account.setActiveAccount(account))
  }
  store.dispatch(updateUiState({ context_loaded: true }))
})

// Set the document direction based on the current language
i18n.on('languageChanged', () => {
  document.body.dir = i18n.dir()
  document.documentElement.lang = i18n.language
})

const container = document.querySelector('#root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
