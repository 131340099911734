import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import flatpickr from 'flatpickr'

const englishUS = 'en-us'
const spanish = 'es'
const french = 'fr'
const german = 'de'
const SUPPORTED_LOCALES = [englishUS, spanish, french, german]

const localeIsNotSupported = (locale) => SUPPORTED_LOCALES.indexOf(locale) === -1 || locale === englishUS
const localizeMoment = async (locale) => {
  await import(`moment/locale/${locale}.js`)

  moment.locale(locale)
}
const localizeDatePickers = async (locale) => {
  const datePickerLocale = await import(`flatpickr/dist/l10n/${locale}.js`)

  flatpickr.localize(datePickerLocale.default[locale])
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: englishUS,
    supportedLngs: SUPPORTED_LOCALES,
    debug: process.env.NODE_ENV === 'development',
    load: 'currentOnly',
    lowerCaseLng: true,
    maxRetries: 3,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true
    },
    detection: {
      caches: [],
      order: ['querystring', 'navigator', 'htmlTag', 'path', 'subdomain']
    }
  }).then(async () => {
    const locale = i18n.language.toLowerCase()

    // Return early when the locale is not supported or when it's US English (it comes built in)
    if (localeIsNotSupported(locale)) {
      return
    }

    await localizeMoment(locale)
    await localizeDatePickers(locale)
  })

export default i18n
