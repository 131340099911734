import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import NumberInput from '../../components/NumberInput'
import { SettingsGroup } from '../../components/SettingsGroup'
import Alert from '../../components/Alert'

const Subscription = ({ subscription, working, onQuantityChanged }) => {
  const [quantity, setQuantity] = useState(null)
  const { t } = useTranslation()

  const handleQuantityChange = (quantity, item) => {
    if (quantity !== item.quantity) {
      setQuantity(quantity)
    } else {
      setQuantity(null)
    }
  }

  const saveQuantity = () => {
    onQuantityChanged && onQuantityChanged(quantity)
    setQuantity(null)
  }

  const renderOverdueWarning = () => {
    if ((subscription.status === 'past_due') || (subscription.status === 'unpaid')) {
      return (
        <Alert type='warning'>
          {t('Your subscription is overdue. Payment was due on {{due_date}}.', { due_date: moment(subscription.current_period_end * 1000).format('MMMM Do') })}
        </Alert>
      )
    }

    return null
  }

  const renderSubscriptionFooter = () => {
    return (
      <Alert>
        {subscription.cancel_at_period_end
          ? <span><Trans>Your plan will cancel on <span>{{ end_date: moment(subscription.current_period_end * 1000).format('MMMM Do') }}</span>. It will not be automatically renewed.</Trans></span>
          : <span><Trans>Your next bill will be on <span>{{ end_date: moment(subscription.current_period_end * 1000).format('MMMM Do') }}</span> for an estimated <span>${{ estimate: (subscription.estimate / 100).toFixed(2) }}</span></Trans></span>}
      </Alert>
    )
  }

  const renderSubscriptionItem = (item) => {
    return (
      <div className='flex gap-4 items-center w-full' key={item.id}>
        <div>
          {working
            ? <span>{t('Loading...')}</span>
            : <NumberInput
                value={quantity || item.quantity}
                onChange={quantity => handleQuantityChange(quantity, item)}
                label={t('Quantity')}
              />}
        </div>
        {item.amount && (
          <div>
            {subscription.product && <span>{subscription.product.name}</span>}
            <span>{item.tiered ? t('from') + ' ' : ''} ${(item.amount / 100).toFixed(2)}</span>
            <span> / {t(item.interval)}</span>
          </div>
        )}
      </div>
    )
  }

  const renderSubscriptionItems = () => {
    if (!subscription.items) {
      return null
    }

    return (
      <div className='flex'>
        {subscription.items?.map(renderSubscriptionItem)}
      </div>
    )
  }

  return (
    <SettingsGroup>
      <SettingsGroup.Header>
        <SettingsGroup.HeaderTitle>
          <SettingsGroup.Title>{t('Subscription details')}</SettingsGroup.Title>
          <SettingsGroup.HelpText>{t('Your subscription to Cobrowse')}</SettingsGroup.HelpText>
        </SettingsGroup.HeaderTitle>
        <SettingsGroup.Button disabled={!quantity} onClick={saveQuantity} thinking={working}>{t('Update')}</SettingsGroup.Button>
      </SettingsGroup.Header>
      <SettingsGroup.Body>
        <div className='flex flex-col gap-y-4'>
          {renderOverdueWarning()}
          {renderSubscriptionItems()}
          {renderSubscriptionFooter()}
        </div>
      </SettingsGroup.Body>
    </SettingsGroup>
  )
}

export default Subscription
