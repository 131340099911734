import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DateRangePicker from '../../components/DateRangePicker'
import Button from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'

const SessionListDownloadFilters = ({ onDownload, thinking, error }) => {
  const presetDays = [30, 60, 90]
  const { t } = useTranslation()
  const [selectedDates, setSelectedDates] = useState([moment().subtract(presetDays[0], 'days').toDate(), moment().toDate()])
  const handleOnDownload = () => onDownload(selectedDates)
  const updateDateRange = (days) => setSelectedDates([moment().subtract(days, 'days').toDate(), moment().toDate()])

  return (
    <>
      <div className='flex flex-col gap-y-4 mb-4'>
        <div>
          <DateRangePicker inline onChange={setSelectedDates} selectedDates={selectedDates} />
        </div>
        <div className='flex gap-x-2'>
          {presetDays.map((days) => (
            <Button
              variant='secondary'
              className='flex-grow text-sm bg-black-200 shadow-none'
              onClick={() => updateDateRange(days)}
              key={days}
            >
              {t('{{days}} days', { days })}
            </Button>
          ))}
        </div>
      </div>
      <div className='flex gap-x-2 items-center'>
        {error?.show && (
          <div className={mergeClassNames('text-sm', error.severity === 'warning' && 'text-black', error.severity === 'error' && 'text-red')}>
            {error.message}
          </div>
        )}
        <Button
          thinking={thinking}
          onClick={handleOnDownload}
          className='w-full'
        >
          {t('Download')}
        </Button>
      </div>
    </>
  )
}

export default SessionListDownloadFilters
