import React from 'react'
import Icon from './Icon'
import { useTranslation } from 'react-i18next'

const URLView = ({ url }) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col px-4 text-xs gap-y-1 text-slate-900 max-w-fit'>
      <div className='text-sm font-medium tracking-[1px]'>{t('Browser URL')}</div>
      <div className='flex items-center gap-x-2'>
        <Icon type='globe' className='w-3 h-3 text-violet-500' />
        <div className='overflow-hidden'>
          <span>{url}</span>
        </div>
      </div>
    </div>
  )
}

export default URLView
