import React from 'react'
import LinkButton from '../../components/LinkButton'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import Icon from '../../components/Icon'
import Button from '../../components/Button'

const ViewRecordingButton = ({ session, disabled = false, className, ...props }) => {
  const { t } = useTranslation()
  const buttonProps = {
    variant: 'secondary',
    className: mergeClassNames('w-full flex gap-x-2 text-center h-11 sm:h-9', className),
    ...props
  }

  if (disabled) {
    return (
      <Button
        {...buttonProps}
        {...props}
        disabled
      >
        <Icon type='player-play' />
        {t('Expired')}
      </Button>
    )
  }

  return (
    <LinkButton
      target='_blank'
      to={`/session/${session.id}/recording${window.location.search}`}
      {...getTestId('session-recording-button')}
      variant='secondary'
      className={mergeClassNames('w-full flex gap-x-2 text-center h-11 sm:h-9', className)}
      {...props}
    >
      <Icon type='player-play' />
      {t('Recording')}
    </LinkButton>
  )
}

export default ViewRecordingButton
