export const UPDATE_MOUSE = 'mouse/UPDATE'

const initialState = {
  resource: false
}

export default function mouse (state = initialState, action) {
  switch (action.type) {
    case UPDATE_MOUSE:
      return { ...state, resource: action.resource }

    default:
      return state
  }
}

export const updateMouse = (resource) => {
  return {
    type: UPDATE_MOUSE,
    resource
  }
}
