import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Link from '../../components/Link'
import Button from '../../components/Button'
import Account from '../accounts/Account.state'

const MessageWrapper = ({ children }) => {
  return <p className='text-title-lg mb-4 font-bold'>{children}</p>
}

const ErrorMessage = ({ error, children }) => {
  const account = useSelector(state => Account.activeAccount(state))
  const { t } = useTranslation()

  const retry = () => {
    window.location.reload()
  }

  const renderLoginRequired = () => {
    return (
      <div>
        <MessageWrapper>{error.message}</MessageWrapper>
        <p>{t('Your login session may have expired, please login and try again.')}</p>
      </div>
    )
  }

  const renderOverConcurrentSessionsError = () => {
    return (
      <div>
        <MessageWrapper>{error.message}</MessageWrapper>
        <p className='mb-4'>{t('This means other users in your account have active sessions so you may not start a new one. End one of the other sessions before starting a new session.')}</p>
        <div className='flex flex-col gap-y-4 items-center'>
          <Button onClick={retry}>{t('Retry')}</Button>
          <span><Link to='/dashboard/history'>{t('Manage all sessions')}</Link></span>
        </div>
      </div>
    )
  }

  const renderOverPeriodSessionsError = () => {
    const canManageBilling = Account.hasFeature(account.resource, 'manage_billing', false)
    return (
      <div>
        <MessageWrapper>{children}</MessageWrapper>
        {canManageBilling
          ? <p className='mb-4'>{t('Your account has reached its license limit for this billing period.')}</p>
          : <p className='mb-4'>{t('Your account has reached its license limit for this billing period.')} {t('Please contact your account administrator to manage your account licenses.')}</p>}
        <div className='flex flex-col gap-y-4 items-center'>
          <Button onClick={retry}>{t('Retry')}</Button>
          {canManageBilling ? <Link to='/dashboard/settings/billing'>{t('Manage billing')}</Link> : null}
        </div>
      </div>
    )
  }

  const renderError = () => {
    if (error?.id === 'session_concurrency_limit') {
      return renderOverConcurrentSessionsError()
    }

    if (error?.id === 'session_usage_limit') {
      return renderOverPeriodSessionsError()
    }

    if (error?.id === 'auth_login_required') {
      return renderLoginRequired()
    }

    const inIframe = (window.self !== window.top)

    return (
      <div>
        <MessageWrapper>{children}</MessageWrapper>
        {error ? <p className='mb-4'>{error.message}</p> : null}
        {inIframe ? null : <a href='/dashboard' className='text-black'>{t('Back to safety')}</a>}
      </div>
    )
  }

  return (
    <div className='container h-full px-5 max-w-4xl text-center flex items-center justify-center md:mx-auto'>
      {renderError()}
    </div>
  )
}

export default ErrorMessage
