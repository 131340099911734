import React from 'react'
import DOMNode from './DOMNode'
import { rewriteURLs, rewriteSelectors } from '../css'

export default class DOMStyle extends DOMNode {
  static nodeFilter (node) {
    return node
  }

  onDOMStyleRef = (el) => {
    this.el = el
  }

  proxyUrl = (url) => this.props.proxy(this.props.base, url)

  rewriteCSS = (css) => {
    if (!css) return null

    const cssWithReplacedUrls = rewriteURLs(css, this.proxyUrl)

    return rewriteSelectors(cssWithReplacedUrls)
  }

  render () {
    const { node } = this.props
    return (
      <style ref={this.onDOMStyleRef}>{this.rewriteCSS(node.css)}</style>
    )
  }
}
