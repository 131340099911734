import React, { useEffect, useState } from 'react'
import DOMScreen from './DOMScreen'
import ImageScreen from './ImageScreen'
import VideoScreen from './VideoScreenJMuxer'
import Icon from '../../components/Icon'
import mergeClassNames from '../../utils/mergeClassNames'
import { getTestId } from '../../utils/getTestId'

const RemoteScreen = (props) => {
  const [animationCount, setAnimationCount] = useState(0)

  useEffect(() => {
    if (props.mouse?.state === 'mousedown') {
      setAnimationCount((prev) => prev + 1)
    }
  }, [props.mouse?.state])

  const renderDisplay = (display) => {
    const mimeType = display && display.mime_type
    if (!mimeType) {
      return null
    } else if (mimeType.startsWith('image/')) {
      return <ImageScreen {...props} />
    } else if (mimeType.startsWith('video/')) {
      return <VideoScreen {...props} />
    } else if (mimeType === 'application/x-cbio-vdom-patch') {
      return <DOMScreen {...props} />
    } else {
      return null
    }
  }

  const renderMouse = () => {
    const { mouse } = props

    if (!mouse) {
      return null
    }

    const style = {
      top: mouse.y * props.height * props.scale,
      left: mouse.x * props.width * props.scale
    }

    const mouseStateClassNames = {
      mouseout: 'hidden'
    }

    return (
      <div
        className={mergeClassNames('text-[#3c4044] absolute -translate-x-1/2 -translate-y-1/2 pointer-events-none', mouseStateClassNames[mouse.state])}
        style={style}
        {...getTestId('remote-screen-mouse')}
      >
        <div key={animationCount} className={mergeClassNames('h-0 w-0 bg-[#3c4044] rounded-full absolute -top-1 -left-1 -translate-x-1/2 -translate-y-1/2', animationCount > 0 && 'animate-mouseclick')} />
        <Icon type='mouse-pointer' className='absolute z-50 w-6 h-6 -left-1' />
      </div>
    )
  }

  return (
    <div className='relative' style={{ height: props.height * props.scale, width: props.width * props.scale }}>
      {renderDisplay(props.display)}
      {renderMouse()}
    </div>
  )
}

export default RemoteScreen
