import { useCallback, useState } from 'react'

export default function useElementPosition () {
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const [width, setWidth] = useState(0)

  const updatePosition = useCallback((node) => {
    const rect = node.getBoundingClientRect()
    const computedStyle = global.window.getComputedStyle(node)
    const marginBottom = parseInt(computedStyle.marginBottom, 10) || 0
    const marginLeft = parseInt(computedStyle.marginLeft, 10) || 0
    const marginRight = parseInt(computedStyle.marginRight, 10) || 0

    setTop(rect.bottom + marginBottom)
    setLeft(rect.left + marginLeft)
    setWidth(rect.width + marginLeft + marginRight)
  }, [])

  return {
    top,
    left,
    width,
    updatePosition
  }
}
