import React, { useRef } from 'react'
import TextEntryTag from './TextEntryTag'
import TaggingTextEntryInput from './TaggingTextEntryInput'
import { getTestId } from '../utils/getTestId'
import Icon from './Icon'

const TaggingTextEntry = ({ value, icon, fields, placeholder, tags, getSuggestions, onUpdated, onCommit, onClear }) => {
  const _tags = useRef({})
  const input = useRef(null)

  const extractTags = () => {
    let searchString = value
    const matches = searchString.match(/(\S)+:(\S)+/g) || []
    let tags = []
    matches.forEach(tag => {
      const [field, value, more] = tag.split(':')
      if (more) return
      searchString = searchString.replace(tag, '').trim()
      tags = [...tags, { field, value }]
    })
    return { value: searchString, tags }
  }

  const handleOnChange = (value) => {
    onUpdated(value, tags)
  }

  const handleOnClear = () => {
    input.current.setInputValue('')
    onClear()
  }

  const handleOnSelect = (item) => {
    addTag({ ...item, value: '' })
  }

  const handleKeyDown = (e) => {
    if (
      (e.key === 'Backspace' || e.key === 'ArrowLeft') &&
      !e.repeat &&
      e.target.selectionStart === 0
    ) {
      const tag = tags[tags.length - 1]

      if (tag) {
        const tagEl = _tags.current[tag.id]
        if (tagEl) setTimeout(() => tagEl.querySelector('input')?.focus(), 0)
      }
    } else if (e.key === 'Enter') {
      const result = extractTags()

      onUpdated(result.value, [...tags, ...result.tags])
      onCommit(result.value, [...tags, ...result.tags])

      if (!result.tags.length) {
        setTimeout(() => input.current.blur(), 0)
      } else {
        input.current.setInputValue(result.value)
      }
    }
  }

  const updateTagValue = (tag, val) => {
    const existing = tags.find((t) => t.id === tag.id)
    const index = tags.indexOf(existing)
    const copy = [...tags]

    copy[index] = { ...existing, value: val }

    onUpdated(value, copy)
  }

  const addTag = (tag = {}) => {
    onUpdated(value, [
      ...(tags || []),
      { ...tag }
    ])
  }

  const removeTag = (tag, options = {}) => {
    const removed = tags.filter((t) => t !== tag)

    if (options.refocus) {
      input.current.focus()
    }

    onUpdated(value, removed)
    onCommit(value, removed)
  }

  const commitTag = (tag, options = {}) => {
    if (options.refocus) {
      input.current.focus()
    }

    onCommit(value, tags)
  }

  const renderTag = (tag) => {
    if (!tag.id) {
      tag.id = TaggingTextEntry.nextTagId()
    }

    return (
      <TextEntryTag
        ref={(el) => {
          _tags.current[tag.id] = el
        }}
        key={tag.id}
        tag={tag}
        getSuggestions={getSuggestions}
        onValueChange={updateTagValue}
        onRemove={removeTag}
        onCommit={commitTag}
      />
    )
  }

  const renderTags = () => {
    return tags.map(renderTag)
  }

  const isFilterBoxEmpty = tags.length === 0 && value === ''

  return (
    <div className='h-full bg-white rounded-lg relative group border-2 retina:border-1.5 border-white focus-within:border-2 focus-within:retina:border-1.5 focus-within:!border-black hover:border-2 hover:retina:border-1.5 hover:border-black/50'>
      <div className='h-full flex items-center relative pe-2 ps-2 md:ps-6'>
        <div className='flex items-center w-full scrollbar-hidden overflow-x-auto'>
          <div className='min-w-max'>
            {icon}
          </div>
          {renderTags()}
          <TaggingTextEntryInput
            items={fields ?? []}
            inputRef={input}
            onSelect={handleOnSelect}
            inputOnKeyDown={handleKeyDown}
            placeholder={placeholder}
            handleOnChange={handleOnChange}
            value={value || ''}
          />
        </div>
        {!isFilterBoxEmpty && (
          <div className='absolute end-0 pe-2 top-0 bottom-0 rounded-e-lg flex items-center opacity-0 text-black/50 hover:text-black group-hover:opacity-100 cursor-pointer before:bg-gradient-to-l before:absolute before:end-0 before:from-white before:w-12 before:h-full before:rounded-e-lg'>
            <Icon className='z-10' type='close' onClick={handleOnClear} {...getTestId('clear-filters')} />
          </div>
        )}
      </div>
    </div>
  )
}

TaggingTextEntry.nextTagId = () => {
  if (!TaggingTextEntry._tag_id) {
    TaggingTextEntry._tag_id = 0
  }

  TaggingTextEntry._tag_id += 1

  return TaggingTextEntry._tag_id
}

export default TaggingTextEntry
