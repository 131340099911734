const platformNameMapping = {
  ios: 'iOS',
  macos: 'macOS',
  windows: 'Windows',
  android: 'Android',
  web: 'Web'
}

export default function (platform) {
  return platformNameMapping[platform]
}
