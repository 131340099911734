import RESTObject from '../redux-rest'
import { connect, disconnect, makeUrl } from '../websockets/Sockets.state'

export default class User extends RESTObject {
  static namespace () {
    return 'User'
  }

  static url (params = {}) {
    return `/api/1/users/${params.id || ''}`
  }

  static socketUrl (resource) {
    if (!resource) return null
    const token = resource.notification_token
    const url = resource.notification_url
    if ((!token) || (!url)) return null
    return makeUrl(url, token)
  }

  static getSocket () {
    return (dispatch, getState) => {
      return dispatch(connect('user-socket', {
        getUrl: async () => this.socketUrl(this.fromState(getState()).resource),
        configureSocket: socket => {
          socket.on('error', async () => {
            if (navigator.onLine !== false) {
              const res = await dispatch(User.get({ id: 'me' }))
              if (res?.statusCode >= 400 && res?.statusCode < 500) {
                console.log('Disconnecting socket due to user refresh failure')
                socket.close()
              }
            }
          })
        }
      }))
    }
  }

  static logout () {
    return this.destroy({ id: 'me' })
  }

  static actionCreators () {
    return {
      ...super.actionCreators(),
      connectUserSocket: this.getSocket.bind(this),
      closeUserSocket: () => disconnect('user-socket'),
      logout: () => this.destroy({ id: 'me' })
    }
  }
}
