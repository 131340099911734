import React from 'react'
import DeviceModule from './Device.state'
import Icon from '../../components/Icon'
import mergeClassNames from '../../utils/mergeClassNames'
import PlatformIcon from '../../components/PlatformIcon'
import { getTestId } from '../../utils/getTestId'
import Timestamp from '../../components/Timestamp'
import { useTranslation } from 'react-i18next'

const DeviceStatus = ({ device, isLoading, isOnline, hideRealtimeInfo = false, identifierClassName }) => {
  const { t } = useTranslation()

  const meetsVersion = (versions) => {
    return DeviceModule.meetsVersion(device.device, versions)
  }

  const customData = () => {
    return device.custom_data || {}
  }

  const deviceIdentifier = () => {
    return customData().device_name || userIdentifier()
  }

  const userIdentifier = () => {
    return customData().user_email || customData().user_id || `${t('Unnamed device')} ${device.id?.substring(0, 6) ?? ''}`
  }

  const sdkVersion = () => {
    return device.device?.sdk_version
  }

  const renderLastSeen = () => {
    return (
      <div className='flex leading-4 tracking-wider gap-x-1 text-black/60 text-md'>
        {renderSDKStatus()}
        {isOnline && (
          <div className='flex items-center gap-x-1'>
            <div
              className='w-2 h-2 rounded-full bg-green-v2-500'
              title={t('New devices found.')}
            />
            {t('Online')}
          </div>
        )}
        {isLoading && <><Icon type='spin2' className='h-2.5 w-2.5 me-2' spin />{t('Loading')}</>}
        {!isOnline && !isLoading && <><span>{t('Last seen')} </span><Timestamp timestamp={device.last_seen} /></>}
      </div>
    )
  }

  const renderSDKStatus = () => {
    let title = t('Deprecated: The SDK is very old, support will be removed very soon.')
    let message = t('Deprecated: SDK {{version}}', { version: sdkVersion() })

    try {
      if (meetsVersion({ ios: '2.0.0', android: '2.0.0', web: '2.0.0' })) return false
    } catch (e) {
      title = t('The SDK version is invalid.')
      message = t('Invalid SDK version')
    }

    return (
      <span className='me-2 bg-red text-white inline-block py-0.5 px-1 text-[10px] rounded whitespace-nowrap align-middle' title={title}>{message}</span>
    )
  }

  const renderPlatformIcon = () => {
    const { device: deviceInfo } = device

    return (
      <PlatformIcon
        className='w-5 h-5'
        device={deviceInfo}
        isOnline={isOnline}
        wrapperClassName={mergeClassNames('relative bg-grey rounded-full p-2 outline outline-2 outline-black/25', isOnline && 'outline-green-500')}
      />
    )
  }

  return (
    <>
      <div className='flex items-center'>
        <div className='me-2 lg:me-4'>
          {renderPlatformIcon()}
        </div>
      </div>
      <div className={mergeClassNames('flex-1 -mt-0.5 text-base', !hideRealtimeInfo && 'md:h-9', identifierClassName)}>
        <div
          className='overflow-hidden truncate whitespace-nowrap'
          {...getTestId('device-identifier')}
        >
          {deviceIdentifier()}
        </div>
        {!hideRealtimeInfo && renderLastSeen()}
      </div>
    </>
  )
}

export default DeviceStatus
