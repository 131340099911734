class ServerTime {
  #offset = null

  update (serverTime, rtt = 0) {
    if (serverTime) {
      const latestServerTime = new Date(serverTime)
      // adjust local time to try an account for round trip time
      // to the server. This isn't going to be very accurate, but
      // might as well take a guess
      const latestLocalTime = new Date(Date.now() - rtt)

      // the server time is only accurate to 1 second, so we need to average that out
      // by adding 500 ms to the offset
      const offset = (latestServerTime.getTime() - latestLocalTime.getTime()) + 500

      // if this is the first update, just adopt the offset directly
      if (this.#offset === null) this.#offset = offset
      // otherwise merge it in via a moving average so we don't
      // jump about too much caused by network latency or the
      // limited precision of the Date header
      else this.#offset = (0.8 * this.#offset) + (0.2 * offset)
    }
  }

  offset () {
    if (this.#offset === null) return 0
    else return this.#offset
  }

  now () {
    return Date.now() + this.offset()
  }
}

export default new ServerTime()
