import { useEffect } from 'react'

const usePageTitle = (title, method = 'prepend') => {
  useEffect(() => {
    if (!title) {
      return
    }

    const originalTitle = document.title
    const newTitle = [title, originalTitle]

    if (method !== 'prepend') {
      newTitle.reverse()
    }

    document.title = newTitle.join(' | ')

    return () => {
      document.title = originalTitle
    }
  }, [title, method])
}

export default usePageTitle
