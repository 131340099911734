import React, { useState } from 'react'
import { useCombobox } from 'downshift'
import useElementPosition from '../hooks/useElementPosition'
import Input from './Input'
import { getTestId } from '../utils/getTestId'
import PopoverContainer from './PopoverContainer'
import mergeClassNames from '../utils/mergeClassNames'

const baseTestId = 'text-entry-tag'

function getFilteredItems (inputValue) {
  return function itemsFilter (item) {
    return !inputValue || item.toLowerCase().includes(inputValue.toLowerCase())
  }
}

function isVisible (isOpen, value, items) {
  return isOpen && value?.length >= 2 && items?.length > 0
}

export default function TextEntryTagInput ({
  items,
  tag,
  onValueChange,
  inputRef,
  inputOnKeyDown,
  onBlur: parentOnBlur
}) {
  const [inputItems, setInputItems] = useState(items)
  const { updatePosition } = useElementPosition()

  const {
    isOpen,
    inputValue,
    getMenuProps,
    openMenu,
    closeMenu,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    setHighlightedIndex,
    setInputValue
  } = useCombobox({
    initialInputValue: tag.value,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(items.filter(getFilteredItems(inputValue)))
      onValueChange(tag, inputValue)
    },
    onSelectedItemChange: (changes) => {
      onValueChange(tag, changes.selectedItem)
    }
  })

  const { onKeyDown: baseOnKeyDown, onBlur: baseOnBlur, ...inputProps } = getInputProps({
    ref: (node) => {
      if (node && node !== inputRef.current) {
        inputRef.current = node
        updatePosition(inputRef.current)
      }
    }
  })

  const onKeyDown = (ev) => {
    if (ev.key === 'Tab' && inputItems?.[highlightedIndex]) {
      setInputValue(inputItems[highlightedIndex])
    }

    inputOnKeyDown(ev)
    baseOnKeyDown(ev)
  }

  const onBlur = (ev) => {
    parentOnBlur(ev)
    closeMenu()
  }

  return (
    <div className='px-1 text-black'>
      <div {...getComboboxProps()}>
        <Input
          variant='plain'
          className='bg-transparent outline-none w-full cursor-text max-w-xs'
          type='text'
          testId={`${baseTestId}-input`}
          {...{ onKeyDown }}
          {...inputProps}
          {...{ onBlur }}
          onFocus={openMenu}
        />
      </div>

      <PopoverContainer
        className='top-12 bg-white rounded-lg'
        arrowPosition='left'
        show={isVisible(isOpen, inputValue, inputItems)}
        {...getMenuProps()}
        {...getTestId(`${baseTestId}-menu`)}
      >
        <div className='p-2 min-w-[200px]'>
          {inputItems.map((item, index) => (
            <div
              key={item}
              className={mergeClassNames('p-2 bg-white hover:bg-grey rounded-md', {
                'bg-grey': highlightedIndex === index
              })}
              onMouseEnter={() => setHighlightedIndex(index)}
              onMouseLeave={() => setHighlightedIndex(-1)}
              onClick={() => {
                setInputValue(item)
                onBlur()
                closeMenu()
              }}
              {...getTestId(`${baseTestId}-menu-item`)}
            >
              {item}
            </div>
          ))}
        </div>
      </PopoverContainer>
    </div>
  )
}
