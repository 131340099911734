import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import TextArea from '../../components/TextArea'
import SettingsPage from '../accounts/SettingsPage'
import RedactionConfig from './RedactionConfig.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import Alert from '../../components/Alert'

const RedactionSettings = () => {
  const [platformRedactionSettings, setPlatformRedactionSettings] = useState({})
  const dispatch = useDispatch()
  const redactionConfig = useSelector(state => RedactionConfig.fromState(state))
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(RedactionConfig.actionCreators().getRedactionConfig())
  }, [dispatch])

  const configFor = (type, platform) => {
    return platformRedactionSettings?.[type]?.[platform]?.split('\n') ||
      redactionConfig.resource?.[type]?.[platform] || []
  }

  const updateRedactionConfig = (platform) => {
    return dispatch(RedactionConfig.actionCreators().updateRedactionConfig({
      redaction: { [platform]: configFor('redaction', platform) },
      unredaction: { [platform]: configFor('unredaction', platform) }
    }))
  }

  const handleRedactionConfigChange = (e, type, platform) => {
    setPlatformRedactionSettings({
      ...platformRedactionSettings,
      [type]: {
        ...platformRedactionSettings[type],
        [platform]: e.target.value
      }
    })
  }

  const renderTextArea = (id, label, placeholder, type, platform) => {
    return (
      <>
        <label htmlFor={id} className='sr-only'>{label}</label>
        <TextArea
          className='font-mono h-32'
          type='text'
          id={id}
          placeholder={placeholder}
          value={configFor(type, platform).join('\n')}
          onChange={(e) => handleRedactionConfigChange(e, type, platform)}
        />
      </>
    )
  }

  const renderRedactionConfig = () => {
    if (!redactionConfig.resource) {
      return null
    }

    return (
      <>
        <Alert className='bg-white'>
          <Trans>
            We recommend using the SDK API for redaction. See the <a target='_blank' className='text-black underline' rel='noopener noreferrer' href='https://docs.cobrowse.io/sdk-features/redact-sensitive-data'>SDK docs</a>.
          </Trans>
        </Alert>
        <SettingsGroup>
          <SettingsGroup.Header>
            <SettingsGroup.HeaderTitle>
              <SettingsGroup.Title icon='globe'>{t('Web redaction configuration')}</SettingsGroup.Title>
              <SettingsGroup.HelpText>
                {t('Enter views to redact on web pages, one per line.')}
              </SettingsGroup.HelpText>
            </SettingsGroup.HeaderTitle>
            <SettingsGroup.Button thinking={redactionConfig.working} onClick={() => updateRedactionConfig('web')}>{t('Save')}</SettingsGroup.Button>
          </SettingsGroup.Header>
          <SettingsGroup.Body>
            <p>{t('Redaction')}</p>
            {renderTextArea(
              'web_redaction_selectors',
              t('Web redaction selectors'),
              '.redacted',
              'redaction',
              'web'
            )}
            <Alert className='mt-5 mb-2' type='warning'>Unredaction dashboard selectors are a beta feature. <b>Requires Web SDK version 2.40.0 or above.</b></Alert>
            <p>{t('Unredaction')}</p>
            {renderTextArea(
              'web_unredaction_selectors',
              t('Web unredaction selectors'),
              '.unredacted',
              'unredaction',
              'web'
            )}
          </SettingsGroup.Body>
        </SettingsGroup>
        <SettingsGroup>
          <SettingsGroup.Header>
            <SettingsGroup.HeaderTitle>
              <SettingsGroup.Title icon='android'>{t('Android redaction configuration')}</SettingsGroup.Title>
              <SettingsGroup.HelpText>
                {t('Enter views to redact on Android devices, one per line.')}
              </SettingsGroup.HelpText>
            </SettingsGroup.HeaderTitle>
            <SettingsGroup.Button thinking={redactionConfig.working} onClick={() => updateRedactionConfig('android')}>{t('Save')}</SettingsGroup.Button>
          </SettingsGroup.Header>
          <SettingsGroup.Body>
            <p>{t('Redaction')}</p>
            {renderTextArea(
              'android_redaction_selectors',
              t('Android redaction configuration'),
              'TextInput#mySecretTextField',
              'redaction',
              'android'
            )}
            <Alert className='mt-5 mb-2' type='warning'>Unredaction dashboard selectors are a beta feature. <b>Requires Android SDK version 2.35.0 or above.</b> Unredaction selectors are not yet supported for Jetpack Compose.</Alert>
            <p>{t('Unredaction')}</p>
            {renderTextArea(
              'android_unredaction_selectors',
              t('Android unredaction configuration'),
              'TextInput#mySecretTextField',
              'unredaction',
              'android'
            )}
          </SettingsGroup.Body>
        </SettingsGroup>
        <SettingsGroup>
          <SettingsGroup.Header>
            <SettingsGroup.HeaderTitle>
              <SettingsGroup.Title icon='apple'>{t('iOS redaction configuration')}</SettingsGroup.Title>
              <SettingsGroup.HelpText>
                {t('Enter views to redact on iOS devices, one per line.')}
              </SettingsGroup.HelpText>
            </SettingsGroup.HeaderTitle>
            <SettingsGroup.Button thinking={redactionConfig.working} onClick={() => updateRedactionConfig('ios')}>{t('Save')}</SettingsGroup.Button>
          </SettingsGroup.Header>
          <SettingsGroup.Body>
            <p>{t('Redaction')}</p>
            {renderTextArea(
              'ios_redaction_selectors',
              t('iOS redaction configuration'),
              'UILabel#12456',
              'redaction',
              'ios'
            )}
            <Alert className='mt-5 mb-2' type='warning'>Unredaction dashboard selectors are a beta feature. <b>Requires iOS SDK version 2.32.0 or above.</b> Unredaction selectors are not yet supported for SwiftUI.</Alert>
            <p>{t('Unredaction')}</p>
            {renderTextArea(
              'ios_unredaction_selectors',
              t('iOS unredaction configuration'),
              'UILabel#12456',
              'unredaction',
              'ios'
            )}
          </SettingsGroup.Body>
        </SettingsGroup>
      </>
    )
  }

  return (
    <SettingsPage feature='manage_redaction'>
      <div>
        <SettingsPage.Title>{t('Redaction')}</SettingsPage.Title>
        <SettingsPage.HelpText className='text-black/75'>
          {t('Redaction allows you to remove elements from the view that your agents will see. This means you can keep user data private when it should not be available to your support agents.')}
          {' '}
          <Trans>Find out more about redaction in our <a target='_blank' className='text-black underline' rel='noopener noreferrer' href='https://docs.cobrowse.io/sdk-features/redact-sensitive-data'>documentation</a>.</Trans>
        </SettingsPage.HelpText>
      </div>
      <SettingsPage.Body>
        {renderRedactionConfig()}
      </SettingsPage.Body>
    </SettingsPage>
  )
}

export default RedactionSettings
