import React, { useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import autosizeInput from 'autosize-input'
import TextEntryTagInput from './TextEntryTagInput'
import Button from './Button'
import { getTestId } from '../utils/getTestId'
import Icon from './Icon'

const TextEntryTag = forwardRef(({ tag, getSuggestions, onValueChange, onRemove, onCommit }, ref) => {
  const input = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    const removeAutosize = autosizeInput(input.current)

    triggerResize()

    if (!tag.value) {
      focus()
    }

    return () => removeAutosize()
  }, [tag.value])

  const triggerResize = () => {
    input.current.dispatchEvent(new window.Event('input'))
    input.current.style.width = `${parseInt(input.current.style.width, 10) + 2}px`
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      commit({ refocus: true })
    }

    if (e.key === 'Tab') {
      commit({ refocus: true })
    }

    if (
      e.key === 'Backspace' &&
      !e.repeat &&
      e.target.value === '' &&
      e.target.selectionStart === 0
    ) {
      e.preventDefault()
      remove({ refocus: true })
    }
  }

  const remove = (options) => {
    onRemove(tag, options)
  }

  const focus = () => {
    input.current.focus()
  }

  const commit = (options) => {
    if (!tag.value) {
      remove(options)
    } else {
      if (options?.target?.value) {
        tag.value = options.target.value
      }

      onCommit(tag, options)
    }
  }

  return (
    <div
      className='bg-grey text-black rounded-full flex ps-1 pe-3 py-1 ms-2 text-md'
      {...getTestId('text-entry-tag')}
      ref={ref}
    >
      <div className='min-w-max flex items-center justify-center bg-white rounded-full w-5 h-5 text-sm p-1'>
        {(tag.iconType && <Icon type={tag.iconType} className='h-3' />) || tag.label || tag.field}
      </div>
      <TextEntryTagInput
        items={getSuggestions(tag.field)}
        tag={tag}
        onValueChange={onValueChange}
        inputRef={input}
        inputOnKeyDown={handleKeyDown}
        onBlur={commit}
        initialValue={tag.value || ''}
        className='px-1'
      />
      <Button
        variant='plain'
        className='flex items-center'
        onClick={() => onRemove(tag)}
        {...getTestId('text-entry-tag-remove')}
      >
        <span className='sr-only'>{t('Remove filter')}</span>
        <Icon type='close' className='min-w-max h-3' />
      </Button>
    </div>
  )
})

export default TextEntryTag
