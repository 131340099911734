/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from '../routing/withRouter'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ActiveSession from './ActiveSession'
import PendingSession from './PendingSession'
import EndedSession from './EndedSession'
import ErrorMessage from '../errors/ErrorMessage'
import LoadingScreen from '../../components/LoadingScreen'
import Session from './Session.state'
import Account from '../accounts/Account.state'
import User from '../users/User.state'
import { updateUiState } from '../ui-state/UI.state'
import EventSerialization from './EventSerialization'
import { proxyUrl, setProxyData } from '../proxy'
import SessionCapability from './SessionCapability'
import {
  SESSION_TOOL_TYPE_CONTROL,
  SESSION_TOOL_TYPE_DRAWING,
  SESSION_TOOL_TYPE_LASER
} from './SessionToolTypes'
import { SESSION_CAPABILITY_TYPE_DRAWING, SESSION_CAPABILITY_TYPE_FULL_DEVICE, SESSION_CAPABILITY_TYPE_LASER } from './SessionCapabilityTypes'
import mergeClassNames from '../../utils/mergeClassNames'
import isInIframe from '../../utils/isInIframe'
import { storeColor } from './colorPicker'

class SessionScreen extends Component {
  componentDidMount () {
    this.props.resetSessionContent()
    this.props.updateUiState({ tool: null })

    // then start this session
    this.props.registerAgentJoin({ id: this.id() })
    this.onSessionChanged()

    // temporary for debugging
    window.updateSession = update => this.props.updateSession({ id: this.id(), ...update })

    // TODO: replace with usePageTitle hook when migrating this component to a functional component
    // The hack below is needed because the previous route is not yet unmounted (and thus its title is not yet restored)
    // when this component is mounted.
    const originalTitleSplit = document.title.split(' | ')

    this.originalTitle = originalTitleSplit[originalTitleSplit.length - 1]
    document.title = this.props.t('Session') + ' | ' + this.originalTitle
  }

  componentDidUpdate (prevProps) {
    this.onSessionChanged()

    if (this.props.ui?.tool !== prevProps.ui?.tool) {
      this.onToolUpdated(this.props.ui.tool)
    }
  }

  componentWillUnmount () {
    this.props.closeStreamSocket()
    this.props.resetSessionContent()

    // TODO: replace with usePageTitle hook when migrating this component to a functional component
    document.title = this.originalTitle
  }

  onSessionChanged () {
    // ensure sockets are opened if we have URLs for them
    // also ensures that the token is always the latest one
    // we're aware of.
    this.props.subscribeSession()
    if (Session.isStreamAvailable(this.props.session.resource)) this.props.openStreamSocket()

    if (this.props.session.resource) this.setProxyData()

    if (this.props.session.resource && this.props.ui.tool === null) {
      this.props.updateUiState({ tool: this.firstAvailableTool() })
    }
  }

  setProxyData () {
    const { proxy_url: proxyUrl, proxy_token: proxyToken } = this.props.session.resource
    if (!(proxyUrl && proxyToken)) return
    setProxyData(proxyUrl, proxyToken)
  }

  firstAvailableTool () {
    const capabilities = [
      {
        capability: SESSION_CAPABILITY_TYPE_LASER,
        tool: SESSION_TOOL_TYPE_LASER
      },
      {
        capability: SESSION_CAPABILITY_TYPE_DRAWING,
        tool: SESSION_TOOL_TYPE_DRAWING
      }
    ]
    const capability = capabilities.find((capability) => SessionCapability.hasCapability(this.props.session.resource, capability.capability))

    return capability?.tool
  }

  onScreenResize = (size) => {
    this.props.updateUiState({ screenSize: size })
  }

  onToolSelected = (tool) => {
    this.props.updateUiState({ tool })
  }

  onColorPicked = (selectedColor) => {
    this.props.updateUiState({ selectedColor })
    storeColor(selectedColor)
  }

  onToolUpdated = (tool) => {
    const { session } = this.props
    if (tool === SESSION_TOOL_TYPE_CONTROL &&
      session.resource.remote_control !== 'requested' &&
      session.resource.remote_control !== 'on') {
      this.requestRemoteControl()
    }
  }

  onToolUsed = (tool, e, bounds, display) => {
    const serialized = EventSerialization.serialize(e, bounds)
    const session = this.props.session.resource

    if (!SessionCapability.allowsEvent(session, serialized)) {
      return
    }

    if (tool === SESSION_TOOL_TYPE_DRAWING) this.props.sendImageDrawEvent(serialized, display)
    else if (tool === SESSION_TOOL_TYPE_CONTROL && this.props.session.resource.remote_control === 'on') {
      this.props.sendControlEvent(serialized, display)
    } else if (tool === SESSION_TOOL_TYPE_LASER) this.props.sendLaserEvent(serialized, display)
  }

  requestRemoteControl = () => {
    this.props.updateSession({ id: this.props.session.resource.id, remote_control: 'requested' })
  }

  id = () => {
    return this.props.router.params.id
  }

  renderPendingSession = () => {
    return (
      <PendingSession
        key='pending'
        session={this.props.session.resource}
        endSession={this.props.endSession}
      />
    )
  }

  renderActiveSession = () => {
    const allowFullDevice = Account.hasFeature(this.props.account.resource, 'full_device', true) &&
      SessionCapability.hasCapability(this.props.session.resource, SESSION_CAPABILITY_TYPE_FULL_DEVICE)

    return (
      <ActiveSession
        key='active'
        displays={this.props.displays}
        screen={this.props.screen.resource}
        mouse={this.props.mouse.resource}
        connectivity={this.props.connectivity}
        session={this.props.session.resource}
        endSession={this.props.endSession}
        updateSession={this.props.updateSession}
        sync={this.props.sendSync}
        tool={this.props.ui.tool}
        selectedColor={this.props.ui.selectedColor}
        drawing={this.props.ui.drawing}
        onToolSelected={this.onToolSelected}
        onColorPicked={this.onColorPicked}
        onToolUsed={this.onToolUsed}
        onScreenResize={this.onScreenResize}
        requestRemoteControl={this.requestRemoteControl}
        allowControl={Account.hasFeature(this.props.account.resource, 'remote_control', true)}
        allowFullDevice={allowFullDevice}
        user={this.props.user}
        account={this.props.account}
        proxy={proxyUrl}
      />
    )
  }

  renderEndedSession = () => {
    return (
      <EndedSession
        allowRating={Account.hasFeature(this.props.account.resource, 'session_ratings', true)}
        session={this.props.session.resource}
      />
    )
  }

  renderError = (error) => {
    return (
      <ErrorMessage error={error} key='error'>
        Sorry, something went wrong loading your session.
      </ErrorMessage>
    )
  }

  renderSession = (state) => {
    switch (state) {
      case 'pending': return this.renderPendingSession()
      case 'authorizing': return this.renderPendingSession()
      case 'active': return this.renderActiveSession()
      case 'ended': return this.renderEndedSession()
      default: return null
    }
  }

  render () {
    if (this.props.session.error) return this.renderError(this.props.session.error)
    if (!this.props.session.resource) {
      return (
        <div className={mergeClassNames('flex items-center justify-center h-full bg-grey-dark', isInIframe() && 'bg-transparent')}>
          <LoadingScreen
            key='loading'
            cancelText={this.props.t('Cancel')}
            message={this.props.t('Loading session')}
            onCancel={() => this.props.endSession({ id: this.id() })}
          />
        </div>
      )
    }
    return this.renderSession(this.props.session.resource.state)
  }
}

const mapStateToProps = state => ({
  user: User.fromState(state),
  session: Session.fromState(state),
  account: Account.activeAccount(state),
  displays: state.displays,
  screen: state.screen,
  mouse: state.mouse,
  connectivity: state.connectivity,
  ui: state.ui
})

const mapDispatchToProps = dispatch => bindActionCreators({
  ...Session.actionCreators(),
  updateUiState
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SessionScreen)))
