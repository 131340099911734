import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonLink from '../../components/ButtonLink'
import FilterParamsEditor from '../filters/FilterParamsEditor'
import SessionListDownload from './SessionListDownload'
import StandardFields from '../devices/StandardFields'
import StandardList from '../../components/StandardList'
import FilterParams from '../filters/FilterParams'
import useThrottle from '../../hooks/useThrottle'
import Session from './Session'

const SessionList = ({ refresh, sessions, reset }) => {
  const [filter, setFilter] = useState(FilterParams.search)
  const filterEditor = useRef()
  const { t } = useTranslation()

  const filterSessions = useCallback(() => {
    return {
      filter: FilterParams.search,
      ...FilterParams.parse()
    }
  }, [])

  const refreshSessions = useCallback(() => {
    refresh(filterSessions())
  }, [refresh, filterSessions])

  useEffect(() => {
    refreshSessions()

    return () => {
      // Clear sessions from store to improve route change speed perception when
      // coming back to this route
      reset()
    }
  }, [refreshSessions, reset])

  const loadMore = useThrottle(() => {
    if (sessions.working) {
      return
    }

    const sessionsCollection = sessions.collection
    const last = sessionsCollection[sessionsCollection.length - 1]

    refresh({
      ...filterSessions(),
      activated_before: last.activated
    }, { merge: true })
  }, 400)

  const commitFilter = (text) => {
    refreshSessions()
    setFilter(text)
  }

  const clearFilters = () => {
    filterEditor.current.clearFilters()
  }

  const addFilter = useCallback((key, value) => {
    FilterParams.params = { ...FilterParams.params, [key]: value }
    filterEditor.current.setParams(FilterParams.search, FilterParams.params)
    refreshSessions()
  }, [refreshSessions])

  const renderSession = (session) => {
    return <Session key={session.id} session={session} addFilter={addFilter} />
  }

  const renderEmptyList = () => {
    if (sessions.working) {
      return null
    }

    if (filter || (!FilterParams.empty())) {
      return (
        <div className='text-center mt-5'>
          <span>{t("There aren't any sessions that match your filters.")}</span>{' '}
          <ButtonLink className='underline' onClick={clearFilters}>{t('Clear filters')}</ButtonLink>
        </div>
      )
    }

    return (
      <div className='text-center mt-5'>
        <span>{t("There aren't any sessions associated with your account.")}</span>
      </div>
    )
  }

  const renderControls = () => {
    return (
      <div className='flex gap-x-4 flex-wrap'>
        <div className='flex-1 min-w-0'>
          <FilterParamsEditor
            ref={filterEditor}
            loading={sessions.working}
            placeholder={t('Filter sessions...')}
            autocompleteFields={StandardFields.customData()}
            onUpdated={commitFilter}
          />
        </div>
        <div>
          <SessionListDownload filter={filterSessions()} />
        </div>
      </div>
    )
  }

  const sortedSessions = sessions.collection.sort((a, b) => {
    if (a.state === 'active' && b.state !== 'active') return -1
    if (b.state === 'active' && a.state !== 'active') return 1
    return (new Date(b.created)).getTime() - (new Date(a.created)).getTime()
  })

  return (
    <StandardList
      loading={sessions.working}
      listContainerClassName='sm:gap-y-2'
      items={sortedSessions}
      renderItem={renderSession}
      renderEmptyList={renderEmptyList}
      onBottomReached={loadMore}
      testId='session-list'
    >{renderControls()}
    </StandardList>
  )
}

export default SessionList
