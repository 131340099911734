import React, { useEffect, useId, useRef, useState } from 'react'
import Button from './Button'
import Icon from './Icon'
import mergeClassNames from '../utils/mergeClassNames'
import { useTranslation } from 'react-i18next'
import Input, { inputClassName } from './Input'

const CopyText = ({ variant = 'text', id, className, valueClassName, label, labelStyle = 'group', hideConfirmation = false, trim = false, children }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const textRef = useRef()
  const timeoutRef = useRef(null)
  const inputId = useId()
  const isInnerLabel = label && labelStyle === 'inner'

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (trim) {
      // Set the width of the input to the length of the text
      textRef.current.style.width = textRef.current.value.length + 'ch'
    }
  }, [trim])

  const copyText = () => {
    navigator.clipboard.writeText(text()).catch(() => {
      // Fallback for iframes without clipboard permissions
      selectText()
      document.execCommand('copy')
    })

    if (!hideConfirmation) {
      setCopied(true)

      timeoutRef.current = setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }

  const selectInputText = () => {
    textRef.current.select()
  }

  const selectSpanText = () => {
    const range = document.createRange()
    const selection = window.getSelection()

    range.selectNodeContents(textRef.current)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  const selectText = (event) => {
    if (!event) {
      return variant === 'input' ? selectInputText() : selectSpanText()
    }

    if (event.target.tagName === 'INPUT') {
      return selectInputText()
    }

    if (event.target.tagName === 'SPAN') {
      return selectSpanText()
    }
  }

  const text = () => {
    const value = children

    return Array.isArray(value)
      ? value.join('')
      : value
  }

  return (
    <div className={mergeClassNames('flex items-center', variant === 'input' && !isInnerLabel && 'w-full')}>
      {label && labelStyle === 'group' && (
        <label htmlFor={id || inputId} className='ps-3 py-2 bg-grey text-base text-black/60 border-2 retina:border-1.5 border-r-0 border-grey rounded-s select-none min-w-max'>{label}</label>
      )}
      <div className={mergeClassNames('relative flex items-center gap-x-2', variant === 'input' && [inputClassName, 'bg-grey border-grey w-full'], className)}>
        <div className={mergeClassNames('flex gap-x-2 items-center', variant === 'input' && !trim && 'w-full', isInnerLabel && 'flex-wrap items-start')}>
          {isInnerLabel && (
            <div className='text-base text-black/60'>{label}</div>
          )}
          {variant === 'text' && (
            <span
              ref={textRef}
              onClick={selectText}
              className={valueClassName}
            >
              {text()}
            </span>
          )}
          {label && labelStyle === 'hidden' && (
            <label htmlFor={id || inputId} className='sr-only'>{label}</label>
          )}
          {variant === 'input' && (
            <Input
              ref={textRef}
              id={id || inputId}
              variant='plain'
              className={mergeClassNames('bg-transparent outline-none w-full truncate', valueClassName)}
              value={text()}
              readOnly
              onClick={selectText}
            />
          )}
        </div>
        <Button
          variant='plain'
          className='group'
          title={t('Copy to clipboard')}
          onClick={copyText}
        >
          <Icon
            type='copy'
            className='h-6 p-1 text-black opacity-50 group-hover:opacity-100 cursor-pointer transition-all ease-in'
          />
        </Button>
        {!hideConfirmation && (
          <div className={
            mergeClassNames(
              'absolute start-0 top-0 h-full w-full bg-purple-200/50 transition duration-300 flex items-center justify-center opacity-0 pointer-events-none',
              copied && 'opacity-100 backdrop-blur-sm pointer-events-auto'
            )
          }
          >
            <span>{t('Copied!')}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CopyText
