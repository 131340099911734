export const SESSION_CAPABILITY_TYPE_POINTER = 'pointer'
export const SESSION_CAPABILITY_TYPE_SCROLL = 'scroll'
export const SESSION_CAPABILITY_TYPE_SELECT = 'select'
export const SESSION_CAPABILITY_TYPE_KEYPRESS = 'keypress'
export const SESSION_CAPABILITY_TYPE_DRAWING = 'drawing'
export const SESSION_CAPABILITY_TYPE_DISAPPEARING_INK = 'disappearing_ink'
export const SESSION_CAPABILITY_TYPE_LASER = 'laser'
export const SESSION_CAPABILITY_TYPE_FULL_DEVICE = 'full_device'
export const SESSION_CAPABILITY_TYPE_CURSOR = 'cursor'

// This is a group of capabilities related to the remote control tool.
// We can use this to check if some or none are allowed.
export const SESSION_CAPABILITY_GROUP_REMOTE_CONTROL = [
  SESSION_CAPABILITY_TYPE_POINTER,
  SESSION_CAPABILITY_TYPE_SCROLL,
  SESSION_CAPABILITY_TYPE_SELECT,
  SESSION_CAPABILITY_TYPE_KEYPRESS,
  SESSION_CAPABILITY_TYPE_CURSOR
]

export default [
  SESSION_CAPABILITY_TYPE_POINTER,
  SESSION_CAPABILITY_TYPE_SCROLL,
  SESSION_CAPABILITY_TYPE_SELECT,
  SESSION_CAPABILITY_TYPE_KEYPRESS,
  SESSION_CAPABILITY_TYPE_DRAWING,
  SESSION_CAPABILITY_TYPE_DISAPPEARING_INK,
  SESSION_CAPABILITY_TYPE_LASER,
  SESSION_CAPABILITY_TYPE_FULL_DEVICE,
  SESSION_CAPABILITY_TYPE_CURSOR
]
