import React from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import LoginButtons from './LoginButtons'
import Link from '../../components/Link'
import AuthContainer from './AuthContainer'
import Icon from '../../components/Icon'
import Panel from '../../components/Panel'
import usePageTitle from '../../hooks/usePageTitle'

const LoginScreen = () => {
  const { redirectTo } = qs.parse(window.location.search.substring(1))
  const { t } = useTranslation()

  usePageTitle(t('Sign in'))

  return (
    <AuthContainer>
      <a href='/'>
        <Panel className='absolute end-8 md:end-12 top-4 text-black p-1 rounded-lg'>
          <Icon type='close' />
        </Panel>
      </a>
      <div className='container mx-auto px-8 md:px-4 mt-6'>
        <div className='flex flex-col max-w-2xl mx-auto'>
          <h1 className='text-h-md md:text-h-lg mb-6'>{t('Sign in to your account')}</h1>
          <LoginButtons query={{ redirectTo }} />
          <div className='text-center text-black/75 mt-8'>
            <p className='my-4'>{t("Don't have an account?")} <Link to='/register'>{t('Create a new account')}</Link></p>
          </div>
        </div>
      </div>
    </AuthContainer>
  )
}

export default LoginScreen
