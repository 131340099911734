import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import Button from './Button'

const ButtonLink = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <Button ref={ref} variant='plain' className={mergeClassNames('text-black hover:text-black/75', className)} {...props}>
      {children}
    </Button>
  )
})

export default ButtonLink
