import React from 'react'
import LinkButton from '../../components/LinkButton'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'

const ConnectButton = ({ deviceId, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <LinkButton
      to={`/connect/device/${deviceId}${window.location.search}`}
      variant='primary'
      className={mergeClassNames('w-full text-center h-11 sm:h-9', className)}
      {...getTestId('connect-button')}
      {...props}
    >
      {t('Connect')}
    </LinkButton>
  )
}

export default ConnectButton
