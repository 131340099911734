/**
* This class maps node ids as output from the client's vDOM
* to their counterpart DOM element on the agent side
*/
class NodeTracker {
  _nodes = new Map()

  get = (nodeId) => this._nodes.get(nodeId)

  set = (nodeId, elem) => {
    this._nodes.set(nodeId, elem)
  }

  delete = (nodeId) => {
    this._nodes.delete(nodeId)
  }

  reset = () => {
    this._nodes.clear()
  }
}

export default new NodeTracker()
