import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ErrorMessage from './modules/errors/ErrorMessage'
import LoadingScreen from './components/LoadingScreen'
import Login from './modules/auth/Login'
import User from './modules/users/User.state'
import Account from './modules/accounts/Account.state'
import useActiveAccountSelector from './hooks/useActiveAccountSelector'
import { useTranslation } from 'react-i18next'

const ContextLoading = ({ component, required, ...props }) => {
  const ui = useSelector(state => state.ui)
  const accounts = useSelector(state => Account.fromState(state))
  const account = useActiveAccountSelector()
  const user = useSelector(state => User.fromState(state))
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (user.resource) {
      dispatch(User.actionCreators().connectUserSocket())
    }

    const authCheckInterval = setInterval(() => {
      if (user.resource) {
        dispatch(User.actionCreators().getUser({ id: 'me' }))
      }
    }, 5 * 60 * 1000)

    return () => clearInterval(authCheckInterval)
  }, [dispatch, user.resource])

  if (!ui.context_loaded) return <LoadingScreen />

  if (!user.resource) return <Login />

  if (required?.account !== false) {
    if ((!accounts.collection) || (!accounts.collection.length)) {
      return <ErrorMessage>{t('No accounts found for user. Please contact Cobrowse support to fix this.')}</ErrorMessage>
    }

    if (!account.resource) {
      return <ErrorMessage>{t('Account not selected.')}</ErrorMessage>
    }
  }

  const Comp = component
  const mergedProps = { ...props, account, user }

  return <Comp {...mergedProps} />
}

export default ContextLoading
