import React, { useId } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from '../users/UserIcon'
import Select from '../../components/Select'
import Option from '../../components/SelectOption'
import Icon from '../../components/Icon'
import Button from '../../components/Button'

const MemberRole = ({ member, updateAccountMember, readOnly = false }) => {
  const { t } = useTranslation()
  const selectId = useId()

  if (!member.role) {
    return null
  }

  return (
    <div>
      <label htmlFor={selectId} className='sr-only'>{t('Role')}</label>
      <Select
        id={selectId}
        variant='simple'
        disabled={!updateAccountMember}
        readOnly={readOnly}
        value={member.role}
        onChange={(e) => updateAccountMember({ ...member, role: e.target.value })}
      >
        <Option value='agent'>{t('Support agent')}</Option>
        <Option value='administrator'>{t('Administrator')}</Option>
      </Select>
    </div>
  )
}

const MemberControls = ({ member, updateAccountMember, removeAccountMember, readOnly = false }) => {
  const { t } = useTranslation()

  if (!member.role || readOnly) {
    return null
  }

  return (
    <Button
      variant='secondary'
      className='w-11'
      disabled={!updateAccountMember}
      onClick={() => removeAccountMember(member)}
    >
      <Icon type='trash-empty' className='h-4 w-4' />
      <span className='sr-only'>{t('Remove member')}</span>
    </Button>
  )
}

const Member = ({ member, self, updateAccountMember, removeAccountMember, readOnly = false }) => {
  const { t } = useTranslation()

  return (
    <div key={member.id} className='flex flex-wrap items-center gap-3 py-2'>
      <div className='min-w-0'>
        <UserIcon user={member} />
      </div>
      <div>
        <div className='text-base'>{self ? t('You') : member.name}</div>
        <div className='text-md text-black/60'>{member.username || member.email || ''}</div>
      </div>
      <div className='ms-12 [@media(min-width:400px)]:ms-auto flex gap-x-2 items-center'>
        <MemberRole member={member} updateAccountMember={updateAccountMember} readOnly={readOnly} />
        <MemberControls member={member} updateAccountMember={updateAccountMember} removeAccountMember={removeAccountMember} readOnly={readOnly} />
      </div>
    </div>
  )
}

export default Member
