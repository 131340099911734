import React from 'react'

import Debug from 'debug'

const debug = Debug('cbio.priority.withPriority')

export default function withPriority (WrappedComponent, { loadAttr, calculateRank }) {
  return class extends React.Component {
    static nodeFilter (node) {
      return WrappedComponent.nodeFilter?.(node) ?? node
    }

    get #helperNodeId () {
      return `${this.props.node.tagName}/${this.props.node.id}`
    }

    state = {
      el: null,
      handleComplete: null,
      load: false,
      node: this.#getPropNodeWithoutLoading(),
      scheduler: null
    }

    componentDidMount () {
      this.#debug('componentDidMount()')
      this.#handleNodeUpdate()
    }

    componentDidUpdate (prevProps) {
      this.#debug('componentDidUpdate()')
      this.#handleNodeUpdate(prevProps)
    }

    componentWillUnmount () {
      this.#debug('componentWillUnmount()')
      // Make sure we don't keep this scheduled if it shows up then goes away
      // before loading
      this.state.scheduler?.cancel(this.#helperNodeId)
    }

    render () {
      this.#debug('render()')
      return (
        <WrappedComponent
          {...this.props}
          node={this.state.node}
          onDOMNodeElRef={(el) => this.#onRef(el)}
          onError={this.state.handleComplete}
          onLoad={this.state.handleComplete}
        />
      )
    }

    #handleNodeUpdate (prevProps) {
      if (this.state.load) {
        this.#debug('item has begun (or finished) loading')

        // If we indicated to start loading the element, but for some reason
        // the loading attribute is not set on the underlying element, we
        // have to cancel the item to avoid it hanging up the queue. We have
        // a layer (proxyUrl in DOMScreen) that transforms a URL between
        // the node prop attributes and the element attributes, so it's
        // important to defensively check this on the element itself
        if (!this.state.el.getAttribute(loadAttr)) {
          this.#debug('cancel loading suspected blocked url')
          this.state.scheduler.cancel(this.#helperNodeId)
        }

        // If the node prop changes, we want to push that through to the
        // underlying DOMNode instance
        if (this.state.node !== this.props.node) {
          this.setState({ node: this.props.node })
        }
        return
      } else if (prevProps?.node !== this.props.node) {
        // If we don't want to trigger the network load yet, but we have changed
        // the props, keep the node updates coming in
        this.setState({ node: this.#getPropNodeWithoutLoading() })
      }

      if (!this.state.scheduler) {
        this.#debug('yield while waiting on scheduler')
        return
      }

      const existing = this.state.scheduler.get(this.#helperNodeId)
      if (existing) {
        // If we're already scheduled or cannot schedule yet, then don't try and
        // (re-)schedule it
        this.#debug('yield to existing prioritization', existing)
        return
      }

      // Determine the scheduling rank for this node instance
      const rank = calculateRank(this.props, this.state.el)

      if (typeof rank !== 'number') {
        // If no rank is specified, this indicates we don't want to schedule it,
        // load it immediately
        this.#debug('bypass prioritization because of empty rank', { rank })
        this.#beginLoading()
        return
      }

      // We need to schedule this node to be rendered in priority order
      this.#reschedule(rank)
    }

    #reschedule (rank) {
      this.#debug('scheduling resource to load', { rank })

      this.state.scheduler.schedule(this.#helperNodeId, rank, async () => {
        // If the state of this node is such that the onload/onerror will never
        // trigger (e.g., the "src" of an image is not specified), then
        // immediately return without binding a handleComplete callback. If we
        // don't do this then a resource will clog up the priority queue
        if (!this.#elementWillLoad()) {
          this.#debug('immediately resolving resource because element will not trigger onload')
          this.#beginLoading()
          return
        }

        // If the element became lower-priority, bail and re-schedule it. This
        // happens if an image starts visible initially, but becomes hidden
        // after the higher-priority stylesheets have loaded
        const currRank = calculateRank(this.props, this.state.el)
        if (typeof currRank === 'number' && currRank > rank) {
          this.#debug('rescheduling because rank has increased since it was originally scheduled', { prevRank: rank, currRank })
          this.#reschedule(currRank)
          return
        }

        this.#debug('begin loading resource', { rank })
        return await new Promise((resolve) => {
          let isComplete = false
          this.#beginLoading((...args) => {
            if (isComplete) return
            this.#debug('onload() (or onerror())', ...args)
            isComplete = true
            resolve()
          })
        })
      })
    }

    /**
     * Starting the network loading of the resource by "releasing" the
     * `loadAttr` into the DOM element, and binding an onload listener,
     * if specified.
     */
    #beginLoading (handleComplete) {
      this.setState({
        handleComplete,
        load: true,
        node: this.props.node
      })
    }

    /**
     * Create a transformed copy of the props.node attribute that has its
     * `loadAttr` "withheld", this allows us to render the DOM element without
     * immediatley downloading it.
     */
    #getPropNodeWithoutLoading () {
      // eslint-disable-next-line no-unused-vars
      const { [loadAttr]: _, ...attributes } = this.props.node.attributes ?? {}
      return { ...this.props.node, attributes }
    }

    /**
     * Determine if the act of applying the current DOM element's `loadAttr`
     * will guarantee an `onload` invocation on the element in the future.
     */
    #elementWillLoad () {
      const propLoadValue = this.props.node.attributes?.[loadAttr]
      const stateLoadValue = this.state.node?.attributes?.[loadAttr]

      // Load will only invoke if the load property is set, and if it's
      // different than anything rendered right now
      return propLoadValue && propLoadValue !== stateLoadValue
    }

    #onRef (el) {
      if (el) {
        const scheduler = el.ownerDocument.__scheduler
        if (scheduler) this.setState({ el, scheduler })
        else this.setState({ el })
      }
    }

    #debug (...args) {
      debug(this.#helperNodeId, ...args, this.state.el?.getAttribute(loadAttr), {
        loadAttr: {
          element: this.state.el?.getAttribute(loadAttr),
          props: this.props.node.attributes?.[loadAttr],
          state: this.state.node?.attributes?.[loadAttr]
        },
        node: {
          props: this.props.node,
          state: this.state.node
        }
      })
    }
  }
}
