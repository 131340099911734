export function getScrollBarSize (doc = document) {
  if (!doc?.body) return { width: 0, height: 0 }

  const inner = doc.createElement('div')
  inner.style.width = '100%'
  inner.style.height = '500px'

  const outer = doc.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '200px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  doc.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth
  if (w1 === w2) w2 = outer.clientWidth
  doc.body.removeChild(outer)
  return {
    width: w1 - w2,
    height: w1 - w2 // assuming scrollbar height = width
  }
}
