export const UPDATE_PRESENCE = 'presence/UPDATE'
export const RESET_PRESENCE = 'presence/RESET'

const initialState = { }

export default function presence (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRESENCE:
      return {
        ...state,
        [action.presence.id]: action.presence
      }

    case RESET_PRESENCE:
      return { ...initialState }

    default:
      return state
  }
}

export const updatePresence = (presence) => {
  return {
    type: UPDATE_PRESENCE,
    presence
  }
}

export const resetPresence = () => {
  return {
    type: RESET_PRESENCE
  }
}
