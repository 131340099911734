import React, { useEffect, useState } from 'react'
import { getTestId } from '../../utils/getTestId'

const SessionVideo = ({ src, ...props }) => {
  const [source, setSource] = useState(null)

  // Only set the src once to avoid duplicate requests due to jitter
  useEffect(() => {
    setSource(src)
  }, [src])

  return source
    ? <video {...props} src={source} {...getTestId('session-recording-video-player')} />
    : null
}

export default SessionVideo
