export const colors = [
  { title: 'black', color: '#3c4044' },
  { title: 'green', color: '#34a853' },
  { title: 'red', color: '#e94435' },
  { title: 'blue', color: '#10b5cb' },
  { title: 'yellow', color: '#f9ab02' },
  { title: 'white', color: '#f8f9fa', borderColor: 'black' }
]

// export const colors = [
//   { title: 'purple', color: '#4772F5' },
//   { title: 'yellow', color: '#F5AF47' },
//   { title: 'red', color: '#FF8A41' },
//   { title: 'blue', color: '#1595DF' },
//   { title: 'pink', color: '#F056B2' },
//   { title: 'lime', color: '#A7D012' },
// ]

const storageKey = 'cbio_annotation_color'

export function readColor () {
  try {
    const storedColor = window.localStorage.getItem(storageKey)
    if (storedColor != null) return JSON.parse(storedColor)
  } catch (err) {
    console.warn('Failed to retrieve color. Defaulting')
    return null
  }
}

export function getAnnotationColor () {
  const color = readColor()

  if (color != null) return color

  return colors.find(({ title }) => title === 'red')
}

export function storeColor (color) {
  try {
    window.localStorage.setItem(storageKey, JSON.stringify(color))
  } catch (err) {
    console.warn('Failed to store color on local storage', err)
  }
}
