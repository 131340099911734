import React from 'react'
import { useTranslation } from 'react-i18next'
import AuthContainer from './AuthContainer'
import LoginButtons from './LoginButtons'
import Link from '../../components/Link'
import usePageTitle from '../../hooks/usePageTitle'

const Login = ({ redirectTo = window.location.pathname }) => {
  const { t } = useTranslation()

  usePageTitle(t('Sign in'))

  return (
    <AuthContainer>
      <div className='container mx-auto px-8 md:px-4 mt-6'>
        <div className='flex flex-col max-w-2xl mx-auto'>
          <h1 className='text-h-md md:text-h-lg'>{t('Login is required')}</h1>
          <p className='my-6'>{t('You need to log in to access this page.')}</p>
          <LoginButtons query={{ redirectTo }} />
          <div className='text-center text-black/75 mt-8'>
            <p className='my-4'>{t("Don't have an account?")} <Link to='/register'>{t('Create a new account')}</Link></p>
          </div>
        </div>
      </div>
    </AuthContainer>
  )
}

export default Login
