import DOMNode from './DOMNode'

export default class DOMCanvas extends DOMNode {
  static nodeFilter (node) {
    return node
  }

  componentDidMount () {
    super.componentDidMount()
    this.updateCanvas()
  }

  componentDidUpdate () {
    super.componentDidUpdate()
    this.updateCanvas()
  }

  updateCanvas () {
    const { node } = this.props
    if (!node.data) return
    const img = new window.Image()
    const canvas = this.el
    img.onload = function () {
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    }
    img.src = node.data
  }
}
