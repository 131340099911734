import React from 'react'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Alert from '../../components/Alert'
import { buttonClassName } from '../../components/Button'

const SAMLLogin = () => {
  const { t } = useTranslation()
  const { provider } = useParams()

  const renderLoginError = () => {
    const { error } = qs.parse(window.location.search.substring(1))

    if (!error) {
      return null
    }

    const messages = {
      saml_invalid_provider: t('The SAML provider was not found – Please check your provider ID.'),
      saml_invalid_certificate: t('The SAML certificate is missing or invalid – Please check your certificate.'),
      saml_invalid_entrypoint: t('The SAML entrypoint is missing or invalid – Please check your entrypoint.'),
      saml_error: t('Something went wrong during SAML login. Detail: {{error}}', { error })
    }

    const message = messages[error] || t('Sorry, something went wrong logging into your account. Detail: {{error}}', { error })

    return (
      <Alert type='alert'>{message}</Alert>
    )
  }

  const { redirectTo } = qs.parse(window.location.search.substring(1))

  return (
    <div className='flex flex-col gap-y-4 items-center justify-center h-full px-4'>
      {renderLoginError()}
      <a
        className={buttonClassName()}
        href={`/api/1/saml/auth?${qs.stringify({
          provider,
          redirectTo
        })}`}
      >
        {t('Login to Cobrowse')}
      </a>
    </div>
  )
}

export default SAMLLogin
