import React from 'react'
import { transform } from 'lodash'
import mergeClassNames from '../utils/mergeClassNames'

const TimelineEvent = ({ icon, title, subtitle, changes, variant = 'audit', className, children }) => {
  const renderChange = (key, value) => {
    if (typeof value === 'boolean') {
      value = JSON.stringify(value)
    }

    if (value === null) {
      value = 'default'
    }

    return (
      <div key={key} className='bg-grey p-2 rounded-lg text-black/60 text-base flex flex-wrap items-center gap-x-1 md:flex-nowrap'>
        <span className='text-black break-all sm:break-normal'>{key}</span>
        <span> → </span>
        <span className='break-all'>{value}</span>
      </div>
    )
  }

  const renderChanges = (changes) => {
    function flattenObject (ob, prefix = false, accumulator = {}) {
      return transform(ob, (result, value, key) => {
        const prefixed = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'object' && value !== null) {
          return flattenObject(value, prefixed, result)
        }

        result[prefixed] = value

        return result
      }, accumulator)
    }

    return <div>{Object.entries(flattenObject(changes)).map(e => renderChange(...e))}</div>
  }

  return (
    <div className={mergeClassNames('h-full relative min-w-0 w-full', className)}>
      <div className='flex gap-x-3'>
        <div className='relative flex flex-row gap-x-1.5'>
          {/* Vertical line */}
          <div className={mergeClassNames('h-[calc(100%+12px)] ms-1 min-h-[20px] mt-5 border-l-2 retina:border-l-1.5 border-[#000000]/15 group-last/list-item:hidden sm:h-full', variant === 'recording' && 'h-[calc(100%+4px)] sm:h-[calc(100%+4px)]')} />
          {/* Circle next to each entry */}
          <div className='absolute sm:max-lg:start-[0.5px] start-[1px] sm:max-lg:top-3 top-4 w-2 h-2 bg-white border-2 retina:border-1.5 border-[#000000]/15 rounded-full' />
          {/* Extra margin on last element to keep it aligned with the rest */}
          <div className='group-last/list-item:ms-2.5' />
          <div className='w-9 flex justify-center min-w-fit empty:me-0 empty:w-0'>{icon}</div>
        </div>
        <div className='flex flex-col w-full min-w-0'>
          <div>
            <div className='text-black text-title-md'>{title}</div>
            <div className='text-black/60 text-md'>{subtitle}</div>
          </div>
          <div className='mt-2 pb-4 flex flex-col gap-y-4'>
            {children}
            {renderChanges(changes)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimelineEvent
