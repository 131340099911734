import { getAnnotationColor } from '../sessions/colorPicker'

export const UPDATE_UI_STATE = 'ui/UPDATE'

const initialState = {
  selectedColor: getAnnotationColor()
}

export default function ui (state = initialState, action) {
  switch (action.type) {
    case UPDATE_UI_STATE:
      return { ...state, ...action.state }

    default:
      return state
  }
}

export const updateUiState = (state) => {
  return {
    type: UPDATE_UI_STATE,
    state
  }
}
