import { parents } from '@cobrowseio/vdom-utils/src'
import Frame from '../screens/Frame'
import {
  SESSION_EVENT_TYPE_MOUSE,
  SESSION_EVENT_TYPE_TOUCH,
  SESSION_EVENT_TYPE_POINTER,
  SESSION_EVENT_TYPE_FOCUS,
  SESSION_EVENT_TYPE_KEYPRESS,
  SESSION_EVENT_TYPE_SCROLL,
  SESSION_EVENT_TYPE_CHANGE,
  SESSION_EVENT_TYPE_INPUT,
  SESSION_EVENT_TYPE_LASER,
  SESSION_EVENT_TYPE_DRAWING,
  SESSION_EVENT_TYPE_SELECT
} from './SessionEventTypes'

export default class EventSerialization {
  static proportionalPositionForEvent = (e, bounds) => {
    if (bounds?.x < 0 || bounds?.y < 0) return { x: bounds.x, y: bounds.y }
    if (!e.clientX || !e.clientY || !bounds) return { x: false, y: false }

    const x = e.clientX - bounds.x
    const y = e.clientY - bounds.y
    return { x: x / bounds.width, y: y / bounds.height }
  }

  static proportionalOffsetForEvent = (e, bounds) => {
    const iframe = e.target?.ownerDocument?.defaultView?.frameElement

    if (!iframe) return { offsetX: 0, offsetY: 0 }
    if (Frame.isRemoteScreen(iframe)) return { offsetX: 0, offsetY: 0 }

    const frames = [iframe, ...parents(iframe).filter(Frame.isFrame)]
    const remoteScreen = frames.find(Frame.isRemoteScreen)
    const remoteScreenIndex = frames.indexOf(remoteScreen)
    const framesWithinRemoteScreen = remoteScreen ? frames.slice(0, remoteScreenIndex) : frames
    const offset = framesWithinRemoteScreen.map(n => n.getBoundingClientRect()).reduce((a, b) => {
      return { x: a.x + b.x, y: a.y + b.y }
    }, { x: 0, y: 0 })

    return {
      offsetX: Math.round(offset.x) / bounds.width,
      offsetY: Math.round(offset.y) / bounds.height
    }
  }

  static serializeTarget (e, includeValue = false) {
    const path = e.originalPath || e.path || e.composedPath?.()
    const target = path?.[0] || e.target
    if ((!target) || (!target.__cobrowse_id)) return undefined
    const serialized = {
      id: target.__cobrowse_id
    }
    if (includeValue) {
      serialized.value = target.value
      if (target.tagName === 'INPUT' &&
        (target.type === 'radio' || target.type === 'checkbox') &&
        typeof target.checked !== 'undefined') { serialized.checked = target.checked }
      if (target.isContentEditable) { serialized.innerHTML = target.innerHTML }
    }
    return serialized
  }

  static serializeMouse (e, bounds) {
    return {
      type: SESSION_EVENT_TYPE_MOUSE,
      button: e.button,
      target: this.serializeTarget(e),
      state: e.type,
      colour: bounds?.colour,
      ...this.proportionalPositionForEvent(e, bounds),
      ...this.proportionalOffsetForEvent(e, bounds)
    }
  }

  static serializeTouch (e, bounds) {
    return {
      type: SESSION_EVENT_TYPE_TOUCH,
      target: this.serializeTarget(e),
      state: e.type,
      ...this.proportionalPositionForEvent(e.changedTouches[0], bounds)
    }
  }

  static serializePointer (e, bounds) {
    return {
      type: SESSION_EVENT_TYPE_POINTER,
      target: this.serializeTarget(e),
      state: e.type,
      pointerId: e.pointerId,
      pointerType: e.pointerType,
      isPrimary: e.isPrimary,
      ...this.proportionalPositionForEvent(e, bounds)
    }
  }

  static serializeFocus (e) {
    return {
      type: SESSION_EVENT_TYPE_FOCUS,
      target: this.serializeTarget(e),
      state: e.type
    }
  }

  static serializeKeypress (e) {
    return {
      type: SESSION_EVENT_TYPE_KEYPRESS,
      target: this.serializeTarget(e, true),
      state: e.type,
      key: e.key,
      code: e.code,
      ctrlKey: e.ctrlKey,
      shiftKey: e.shiftKey,
      altKey: e.altKey,
      metaKey: e.metaKey,
      which: e.which,
      charCode: e.charCode,
      keyCode: e.keyCode
    }
  }

  static serializeScroll (e) {
    const path = e.path || e.composedPath?.()
    const target = path?.[0] || e.target
    const container = target.contentWindow || target
    const scrollable = container.scrollingElement || container
    return {
      type: SESSION_EVENT_TYPE_SCROLL,
      target: this.serializeTarget(e),
      x: scrollable.scrollLeft || 0,
      y: scrollable.scrollTop || 0
    }
  }

  static serializeChange (e) {
    return {
      type: SESSION_EVENT_TYPE_CHANGE,
      target: this.serializeTarget(e, true),
      state: e.type
    }
  }

  static serializeInput (e) {
    return {
      type: SESSION_EVENT_TYPE_INPUT,
      target: this.serializeTarget(e, true),
      state: e.type,
      inputType: e.inputType,
      data: e.data
    }
  }

  static serializeLaser (e, bounds) {
    return {
      type: SESSION_EVENT_TYPE_LASER,
      target: this.serializeTarget(e),
      state: e.state,
      ...this.proportionalPositionForEvent(e.changedTouches[0], bounds),
      colour: e.colour
    }
  }

  static serializeDrawing (e) {
    return {
      ...e,
      type: SESSION_EVENT_TYPE_DRAWING
    }
  }

  static serializeSelectionChange (e) {
    // we use optional chaining as Firefox can return null while calling getSelection
    // https://bugzilla.mozilla.org/show_bug.cgi?id=827585
    const selection = e.target?.getSelection?.()
    let serializedSelection = null

    if (selection && selection.type === 'Range' && !selection.isCollapsed && selection.rangeCount > 0) {
      try {
        // gecko browsers support multiple selections.
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Selection/rangeCount
        serializedSelection = [...Array(selection.rangeCount)].reduce((acc, _, idx) => {
          const range = selection.getRangeAt(idx)
          const { startOffset, endOffset } = range

          const start = range.startContainer.__cobrowse_id
          const end = range.endContainer.__cobrowse_id

          acc.push({
            start,
            end,
            // if the element is redacted by a parent than we need to use offset 0 instead
            // of the actually selected offset
            startOffset,
            endOffset
          })

          return acc
        }, [])
      } catch (err) {
        console.warn('CobrowseIO: Could not serialize text selection', err)
        return e
      }
    }

    return {
      type: SESSION_EVENT_TYPE_SELECT,
      target: this.serializeTarget(e),
      state: e.type,
      selection: serializedSelection
    }
  }

  static serializeInputSelection (e) {
    let serializedSelection = null
    const { selectionStart, selectionEnd, selectionDirection } = e.target

    try {
      if (selectionStart !== selectionEnd && e.target === e.target.ownerDocument.activeElement) {
        serializedSelection = {
          start: selectionStart,
          end: selectionEnd,
          direction: selectionDirection
        }
      }
    } catch (err) {
      console.warn('Failed to serialise text selection for the input', e.target)
    }

    return {
      type: SESSION_EVENT_TYPE_SELECT,
      target: this.serializeTarget(e),
      state: e.type,
      selection: serializedSelection
    }
  }

  /* eslint-disable complexity */
  static serialize (e, bounds) {
    switch (e.type) {
      case 'mousedown':
      case 'mousemove':
      case 'mouseup':
      case 'mouseover':
      case 'mouseout':
      case 'click':
      case 'dblclick':
        return EventSerialization.serializeMouse(e, bounds)
      case 'touchstart':
      case 'touchmove':
      case 'touchend':
        return EventSerialization.serializeTouch(e, bounds)
      case 'pointerdown':
      case 'pointermove':
      case 'pointerup':
        return EventSerialization.serializePointer(e, bounds)
      case 'keydown':
      case 'keypress':
      case 'keyup':
        return EventSerialization.serializeKeypress(e)
      case 'scroll':
        return EventSerialization.serializeScroll(e)
      case 'focus':
      case 'blur':
      case 'focusin':
      case 'focusout':
        return EventSerialization.serializeFocus(e)
      case 'input':
      case 'textInput':
        return EventSerialization.serializeInput(e)
      case 'change':
        return EventSerialization.serializeChange(e)
      case 'laser':
        return EventSerialization.serializeLaser(e, bounds)
      case 'drawing':
        return EventSerialization.serializeDrawing(e)
      case 'selectionchange':
        return EventSerialization.serializeSelectionChange(e)
      case 'select':
        return EventSerialization.serializeInputSelection(e)
      default:
        return e
    }
  }
  /* eslint-enable complexity */
}
