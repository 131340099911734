import React, { forwardRef } from 'react'
import { getTestId } from '../utils/getTestId'
import mergeClassNames from '../utils/mergeClassNames'

const Checkbox = forwardRef(({ checked, onChange, className, testId, ...props }, ref) => {
  return (
    <input
      ref={ref}
      checked={checked}
      type='checkbox'
      onChange={onChange}
      className={mergeClassNames('appearance-none bg-white border border-grey p-0 rounded-2xl inline-block relative w-10 h-6 outline-none align-middle cursor-pointer after:absolute after:w-5 after:h-5 after:rounded-full after:bg-black-400 after:top-px after:start-px checked:after:bg-black checked:after:start-[calc(100%_-_21px)] after:transition-all after:delay-200 after:ease-linear', className)}
      {...getTestId(testId)}
      {...props}
    />
  )
})

export default Checkbox
