export const SESSION_EVENT_TYPE_MOUSE = 'Mouse'
export const SESSION_EVENT_TYPE_TOUCH = 'Touch'
export const SESSION_EVENT_TYPE_POINTER = 'Pointer'
export const SESSION_EVENT_TYPE_FOCUS = 'Focus'
export const SESSION_EVENT_TYPE_KEYPRESS = 'KeyPress'
export const SESSION_EVENT_TYPE_SCROLL = 'Scroll'
export const SESSION_EVENT_TYPE_CHANGE = 'Change'
export const SESSION_EVENT_TYPE_INPUT = 'Input'
export const SESSION_EVENT_TYPE_LASER = 'Laser'
export const SESSION_EVENT_TYPE_DRAWING = 'Drawing'
export const SESSION_EVENT_TYPE_SELECT = 'Select'
