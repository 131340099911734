import React from 'react'
import { Navigate, RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom'
import Context from './ContextLoading'
import DevicesDashboard from './modules/devices/DevicesDashboard'
import GeneralSettings from './modules/accounts/GeneralSettings'
import FirebaseSettings from './modules/push-notifications/FirebaseSettings'
import RedactionSettings from './modules/redaction/RedactionSettings'
import AuditSettings from './modules/audit/AuditSettings'
import TeamSettings from './modules/accounts/TeamSettings'
import IntegrationSettings from './modules/accounts/IntegrationSettings'
import BillingSettings from './modules/billing/BillingSettings'
import DeleteAccountSettings from './modules/accounts/DeleteAccountSettings'
import SessionHistoryDashboard from './modules/sessions/SessionHistoryDashboard'
import SessionScreen from './modules/sessions/SessionScreen'
import SessionRecordingScreen from './modules/recordings/SessionRecordingScreen'
import RenderScreen from './modules/recordings/RenderScreen'
import ConnectDevice from './modules/devices/ConnectDevice'
import FindDevice from './modules/devices/FindDevice'
import JoinScreen from './modules/accounts/JoinScreen'
import CodeEntryScreen from './modules/sessions/CodeEntryScreen'
import PresentMode from './modules/present/PresentMode'
import AdminDashboard from './modules/admin/AdminDashboard'
import AdminConfiguration from './modules/admin/AdminConfiguration'
import EnterpriseLicenseDashboard from './modules/admin/EnterpriseLicenseDashboard'
import WelcomeTutorial, { WelcomeTutorialStage } from './modules/welcome/WelcomeTutorial'
import LoginScreen from './modules/auth/LoginScreen'
import RegisterScreen from './modules/auth/RegisterScreen'
import LoginSuccessScreen from './modules/auth/LoginSuccessScreen'
import SAMLLogin from './modules/auth/SAMLLogin'
import MagicLinkRedirect from './modules/auth/MagicLinkRedirect'
import ZendeskLoginScreen from './modules/zendesk/ZendeskLoginScreen'
import SiteMap from './modules/site-map/SiteMap'
import Layout from './routing/Layout'
import env from './utils/env'

function ctx (Cmp, required) {
  return <Context component={Cmp} required={required} />
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Navigate to='/dashboard' replace />
        },
        {
          path: '/login',
          element: <Outlet />, // TODO: remove this once we have proper layouts
          children: [
            {
              index: true,
              element: <LoginScreen />
            },
            {
              path: 'saml/:provider',
              element: <SAMLLogin />
            },
            {
              path: 'magic/:token',
              element: <MagicLinkRedirect />
            },
            {
              path: 'zendesk',
              element: <ZendeskLoginScreen />
            },
            {
              path: 'success',
              element: <LoginSuccessScreen />
            }
          ]
        },
        {
          path: '/register',
          element: <RegisterScreen />
        },
        {
          path: '/join/:token',
          element: <JoinScreen />
        },
        {
          path: '/render',
          element: <RenderScreen />
        },
        {
          path: '/dashboard',
          element: <Outlet />, // TODO: remove this once we have proper layouts
          children: [
            {
              index: true,
              element: ctx(DevicesDashboard)
            },
            {
              path: 'history',
              element: ctx(SessionHistoryDashboard)
            },
            {
              path: 'present',
              element: ctx(PresentMode)
            },
            {
              path: 'settings',
              element: <Outlet />, // TODO: remove this once we have proper layouts
              children: [
                {
                  index: true,
                  element: ctx(GeneralSettings)
                },
                {
                  path: 'team',
                  element: ctx(TeamSettings)
                },
                {
                  path: 'push',
                  element: ctx(FirebaseSettings)
                },
                {
                  path: 'redaction',
                  element: ctx(RedactionSettings)
                },
                {
                  path: 'integrations',
                  element: ctx(IntegrationSettings)
                },
                {
                  path: 'billing',
                  element: ctx(BillingSettings)
                },
                {
                  path: 'audit',
                  element: ctx(AuditSettings)
                },
                {
                  path: 'delete',
                  element: ctx(DeleteAccountSettings)
                }
              ]
            }
          ]
        },
        {
          path: '/code',
          element: ctx(CodeEntryScreen)
        },
        {
          path: '/connect',
          element: <Outlet />, // TODO: remove this once we have proper layouts
          children: [
            {
              index: true,
              element: ctx(FindDevice)
            },
            {
              path: 'device/:device',
              element: ctx(ConnectDevice)
            }
          ]
        },
        {
          path: '/session/:id',
          element: <Outlet />, // TODO: remove this once we have proper layouts
          children: [
            {
              index: true,
              element: ctx(SessionScreen)
            },
            {
              path: 'recording',
              element: ctx(SessionRecordingScreen)
            }
          ]
        },
        {
          path: '/admin',
          element: <Outlet />, // TODO: remove this once we have proper layouts
          children: [
            {
              index: true,
              element: ctx(AdminDashboard, { account: false })
            },
            {
              path: 'configuration',
              element: ctx(AdminConfiguration, { account: false })
            },
            {
              path: 'license',
              element: ctx(EnterpriseLicenseDashboard, { account: false })
            }
          ]
        },
        {
          path: '/welcome',
          element: <Outlet />, // TODO: remove this once we have proper layouts
          children: [
            {
              index: true,
              element: ctx(WelcomeTutorial)
            },
            {
              path: ':stage',
              element: ctx(WelcomeTutorialStage)
            }
          ]
        }
      ]
    },
    { path: '/site-map', element: ctx(SiteMap) },
    { path: '*', element: <Navigate to='/dashboard' replace /> }
  ],
  {
    basename: env.COBROWSE_BASENAME
  }
)

const App = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default App
