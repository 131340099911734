import URL from 'url-parse'
import createReducers from './reducers'
import { resetGlobalError, setGlobalError } from '../errors/Error.state'

export default class RESTObject {
  static namespace () {
    throw new Error('static namespace() is required')
  }

  static fetcher = window.fetch

  static statePath () {
    return this.namespace()
      .replace(/^([A-Z])/, (char) => `${char.toLowerCase()}`)
      .replace(/([A-Z])/g, (char) => `_${char}`)
      .toLowerCase()
  }

  static url (params = {}) {
    throw new Error('url is required', params)
  }

  static get types () {
    return {
      RESET: `${this.namespace()}/RESET`.toUpperCase(),
      CACHE: `${this.namespace()}/CACHE`.toUpperCase(),
      CREATE: `${this.namespace()}/CREATE`.toUpperCase(),
      GET: `${this.namespace()}/GET`.toUpperCase(),
      UPDATE: `${this.namespace()}/UPDATE`.toUpperCase(),
      DELETE: `${this.namespace()}/DELETE`.toUpperCase()
    }
  }

  static idFor (resource) {
    return resource.id
  }

  static _url (params = {}, context = {}) {
    // strip trailing slashes from URLs
    const url = new URL(`${this.baseurl || ''}${this.url(params, context)}`)
    if (/\/$/.test(url.pathname)) { url.set('pathname', url.pathname.slice(0, -1)) }
    return url.toString()
  }

  static async fetch (type, resource, context, options = {}) {
    const url = this._url(resource, context)
    return this.fetcher(url, {
      body: this.canHaveBody(type) ? JSON.stringify(resource) : undefined,
      headers: { ...this.headers(), ...options.headers },
      method: this.methodForType(type),
      credentials: 'same-origin',
      signal: options.signal
    })
  }

  static _request (type, resource, context, options = {}) {
    return async dispatch => {
      dispatch({ type, state: 'pending', resource, options })

      // console.log('request', type, url, fetchOptions, context);

      try {
        const res = await this.fetch(type, resource, context, options)
        const body = res.status === 204 ? undefined : await res.json()
        dispatch({
          type,
          initial_resource: resource,
          state: res.ok ? 'resolved' : 'rejected',
          options,
          resource: body,
          status: res.status
        })

        if (res.ok) {
          dispatch(resetGlobalError())
        } else {
          dispatch(setGlobalError({
            id: body.id,
            message: body.message
          }))
        }

        return body
      } catch (err) {
        if (options.signal?.aborted) {
          return
        }

        console.log(err)
        dispatch({
          type, state: 'rejected', resource: null, err, options
        })

        if (window.navigator.onLine) {
          dispatch(setGlobalError({
            id: 'network_failure',
            message: 'Network error'
          }))
        } else {
          dispatch(setGlobalError({
            id: 'network_offline',
            message: 'You seem to be offline. Please check your internet connection and try again.'
          }))
        }
      }
    }
  }

  static get (resource, context, options) {
    return this._request(this.types.GET, resource, context, options)
  }

  static create (resource, context, options) {
    return this._request(this.types.CREATE, resource, context, options)
  }

  static update (resource, context, options) {
    return this._request(this.types.UPDATE, resource, context, options)
  }

  static destroy (resource, context, options) {
    return this._request(this.types.DELETE, resource, context, options)
  }

  static cache (resource, options) {
    return { type: this.types.CACHE, resource, options }
  }

  static reset () {
    return { type: this.types.RESET }
  }

  static fromState (state) {
    return state[this.statePath()]
  }

  static reducer () {
    return createReducers(this)
  }

  static methodForType (type) {
    if (type === this.types.CREATE) return 'POST'
    if (type === this.types.UPDATE) return 'PUT'
    if (type === this.types.DELETE) return 'DELETE'
    return 'GET'
  }

  static canHaveBody (type) {
    if (type === this.types.CREATE) return true
    if (type === this.types.UPDATE) return true
    if (type === this.types.DELETE) return true
    return false
  }

  static headers () {
    return this._headers || {}
  }

  static unsetHeader (header) {
    if (this._headers) delete this._headers[header]
  }

  static setHeader (header, value) {
    if (!this._headers) this._headers = {}
    this._headers[header] = value
  }

  static actionCreators () {
    return {
      [`get${this.namespace()}`]: this.get.bind(this),
      [`get${this.namespace()}s`]: this.get.bind(this),
      [`create${this.namespace()}`]: this.create.bind(this),
      [`update${this.namespace()}`]: this.update.bind(this),
      [`remove${this.namespace()}`]: this.destroy.bind(this),
      [`cache${this.namespace()}`]: this.cache.bind(this),
      [`reset${this.namespace()}`]: this.reset.bind(this)
    }
  }
}

// populate some defaults
RESTObject.setHeader('Accept', 'application/json')
RESTObject.setHeader('Content-Type', 'application/json')
