import React, { useEffect, useState } from 'react'
import moment from 'moment'

const Timestamp = ({ timestamp }) => {
  const [, setRenderCount] = useState(0)

  useEffect(() => {
    const diff = moment(Date.now()).diff(moment(timestamp))
    const interval = Math.min(Math.max(30 * 1000, (diff / 4)), 10 * 60 * 1000)
    const timeout = setTimeout(() => setRenderCount((renderCount) => ++renderCount), interval)

    return () => clearTimeout(timeout)
  }, [timestamp])

  return <time dateTime={timestamp} title={timestamp}>{moment(timestamp).fromNow()}</time>
}

export default Timestamp
