import RESTObject from '../redux-rest'

export default class MemberLicense extends RESTObject {
  static namespace () {
    return 'MemberLicense'
  }

  static url () {
    return '/api/1/accounts/licenses'
  }
}
