import React, { useRef, useEffect, useCallback } from 'react'
import JMuxer from 'jmuxer'
import { getTestId } from '../../utils/getTestId'
import Debug from 'debug'

const debug = Debug('cbio.screens.videojmuxer')

const append = function (source, addition) {
  const appended = new Uint8Array(source.byteLength + addition.byteLength)
  appended.set(source, 0)
  appended.set(addition, source.byteLength)
  return appended
}

const VideoScreen = ({ display, onSize }) => {
  const preReadyBufferRef = useRef()
  const jmuxerRef = useRef()
  const videoRef = useRef()

  useEffect(() => {
    preReadyBufferRef.current = new Uint8Array()

    jmuxerRef.current = new JMuxer({
      node: videoRef.current,
      mode: 'video',
      flushingTime: 10,
      clearBuffer: true,
      fps: 10,
      debug: debug.enabled,
      onReady: () => {
        if (jmuxerRef.current?.mediaSource) {
          jmuxerRef.current.mediaSource.duration = Infinity
        }

        jmuxerRef.current?.feed({ video: preReadyBufferRef.current })
        preReadyBufferRef.current = null
      }
    })

    return () => {
      preReadyBufferRef.current = null
      jmuxerRef.current.destroy()
    }
  }, [])

  const skipAhead = useCallback(() => {
    if (videoRef.current.buffered.length) {
      videoRef.current.currentTime = videoRef.current.buffered.end(videoRef.current.buffered.length - 1)
      videoRef.current.play().catch((e) => { console.error(e) })
    }
  }, [])

  const notifySize = useCallback((width, height) => {
    if (onSize) {
      onSize({
        height,
        width
      })
    }
  }, [onSize])

  const onTimeUpdate = useCallback(() => {
    if (videoRef.current?.buffered.length) {
      const delay = videoRef.current.buffered.end(videoRef.current.buffered.length - 1) - videoRef.current.currentTime
      if (delay > 1) skipAhead()
      else if (delay > 0.7) videoRef.current.playbackRate = 5
      else if (delay > 0.5) videoRef.current.playbackRate = 3
      else if (delay > 0.2) videoRef.current.playbackRate = 2
      else videoRef.current.playbackRate = 1
    }
  }, [skipAhead])

  const onFrame = useCallback((frame) => {
    const data = frame.data || frame.image

    if (preReadyBufferRef.current) {
      preReadyBufferRef.current = append(preReadyBufferRef.current, data)
    } else {
      try {
        jmuxerRef.current.feed({ video: data })
        notifySize(videoRef.current.videoWidth, videoRef.current.videoHeight)

        if (videoRef.current.paused) skipAhead()
      } catch (e) {
        console.error(e)
      }
    }
  }, [notifySize, skipAhead])

  const onFrames = useCallback((frames) => {
    frames.read('video/').forEach((frame) => {
      onFrame(frame)
    })
  }, [onFrame])

  useEffect(() => {
    const video = videoRef.current

    display.frames.on('frame', onFrames)
    video?.addEventListener('timeupdate', onTimeUpdate)

    return () => {
      display.frames.off('frame', onFrames)
      video?.removeEventListener('timeupdate', onTimeUpdate)
    }
  }, [onFrames, onTimeUpdate, display.frames])

  return (
    <video id='player' className='VideoScreen' muted autoPlay disablePictureInPicture style={{ height: '100%' }} ref={videoRef} {...getTestId('videoscreen-player')} />
  )
}

export default VideoScreen
