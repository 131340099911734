import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const Loading = ({ className, spinnerClassName, children }) => {
  return (
    <div className={mergeClassNames('flex flex-col items-center justify-center gap-y-2', className)}>
      {/* <div className={mergeClassNames('w-6 h-6 grid animate-spinner-front before:border-4 before:border-t-black before:border-r-black before:border-b-[#0000] before:border-l-[#0000] before:rounded-full before:mix-blend-darken before:animate-spinner-back before:[grid-area:1/1] after:border-4 after:border-t-[#0000] after:border-r-[#0000] after:border-b-[#B1B2C3] after:border-l-[#B1B2C3] after:rounded-full after:mix-blend-darken after:animate-spinner-back after:[grid-area:1/1] after:[animation-direction:reverse]', spinnerClassName)} /> */}
      {children && (
        <div className='text-lg'>
          {children}
        </div>
      )}
    </div>
  )
}

export default Loading
