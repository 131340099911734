import React from 'react'
import { useTranslation } from 'react-i18next'
import DeviceInfo from '../devices/DeviceInfo'
import Member from '../accounts/Member'
import TimelineEvent from '../../components/TimelineEvent'
import Timestamp from '../../components/Timestamp'
import UserIcon from '../users/UserIcon'
import Icon from '../../components/Icon'
import moment from 'moment'
import IconContainer from '../../components/IconContainer'

const SOURCE_USER = 'user'
const SOURCE_DEVICE = 'device'
const SOURCE_SERVICE = 'service'

const AuditEvent = ({ event, variant = 'audit' }) => {
  const { t } = useTranslation()

  const getGenericChanges = (event) => {
    const changed = { ...event.changed }

    if (event.resource_type === 'Session') {
      delete changed.device
      delete changed.custom_data
      delete changed.recording
    }

    if (event.resource_type === 'AccountMember') {
      delete changed.member
      delete changed.role
    }

    // do not print any changes for JoinToken events. They are not relevant
    if (event.resource_type === 'JoinToken') {
      return {}
    }

    return changed
  }

  const getTranslationTokenForRole = (role) => {
    switch (role) {
      case 'administrator':
        return t('Administrator')
      case 'agent':
        return t('Support agent')
      default:
        return role
    }
  }

  const getTranslationTokenForSource = (source) => {
    switch (source) {
      case SOURCE_DEVICE:
        return t('Device')
      case SOURCE_SERVICE:
        return t('The server')
      case SOURCE_USER:
        return t('User')
      default:
        return source
    }
  }

  const renderEventType = (event) => {
    switch (event.name) {
      case 'update':
        return t('updated')
      case 'delete':
        return t('removed')
      case 'create':
        return t('created')
      default:
        return t('changed')
    }
  }

  const renderIcon = (event) => {
    if (event.user && event.source === SOURCE_USER) {
      return <UserIcon user={event.user} />
    } else {
      return (
        <IconContainer className='text-black bg-grey'>
          <Icon type={event.source === SOURCE_DEVICE ? 'mobile' : 'cloud'} className='h-6' />
        </IconContainer>
      )
    }
  }

  const renderResource = (event) => {
    switch (event.resource_type) {
      case 'AccountMember':
        return t('account access')
      case 'Account':
        return t('account settings')
      case 'Session':
        return t('a session')
      case 'SAMLProvider':
        return t('SAML configuration')
      case 'JoinToken': {
        const dateDiff = moment(Date.now()).diff(moment(event.timestamp))

        return (
          <>
            <span>
              {`${t('a join link with the role of')} ${t(getTranslationTokenForRole(event.changed.role))} ${dateDiff <= 0 ? t('that expired') : t('expiring in')} `}
            </span>
            <Timestamp timestamp={event.changed.expires} />
          </>
        )
      }
      default:
        return event.resource_type
    }
  }

  const renderAccountMemberChanges = (changes) => {
    if (!changes.member) {
      return <p>{t('This user has been deleted.')}</p>
    }

    return <Member member={{ ...changes.member, role: changes.role }} readOnly />
  }

  const renderSessionChanges = (changes = {}) => {
    if (changes.device || changes.custom_data) {
      return <DeviceInfo showAll key='data' customData={changes.custom_data} device={changes.device} />
    } else if (changes.recording === null) {
      return <div>{t('The session recording was deleted.')}</div>
    } else return null
  }

  const renderChanges = (event) => {
    switch (event.resource_type) {
      case 'AccountMember':
        return renderAccountMemberChanges(event.changed)
      case 'Session':
        return renderSessionChanges(event.changed)
      default:
        return null
    }
  }

  const renderAction = (event) => {
    return (
      <span className='text-base font-normal'>
        {renderEventType(event)}
        {' '}
        {renderResource(event)}
      </span>
    )
  }

  const renderTitle = (event) => {
    if (event.user && event.source === SOURCE_USER) {
      return (
        <span>
          {event.user.name} ({event.user.email}){' '}
          {renderAction(event)}
        </span>
      )
    } else {
      return <span>{getTranslationTokenForSource(event.source)} {renderAction(event)}</span>
    }
  }

  const renderSubtitle = (event) => {
    return (
      <span>
        <Timestamp timestamp={event.timestamp} />
        {event.ip ? <span> {t('from IP')}: {event.ip}</span> : null}
      </span>
    )
  }

  return (
    <TimelineEvent
      icon={renderIcon(event)}
      title={renderTitle(event)}
      subtitle={renderSubtitle(event)}
      changes={getGenericChanges(event)}
      variant={variant}
    >
      {renderChanges(event)}
    </TimelineEvent>
  )
}

export default AuditEvent
