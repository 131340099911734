import RESTObject from '../redux-rest'

export default class PushNotification extends RESTObject {
  static namespace () {
    return 'PushNotification'
  }

  static url (params = {}) {
    return `/api/1/devices/${params.device}/notifications`
  }
}
