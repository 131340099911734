import React, { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import CopyText from '../../components/CopyText'
import SelectOption from '../../components/SelectOption'
import Select from '../../components/Select'
import Timestamp from '../../components/Timestamp'
import Alert from '../../components/Alert'

export function GenerateJoinLink ({ createJoinToken, defaultRole = 'agent' }) {
  const [role, setRole] = useState(defaultRole)
  const [hasFailed, setHasFailed] = useState(false)
  const [joinTokenResponse, setJoinTokenResponse] = useState(null)
  const selectId = useId()
  const { t } = useTranslation()

  const getJoinToken = () => {
    setHasFailed(false)
    createJoinToken({ role })
      .then((res) => {
        if (res.statusCode != null && res.statusCode !== 200) {
          setHasFailed(true)
          return
        }

        setJoinTokenResponse(res)
      }).catch((err) => {
        console.debug('Failed to create join_token', err)
        // display an error to the user
        setHasFailed(true)
      })
  }

  return (
    <div className='flex flex-col gap-y-4'>
      <div className='flex flex-col gap-4 sm:flex-row'>
        <label htmlFor={selectId} className='sr-only'>{t('Role')}</label>
        <Select
          id={selectId}
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className='w-full'
        >
          <SelectOption value='agent'>{t('Support agent')}</SelectOption>
          <SelectOption value='administrator'>{t('Administrator')}</SelectOption>
        </Select>
        <Button onClick={getJoinToken} className='min-w-fit'>{t('Generate join link')}</Button>
      </div>
      {hasFailed && <Alert type='alert'>{t('Failed to create join link. Please try again.')}</Alert>}
      {joinTokenResponse !== null && (
        <div>
          <div className='flex items-center'>
            <div className='px-3 py-2 bg-grey text-base text-black/60 border-2 retina:border-1.5 border-r-0 border-grey rounded-s select-none min-w-max'>{joinTokenResponse.role === 'agent' ? t('Support agent') : t('Administrator')}</div>
            <div className='rounded-s-none w-full'>
              <CopyText
                variant='input'
                className='rounded-s-none'
              >
                {window.location.origin}/join/{joinTokenResponse.join_token}
              </CopyText>
            </div>
          </div>
          <Alert type='warning' variant='inline'>
            {t('This join link will expire')}{' '}<Timestamp timestamp={joinTokenResponse.expires} />.
          </Alert>
        </div>
      )}
    </div>
  )
}
