import React, { useEffect, useRef } from 'react'
import flatpickr from 'flatpickr'
import Input from './Input'
import 'flatpickr/dist/themes/light.css'
import './DateRangePicker.css'

const DatePicker = ({ selectedDate, minDate, inline = false, onChange, ...props }) => {
  const datePickerRef = useRef()

  useEffect(() => {
    const fp = flatpickr(datePickerRef.current, {
      inline,
      defaultDate: selectedDate,
      minDate,
      onChange: (dates) => dates.length === 1 && onChange(
        dates[0]
      )
    })

    fp.setDate(selectedDate)
  }, [selectedDate, inline, minDate, onChange])

  const Component = inline ? 'div' : Input

  return (
    <Component ref={datePickerRef} {...props} />
  )
}

export default DatePicker
