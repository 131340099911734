import { EventEmitter } from 'events'
export const UPDATE_FRAME = 'frames/UPDATE'
export const RESET_FRAMES = 'frames/RESET'

const initialState = { }

function displayId (frame) {
  return frame.display || 'default'
}

function frameFilter (source, frame, display) {
  const emitter = new EventEmitter()
  let buffer = []
  emitter.read = (mime) => {
    const b = buffer.filter(f => f.mime_type.startsWith(mime))
    buffer = buffer.filter(f => !f.mime_type.startsWith(mime))
    return b
  }
  const forward = (f) => {
    if (displayId(f) === display) {
      buffer.push(f)
      emitter.emit('frame', emitter)
    }
  }
  source.on('frame', forward)
  forward(frame)
  return emitter
}

export default function displays (state = initialState, action) {
  switch (action.type) {
    case UPDATE_FRAME: {
      const existing = state[displayId(action.frame)]
      const display = displayId(action.frame)
      return {
        ...state,
        [display]: {
          frames: (existing && existing.frames) || frameFilter(action.source, action.frame, display),
          mime_type: action.frame.mime_type
        }
      }
    }

    case RESET_FRAMES:
      return { ...initialState }

    default:
      return state
  }
}

export const updateFrame = (frame, source) => {
  return {
    type: UPDATE_FRAME,
    frame,
    source
  }
}

export const resetFrames = () => {
  return {
    type: RESET_FRAMES
  }
}
