import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CopyText from '../../components/CopyText'
import Switch from '../../components/Switch'
import Input from '../../components/Input'
import SettingsPage from '../accounts/SettingsPage'
import Account from './Account.state'
import User from '../users/User.state'
import FeatureSwitches from './FeatureSwitches.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import Label from '../../components/Label'
import { getTestId } from '../../utils/getTestId'
import SessionConfig from '../sessions/SessionConfig.state'
import Alert from '../../components/Alert'
import Icon from '../../components/Icon'

const GeneralSettings = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => Account.activeAccount(state))
  const user = useSelector(state => User.fromState(state))
  const features = useSelector(state => FeatureSwitches.fromState(state))
  const sessionConfig = useSelector(state => SessionConfig.fromState(state))
  const [universalProxyEnabled, setUniversalProxyEnabled] = useState(false)
  const orgName = useRef()
  const userName = useRef()
  const { t } = useTranslation()

  useEffect(() => {
    if (Account.hasFeature(account.resource, 'manage_account')) {
      dispatch(FeatureSwitches.actionCreators().getFeatureSwitches())
    }
  }, [account.resource, dispatch])

  useEffect(() => {
    const isEnabled = features.resource?.universal_proxy?.enabled !== false
    setUniversalProxyEnabled(isEnabled)
    if (isEnabled) dispatch(SessionConfig.actionCreators().getSessionConfig())
  }, [features.resource, dispatch])

  const updateUserInfo = () => {
    return dispatch(User.actionCreators().updateUser({
      id: 'me',
      name: userName.current.value
    }))
  }

  const updateAccountInfo = () => {
    return dispatch(Account.actionCreators().updateAccount({
      id: account.resource.id,
      organisation_name: orgName.current.value
    }))
  }

  const updateFeatureSwitch = (feature, state) => {
    return dispatch(FeatureSwitches.actionCreators().updateFeatureSwitches({ [feature]: { enabled: state } }))
      .then(() => dispatch(Account.actionCreators().getAccounts()))
  }

  const renderUserInfoForm = () => {
    if (!account.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('User settings')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>{t('Change details about your user account here.')}</SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
          <SettingsGroup.Button
            thinking={user.working}
            onClick={updateUserInfo}
          >
            {t('Save')}
          </SettingsGroup.Button>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <Label htmlFor='name'>{t('Display name')}</Label>
          <Input
            type='text'
            id='name'
            placeholder='John Smith'
            defaultValue={user.resource.name}
            autoComplete='on'
            ref={userName}
          />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderBasicInfoForm = () => {
    if (!account.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Account settings')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>{t('You can name your account here.')}</SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
          <SettingsGroup.Button
            thinking={account.working}
            onClick={updateAccountInfo}
          >
            {t('Save')}
          </SettingsGroup.Button>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <Label htmlFor='organisation_name'>{t('Company name')}</Label>
          <Input
            type='text'
            id='organisation_name'
            placeholder='Acme Inc.'
            defaultValue={account.resource.organisation_name}
            ref={orgName}
          />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderLicenseKey = () => {
    if (!account.resource) {
      return null
    }
    if (!account.resource.license_key) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('License key')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t("This is your license key. You'll need to add it to your apps to associate Cobrowse sessions with your account.")}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          <CopyText
            variant='input'
            trim
            label={t('License key')}
            labelStyle='hidden'
            valueClassName='font-mono'
          >
            {account.resource.license_key}
          </CopyText>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderFeatureSwitch = (name, label, description, options = { invert: false }) => {
    const state = { ...features.resource[name] } || {}

    if (options.invert) {
      const enabled = state.enabled !== false
      state.enabled = !enabled
    }

    const checked = state.enabled !== false

    return (
      <div>
        <Switch
          name={name}
          label={label}
          checked={checked}
          onChange={(feature, state) => {
            updateFeatureSwitch(feature, options.invert ? !state : state)
          }}
          disabled={options.disabled}
          {...getTestId(`switch-${name}`)}
        >
          <span className='text-sm text-black/75 first-letter:capitalize'>{description}</span>
        </Switch>
      </div>
    )
  }

  const renderSessionFeatureSwitches = () => {
    if (!features.resource) {
      return null
    }

    const remoteControl = features.resource.remote_control || {}

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Session settings')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t('Configure what your agents can do when in a screen sharing session.')}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body className='flex flex-col gap-y-5 md:gap-y-8'>
          {renderFeatureSwitch('session_push', t('Agent initiated sessions'), t('can your support agents initiate a screen share session with a user?'))}
          {renderFeatureSwitch('session_consent', t('Require consent for connection'), t('should your users be required to accept a screen share before it starts?'))}
          {renderFeatureSwitch('full_device', t('Full device upgrade'), t('can your support agents request access to screens outside your app?'))}
          {renderFeatureSwitch('remote_control', t('Remote control'), t('can your support agents remotely control devices?'))}
          {renderFeatureSwitch('remote_control_consent', t('Require consent for remote control'), t('is consent required before using remote control tools?'), { disabled: remoteControl.enabled === false })}
          {renderFeatureSwitch('session_recording', t('Record sessions'), t('should we record sessions for playback and review?'))}
          {renderFeatureSwitch('session_ratings', t('Session ratings'), t('should we collect feedback from agents on session performance?'))}
          {renderFeatureSwitch('present_mode', t('Present'), t('can your support agents share their screen with users?'))}
          {renderFeatureSwitch('universal_proxy', t('Universal Cobrowse'), t('can your support agents follow users to certain 3rd party sites?'))}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderContentTypes = () => {
    if (!sessionConfig.resource) return null
    if (!universalProxyEnabled) return null

    const listTextHtmlUrls = ({ 'text/html': urls } = {}) => {
      if (!urls?.length) return <div className='text-black/60 mb-4'>{t('No sites are allowed')}</div>
      if (urls.includes('*')) return <div className='text-black/60 mb-4'>{t('All sites are allowed')}</div>
      return <ul>{urls.map((url, index) => <li key={index} className='flex flex-row items-center mb-4'><Icon type='browser' className='mr-2' />{url}</li>)}</ul>
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Universal Cobrowse sites')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t('The sites that are allowed through Universal Cobrowse.')}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body>
          {listTextHtmlUrls(sessionConfig.resource.content_types)}
          <Alert className='pt-3 pb-3' type='info'>To change this setting please contact your sales representative or account manager to request domain validation.</Alert>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderDeviceFeatureSwitches = () => {
    if (!features.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Device settings')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t('Configure behaviour and access for your devices.')}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body className='flex flex-col gap-y-5 md:gap-y-8'>
          {renderFeatureSwitch('device_sockets', t('Device sockets'), t('Required for online/offline presence and agent initiated sessions without Firebase push'))}
          {renderFeatureSwitch('device_listing', t('Device listing'), t('Allow agents to list devices that have registered into your account?'))}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderPrivacySwitches = () => {
    if (!features.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Header>
          <SettingsGroup.HeaderTitle>
            <SettingsGroup.Title>{t('Privacy settings')}</SettingsGroup.Title>
            <SettingsGroup.HelpText>
              {t('Control privacy settings for your account.')}
            </SettingsGroup.HelpText>
          </SettingsGroup.HeaderTitle>
        </SettingsGroup.Header>
        <SettingsGroup.Body className='flex flex-col gap-y-5 md:gap-y-8'>
          {renderFeatureSwitch('agent_emails', t('Hide agent emails'), t('should the agent email be hidden from the SDKs?'), { invert: true })}
          {renderFeatureSwitch('store_ips', t('Discard IP addresses'), t('should Cobrowse discard IP addresses in the audit trail?'), { invert: true })}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderAccountSettings = () => {
    return (
      <>
        {renderUserInfoForm()}
        {Account.hasFeature(account.resource, 'manage_account')
          ? (
            <>
              {renderBasicInfoForm()}
              {renderLicenseKey()}
              {renderSessionFeatureSwitches()}
              {renderContentTypes()}
              {renderDeviceFeatureSwitches()}
              {renderPrivacySwitches()}
            </>
            )
          : (
            <div className='text-center mt-12'>
              <div><b>{t('Looking for more account settings?')}</b></div>
              <p>{t('Ask your account administrator to grant you more permissions if you need to change account wide settings.')}</p>
            </div>
            )}
      </>
    )
  }

  return (
    <SettingsPage>
      <SettingsPage.Title>
        {t('General')}
      </SettingsPage.Title>
      <SettingsPage.Body>
        {renderAccountSettings()}
      </SettingsPage.Body>
    </SettingsPage>
  )
}

export default GeneralSettings
