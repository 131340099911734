export const SET_GLOBAL_ERROR = 'global_error/SET'
export const RESET_GLOBAL_ERROR = 'global_error/RESET'

const initialState = { }

export default function globalError (state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL_ERROR:
      return { ...state, ...action.state }
    case RESET_GLOBAL_ERROR:
      return initialState
    default:
      return state
  }
}

export const setGlobalError = (state) => {
  return {
    type: SET_GLOBAL_ERROR,
    state
  }
}

export const resetGlobalError = () => {
  return {
    type: RESET_GLOBAL_ERROR
  }
}
