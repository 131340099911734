import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DeviceView from './Device'
import ErrorMessage from '../errors/ErrorMessage'
import LoadingScreen from '../../components/LoadingScreen'
import Device from './Device.state'
import FilterParams from '../filters/FilterParams'
import EmbedParams from '../../utils/EmbedParams'
import usePageTitle from '../../hooks/usePageTitle'

const Container = ({ children }) => {
  return (
    <div className='bg-grey-dark flex flex-col basis-full'>
      {children}
    </div>
  )
}

const FindDevice = () => {
  const [cancelled, setCancelled] = useState(false)
  const dispatch = useDispatch()
  const devices = useSelector(state => Device.fromState(state))
  const pollTimeout = useRef(null)
  const { t } = useTranslation()
  const navigate = useNavigate()

  usePageTitle(t('Find device'))

  const getPresence = (device) => dispatch(Device.actionCreators().getPresence(device))

  const pollDevices = useCallback(() => {
    if ((!FilterParams.search) && FilterParams.empty()) {
      return Promise.reject(new Error('No filter params'))
    }

    // limit to recently registered devices
    return dispatch(Device.actionCreators().getDevices(undefined, { ...FilterParams.parse(), filter: FilterParams.search, seen_after: EmbedParams.seenAfter() }))
      .then((devices) => {
        if (devices.length === 0) {
          pollTimeout.current = setTimeout(() => {
            pollDevices().catch(err => console.error('Device poll error: ', err))
          }, 3000)
        }
      })
  }, [dispatch])

  useEffect(() => {
    pollDevices().catch(err => console.error('Device poll error: ', err))

    return () => clearTimeout(pollTimeout.current)
  }, [pollDevices])

  const cancel = () => {
    clearTimeout(pollTimeout.current)

    if (EmbedParams.endAction() === 'none') {
      setCancelled(true)
    } else {
      navigate(EmbedParams.endRedirect())
    }
  }

  const devicesCollection = devices.collection

  useEffect(() => {
    if (devicesCollection.length === 1 || (EmbedParams.forceStart() && devicesCollection.length >= 1)) {
      const [device] = devicesCollection

      navigate(`/connect/device/${device.id}${window.location.search}`, { replace: true })
    }
  }, [devicesCollection, navigate])

  if ((!FilterParams.search) && FilterParams.empty()) {
    return (
      <Container>
        <ErrorMessage>{t('You must specify at least one filter parameter')}</ErrorMessage>
      </Container>
    )
  }

  if (cancelled) {
    return (
      <Container>
        <div className='container w-10/12 mx-auto h-full flex items-center justify-center'>
          <p>{t('Search stopped')}</p>
        </div>
      </Container>
    )
  }

  if (devices.working || (devicesCollection.length === 0)) {
    return (
      <Container>
        <LoadingScreen
          message={t('Searching for devices')}
          cancelText={t('Cancel')}
          onCancel={cancel}
        />
      </Container>
    )
  }

  if ((!EmbedParams.forceStart()) && devicesCollection.length > 1) {
    return (
      <Container>
        <h1 className='sr-only'>{t('Find device')}</h1>
        <div className='flex flex-col gap-y-4 p-4 sm:p-6'>
          <h2 className='text-h-md'>{t('Multiple devices found')}</h2>
          <p>{t('Multiple devices were found that match your filters. Select which one you would like to connect to:')}</p>
          <div className='flex flex-col gap-y-4 sm:gap-y-2'>
            {devicesCollection.map((device) => (
              <div className='group/list-item' key={device.id}>
                <DeviceView
                  device={device}
                  getPresence={getPresence}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    )
  }
}

export default FindDevice
