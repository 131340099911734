import React, { Component } from 'react'
import { createRoot } from 'react-dom/client'
import { SESSION_CAPABILITY_TYPE_SCROLL } from '../sessions/SessionCapabilityTypes'
import { CapabilitiesProvider } from './CapabilitiesContext'
import { PriorityScheduler } from '../../utils/priorityScheduler'
import { ToolProvider } from './ToolContext'
import { SelectedColorProvider } from './SelectedColorContext'

export default class Frame extends Component {
  root = null

  get scheduler () {
    return this.document()?.___scheduler
  }

  static isFrame = (n) => n?.matches?.('iframe')
  static isRemoteScreen = (n) => this.isFrame(n) && n.attributes['data-is-remote-screen']?.value === 'true'

  componentDidMount () {
    if (this.props.elRef) this.props.elRef(this.el)
    this.updateContent()
    this.interval = setInterval(this.updateContent, 50)
  }

  componentDidUpdate () {
    this.markDirty()
    this.updateContent()
  }

  componentWillUnmount () {
    clearInterval(this.interval)
    if (this.root) {
      setTimeout(() => {
        this.root.unmount()
        this.scheduler?.destroy()
      })
    }
  }

  markDirty = () => {
    const doc = this.document()
    if (doc) doc.clean = false
  }

  onFrameRef = (el) => {
    this.el = el
  }

  document = () => {
    try {
      return this.el?.contentWindow?.document
    } catch (e) {
      return false
    }
  }

  updateContent = () => {
    const doc = this.document()

    if (!doc) {
      // console.warn('iframe still initialising', this.el);
      return
    }

    if (doc.clean) {
      return
    }

    const { doctype } = this.props
    if (!doc.doctype && doctype) {
      doc.write(`${doctype}<html><head></head><body></body></html>`)
    }

    const reactChildren = this.props.children(this.el)
    if (!reactChildren) {
      // console.warn('iframe content not available yet');
      return
    }

    if (!doc || !doc.documentElement) {
      // console.warn('iframe html element not available yet');
      return
    }

    // Lazy-load the scheduler because there can be lots of empty frames created
    // but we don't want to inflate the number of schedulers created and muddies
    // ability to debug issues
    if (!Object.getOwnPropertyDescriptor(doc, '__scheduler')) {
      Object.defineProperty(doc, '__scheduler', {
        get: () => {
          return doc.___scheduler ?? (doc.___scheduler = new PriorityScheduler())
        }
      })
    }

    if (!doc.__root) {
      doc.__root = createRoot(doc)
      this.root = doc.__root
    }

    this.root.render(
      <CapabilitiesProvider capabilities={this.props.capabilities}>
        <ToolProvider tool={this.props.tool}>
          <SelectedColorProvider color={this.props.selectedColor}>
            {reactChildren}
          </SelectedColorProvider>
        </ToolProvider>
      </CapabilitiesProvider>
    )

    doc.clean = true

    // Prevent scrolling inside the iframe when no 'scrolling' capability is set
    if (this.props.capabilities?.[SESSION_CAPABILITY_TYPE_SCROLL] === false && this.el?.contentDocument?.body) {
      this.el.contentDocument.body.style = 'overflow: hidden; height: 100%;'
    }
  }

  render () {
    const { 'data-test-id': testId } = { ...this.props }

    return (
      <iframe
        title={this.props.title || 'safe-frame'}
        style={this.props.style}
        sandbox={this.props.sandbox}
        className={this.props.className}
        ref={this.onFrameRef}
        data-test-id={testId}
        data-is-remote-screen={this.props.isRemoteScreen}
      />
    )
  }
}
