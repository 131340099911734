import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { buttonClassName } from './Button'

const LinkButton = forwardRef(({ className, variant, children, ...props }, ref) => (
  <RouterLink ref={ref} className={buttonClassName(variant, className)} {...props}>
    {children}
  </RouterLink>
))

export default LinkButton
