export const CONNECTION_METRICS = 'connectivity/UPDATE_METRICS'

const initialState = {
  metrics: {
    last_alive: false,
    rtt: 0
  }
}

export default function connectivity (state = initialState, action) {
  switch (action.type) {
    case CONNECTION_METRICS:
      return { ...state, metrics: { ...state.metrics, ...action.metrics } }

    default:
      return state
  }
}

export const updateConnectionMetrics = (metrics) => {
  return {
    type: CONNECTION_METRICS,
    metrics
  }
}
