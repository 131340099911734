import React from 'react'
import { createPortal } from 'react-dom'

const BannerMessage = ({ children }) => {
  const renderMessage = () => {
    return (
      <div className='bg-black text-white/[.65] text-base px-6 py-3 order-none'>{children}</div>
    )
  }

  if (!children) return null

  return createPortal(
    renderMessage(),
    document.querySelector('#banners')
  )
}

export default BannerMessage
