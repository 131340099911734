export const unproxifyPath = (path) => {
  const { pathname, hash, searchParams } = new URL(path, 'http://placeholder.base')

  const parts = pathname.split('/')
  const protocolIndex = parts.findIndex(part => part === 'http:' || part === 'https:')
  const protocol = parts[protocolIndex]
  const hostParts = parts.slice(1, protocolIndex).reverse().map(decodeURIComponent)
  const pathParts = parts.slice(protocolIndex + 1, parts.length)

  const search = searchParams.get('search')
  const port = searchParams.get('port')
  const username = searchParams.get('username')
  const password = searchParams.get('password')

  const targetHost = hostParts.join('.')
  const targetPath = pathParts.join('/')
  const searchWithPrefix = search ? `?${search}` : ''
  const portWithPrefix = port ? `:${port}` : ''
  const credentials = username && password ? `${username}:${password}@` : ''

  return new URL(`${protocol}//${credentials}${targetHost}${portWithPrefix}/${targetPath}${searchWithPrefix}${hash}`)
}
