import { debounce } from 'lodash'
import { useEffect, useRef, useMemo } from 'react'

const useDebounce = (fn, delay = 500) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = fn
  }, [fn])

  const debouncedFn = useMemo(() => {
    const func = (...args) => {
      ref.current?.(...args)
    }

    return debounce(func, delay)
  }, [delay])

  return debouncedFn
}

export default useDebounce
