import React from 'react'
import Icon from '../../components/Icon'
import IconContainer from '../../components/IconContainer'

const UserIcon = ({ className, user, expandable = false }) => {
  const initials = () => {
    if (!user) return ''
    if (!user.name) return ''
    if (user.picture) return ''

    return user.name.split(' ')
      .map(i => i.toUpperCase().charAt(0))
      .join('')
      .substring(0, 2)
  }

  if (!user) {
    return null
  }

  return (
    <IconContainer
      className={className}
      role='img'
      title='User profile'
      style={{
        backgroundImage: user.picture ? `url(${user.picture || ''})` : undefined,
        backgroundColor: user.colour
      }}
    >
      <span className='text-black'>{initials()}</span>
      {expandable && (
        <div className='absolute bottom-0 -end-1 rounded-full bg-white text-black w-3.5 h-3.5 flex flex-col justify-center items-center'>
          <Icon type='down-open' className='h-3.5' />
        </div>
      )}
    </IconContainer>
  )
}

export default UserIcon
